import { AfterViewInit, Directive } from '@angular/core';
declare var $: any

@Directive({
  selector: '[appProductSliders]'
})
export class ProductSlidersDirective implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    $('.product-image--large-horizontal').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
    });
    $('.product-image--thumb-horizontal').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      focusOnSelect: true,
      prevArrow: '<button type="button" class="gallery__nav gallery__nav-horizontal gallery__nav-horizontal--left prevArrow"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button type="button"  class="gallery__nav gallery__nav-horizontal gallery__nav-horizontal--right nextArrow"><i class="fas fa-chevron-right"></i></button>'
    });
  }

}
