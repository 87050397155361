import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-desifarms-pay-online',
  templateUrl: './desifarms-pay-online.component.html',
  styleUrls: ['./desifarms-pay-online.component.scss']
})
export class DesifarmsPayOnlineComponent implements OnInit {

  @ViewChild('payment') payment: ElementRef

  constructor() { }

  ngOnInit(): void {
    console.log(this.payment)
  }

}
