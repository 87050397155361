import { Component, OnInit } from '@angular/core';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';

@Component({
  selector: 'app-desifarms-dashboard',
  templateUrl: './desifarms-dashboard.component.html',
  styleUrls: ['./desifarms-dashboard.component.scss']
})
export class DesifarmsDashboardComponent implements OnInit {

  loginData: any

  constructor(
    private _commonService: DesifarmCommonService,
  ) { }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()
  }

}
