import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DesifarmCommonService } from '../desifarm-common/desifarm-common.service';

@Injectable({
  providedIn: 'root'
})
export class DesifarmLocalCartService {

  cartList = new BehaviorSubject<any[]>([]);
  searProductList = new BehaviorSubject<any[]>([]);
  searProductListCount = new BehaviorSubject<any>(0);
  placedOrderDetails = new BehaviorSubject<any>({});
  selectedDistrict = new BehaviorSubject<any>(null);
  selectedCity = new BehaviorSubject<any>(null);

  //SUBSCRIBE CART
  subscribeCartList = new BehaviorSubject<any[]>([]);

  constructor(
    private _commonService: DesifarmCommonService,
  ) { }

  getCartProducts() {
    if (this._commonService.getData("webCart") != null) {
      this.cartList.next(this._commonService.getData("webCart"))
    }
  }

  setCartProducts(ProductsArray) {
    this._commonService.setData("webCart", ProductsArray)
    this.getCartProducts()
  }

  resetCartProducts() {
    this._commonService.setData("webCart", [])
    this.getCartProducts()
  }

  // Subscribe cart 

  setSubscribeCartProducts(ProductsArray) {
    this._commonService.setData("webSubscribeCart", ProductsArray)
    this.getSubscribeCartProducts()
  }

  getSubscribeCartProducts() {
    if (this._commonService.getData("webSubscribeCart") != null) {
      this.subscribeCartList.next(this._commonService.getData("webSubscribeCart"))
    }
  }

  resetSubscribeCartProducts() {
    this._commonService.setData("webSubscribeCart", [])
    this.getCartProducts()
  }

  getSelectedDistrict() {
    if (localStorage.getItem(environment.selectedCity)) {
      const District = JSON.parse(localStorage.getItem(environment.selectedCity))
      this.selectedDistrict.next(District.District_Id)
      this.selectedCity.next(District)
    } else this.selectedDistrict.next(null)
  }

  setSelectedDistrict(data) {
    localStorage.setItem(environment.selectedCity, JSON.stringify(data))
    this.getSelectedDistrict()

    this.resetCartProducts()
    this.resetSubscribeCartProducts()
    this.reloadPage()

  }

  reloadPage() {
    this._commonService.router.navigate(['']).then(nav => {
      window.location.reload()
    })
    // if (this._commonService.router.url == '/') {
    // }
  }
}
