import { AfterViewInit, Directive } from '@angular/core';
declare var $: any

@Directive({
  selector: '[appProductImageZoom]'
})
export class ProductImageZoomDirective implements AfterViewInit {

  constructor() {
    $("#img-zoom").elevateZoom({
      gallery: "gallery-zoom",
      galleryActiveClass: "zoom-active",
      containLensZoom: true,
      zoomType: "inner",
      cursor: "crosshair"
    });
  }

  ngAfterViewInit() {
    /********************************
    *  Product Gallery - Image Zoom
    **********************************/
    $("#img-zoom").elevateZoom({
      gallery: "gallery-zoom",
      galleryActiveClass: "zoom-active",
      containLensZoom: true,
      zoomType: "inner",
      cursor: "crosshair"
    });
  }

}
