<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"><a routerLink="/">Home</a></li>
                    <li class="page-breadcrumb__nav active">Shipping Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<section class="mysec mb-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <p style="margin-top: 10px !important;">The Shipping Policy of SNA Milk & Milk Products Pvt. Ltd applies to all its subsidiaries and affiliated brands including NSK Store.</p>
                <p>While SNA Milk & Milk Products Pvt. Ltd endeavours to provide the most convenient delivery time as required by the consumer, SNA Milk & Milk Products Pvt. Ltd cannot commit any time fixed for delivery. The delivery of our products will be made between 8:00 am to 1:00 pm of the date. It may get delayed on certain days due to extreme weather conditions or governmental regulatory activities.</p>
                <p>Currently, the products will be delivered to addresses in Pune.</p>
                <p>Orders will be booked for at least third business day delivery.</p>
                <p>While placing the order the customer shall provide the following details:</p>
                <ul>
                    <li>Name of the Recipient</li>
                    <li>Flat Number/Bungalow Number</li>
                    <li>Name of the Building/Society</li>
                    <li>Name of the Road/Street/Marg</li>
                    <li>Contact Number Landline/Mobile</li>
                    <li>Proper/familiar Landmark</li>
                    <li>Email address</li>
                </ul>
                <p>After the order is accepted by SNA Milk & Milk Products Pvt. Ltd, a confirmation email or SMS, or call will be sent or made to the customer.</p>
                <p>The delivery will be made to the shipping address mentioned at the time of placing the order. The consignment will not be redirected/redelivered to any other address under any circumstances.</p>
                <p>SNA Milk & Milk Products Pvt. Ltd’s delivery agent will attempt delivery of the products once. In case the delivery is not executed during the attempt, the customer shall still be charged for the order and no redelivery attempt will be made.</p>
                <p>SNA Milk & Milk Products Pvt. Ltd will consider the order as “EXECUTED” in the following cases :</p>
                <ul class="mb-3">
                    <li>Delivery not done due to wrong address given by the customers</li>
                    <li>Non-availability of the recipients at the address;</li>
                    <li>Premises locked, Wrong phone number or phone not working or switched off or not reachable when tried by the delivery agent of SNA Milk & Milk Products Pvt. Ltd.</li>
                    <li>Addressee refused to accept the products.</li>
                    <li>Delivered the product at the security cabin of the building/Reception/Neighbour, based on the instructions given by the customer.</li>
                    <li>In case of civil disturbances, floods, Heavy Rains, a National strike/bandh, or any other major event, we reserve the right to cancel the order or reschedule the delivery to another date.</li>
                    <li>Request for cancellation of confirmed order can be made up to 48 hours before the delivery time.</li>
                </ul>
            </div>
        </div>
    </div>
</section>