<div class="wrapper">
  <div class="row">
    <div class="col-md-12" style="text-align: right;z-index: 999;">
      <span class="closecart" style="font-size:40px !important;
      color:gray !important;text-align: right !important;cursor: pointer;" aria-hidden="true"
        (click)="close()">&times;</span>
    </div>
    <div class="col-md-12">
      <h2 class="address-heading" *ngIf="!isEdit">Add New Address </h2>
      <h2 class="address-heading" *ngIf="isEdit">Edit Your Address</h2>

    </div>

  </div>

  <div style="border: 3px solid lightgray;
              padding: 15px;" class="mymodal">
    <form [formGroup]="form">
      <!-- <div class="form-box__single-group registerform">
        <label class="mylabel" for="form-register-username">Full Name *</label>
        <input formControlName="Full_Name" type="text" placeholder="Full Name" required>
      </div> -->
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno">First Name *</label>
            <input formControlName="First_Name" type="text" placeholder="First Name" disabled required spanceNotAllowed>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno">Last Name *</label>
            <input formControlName="Last_Name" type="text" placeholder="Last Name" disabled required>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno">Mobile No *</label>
            <input formControlName="Mobile_No_Address" type="text" placeholder="Mobile No" [class.is-invalid]="f.get('Mobile_No_Address').touched && f.get('Mobile_No_Address').invalid" disabled  required>
            <div class="invalid-feedback"
              *ngIf="f.get('Mobile_No_Address').hasError('required') && (f.get('Mobile_No_Address').touched || f.get('Mobile_No_Address').dirty)">
              Mobile No is required.
            </div>
            <div class="invalid-feedback"
              *ngIf="f.get('Mobile_No_Address').hasError('pattern') && (f.get('Mobile_No_Address').touched || f.get('Mobile_No_Address').dirty)">
              Only Number is Allowed.
            </div>
            <div class="invalid-feedback"
              *ngIf="f.get('Mobile_No_Address').hasError('maxlength') && (f.get('Mobile_No_Address').touched || f.get('Mobile_No_Address').dirty)">
              Only 9 Digit is Allowed.
            </div>
            <div class="invalid-feedback"
              *ngIf="f.get('Mobile_No_Address').hasError('minlength') && (f.get('Mobile_No_Address').touched || f.get('Mobile_No_Address').dirty)">
              Only 9 Digit is Allowed.
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-address-type">Address Type *</label>
            <select formControlName="Address_Type">
              <option value="" selected>Select Address Type</option>
              <option value="Home">Home </option>
              <option value="Office">Office </option>
              <option value="Other">Other </option>
            </select>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-city">Select Emirate *</label>
            <select formControlName="District_Id" (change)="onCity($event)">
              <option value=null selected disabled>Select Emirate</option>
              <option *ngFor="let option of districts" [value]="option.District_Id">
                {{option.District_Name}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">

          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno">Select Area *</label>
            <input list="area" (change)="onAreaSelection($event)" type="text" placeholder="Select Area"
              formControlName="Area_Name" required>
            <datalist id="area">
              <option value="" selected>Select Area</option>
              <option *ngFor="let option of areas" [value]="option.Area_Name">
            </datalist>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno">Select Sub Area </label>
            <input list="subareas" (change)="onArea($event)" type="text" placeholder="Optional"
              formControlName="Sub_Area_Name">
            <datalist id="subareas">
              <option value="" selected>Select Sub Area</option>
              <option *ngFor="let option of subAreas" [value]="option.Name">
            </datalist>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno"> Building No/Flat/House No *</label>
            <input formControlName="Flat_House_No" type="text" placeholder=" Building No/Flat/House No" required>
            <!-- <input formControlName="Area_Colony_Sreet_Name" type="text" placeholder=" Building No/Flat/House No" required> -->
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno">Apartment/Society Name *</label>
            <input formControlName="Area_Colony_Sreet_Name" type="text" placeholder="Apartment/Society Name" required>
            <!-- <input formControlName="Area_Colony_Sreet_Name" type="text" placeholder="Apartment/Society Name" required> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno">Landmark </label>
            <input formControlName="Landmark" type="text" placeholder="Landmark">
          </div>
        </div>
        <!-- *ngIf="isEdit" -->
        <div class="col-md-6 col-sm-12">
          <div class="form-box__single-group registerform">
            <label class="mylabel" for="form-register-mobileno">PO Box No </label>
            <input formControlName="Pincode" type="text" placeholder="PO Box No">
            <span class="text-danger" *ngIf="f.get('Pincode').hasError('pattern')"><b>Only Numbers are
                allowed</b></span>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <p class="text-danger mt-2 pl-1">
            Delivery Available In ({{vnfareaslist}})
          </p>
        </div>
      </div> -->
      <br>
      <div class="text-center mb-3">
        <button
          class="btn btn--box btn--medium registerbtn btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"
          (click)="submit()" [disabled]="form.invalid">Save
          Address</button>
      </div>
    </form>
  </div>
</div>