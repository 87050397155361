import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http'
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
var CryptoJS = require('crypto-js')
declare var require: any

@Injectable({
  providedIn: 'root'
})
export class DesifarmCommonService {

  isMainCategoriesLoaded = new BehaviorSubject<boolean>(false)
  isBestSellerLoaded = new BehaviorSubject<boolean>(false)
  isHomePageProductsLoaded = new BehaviorSubject<boolean>(false)
  isHomePageOffer = new BehaviorSubject<boolean>(false)
  isLoggedIn = new BehaviorSubject<boolean>(false)
  loggedInData = new BehaviorSubject<any>({})

  constructor(
    public router: Router,
    public routes: ActivatedRoute,
    public toastr: ToastrService,
    public _spinner: NgxSpinnerService,
    public _metaService: Meta,
    private http: HttpClient
  ) { }

  setUser(login: any) {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(login),
      'enGeniUs@.*5689'
    );
    // localStorage.setItem('desifarms', ciphertext);
    localStorage.setItem(environment.loginKey, ciphertext);
  }

  getUser(): any {
    if (localStorage.getItem(environment.loginKey) != null) {
      const decryptedText = CryptoJS.AES.decrypt(
        localStorage.getItem(environment.loginKey),
        'enGeniUs@.*5689'
      );
      const plaintext = decryptedText.toString(CryptoJS.enc.Utf8);
      return JSON.parse(plaintext);
    } else return null;
  }

  setData(key, value) {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      'enGeniUs@.*5689'
    );
    localStorage.setItem(key, ciphertext);
  }

  getData(key) {
    if (localStorage.getItem(key) != null) {
      const decryptedText = CryptoJS.AES.decrypt(
        localStorage.getItem(key),
        'enGeniUs@.*5689'
      );
      const plaintext = decryptedText.toString(CryptoJS.enc.Utf8);
      return JSON.parse(plaintext);
    } else return null;
  }

  onSuccess(msg, timeOut = 500) {
    this.toastr.success(msg, '', {
      timeOut: timeOut,
    })
  }

  onFailed(msg, timeOut = 500) {
    this.toastr.error(msg, '', {
      timeOut: timeOut,
    })
  }

  closeToast() {
    this._spinner.hide()
  }

  redirectToHome() {
    this.router.navigate(['/'])
  }

  getApi(path): Observable<any> {
    return this.http.get<DesifarmAck>(`${environment.url}${path}`)
  }

  postApi(path, data): Observable<any> {
    return this.http.post<DesifarmAck>(`${environment.url}${path}`, data)
  }

  homePageMetaTags = () => {
    this._metaService.addTags([
      { name: 'title', content: 'Buy Fresh fruits and vegetables Online in Dubai | Nasik fruits and vegetables' },
      { name: 'description', content: 'Order Fresh and Quality Grocery online at Best Price from Nasik fruits and vegetables | Buy Fresh Fruits & Vegetables Online in Dubai Vegetables, Organic fruits, meat, dairy, Bakery, Herbs and more. Free Home Delivery. Buy Now' },
      { name: 'keywords', content: 'fresh fruits and vegetables, fruits and vegetables online, fresh fruits company dubai, online fruits and vegetables, grocery store in dubai, buy vegetables online dubai, buy vegetables and fruits, buy fresh fruits, fresh vegetables supplier.' },
    ])
  }

  fruitsMetaTags = () => {
    this._metaService.addTags([
      { name: 'title', content: 'Nasik fruits and vegetables  | online fruits and vegetables | Dubai delivery' },
      { name: 'description', content: 'Enjoy the freshest fruits in Dubai with fast delivery and unbeatable quality. Order online now from the best fresh fruits company in the city!' },
      { name: 'keywords', content: 'fruits in uae, fresh fruits company in dubai, dubai fruit market, dragon fruits, mango, red apple, pineapple, fruits, citrus, fruit baskets' },
    ])
  }

  vegetablesMetaTags = () => {
    this._metaService.addTags([
      { name: 'title', content: 'Nsk Store| Fresh Vegetables in Dubai | order vegetables Online' },
      { name: 'description', content: `Order fresh organic fruits and vegetables online from Dubai's best grocery store, with home delivery and fruit baskets available. Shop now and enjoy the convenience of having your fresh produce delivered right to your door!` },
      { name: 'keywords', content: `fresh vegetables and fruits, vegetables, bottle gourd, fruits and vegetable market, sweet melon, order vegetables, buy vegetables, fresh vegetables, organic vegetables, vegan` },
    ])
  }

  herbsMetaTags = () => {
    this._metaService.addTags([
      { name: 'title', content: 'Buy herbs and spices online | NSK Store ' },
      { name: 'description', content: `Buy fresh herbs online from Nasik fruits and vegetables, your one-stop shop for all your herbal needs. We offer a wide variety of herbs, including cilantro, parsley, mint, and basil, all at competitive prices. Order now and get fast delivery to your door!` },
      { name: 'keywords', content: ` fresh herbs, buy herbs online, Nasik fruits and vegetables, herbal needs, cilantro, parsley, mint, basil, competitive prices, fast delivery` },
    ])
  }

  dairyMetaTags = () => {
    this._metaService.addTags([
      { name: 'title', content: 'Buy Dairy products online Dubai  | NSK Store' },
      { name: 'description', content: `Shop for fresh dairy products online at Nasik fruits and vegetables. We offer a wide variety of dairy products, including milk, yoghurt, cheese, and butter, all from the best local suppliers. Order now and get fast delivery to your door!` },
      { name: 'keywords', content: `fresh dairy products, buy dairy online, Nasik fruits and vegetables, milk, yoghourt, cheese, butter, local suppliers, fast delivery` },
    ])
  }

  groceryMetaTags = () => {
    this._metaService.addTags([
      { name: 'title', content: 'NSK Store | Online Supermarket | Grocery Delivery in Dubai & Abu Dhabi |' },
      { name: 'description', content: `Nasik fruits and vegetables is your one-stop shop for all your grocery needs. We offer a wide variety of groceries, including fresh produce, meat, seafood, and packaged goods. Order now and get fast delivery to your door!` },
      { name: 'keywords', content: `grocery shopping online, buy groceries online, Nasik fruits and vegetables, fresh produce, meat, seafood, packaged goods, fast delivery` },
    ])
  }

  bulkPurchaseMetaTags = () => {
    this._metaService.addTags([
      { name: 'title', content: 'bulk buys UAE | Your Online Bulk Grocery in Dubai, Abu Dhabi' },
      { name: 'description', content: `Nasik fruits and vegetables offers bulk discounts on a wide variety of products, including groceries, herbs, and dairy. Order your bulk purchases online today and save money!` },
      { name: 'keywords', content: `bulk purchase online, Nasik fruits and vegetables, bulk discounts, Fresh Fruits , groceries, herbs, dairy, save money` },
    ])
  }

  saladMetaTags = () => {
    this._metaService.addTags([
      { name: 'title', content: 'Buy Green Salad Online in Dubai | Get Fresh Salads Online | NSK Store' },
      { name: 'description', content: `Order fresh salads online from Nasik fruits and vegetables. We offer a variety of salads to choose from, all made with fresh, high-quality ingredients. Order now and get fast delivery to your door!` },
      { name: 'keywords', content: `fresh salads online, Nasik fruits and vegetables, order salads online, high-quality ingredients, fast delivery` },
    ])
  }

  homePageOgTag = () => {
    this._metaService.addTag({ property: 'og:type', content: 'website' });
    this._metaService.addTag({ property: 'og:title', content: 'Buy Fresh fruits and vegetables Online in Dubai | Nasik fruits and vegetables' });
    this._metaService.addTag({ property: 'og:site_name', content: 'NSK Store' });
    this._metaService.addTag({ property: 'og:url', content: 'https://nasiktrade.com/' });
    this._metaService.addTag({ property: 'og:image', content: 'https://nasiktrade.com/assets/img/logo/desi.png' });
    this._metaService.addTag({ property: 'og:description', content: `Order Fresh and Quality Grocery online at Best Price from Nasik fruits and vegetables | Buy Fresh Fruits & Vegetables Online in Dubai Vegetables, Organic fruits, meat, dairy, Bakery, Herbs and more. Free Home Delivery. Buy Now` });


    this._metaService.addTag({ property: 'twitter:title', content: `Buy Fresh fruits and vegetables Online in Dubai | Nasik fruits and vegetables` });
    this._metaService.addTag({ property: 'twitter:image', content: `https://nasiktrade.com/assets/img/logo/desi.png` });
    this._metaService.addTag({ property: 'twitter:description', content: `Order Fresh and Quality Grocery online at Best Price from Nasik fruits and vegetables | Buy Fresh Fruits & Vegetables Online in Dubai Vegetables, Organic fruits, meat, dairy, Bakery, Herbs and more. Free Home Delivery. Buy Now` });
  }

  fruitsOgTags = () => {
    this._metaService.addTags([
      { property: 'og:title', content: 'Nasik fruits and vegetables  | online fruits and vegetables | Dubai delivery' },
      { property: 'og:site_name', content: 'NSK Store' },
      { property: 'og:url', content: 'https://nasiktrade.com/' },
      { property: 'og:image', content: 'https://nasiktrade.com/assets/img/logo/desi.png' },
      { property: 'og:description', content: `Enjoy the freshest fruits in Dubai with fast delivery and unbeatable quality. Order online now from the best fresh fruits company in the city!` },

      { property: 'twitter:title', content: `Nasik fruits and vegetables  | online fruits and vegetables | Dubai delivery` },
      { property: 'twitter:image', content: `https://nasiktrade.com/assets/img/logo/desi.png` },
      { property: 'twitter:description', content: `Enjoy the freshest fruits in Dubai with fast delivery and unbeatable quality. Order online now from the best fresh fruits company in the city!` },
    ])
  }

  vegetablesOgTags = () => {
    this._metaService.addTags([
      { property: 'og:title', content: 'Nsk Store| Fresh Vegetables in Dubai | order vegetables Online' },
      { property: 'og:site_name', content: 'NSK Store' },
      { property: 'og:url', content: 'https://nasiktrade.com/' },
      { property: 'og:image', content: 'https://nasiktrade.com/assets/img/logo/desi.png' },
      { property: 'og:description', content: `Order fresh organic fruits and vegetables online from Dubai's best grocery store, with home delivery and fruit baskets available. Shop now and enjoy the convenience of having your fresh produce delivered right to your door!` },

      { property: 'twitter:title', content: `Nsk Store| Fresh Vegetables in Dubai | order vegetables Online` },
      { property: 'twitter:image', content: `https://nasiktrade.com/assets/img/logo/desi.png` },
      { property: 'twitter:description', content: `Order fresh organic fruits and vegetables online from Dubai's best grocery store, with home delivery and fruit baskets available. Shop now and enjoy the convenience of having your fresh produce delivered right to your door!` },
    ])
  }

  herbsOgTags = () => {
    this._metaService.addTags([
      { property: 'og:title', content: 'Buy herbs and spices online | NSK Store' },
      { property: 'og:site_name', content: 'NSK Store' },
      { property: 'og:url', content: 'https://nasiktrade.com/' },
      { property: 'og:image', content: 'https://nasiktrade.com/assets/img/logo/desi.png' },
      { property: 'og:description', content: `Buy fresh herbs online from Nasik fruits and vegetables, your one-stop shop for all your herbal needs. We offer a wide variety of herbs, including cilantro, parsley, mint, and basil, all at competitive prices. Order now and get fast delivery to your door!` },

      { property: 'twitter:title', content: `Buy herbs and spices online | NSK Store` },
      { property: 'twitter:image', content: `https://nasiktrade.com/assets/img/logo/desi.png` },
      { property: 'twitter:description', content: `Buy fresh herbs online from Nasik fruits and vegetables, your one-stop shop for all your herbal needs. We offer a wide variety of herbs, including cilantro, parsley, mint, and basil, all at competitive prices. Order now and get fast delivery to your door!` },
    ])
  }

  dairyOgTags = () => {
    this._metaService.addTags([
      { property: 'og:title', content: 'Buy Dairy products online Dubai  | NSK Store' },
      { property: 'og:site_name', content: 'NSK Store' },
      { property: 'og:url', content: 'https://nasiktrade.com/' },
      { property: 'og:image', content: 'https://nasiktrade.com/assets/img/logo/desi.png' },
      { property: 'og:description', content: `Shop for fresh dairy products online at Nasik fruits and vegetables. We offer a wide variety of dairy products, including milk, yoghurt, cheese, and butter, all from the best local suppliers. Order now and get fast delivery to your door!` },

      { property: 'twitter:title', content: `Buy Dairy products online Dubai  | NSK Store` },
      { property: 'twitter:image', content: `https://nasiktrade.com/assets/img/logo/desi.png` },
      { property: 'twitter:description', content: `Shop for fresh dairy products online at Nasik fruits and vegetables. We offer a wide variety of dairy products, including milk, yoghurt, cheese, and butter, all from the best local suppliers. Order now and get fast delivery to your door!` },
    ])
  }

  groceryOgTags = () => {
    this._metaService.addTags([
      { property: 'og:title', content: 'NSK Store | Online Supermarket | Grocery Delivery in Dubai & Abu Dhabi |' },
      { property: 'og:site_name', content: 'NSK Store' },
      { property: 'og:url', content: 'https://nasiktrade.com/' },
      { property: 'og:image', content: 'https://nasiktrade.com/assets/img/logo/desi.png' },
      { property: 'og:description', content: `Nasik fruits and vegetables is your one-stop shop for all your grocery needs. We offer a wide variety of groceries, including fresh produce, meat, seafood, and packaged goods. Order now and get fast delivery to your door!` },

      { property: 'twitter:title', content: `NSK Store | Online Supermarket | Grocery Delivery in Dubai & Abu Dhabi |` },
      { property: 'twitter:image', content: `https://nasiktrade.com/assets/img/logo/desi.png` },
      { property: 'twitter:description', content: `Nasik fruits and vegetables is your one-stop shop for all your grocery needs. We offer a wide variety of groceries, including fresh produce, meat, seafood, and packaged goods. Order now and get fast delivery to your door!` },
    ])
  }

  bulkPurchaseOgTags = () => {
    this._metaService.addTags([
      { property: 'og:title', content: 'bulk buys UAE | Your Online Bulk Grocery in Dubai, Abu Dhabi' },
      { property: 'og:site_name', content: 'NSK Store' },
      { property: 'og:url', content: 'https://nasiktrade.com/' },
      { property: 'og:image', content: 'https://nasiktrade.com/assets/img/logo/desi.png' },
      { property: 'og:description', content: `Nasik fruits and vegetables offers bulk discounts on a wide variety of products, including groceries, herbs, and dairy. Order your bulk purchases online today and save money!` },

      { property: 'twitter:title', content: `bulk buys UAE | Your Online Bulk Grocery in Dubai, Abu Dhabi` },
      { property: 'twitter:image', content: `https://nasiktrade.com/assets/img/logo/desi.png` },
      { property: 'twitter:description', content: `Nasik fruits and vegetables offers bulk discounts on a wide variety of products, including groceries, herbs, and dairy. Order your bulk purchases online today and save money!` },
    ])
  }

  saladOgTags = () => {
    this._metaService.addTags([
      { property: 'og:title', content: 'Buy Green Salad Online in Dubai | Get Fresh Salads Online | NSK Store' },
      { property: 'og:site_name', content: 'NSK Store' },
      { property: 'og:url', content: 'https://nasiktrade.com/' },
      { property: 'og:image', content: 'https://nasiktrade.com/assets/img/logo/desi.png' },
      { property: 'og:description', content: `Order fresh salads online from Nasik fruits and vegetables. We offer a variety of salads to choose from, all made with fresh, high-quality ingredients. Order now and get fast delivery to your door!` },

      { property: 'twitter:title', content: `Buy Green Salad Online in Dubai | Get Fresh Salads Online | NSK Store` },
      { property: 'twitter:image', content: `https://nasiktrade.com/assets/img/logo/desi.png` },
      { property: 'twitter:description', content: `Order fresh salads online from Nasik fruits and vegetables. We offer a variety of salads to choose from, all made with fresh, high-quality ingredients. Order now and get fast delivery to your door!` },
    ])
  }

}