import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';

@Injectable({
  providedIn: 'root'
})
export class DesifarmLoginService {

  constructor(private http: HttpClient) { }

  sign_up(data): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'sign_up', data)
  }

  check_email(data): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'check_email', data)
  }

  sign_in(data): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'sign_in', data)
  }

  customer_info(data): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'customer_info', data)
  }

  sendOTP(Customer_Mobile_Number): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'sendOTP', { Customer_Mobile_Number })
  }

  check_refer_code(Cust_Id): Observable<any> {
    return this.http.get<DesifarmAck>(environment.url + 'check_refer_code/' + Cust_Id)
  }

  verifyOtp(data): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'verifyOtp', data)
  }

  resendOtp(data): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'resendOtp', data)
  }
}
