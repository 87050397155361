import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DesifarmsHomepageComponent } from '../components/desifarms-homepage/desifarms-homepage.component';
import { DesifarmsContactComponent } from '../components/desifarms-contact/desifarms-contact.component';
import { DesifarmsHeaderfooterComponent } from '../components/desifarms-headerfooter/desifarms-headerfooter.component';
import { DesifarmsAboutComponent } from '../components/desifarms-about/desifarms-about.component';
import { DesifarmsShopallitemsComponent } from '../components/desifarms-shopallitems/desifarms-shopallitems.component'
import { DesifarmsProductdetailsComponent } from '../components/desifarms-productdetails/desifarms-productdetails.component';
import { DesifarmsMywhishlistComponent } from '../components/desifarms-mywhishlist/desifarms-mywhishlist.component';
import { DesifarmsMycartComponent } from '../components/desifarms-mycart/desifarms-mycart.component';
import { DesifarmsMyaccountComponent } from '../components/desifarms-myaccount/desifarms-myaccount.component';
// import { DesifarmsLoginComponent } from '../components/desifarms-login/desifarms-login.component';
import { DesifarmsCheckoutComponent } from '../components/desifarms-mycart/desifarms-checkout/desifarms-checkout.component';
import { DesifarmsCompareproductComponent } from '../components/desifarms-compareproduct/desifarms-compareproduct.component';
import { DesifarmsDashboardComponent } from '../components/desifarms-dashboard/desifarms-dashboard.component';
import { DesifarmsMyprofileComponent } from '../components/desifarms-dashboard/desifarms-myprofile/desifarms-myprofile.component';
import { DesifarmsManageaddressComponent } from '../components/desifarms-dashboard/desifarms-manageaddress/desifarms-manageaddress.component';
import { DesifarmsMyordersComponent } from '../components/desifarms-dashboard/desifarms-myorders/desifarms-myorders.component';
import { DesifarmsSubscriptionsComponent } from '../components/desifarms-dashboard/desifarms-subscriptions/desifarms-subscriptions.component';
import { DesifarmsMywalletComponent } from '../components/desifarms-dashboard/desifarms-mywallet/desifarms-mywallet.component';
import { DesifarmSearchProductsComponent } from '../components/desifarm-search-products/desifarm-search-products.component';
import { DesifarmsOrderSuccessComponent } from '../components/desifarms-mycart/desifarms-checkout/desifarms-order-success/desifarms-order-success.component';
import { DesifarmsOurstoreComponent } from '../components/desifarms-ourstore/desifarms-ourstore.component';
import { DesifarmsFranchiseoutletComponent } from '../components/desifarms-ourstore/desifarms-franchiseoutlet/desifarms-franchiseoutlet.component';
import { DesifarmsCareerComponent } from '../components/desifarms-career/desifarms-career.component';
import { DesifarmsBlogdetailsComponent } from '../components/desifarms-blogdetails/desifarms-blogdetails.component';
import { DesifarmsBlogsComponent } from '../components/desifarms-blogs/desifarms-blogs.component';
import { DesifarmsShippingPolicyComponent } from '../components/desifarms-shipping-policy/desifarms-shipping-policy.component';
import { DesifarmsPrivacypolicyComponent } from '../components/desifarms-privacypolicy/desifarms-privacypolicy.component';
import { DesifarmsRefundpolicyComponent } from '../components/desifarms-refundpolicy/desifarms-refundpolicy.component';
import { DesifarmsTermconditionComponent } from '../components/desifarms-termcondition/desifarms-termcondition.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { DesifarmReferAndEarnComponent } from '../components/desifarms-dashboard/desifarm-refer-and-earn/desifarm-refer-and-earn.component';
import { DesifarmsTestimonialComponent } from '../components/desifarms-testimonial/desifarms-testimonial.component';
import { DesifarmSubscribeCartComponent } from '../components/desifarm-subscribe-cart/desifarm-subscribe-cart.component';
import { DesifarmMySubscriptionComponent } from '../components/desifarms-dashboard/desifarm-my-subscription/desifarm-my-subscription.component';
import { DesifarmOnlineInvoiceComponent } from '../components/desifarm-online-invoice/desifarm-online-invoice.component';
import { DesifarmNewinvoiceComponent } from '../components/desifarm-newinvoice/desifarm-newinvoice.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
// const routes: Routes = [
//   { path: "", redirectTo: "/home/homepage", pathMatch: "full" },
//   {
//     path: "home", component: DesifarmsHeaderfooterComponent,

//     children: [
//       { path: "homepage", component: DesifarmsHomepageComponent },
//       { path: "contact", component: DesifarmsContactComponent },
//       { path: "about", component: DesifarmsAboutComponent },
//       // { path: "shopallitems", component: DesifarmsShopallitemsComponent },
//       { path: "products", component: DesifarmsShopallitemsComponent },
//       { path: "products/:id", component: DesifarmsShopallitemsComponent },
//       { path: "productdetails", component: DesifarmsProductdetailsComponent },
//       { path: "mywishlist", component: DesifarmsMywhishlistComponent },
//       { path: "mycart", component: DesifarmsMycartComponent },
//       { path: "myaccount", component: DesifarmsMyaccountComponent },
//       { path: "login", component: DesifarmsLoginComponent },
//       { path: "checkout", component: DesifarmsCheckoutComponent },
//       { path: "compareproduct", component: DesifarmsCompareproductComponent },
//     ]
//   },

// ];

const routes: Routes = [
  {
    path: "",
    component: DesifarmsHeaderfooterComponent,
    children: [
      { path: "", component: DesifarmsHomepageComponent },
      { path: "products", component: DesifarmsShopallitemsComponent },
      { path: "products/:categoryName/:Main_Category_Id", component: DesifarmsShopallitemsComponent },
      { path: "products/:categoryName/:Main_Category_Id/:Sub_Category_Id", component: DesifarmsShopallitemsComponent },
      { path: "mycart", component: DesifarmsMycartComponent },
      { path: "checkout", component: DesifarmsCheckoutComponent },
      { path: "search-products", component: DesifarmSearchProductsComponent },
      { path: "dashboard", component: DesifarmsDashboardComponent, canActivate: [AuthGuard] },
      { path: "myprofile", component: DesifarmsMyprofileComponent, canActivate: [AuthGuard] },
      { path: "manageaddress", component: DesifarmsManageaddressComponent, canActivate: [AuthGuard] },
      { path: "myorders", component: DesifarmsMyordersComponent, canActivate: [AuthGuard] },
      { path: "mysubscription", component: DesifarmMySubscriptionComponent, canActivate: [AuthGuard] },
      { path: "subscription", component: DesifarmsSubscriptionsComponent, canActivate: [AuthGuard] },
      { path: "mywallet", component: DesifarmsMywalletComponent, canActivate: [AuthGuard] },
      { path: "refer-and-earn", component: DesifarmReferAndEarnComponent, canActivate: [AuthGuard] },
      { path: "order-success", component: DesifarmsOrderSuccessComponent, canActivate: [AuthGuard] },
      { path: "aboutus", component: DesifarmsAboutComponent },
      { path: "our-store", component: DesifarmsOurstoreComponent },
      { path: "franchiseoutlet", component: DesifarmsFranchiseoutletComponent },
      { path: "contactus", component: DesifarmsContactComponent },
      { path: "career", component: DesifarmsCareerComponent },
      { path: "blog-details", component: DesifarmsBlogdetailsComponent },
      { path: "blogs", component: DesifarmsBlogsComponent },
      { path: "shippingpolicy", component: DesifarmsShippingPolicyComponent },
      // { path: "privacypolicy", component: DesifarmsPrivacypolicyComponent },
      { path: "refundpolicy", component: DesifarmsRefundpolicyComponent },
      { path: "termscondition", component: DesifarmsTermconditionComponent },
      { path: "testimonial", component: DesifarmsTestimonialComponent },
      { path: "subscribe-cart", component: DesifarmSubscribeCartComponent },
      // { path: "productdetails", component: DesifarmsProductdetailsComponent },
      // { path: "**", component: PageNotFoundComponent },

      {
        path: "dashboard",
        component: DesifarmsDashboardComponent,
        children: []
      },
    ]
  },
  { path: "invoice/:Sale_Order_Id", component: DesifarmOnlineInvoiceComponent },
  { path: "franchise/:Franchise_Order_Id", component: DesifarmNewinvoiceComponent },
  { path: "privacypolicy", component: DesifarmsPrivacypolicyComponent },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
