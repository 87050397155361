<div style="background: #ececec;    border-top: 2px dashed#ea6d14;">
  <div class="container pt-2 pb-4">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <!-- <div class="tab-lists pt-4"  >
                    <img src="assets/img/dashboard/userprofile.png" class="img-responsive"/>
                    <h3 class="mt-3">Susmita Kumkar</h3>
                    <p> Phone: +91- 8668819519</p>
                    <button class="btn btn--box cart-btn btn--small btn--dark btn--black btn--black-hover-green font--semi-bold"
                    ><a  class="tablink" routerLink="myprofile">Edit Info</a></button>
                    <hr>
                    <a routerLink="dashboard">Dashboard</a>
                    <hr>
                    <a  routerLink="myprofile">My Profile</a>
                    <hr>
                    <a routerLink="myorders">My Orders</a>
                    <hr>
                    <a   routerLink="mywallet" style="color: orangered;">My Wallet</a>
                    <hr>
                    <a routerLink="subscription" >My Subscriptions</a>
                    <hr>
                    <a  routerLink="manageaddress">Manage Address</a>

                </div> -->
        <app-desifarm-dashboard-side-menu></app-desifarm-dashboard-side-menu>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <h1 class="tab-heading">My Wallet</h1>
              <div class="nopadding p-4">
                <h4>
                  The NSK Store Wallet is a pre-paid credit account that is associated with your NSK Store account.
                  This prepaid account allows you to pay a lump sum amount once to NSK Store and then shop multiple
                  times without having to pay each time.</h4>
                <p class="balance">Current Balance: AED.{{walletAmountCount}} <a class="btn btn--box cart-btn btn--small-topup btn--dark btn--black btn--black-hover-green font--semi-bold" (click)="onWalletTopup()" >Topup Your Wallet</a> </p>

                <!-- <span
                    class="innerbalance text-center" (click)="onWalletTopup()" style="cursor: pointer;">Topup Your Wallet</span> -->
                <h3 style="font-size: 20px; font-weight: 500;" class="mt-3 mb-2">Wallet Activity</h3>
                <table class="table table-striped table-responsive wallet-table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Date</th>
                      <th class="d-none d-lg-block " scope="col">Transaction Detail</th>
                      <th class="d-lg-none d-md-block" scope="col">Detail</th>
                      <th scope="col">Credit</th>
                      <th   scope="col" >Debit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@mdo</td>
                      <td>@fat</td>
                    </tr> -->
                    <tr *ngFor="let option of walletHistory; let i = index">
                      <th scope="row">{{i + 1}}</th>
                      <td>{{option.Reg_Date }}</td>
                      <td>{{option.Transaction_Detail}}</td>
                      <td><span *ngIf="option.Cr_Amt > 0" class="text-success">AED {{option.Cr_Amt }}</span></td>
                      <td><span *ngIf="option.Dr_Amt > 0" class="text-danger">AED {{option.Dr_Amt }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>