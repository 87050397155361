export class ReqCheckOut {
    Company_Id: string
    Financial_Year_Id: string
    Franchise_Id: string
    Order_status: string
    Order_Cancelation_Reason: string
    Instruction: string
    Remark: string
    Payment_Mode: string
    Payment_Transaction_ID: string
    Customer_Delivery_Address_State_Id: string
    Franchise_State_Id: number
    Company_State_Id: string
    Cust_Id: string
    Cust_Name: string
    Mobile_Number: string
    Email_Id: string
    Order_From: string
    Delivery_Address_Id: string
    Full_Name: string
    Mobile_No_Address: string
    Flat_House_No: string
    Area_Colony_Sreet_Name: string
    Landmark: string
    Pincode: string
    City: string
    Area_Name: string
    Sub_Area_Name: string
    Sub_Area_Id: string
    Address_Type: string
    Delivery_LT: string
    Delivery_LG: string
    Invoice_Prefix: string
    Invoice_No: number
    Invoice_Date_Time: string
    Delivery_Boy_Id: number
    Order_Delivery_Charges: number
    Delivery_Slot_Id: number
    Slot_Name: string
    Customer_Delivery_Date: string
    Slot_Start_Time: any
    Actual_Delivery_Date_Time: string
    Business_Type_Id: number
    Wallet_Amount_Used: number
    Referral_Code: string
    Area_Id: number
    Apartment_Id: number
    Current_Url?: string


    Sale_Order_Id: number
    Product_Id: string
    Product_Packaging_Rate_Id: string
    Product_Name: string
    Package_Qty: string
    Unit_Name: string
    Unit_Id: string
    Short_Description: string
    CGST_Per: string
    SGST_Per: string
    IGST_Per: string
    HSN_Code: string
    Product_Package_MRP: string
    Discount_Per: string
    Selling_Amt: string
    Order_Qty: string
    Total_Discount_Amount: string
    Total_Selling_Amount: string
    Delivery_Charges: string
    CGST_Amount: string
    SGST_Amount: string
    IGST_Amount: string
    Product_Status: string
    ODM_Delivery_Boy_Id: string
    Order_Date_Time: string
    Order_Delivered_Date: string
    Is_Dairy_Product: string
    Active_Subscription: any
}