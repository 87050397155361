import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmMyprofileService {

  constructor(private http: HttpClient) { }

  get_profile(data): Observable<any> {
    return this.http.post<any>(environment.url + 'get_profile', data)
  }

  // not working
  update_profile(data): Observable<any> {
    return this.http.put<any>(environment.url + 'update_profile', data)
  }

  // working
  profile_update(data): Observable<any> {
    return this.http.post<any>(environment.url + 'profile_update', data)
  }
}
