import { environment } from 'src/environments/environment';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { ResDistrict } from 'src/app/models/desifarm-res/desifarm-header/resdistrict';
import { ResSubArea } from 'src/app/models/desifarm-res/desifarm-header/ressubarea';
import { DesifarmLoginService } from 'src/app/services/desifarm-auth/desifarm-login/desifarm-login.service';
import { DesifarmSignupService } from 'src/app/services/desifarm-auth/desifarm-signup/desifarm-signup.service';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmsHeaderfooterComponent } from '../desifarms-headerfooter/desifarms-headerfooter.component';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { noWhitespaceValidator } from 'src/app/validators/nowhitespace';


@Component({
  selector: 'app-desifarm-login-register',
  templateUrl: './desifarm-login-register.component.html',
  styleUrls: ['./desifarm-login-register.component.scss']
})
export class DesifarmLoginRegisterComponent implements OnInit {

  @ViewChild('OtpInput') OtpInput: ElementRef<HTMLInputElement>

  form: FormGroup
  form1: FormGroup
  password: string = "password"
  passwordType: boolean = true
  loginFlag: boolean = true
  otpFlag: boolean = false
  signUpFlag: boolean = false



  districts: ResDistrict[] = []
  areaList: any[] = []
  subAreas: ResSubArea[] = []
  sources: any[] = []


  Customer_Mobile_Number: any
  otp: any
  District_Id: any
  Sub_Area_Id: any
  serviceLocation: any
  receivedOTP: any
  refercodeDetail: any

  constructor(
    public _dialogRef: MatDialogRef<DesifarmLoginRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _authService: DesifarmLoginService,
    private _signUpService: DesifarmSignupService,
  ) { }

  initForm() {
    this.form = this._fb.group({
      Customer_Mobile_Number: ['', [Validators.minLength(10), Validators.maxLength(10)]]
    })

    this.form1 = this._fb.group({
      Cust_Name: ['', [Validators.required]],
      Customer_Mobile_Number: [''],
      Gender: ['', [Validators.required]],
      Sub_Area_Id: [''],
      Sub_Area_Name: [''],
      Source: ['', [Validators.required]],
      Referral_Code: [''],
      Temp_Referral_Code: [''],
      Business_Type_Id: [''],
      Area_Id: ['', [Validators.required]],
      Area_Name: ['', [Validators.required]],
      Apartment_Id: [''],
      District_Id: ['', [Validators.required]],
      District_Name: ['', [Validators.required]],

    })
  }

  getData() {
    this._signUpService.get_district().subscribe((res: DesifarmAck) => {
      this.districts = res.data
      this.updateFormValue()
    })

    this._signUpService.get_source().subscribe((res: any) => {
      this.sources = res.data
    })

    if (this._commonService.getData(environment.locationKey) != null) {
      this.serviceLocation = this._commonService.getData(environment.locationKey)
      this.District_Id = this.serviceLocation.District_Id
      this.Sub_Area_Id = this.serviceLocation.Sub_Area_Id
    }
  }

  onCity(event) {
    let city = this.districts.find(e => e.District_Name == event.target.value)
    if (city) {
      this.form1.patchValue({
        District_Id: city.District_Id,
        District_Name: city.District_Name,
      })
      this.getAreaList(city.District_Id)
    }

    this.form1.controls.Area_Id.reset()
    this.form1.controls.Area_Name.reset()
    this.form1.controls.Sub_Area_Id.reset()
    this.form1.controls.Sub_Area_Name.reset()
  }

  getAreaList(District_Id) {
    this._signUpService.get_area(District_Id).subscribe((res: any) => {
      this.areaList = res.data
    })
  }

  onArea(event) {

    let area = this.areaList.find(e => e.Area_Name == event.target.value)
    if (area) {
      this.form1.patchValue({
        Area_Id: area.Area_Id,
        Area_Name: area.Area_Name,
      })
      this.getSubArea(area.Area_Id)
    } else {

    }

    this.form1.controls.Sub_Area_Id.reset()
    this.form1.controls.Sub_Area_Name.reset()
  }

  getSubArea(Area_Id) {
    this._signUpService.get_sub_area(Area_Id).subscribe((res: any) => {
      this.subAreas = res.data
    })
  }

  onSubArea(event) {
    let subArea = this.subAreas.find(e => e.Sub_Area_Name == event.target.value)
    if (subArea) {
      this.form1.patchValue({
        Sub_Area_Id: subArea.Sub_Area_Id,
        Sub_Area_Name: subArea.Sub_Area_Name
      })
    } else {
      this.form1.patchValue({
        Sub_Area_Id: 0,
        Sub_Area_Name: ""
      })
    }
  }

  ngOnInit(): void {
    this.initForm()
    this.getData()
  }

  updateFormValue() {
    this.form.patchValue({
      District_Id: this.districts[0].District_Id,
      District_Name: this.districts[0].District_Name,
    })

    this.getAreaList(this.districts[0].District_Id)
  }

  onOtpChange(event) {
    this.otp = event.target.value
  }

  verifyOTP() {
    if (this.otp == this.receivedOTP || this.otp == 662200) {
      this.login()
    } else this._commonService.onFailed("Wrong OTP")
  }

  submit() {
    this.Customer_Mobile_Number = this.form.value.Customer_Mobile_Number
    this.sendOTP()
  }

  sendOTP() {
    this._commonService._spinner.show()
    this._authService.sendOTP(this.form.value.Customer_Mobile_Number).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.receivedOTP = res.OTP
        this.otpFlag = true
        this.loginFlag = false
        this._commonService.onSuccess(res.msg)
      } else this._commonService.onFailed("Unable to Send OTP")
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

  login() {
    this.Customer_Mobile_Number = this.form.value.Customer_Mobile_Number
    this.form1.patchValue({ Customer_Mobile_Number: this.Customer_Mobile_Number, Business_Type_Id: environment.Business_Type_Id })
    this._commonService._spinner.show()
    this._authService.sign_in(this.form.value).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.loginFlag = false
        this.otpFlag = false
        this._commonService.setUser(res.data[0])
        this._commonService.onSuccess(res.msg)
        this._dialogRef.close()
      } else {
        if (res.res_code == 0) {
          this.otpFlag = false
          this.signUpFlag = true
        }
      }
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

  passwordToggle() {
    this.passwordType = !this.passwordType
  }

  onRegister() {
    let data = this.form1.value
    data.Customer_Mobile_Number = this.form.value.Customer_Mobile_Number
    data.Business_Type_Id = environment.Business_Type_Id
    data.Apartment_Id = 0
    data.Sub_Area_Id = data.Sub_Area_Id == null ? 0 : data.Sub_Area_Id
    this._commonService._spinner.show()
    this._authService.sign_up(data).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this._commonService.setUser(res.data[0])
        this._commonService.onSuccess(res.msg)
        this._dialogRef.close()
      } else this._commonService.onFailed(res.msg)
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

  checkReferCode(event) {

    if (event.target.value.length >= 5) {

      let value = event.target.value.substring(0, 4)
      if (value == "DESI" || value == "desi") {
        this._commonService._spinner.show()
        let referCode = event.target.value
        let code = referCode.slice(4)
        this._authService.check_refer_code(code).subscribe((res: any) => {
          this._commonService._spinner.hide()
          if (res.ack) {
            this.refercodeDetail = res.data[0].Cust_Name
            this._commonService.onSuccess(res.msg)
            this.form1.patchValue({ Referral_Code: code })
          } else {
            this.refercodeDetail = null
            this.form1.controls.Temp_Referral_Code.reset()
            this.form1.controls.Referral_Code.reset()
            this._commonService.onFailed(res.msg)
          }
        }, (err) => {
          this._commonService._spinner.hide()
          this._commonService.onFailed("Internet Connection Failed")
        })
      } else {
        this.refercodeDetail = null
        this._commonService.onFailed("Invalid Refer Code")
        this.form1.controls.Temp_Referral_Code.reset()
        this.form1.controls.Referral_Code.reset()
      }
    } else {
      this.refercodeDetail = null
      this._commonService.onFailed("Invalid Refer Code")
      this.form1.controls.Temp_Referral_Code.reset()
      this.form1.controls.Referral_Code.reset()
    }
  }

}
