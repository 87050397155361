import { Component, OnInit } from '@angular/core';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';

@Component({
  selector: 'app-desifarm-dashboard-side-menu',
  templateUrl: './desifarm-dashboard-side-menu.component.html',
  styleUrls: ['./desifarm-dashboard-side-menu.component.scss']
})
export class DesifarmDashboardSideMenuComponent implements OnInit {

  loginData: any

  constructor(
    private _commonService: DesifarmCommonService,
  ) { }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()  
  }

}
