import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmWalletTopupService {

  constructor(private http: HttpClient) { }

  wallet_topup(data): Observable<any> {
    return this.http.post<any>(environment.url + 'wallet_topup', data)
  }

  get_wallet_offers(Cust_Id): Observable<any> {
    return this.http.post<any>(environment.url + 'get_wallet_offers', { Cust_Id })
  }

  insert_wallet_topup(data): Observable<any> {
    return this.http.post<any>(environment.url + 'insert_wallet_topup', data)
  }

  update_wallet_topup(data): Observable<any> {
    return this.http.post<any>(environment.url + 'update_wallet_topup', data)
  }



}
