import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmOnlineInvoiceService } from 'src/app/services/desifarm-online-invoice/desifarm-online-invoice.service';

@Component({
  selector: 'app-desifarm-newinvoice',
  templateUrl: './desifarm-newinvoice.component.html',
  styleUrls: ['./desifarm-newinvoice.component.scss']
})
export class DesifarmNewinvoiceComponent implements OnInit {

  data: any;

  SubTotal: number = 0
  SavedAmt: number = 0
  DeliveryCharges: number = 0
  WalletAmount: number = 0
  PayableAmount: number = 0

  constructor(
    private _activatedRoutes: ActivatedRoute,
    private _commonService: DesifarmCommonService,
    private _desifarmOnlineInvoiceService: DesifarmOnlineInvoiceService
  ) { }

  ngOnInit(): void {
    this._activatedRoutes.params.subscribe((params) => {
      if (params.Franchise_Order_Id) {
        this.getInvoiceDetail(params.Franchise_Order_Id)
      }
    })
  }

  getInvoiceDetail(Franchise_Order_Id) {
    this._commonService._spinner.show()
    this._desifarmOnlineInvoiceService.Get_Franchise_Invoice_Detail(Franchise_Order_Id).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.data = res.data[0]
        this.SubTotal = this.data.Product_List.map(element => element.Single_Selling_Amount * element.Actual_Delivery_Qty).reduce((prev, next) => prev + next, 0)
        // this.SavedAmt = this.data.Product_List.map(element => element.Total_Discount_Amount).reduce((prev, next) => prev + next, 0)
        // this.DeliveryCharges = this.data.Order_Delivery_Charges
        // this.WalletAmount = this.data.Wallet_Amount_Used
        // this.PayableAmount = ((this.SubTotal + this.DeliveryCharges) - this.WalletAmount)
        this.PayableAmount = this.SubTotal
      }
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Please Try Again")
    })
  }

  handlePrint() {
    window.print();
  }

}
