import { DesifarmAddressComponent } from './../../desifarms-mycart/desifarms-checkout/desifarm-address/desifarm-address.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmAddressService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-address/desifarm-address.service';

@Component({
  selector: 'app-desifarms-manageaddress',
  templateUrl: './desifarms-manageaddress.component.html',
  styleUrls: ['./desifarms-manageaddress.component.scss']
})
export class DesifarmsManageaddressComponent implements OnInit {

  loginData: any

  address: any[] = []

  constructor(
    private _commonService: DesifarmCommonService,
    private _addressService: DesifarmAddressService,
    private _dialog: MatDialog
  ) { }

  getData() {
    this._commonService._spinner.show()
    this._addressService.get_customer_address(this.loginData.Cust_Id).subscribe((res: DesifarmAck) => {
      this.address = res.data
      this._commonService._spinner.hide()
    })
  }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()
    this.getData()
  }

  onAddress() {
    const dialogRef = this._dialog.open(DesifarmAddressComponent, {
      width: '1000px',
      height: '80%',
      panelClass: 'checkout-address-modal',
    })
    dialogRef.afterClosed().subscribe(() => this.getData())
  }

  onEdit(option) {
    const dialogRef = this._dialog.open(DesifarmAddressComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'checkout-address-modal',
      data: option
    })
    dialogRef.afterClosed().subscribe(() => this.getData())
  }

  onDelete(option) {
    if (confirm("Are You Sure To Delete This Address")) {
      this._commonService._spinner.show()
      this._addressService.delete_customer_address(option.Delivery_Address_Id).subscribe((res: any) => {
        this._commonService._spinner.hide()
        if (res.ack) {
          this.getData()
          this._commonService.onSuccess(res.msg)
        } else this._commonService.onFailed(res.msg)
      }, (err) => {
        this._commonService._spinner.hide()
        this._commonService.onFailed(err)
      })
    }
  }



}
