import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DesifarmBlogsService {
  constructor(private http: HttpClient) {}

  get_blogs_list(): Observable<any> {
    return this.http.get<DesifarmAck>(
      environment.url + 'get_blogs_list/' + environment.Business_Type_Id
    );
  }
}
