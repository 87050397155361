import { DesifarmAck } from './../../../models/desifarm-common/desifarmAck';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmMyprofileService } from 'src/app/services/desifarm-dashboard/desifarm-myprofile/desifarm-myprofile.service';
import * as moment from 'moment';

@Component({
  selector: 'app-desifarms-myprofile',
  templateUrl: './desifarms-myprofile.component.html',
  styleUrls: ['./desifarms-myprofile.component.scss']
})
export class DesifarmsMyprofileComponent implements OnInit {


  form: FormGroup

  loginData: any

  constructor(
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _myProfileService: DesifarmMyprofileService
  ) { }

  initForm() {
    this.form = this._fb.group({
      Cust_Id: [''],
      Cust_Name: ['', [Validators.pattern('[A-Za-z ]*')]],
      First_Name: ['', [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      Last_Name: ['', [Validators.required, Validators.pattern('[A-Za-z ]*')]],
      Customer_Mobile_Number: ['', [Validators.required]],
      Email_Id: ['', [Validators.required]],
      Gender: ['', [Validators.required]],
      DOB: ['', [Validators.required]],
      Anniversary_Date: [''],
    })
    // this.form = this._fb.group({
    //   Cust_Id: [this.loginData.Cust_Id],
    //   Cust_Name: [this.loginData.Cust_Name, [Validators.pattern('[A-Za-z ]*')]],
    //   First_Name: [this.loginData.First_Name, [Validators.pattern('[A-Za-z ]*')]],
    //   Last_Name: [this.loginData.Last_Name, [Validators.pattern('[A-Za-z ]*')]],
    //   Customer_Mobile_Number: [this.loginData.Customer_Mobile_Number],
    //   Email_Id: [this.loginData.Email_Id],
    //   Gender: [this.loginData.Gender],
    //   DOB: [moment(this.loginData.DOB).format('YYYY-MM-DD')],
    //   Anniversary_Date: [moment(this.loginData.Anniversary_Date).format('YYYY-MM-DD')],
    // })
  }

  get f() {
    return this.form
  }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()
    this.initForm()
    this.getProfile(this.loginData.Cust_Id)
  }

  getProfile(Cust_Id) {
    this._commonService._spinner.show()
    this._myProfileService.get_profile({ Cust_Id: Cust_Id }).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.form.patchValue({
          Cust_Id: res.data.Cust_Id,
          Cust_Name: res.data.Cust_Name,
          First_Name: res.data.First_Name,
          Last_Name: res.data.Last_Name,
          Customer_Mobile_Number: res.data.Customer_Mobile_Number,
          Email_Id: res.data.Email_Id,
          Gender: res.data.Gender,
          DOB: res.data.DOB,
          Anniversary_Date: res.data.Anniversary_Date,
        })
      } else {
        this._commonService.onFailed(res.msg)
      }
    }, (err) => {
      this._commonService._spinner.hide()
    })
  }

  submit() {
    try {
      this._commonService._spinner.show()
      this._myProfileService.profile_update(this.form.value)
        .subscribe((res: any) => {
          this._commonService._spinner.hide()
          if (res.ack) {
            this._commonService.onSuccess(res.msg)
            this._commonService.setUser(res.data[0])
            this._commonService.loggedInData.next(res.data[0])
          } else this._commonService.onFailed(res.msg)
        }, (err) => {
          this._commonService._spinner.hide()
        })
    } catch (error) {

    }
  }

}
