<div style="background: #ececec;    border-top: 2px dashed#ea6d14;">
  <div class="container pt-2 pb-4">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <!-- <div class="tab-lists pt-4"  >
                    <img src="assets/img/dashboard/userprofile.png" class="img-responsive"/>
                    <h3 class="mt-3">Susmita Kumkar</h3>
                    <p> Phone: +91- 8668819519</p>
                    <button class="btn btn--box cart-btn btn--small btn--dark btn--black btn--black-hover-green font--semi-bold" 
                    ><a  class="tablink" routerLink="myprofile">Edit Info</a></button>
                    <hr>
                    <a href="#">Dashboard</a>
                    <hr>
                    <a  routerLink="myprofile">My Profile</a>
                    <hr>
                    <a routerLink="myorders">My Orders</a>
                    <hr>
                    <a href="#">My Wallet</a>
                    <hr>
                    <a routerLink="subscription"  style="color: orangered;">My Subscriptions</a>
                    <hr>
                    <a  routerLink="manageaddress">Manage Address</a>
                
                </div> -->
        <app-desifarm-dashboard-side-menu></app-desifarm-dashboard-side-menu>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <h1 class="tab-heading">My Subscriptions</h1>
              <div class="text-center p-4">

                <h3 class="mt-3" style="font-size: 30px;color: #00679f;font-weight: 500;">No Record Found</h3>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
