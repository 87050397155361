<div class="col-lg-12 col-md-12" >
    <div class="pagination-area mt-5">
        <div class="d-inline page-item" [ngClass]="{ disabled: paginate.currentPage == 1 }">
            <a class="page-numbers" (click)="pageSet(paginate.currentPage - 1)"><i class="bx bx-chevron-left"></i> Prev </a>
        </div>
        <span class="page-numbers active" *ngFor="let page of paginate.pages"
            [ngClass]="{ active: paginate.currentPage == page }" >
            <a [ngClass]="{activePage:paginate.currentPage == page}" href="javascript:void(0)" class="" (click)="pageSet(page)">{{ page }}</a>
        </span>
        <div class="d-inline page-item" [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
            <a href="javascript:void(0)" class="page-numbers" aria-hidden="true"
                (click)="pageSet(paginate.currentPage + 1)" aria-label="Next"><i class="bx bx-chevron-right"></i> Next </a>
        </div>
    </div>
</div>
