import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmCheckoutService {

  constructor(private http: HttpClient) { }

  place_cart_orders(data): Observable<any> {
    return this.http.post<any>(environment.url + 'place_cart_orders', data)
  }
  //   Old Api
  place_order(data): Observable<any> {
    return this.http.post<any>(environment.url + 'place_order', data)
  }

  place_online_order(data): Observable<any> {
    return this.http.post<any>(environment.url + 'place_online_order', data)
  }

  update_order_status(data): Observable<any> {
    return this.http.post<any>(environment.url + 'update_order_status', data)
  }

  get_updated_product_for_cart(data): Observable<any> {
    return this.http.post<any>(environment.url + 'get_updated_product_for_cart', data)
  }

  check_pincode(): Observable<any> {
    return this.http.get<any>(environment.url + 'check_pincode')
  }

  check_area(Pincode_Area): Observable<any> {
    return this.http.get<any>(environment.url + 'check_area/' + Pincode_Area)
  }

  get_packaging(Franchise_Id, District_Id): Observable<any> {
    return this.http.get<any>(`${environment.url}get_packaging/${Franchise_Id}/${District_Id}`)
    // return this.http.get<any>(environment.url + 'get_packaging/' + Franchise_Id)
  }

  get_customer_active_subscription_count(Cust_Id): Observable<any> {
    return this.http.post<any>(environment.url + 'get_customer_active_subscription_count', { Cust_Id })
  }
}
