import { Location } from './../../models/desifarm-common/desifarm-local/desifarm-location';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DesifarmLoginService } from 'src/app/services/desifarm-auth/desifarm-login/desifarm-login.service';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { DesifarmShopallitemsService } from 'src/app/services/desifarm-shopallitems/desifarm-shopallitems/desifarm-shopallitems.service';
import { DesifarmProductDetailsComponent } from './desifarm-product-details/desifarm-product-details.component';
import { environment } from 'src/environments/environment';
import { AfterViewInit } from '@angular/core';
import { DesifarmProductSubscribeDetailComponent } from './desifarm-product-subscribe-detail/desifarm-product-subscribe-detail.component';
import { DesifarmsHeaderfooterComponent } from '../desifarms-headerfooter/desifarms-headerfooter.component';
import { DesifarmLoginRegisterComponent } from '../desifarm-login-register/desifarm-login-register.component';
declare var $: any
declare var elevateZoom: any


@Component({
	selector: 'app-desifarms-shopallitems',
	templateUrl: './desifarms-shopallitems.component.html',
	styleUrls: ['./desifarms-shopallitems.component.scss']
})
export class DesifarmsShopallitemsComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('elevatezoomBig') elevatezoomBig: ElementRef;

	Category_Name: string = ''
	Main_Category_Id: number = 0

	page: any
	skip: number = 0
	pageNo: number = 1
	prevPage: number
	take: number = 5000
	loadproduct: number = 9

	product_count: number = 0
	local_product_count: number = 0


	startPageNo: number = 1
	endPageNo: number = 1

	products: any[] = []
	categoriesList: any[] = []
	cartProduct: any[] = []
	subscribeCartProduct: any[] = []
	paginator: any[] = []
	productInfo: any

	options: any = { Main_Category_Id: 0, Sub_Category_Id: 0, Franchise_Id: 8, Start: this.skip, End: this.take, District_Id: null }
	outOfStock: any = { Franchise_Id: 8, Cust_Id: 0, Product_Id: 0, Product_Packaging_Rate_Id: 0 }
	paginate: any = {};
	tempMainCategory: string

	isFeatured: boolean = false
	isPagination: boolean = true

	loginData: any
	location: any

	constructor(
		private _fb: FormBuilder,
		private _commonService: DesifarmCommonService,
		private _authService: DesifarmLoginService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _productsService: DesifarmShopallitemsService,
		private _desifarmsheaderComponent: DesifarmsHeaderfooterComponent,
		private _localCart: DesifarmLocalCartService,
		private _dialog: MatDialog,
		private ngZone: NgZone
	) {
		this._localCart.selectedDistrict.subscribe((District_Id) => {
			this.options.District_Id = District_Id
		})
		this.getFranchise_Id()
	}

	getData() {
		this.getProducts()
		this.getCategoriesList()
		this.getCartProducts()
	}

	handleMetaTag = (categoryName) => {
		const fruit = categoryName.trim().toLowerCase().search('fruits')
		const vegetables = categoryName.trim().toLowerCase().search('vegetables')
		const herbs = categoryName.trim().toLowerCase().search('herbs')
		const dairy = categoryName.trim().toLowerCase().search('dairy')
		const grocery = categoryName.trim().toLowerCase().search('grocery')
		const bulkpurchase = categoryName.trim().toLowerCase().search('bulk purchase')
		const salad = categoryName.trim().toLowerCase().search('salad')

		if (categoryName == 1) {
			this._commonService.fruitsMetaTags()
			this._commonService.fruitsOgTags()
			this.handleProductInformation()
		}

		if (categoryName == 2) {
			this._commonService.vegetablesMetaTags()
			this._commonService.vegetablesOgTags()
			this.handleProductInformation()
		}

		if (categoryName == 3) {
			this._commonService.herbsMetaTags()
			this._commonService.herbsOgTags()
			this.handleProductInformation()
		}

		if (categoryName == 4) {
			this._commonService.dairyMetaTags()
			this._commonService.dairyOgTags()
			this.handleProductInformation()
		}

		if (categoryName == 5) {
			this._commonService.groceryMetaTags()
			this._commonService.groceryOgTags()
			this.handleProductInformation()
		}

		if (categoryName == 6) {
			this._commonService.bulkPurchaseMetaTags()
			this._commonService.bulkPurchaseOgTags()
			this.handleProductInformation()
		}

		if (categoryName == 9) {
			this._commonService.saladMetaTags()
			this._commonService.saladOgTags()
			this.handleProductInformation()
		}
	}

	handleProductInformation = () => {
		this._commonService.postApi("get_category_page_detail", { Main_Category_Id: this.options.Main_Category_Id }).subscribe((res: any) => {
			this.productInfo = res.data
		})
	}

	getProducts() {
		this._commonService._spinner.show()
		this._productsService.get_products(this.options).subscribe((res: any) => {
			this.paginator.length = 0
			this.product_count = res.product_count
			this.local_product_count += res.data.length
			// this.endPageNo = this.local_product_count

			res.data.map((e, i) => {
				e.Added_In_Cart = false;
				e.Cart_Qty = 0
			})
			for (let index = 1; index <= Math.ceil(this.product_count / 9); index++) {
				this.paginator.push(index)
			}
			this.products = res.data
			this._commonService._spinner.hide()
		}, () => { }, () => { this.getCartProducts() })
	}

	getCategoriesList() {
		this._localCart.selectedDistrict.subscribe((District_Id) => {
			this._productsService.get_all_categories_list(District_Id).subscribe((res: any) => {
				res.data.map(e => {
					if (e.Sub_Category_Id == null) e.Sub_Category_Id = ''
					else if (e.Sub_Category_Name == null) e.Sub_Category_Name = ''
					else {
						e.Sub_Category_Id = e.Sub_Category_Id.split('^')
						e.Sub_Category_Name = e.Sub_Category_Name.split('^')
						e.All_Sub_Categories_List = []
						e.Sub_Category_Id.forEach((element, index) => {
							e.All_Sub_Categories_List.push(
								{ Sub_Category_Id: element, Sub_Category_Name: e.Sub_Category_Name[index] }
							)
						})
					}
				})
				this.categoriesList = res.data
			})
		})
	}

	getCartProducts() {
		this._commonService._spinner.show()
		this._localCart.cartList.subscribe((res) => {
			this._commonService._spinner.hide()
			this.cartProduct = res
			this.updateCartStatus()
		}, () => { }, () => { })
	}

	ngOnInit(): void {
		this._route.params.subscribe((params) => {
			// this.Category_Name = params['categoryName']
			// this.tempMainCategory = params['categoryName']
			// this.Main_Category_Id = params['Main_Category_Id']
			// this.options.Main_Category_Id = this.Main_Category_Id
			// this.options.Sub_Category_Id = params['Sub_Category_Id']
			// this.getData()

			this.Category_Name = params['categoryName']
			this.tempMainCategory = params['categoryName']
			this.Main_Category_Id = params['Main_Category_Id']
			this.options.Main_Category_Id = this.Main_Category_Id
			this.options.Sub_Category_Id = params['Sub_Category_Id']
			// this.Main_Category_Id == 0.0 ? this.getAllProduct() : this.getData()
			if (this.Main_Category_Id == 0.0) {
				this.isPagination = false
				this.getCategoriesList()
				this.getCartProducts()
				this.getAllProduct()
			} else this.getData()

			this.handleMetaTag(this.Main_Category_Id)
		})
		this.scrollToTop()
		if (this._commonService.getUser() != null) {
			this.loginData = this._commonService.getUser()
			this.outOfStock.Cust_Id = this.loginData.Cust_Id
		} else this.outOfStock.Cust_Id = 0
	}

	ngAfterViewInit(): void {
		// this.ngZone.runOutsideAngular(() => {
		// 	$(this.elevatezoomBig.nativeElement).elevateZoom({
		// 		gallery: "gallery-zoom",
		// 		galleryActiveClass: "zoom-active",
		// 		containLensZoom: true,
		// 		zoomType: "inner",
		// 		cursor: "crosshair"
		// 	})
		// })
	}


	scrollToTop() {
		window.scrollTo(0, 0)
	}

	getFranchise_Id() {
		let location: Location = this._commonService.getData(environment.locationKey)
		// this.options.Franchise_Id = location.Franchise_Id
		this.options.Franchise_Id = 8
		this.location = location
	}

	onCategoryExpand(option) {
		this.isPagination = true
		this.options.Main_Category_Id = option.Main_Category_Id
		this.options.Sub_Category_Id = 0
		this.skip = 0
		this.options.Start = this.skip
		this.pageNo = 1
		this.local_product_count = 0
		this.getProducts()
		this.Category_Name = option.Category_Name
	}

	onSubCategories(option1) {
		this.isPagination = true
		this.Category_Name = option1.Sub_Category_Name
		this.options.Sub_Category_Id = option1.Sub_Category_Id
		this.Main_Category_Id = 0
		this.skip = 0
		this.options.Start = this.skip
		this.getProducts()
	}

	onFeatureProducts(option) {
		this.isFeatured = true
		this.Category_Name = option.Sub_Category_Name
		this.options.Sub_Category_Id = option.Sub_Category_Id
		this.options.Main_Category_Id = 0
		// this.getProducts()
		this.getData()
	}

	decreaseValueModal(option) {
		this.products.map((e) => {
			if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
				if (e.Cart_Qty == 1) {
					e.Added_In_Cart = false
					let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
					this.cartProduct.splice(index, 1)
					this.updateQty()
					this._commonService.onFailed("Product Removed Successfully..")
				} else {
					e.Cart_Qty = e.Cart_Qty - 1
					this.updateQty()
				}

			}
		})
	}

	increaseValueModal(option) {
		// this.products.map((e) => {
		// 	if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
		// 		// if (e.Cart_Qty < e.Avl_Qry) {
		// 		//   e.Cart_Qty = e.Cart_Qty + 1
		// 		//   this.updateQty()
		// 		// } else this._commonService.onFailed("Out Of Stock")
		// 		e.Cart_Qty = e.Cart_Qty + 1
		// 		this.updateQty()
		// 	}
		// })

		this.products.map((e) => {
			if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
				if (e.Cart_Qty < e.Avl_Qry) {
					e.Cart_Qty = e.Cart_Qty + 1
				} else this._commonService.onFailed("Out Of Stock")
			}
		})
		this.updateQty()
	}

	onCartQtyChange(event, option) {
		let qty = event.target.value
		if (qty && qty > 0) {
			// let qty = event.target.value
			this.products.map((e) => {
				if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
					// if (qty < e.Avl_Qry) e.Cart_Qty = parseInt(qty)
					// else this._commonService.onFailed("Out Of Stock")
					e.Cart_Qty = parseInt(qty)
				}
			})
		} else if (qty < 0 || qty == '' || qty == 0) {
			// this.products.map((e) => {
			// 	if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
			// 		e.Cart_Qty = e.Cart_Qty
			// 	}
			// })
			this.deleteProductFromCart(option)
		}
	}

	onAddToCart(option) {
		//TODO: Working Code
		// this.products.map((e, i) => {
		// 	if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
		// 		e.Added_In_Cart = true
		// 		e.Cart_Qty = 1
		// 		e.Product_Total = e.Cart_Qty * e.selling_amt
		// 		this._commonService.onSuccess("Product Added In Cart")
		// 	}
		// })
		// let product = this.products.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
		// this.cartProduct.push(product)
		// this._localCart.setCartProducts(this.cartProduct)

		this.products.map((e, i) => {
			if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
				if (e.Avl_Qry > 0) {
					e.Added_In_Cart = true
					e.Cart_Qty = 1
					e.Product_Total = e.Cart_Qty * e.selling_amt
					this._commonService.onSuccess("Product Added In Cart")
					let product = this.products.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
					this.cartProduct.push(product)
					this._localCart.setCartProducts(this.cartProduct)
				} else {
					this._commonService.onFailed("Out Of Stock")
					this.outOfStock.Product_Id = option.product_id
					this.outOfStock.Product_Packaging_Rate_Id = option.product_packaging_rate_id
					// this.outOfStock.Franchise_Id = this.location.Franchise_Id
					this.outOfStock.Franchise_Id = 8
					this.outOfStockHit()
				}
			}
		})

	}

	updateQty() {
		// this.products.map((e) => {
		// 	this.cartProduct.map((e1) => {
		// 		if (e.product_packaging_rate_id == e1.product_packaging_rate_id)
		// 			e1.Cart_Qty = e.Cart_Qty
		// 	})
		// })
		// this.updateCart()

		this.products.map((p) => {
			this.cartProduct.map((cp) => {
				if (p.product_packaging_rate_id == cp.product_packaging_rate_id)
					cp.Cart_Qty = p.Cart_Qty
			})
		})
		this.updateCart()
	}

	updateCart() {
		if (this.cartProduct.length > 0) {
			this.cartProduct.map((e) => {
				e.Product_Total = e.Cart_Qty * e.selling_amt
				e.Product_Total = e.Cart_Qty * e.selling_amt
				e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
				e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
				e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
			})
			this._localCart.setCartProducts(this.cartProduct)
		} else this._localCart.setCartProducts(this.cartProduct)
	}

	updateCartStatus() {
		//TODO: Working Code
		// this.products.map((e) => e.Added_In_Cart = false)
		// this.cartProduct.map((cp) => {
		// 	this.products.map((p) => {
		// 		if (cp.product_packaging_rate_id == p.product_packaging_rate_id) {
		// 			p.Added_In_Cart = true
		// 			p.Cart_Qty = cp.Cart_Qty

		// 		}
		// 	})
		// })
		this.products.map((e) => e.Added_In_Cart = false)
		this.cartProduct.map((cp) => {
			this.products.map((p) => {
				if (cp.product_packaging_rate_id == p.product_packaging_rate_id) {
					if (p.Avl_Qry > 0) {
						if (cp.Cart_Qty <= p.Avl_Qry) {
							p.Added_In_Cart = true
							p.Cart_Qty = cp.Cart_Qty
						} else {
							p.Added_In_Cart = true
							cp.Cart_Qty = p.Avl_Qry
							p.Cart_Qty = p.Avl_Qry
						}
					} else {
						let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == p.product_packaging_rate_id)
						this.cartProduct.splice(index, 1)
						this.updateCart()
					}
				}
			})
		})
	}

	setPage(page: number, buttonName: string) {
		this.prevPage = this.page
		this.pageNo = page;
		this.skip = (page - 1) * this.loadproduct;
		this.take = this.loadproduct;
		this.options.Start = this.skip
		this.options.End = this.take
		this.getProducts()
		this.scrollToTop()
	}

	onProductDetail(option) {
		const dialogRef = this._dialog.open(DesifarmProductDetailsComponent, {
			width: '800px',
			height: '70vh',
			panelClass: 'myclass2',
			data: option
		})
		dialogRef.afterClosed().subscribe(() => { this.getCartProducts() })
	}

	onSubscribe(option) {
		this.loginData = this._commonService.getUser()
		if (this.loginData) this.subscribeProduct(option)
		else this.onSignIn(option)

	}

	onSignIn(option) {
		const dialogRef = this._dialog.open(DesifarmLoginRegisterComponent, {
			width: '800px',
			panelClass: 'loginRegisterComponent',
		})
		dialogRef.afterClosed().subscribe(() => {
			this._desifarmsheaderComponent.getData()
			this.loginData = this._commonService.getUser()
			// this.subscribeProduct(option)
			if (this.loginData) this.subscribeProduct(option)
		})
	}

	subscribeProduct(option) {
		let product = this.products.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
		product.Added_In_Cart = true
		product.Cart_Qty = 1
		product.Product_Total = product.Cart_Qty * product.selling_amt
		this.subscribeCartProduct.push(product)
		this._localCart.setSubscribeCartProducts(this.subscribeCartProduct)
		this._router.navigate(['subscribe-cart'])
	}

	deleteProductFromCart(option) {
		let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
		this.cartProduct.splice(index, 1)
		this.updateQty()
	}

	outOfStockHit() {
		this._productsService.insert_out_of_stock(this.outOfStock).subscribe((res: any) => {
		})
	}

	getAllProduct() {
		this.isPagination = false
		this.Category_Name = "All Products"
		this.options.Sub_Category_Id = 0
		this.skip = 0
		this.options.Start = this.skip
		this._commonService._spinner.show()
		this._productsService.get_all_products(8, this.options.District_Id).subscribe((res: any) => {
			this.paginator.length = 0
			this.product_count = res.data.length
			this.local_product_count += res.data.length

			res.data.map((e, i) => {
				e.Added_In_Cart = false;
				e.Cart_Qty = 0
			})
			for (let index = 1; index <= Math.ceil(this.product_count / 9); index++) {
				this.paginator.push(index)
			}
			this.products = res.data
			this._commonService._spinner.hide()
		}, () => { }, () => { this.getCartProducts() })
	}


	checkFirstNumber() {
		let result = this.paginator[0]
		if (result == this.pageNo) return true
		else return false
	}

	checkLastNumber() {
		let result = this.paginator[this.paginator.length - 1]
		if (result == this.pageNo) return true
		else return false
	}

	zoomIn() {
		// let url = `https://cdnjs.cloudflare.com/ajax/libs/elevatezoom/2.2.3/jquery.elevatezoom.js`
		// let node = document.createElement('script');
		// node.src = url;
		// node.type = 'text/javascript';
		// node.async = true;
		// document.getElementsByTagName('body')[0].appendChild(node);


		// $("#img-zoom").elevateZoom({
		// 	gallery: "gallery-zoom",
		// 	galleryActiveClass: "zoom-active",
		// 	containLensZoom: true,
		// 	zoomType: "inner",
		// 	cursor: "crosshair"
		// });
	}

	ngOnDestroy() {
		this.isFeatured = false
	}
}


