import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundoff'
})
export class RoundoffPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform = (value: number): any => value % 1 != 0 ? Math.round(value) : value
  //  transform = (status: number): string => status === 1 ? 'Active' : 'Inactive'

}
