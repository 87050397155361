import { FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { FormBuilder } from '@angular/forms';
import { DesifarmMyordersService } from 'src/app/services/desifarm-dashboard/desifarm-myorders/desifarm-myorders.service';
import { DesifarmsMyordersComponent } from '../desifarms-myorders.component';
import { UserOrderGroup } from 'src/app/models/desifarm-res/desifarm-dashboard/desifarm-myorders/userordergroup';

@Component({
  selector: 'app-desifarms-myorder-details',
  templateUrl: './desifarms-myorder-details.component.html',
  styleUrls: ['./desifarms-myorder-details.component.scss']
})
export class DesifarmsMyorderDetailsComponent implements OnInit {

  Total_Discount_Amount: number = 0
  Value_Of_VAT_Amt: number = 0
  Value_Of_Base_Amt: number = 0
  Sub_Total_Amount: number = 0
  Grand_Total_Amount: number = 0
  Order_Delivery_Charges: number = 0
  Wallet_Amount_Used: number = 0
  SubTotalPlusDeliveryCharges: number = 0


  isOrderCancelFlag: boolean = false

  orderCancelDetail: any

  form: FormGroup

  constructor(
    private _commonService: DesifarmCommonService,
    private _dialog: MatDialog,
    private _fb: FormBuilder,
    private _myOrdersService: DesifarmMyordersService,
    private _myOrdersComponent: DesifarmsMyordersComponent,
    public _dialogRef: MatDialogRef<DesifarmsMyorderDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: UserOrderGroup,
  ) {
    this.Sub_Total_Amount = _data.Orders
      .map(e => e.Total_Selling_Amount)
      .reduce((prev, next) => prev + next)
    this.Order_Delivery_Charges = _data.Orders[0].Order_Delivery_Charges
    this.Wallet_Amount_Used = _data.Orders[0].Wallet_Amount_Used

    let Total = this.Order_Delivery_Charges + this.Sub_Total_Amount
    this.SubTotalPlusDeliveryCharges = Total
    Total -= this.Wallet_Amount_Used
    this.Grand_Total_Amount = Total

    this.Value_Of_Base_Amt = _data.Orders.map(e => e.Base_Amt).reduce((prev, next) => prev + next, 0).toFixed(2)
    this.Value_Of_VAT_Amt = _data.Orders.map(e => e.VAT_Amt).reduce((prev, next) => prev + next, 0).toFixed(2)

  }

  initForm() {
    this.form = this._fb.group({
      Order_Cancelation_Reason: ['', [Validators.required]],
      Sale_Order_Id: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.initForm()
    this.Total_Discount_Amount = this._data.Orders.map(e => e.Total_Discount_Amount).reduce((prev, next) => prev + next, 0)
  }

  back = () => this._dialogRef.close()

  toggleCancelFlag = (data) => {
    this.form.patchValue({
      Sale_Order_Id: data.Sale_Order_Id
    })
    this.orderCancelDetail = data
    this.isOrderCancelFlag = !this.isOrderCancelFlag
  }

  onClose = () => this.isOrderCancelFlag = !this.isOrderCancelFlag

  submit() {
    if (!this.form.invalid) {
      this._commonService._spinner.show()
      this._myOrdersService.cancel_order_from_customer(this.form.value).subscribe((res: any) => {
        this._commonService._spinner.hide()
        if (res.ack) {
          this._commonService.onSuccess(res.msg)
          this.onClose()
          this.back()
          this._myOrdersComponent.getData()
        } else this._commonService.onFailed(res.msg)
      }, (err) => {
        this._commonService._spinner.hide()
        this._commonService.onFailed("Internet Conection Failed")
      })
    } else this._commonService.onFailed("Please Type Cancellation Reason")
  }

}
