<div class="container" style="border: 2px solid lightgray;">
    <div class="row">
        <!-- <div class="col-md-5 col-sm-12" style="background: url('assets/img/signin/1.jpg');    background-size: cover;">
            <div></div>
        </div> -->
        <div class="col-md-12 col-sm-12">
            <div class="mylogin" *ngIf="loginFlag">
                <h1>Welcome to NSK Store</h1>
                <div class="bottomline"></div>
                <div class="login-register-form">
                    <form [formGroup]="form">
                        <div class="form-box__single-group mb-4 first-div-modal" style="margin-top: 80px;">
                            <label for="form-username" class="mylabel">Mobile Number *</label>
                            <input autocomplete="off" maxlength="10" appOnlynumbers formControlName="Customer_Mobile_Number" type="text"
                                id="form-username" autofocus placeholder="Mobile Number" required>
                        </div>

                        
                        <div class="text-center mt-3 mb-4"> <button (click)="submit()"
                                class="btn btn--box loginbtn btn--medium btn--radius btn--dark btn--black btn--black-hover-green  font--semi-bold"
                                [disabled]="form.invalid">Login</button></div>
                        <div class="row mb-5 ml-2 mr-2 pt-3 pb-1 second-div-modal"
                            style="background:lightgray;text-align: center;margin-top: 90px;">

                            <div class="col-md-4">
                                <div class="logintext">
                                    <i class="fas fa-shipping-fast"></i>
                                    <p>Fast Delivery</p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="logintext">
                                    <!-- <i class="fas fa-store"></i> -->
                                    <img src="assets/img/signin/1.png" class="img-responsive" alt="Fresh To Door"/>
                                    <p>Fresh To Door</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="logintext">
                                    <!-- <i class="fas fa-handshake"></i> -->
                                    <img src="assets/img/signin/2.png" class="img-responsive" alt="No Middlemen"/>
                                    <p>No Middlemen </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="login-form-container mylogin mb-5" *ngIf="otpFlag">
                <h1> OTP VERIFICATION</h1>
                <div class="bottomline"></div>
                <div class="login-register-form mt-5 ">
                    <form [formGroup]="form">

                        <div class="form-box__single-group">
                            <!-- <label for="form-username">OTP *</label> -->
                            <h3 class="first-div-modal" style="margin-bottom: 0;margin-top: 60px;">OTP Sent on Your
                                Mobile No Ending with
                                **{{Customer_Mobile_Number | slice:-3 }}</h3>
                            <input type="text" appOnlynumbers maxlength="6" placeholder="OTP" autofocus required (input)="onOtpChange($event)"
                                #OtpInput>
                        </div> <br>
                        <div class="text-center"> <button (click)="verifyOTP()"
                                class="btn mb-5 btn--box btn--medium otpbtn btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"
                                type="submit">VERIFY OTP</button><br>
                            <span style="font-size: 18px;">Didn't receive the OTP?</span><br><br>
                            <a href="#" class="mb-5 resend" style="color: #ff9800; font-size: 20px;">RESEND OTP</a>
                        </div>
                    </form>
                </div>
            </div>

            <div class="login-form-container mylogin" *ngIf="signUpFlag">
                <h1 style="margin-top:10px;">User Registration</h1>
                <div class="login-register-form mt-5">
                    <form [formGroup]="form1">
                        <div class="form-box__single-group registerform">
                            <!-- <label class="mylabel" for="form-register-username">Your Name *</label> -->
                            <input formControlName="Cust_Name" type="text" placeholder="Your Name *" required>
                        </div>
                        <div class="row">
                            <div class="form-box__single-group registerform">
                                <!-- <label class="mylabel" for="form-uregister-sername-email">Gender *</label> -->
                                <select formControlName="Gender">
                                    <option value="" disabled selected>Select Gender *</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                        </div>


                        <div class="form-box__single-group registerform">
                            <!-- <label class="mylabel" for="form-uregister-sername-email">How Did You Find Us *</label> -->
                            <select formControlName="Source">
                                <option value="" disabled selected>How Did You Find Us *</option>
                                <option *ngFor="let option of sources" [value]="option">{{option}}</option>
                            </select>
                        </div>
                        <div class="form-box__single-group registerform">
                            <input list="city" formControlName="District_Name" type="text" placeholder="Select City *" required (change)="onCity($event)">
                                    <datalist id="city">
                                        <option *ngFor="let options of districts" [value]="options.District_Name">
                                    </datalist>
                        </div>
                        <div class="form-box__single-group registerform">
                            <input list="Area" formControlName="Area_Name" type="text" placeholder="Select Area *" required (change)="onArea($event)">
                            <datalist id="Area">
                                <option *ngFor="let options of areaList" [value]="options.Area_Name">
                            </datalist>
                        </div>
                        <div class="form-box__single-group registerform">
                            <input list="subArea" formControlName="Sub_Area_Name" type="text" placeholder="Sub Area (Optional)" (change)="onSubArea($event)" >
                            <datalist id="subArea">
                                <option *ngFor="let options of subAreas" [value]="options.Sub_Area_Name">
                            </datalist>
                        </div>

                        <!-- <div class="form-box__single-group registerform">
                            <div class="row">
                                <div class="col">
                                    <label class="mylabel" for="form-register-username">Select City *</label>
                                    <input list="city" formControlName="District_Name" type="text" placeholder="City" required (change)="onCity($event)">
                                    <datalist id="city">
                                        <option *ngFor="let options of districts" [value]="options.District_Name">
                                    </datalist>
                                </div>
                                <div class="col">
                                    <label class="mylabel" for="form-register-username">Area *</label>
                                    <input list="Area" formControlName="Area_Name" type="text" placeholder="Area" required (change)="onArea($event)">
                                    <datalist id="Area">
                                        <option *ngFor="let options of areaList" [value]="options.Area_Name">
                                    </datalist>
                                </div>
                                <div class="col">
                                    <label class="mylabel" for="form-register-username">Sub Area </label>
                                    <input list="subArea" formControlName="Sub_Area_Name" type="text" placeholder="Optional" (change)="onSubArea($event)" >
                                    <datalist id="subArea">
                                        <option *ngFor="let options of subAreas" [value]="options.Sub_Area_Name">
                                    </datalist>
                                </div>
                            </div>
                        </div> -->

                        <div class="form-box__single-group registerform">
                            <!-- <label class="mylabel" for="form-register-username">Referal Code</label> -->
                            <input (change)="checkReferCode($event)" formControlName="Temp_Referral_Code" type="text"
                                placeholder="Referal Code">
                            <p *ngIf="refercodeDetail" class="text-success">Refer By - {{refercodeDetail}}</p>
                        </div>
                        <br>
                        <!-- <div class="form-box__single-group m-b-20">
                            <label for="form-register-username-password">Password *</label>
                            <div class="password__toggle">
                                <input [type]="passwordType ? 'text' : 'password'"
                                    id="form-register-username-password" placeholder="Enter password"
                                    required>
                                <span (click)="passwordToggle()"
                                    data-toggle="#form-register-username-password"
                                    class="password__toggle--btn fa" [ngClass]="{
                                    'fa-eye-slash': !passwordType,
                                    'fa-eye': passwordType
                                    }"></span>
                            </div>
                        </div> -->
                        <div class="text-center"><button [disabled]="form1.invalid"
                                class="btn btn--box btn--medium registerbtn btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"
                                (click)="onRegister()">REGISTER</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>