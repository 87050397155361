<main id="main-container" class="main-container">

  <div class="
            hero
            slider-dot-fix
            slider-dot slider-dot-fix
            slider-dot--center
            slider-dot-size--medium
            slider-dot-circle
            slider-dot-style--fill
            slider-dot-style--fill-white-active-green
            dot-gap__X--10
            homeslider" id='heroSliderCustom'>

  </div>

  <!-- ::::::  Start  Product Style - Default Section  ::::::  -->
  <div class="product mt-2" *ngIf="featureProducts">
    <div class="container">
      <div class="row">
        <!-- <div class="col-12">
          <div class="section-content section-content--border mb-3">
            <h2>Most Popular Products</h2>
          </div>
        </div> -->
        <div class="section-title">
          <h2>Most Popular Products</h2>
        </div>
        <hr>
      </div>
      <div class="row popularproducts">
        <div class="col-12">
          <owl-carousel-o [options]="customOptions">

            <ng-container *ngFor="let slide of featureProducts">
              <ng-template carouselSlide [id]="slide.Product_Id">
                <img [src]="slide.Product_Image" [alt]="slide.Product_Name" [title]="slide.Product_Name"
                  style="width: 260px;position: relative;" class="popularproducts-img" (click)="onFeatures(slide)">
                <div class="producttext">
                  <p>{{slide.Product_Name}}</p>
                  <a class="btn btn--black shopnowproduct text-uppercase" (click)="onFeatures(slide)">Shop Now</a>
                </div>

              </ng-template>
            </ng-container>

          </owl-carousel-o>

        </div>
      </div>
      <div class="row">
        <div class=""></div>
      </div>
    </div>
  </div>
  <!-- ::::::  End  Product Style - Default Section  ::::::  -->

  <!--New Category Start-->
  <!-- <section style="background-color: #f6f6f6;border-bottom: 2px solid #62aa00;" class="mt-3 categorysection">
    <div class="container pt-2">
      <div class="row">
        <div class="col-12 text-center">
          <div class="default-slider default-slider--hover-bg-red">
            <div class="company-logo__area gap__col--20" appCompanySlider>
              <div class=" " *ngFor="let option of AllmainCategoriesImagesDairy" (click)="onProducts(option)">
                <div class="categories" style="cursor: pointer;" (click)="onProducts(option)">
                  <img [src]="option.Category_Image" class="img-responsive">
                  <p class="smalldiv">{{option.Category_Name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!--New Category END-->
  <!-- <section style="background-color: #f6f6f6;border-bottom: 2px solid #62aa00; margin-top: 0rem!important;"
    class="mt-3 categorysection">
    <div class="container pt-2">
      <div class="row" style="margin-top: 16px;">
        <div class="col-12 text-center">
          <div class="default-slider default-slider--hover-bg-red">
            <div class="company-logo__area gap__col--20" appCompanySlider>
              <div class="" *ngFor="let option of AllmainCategoriesImagesDairy" (click)="onProducts(option)">
                <div class="categories1" style="cursor: pointer;" (click)="onProducts(option)" appColorChanger>
                  <div class="ImgSec">
                    <img [src]="option.Category_Image" class="img-responsive2nd">
                  </div>

                  <div class="TextSec">
                    <p class="smalldiv">{{option.Category_Name}}</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->


  <section style="background-color: #f6f6f6;border-bottom: 2px solid #62aa00; margin-top: 0rem!important;" class="mt-3">
    <div class="flex-container">
      <div class="child" *ngFor="let option of AllmainCategoriesImagesDairy; let i = index" (click)="onProducts(option)">
        <div class="categories1" style="cursor: pointer;" (click)="onProducts(option)" [ngStyle]="changeBackground(i)">
          <div class="ImgSec">
            <img [src]="option.Category_Image" class="img-responsive2nd" [alt]="option.Category_Name">
          </div>

          <div class="TextSec">
            <p class="smalldiv">{{option.Category_Name}}</p>
          </div>

        </div>
      </div>
      <!-- <div *ngFor="let option of AllmainCategoriesImagesDairy" appColorChanger>
        <img [src]="option.Category_Image" class="img-responsive2nd">
        <p class="smalldiv">{{option.Category_Name}}</p>
      </div> -->
      <!-- <div>1</div>
      <div>2</div>
      <div>3</div>  
      <div>4</div>
      <div>5</div>
      <div>6</div>  
      <div>7</div>
      <div>8</div> -->
    </div>
  </section>


  <!-- <section style="background-color: #f6f6f6;border-bottom: 2px solid #62aa00; margin-top: 0rem!important;" class="mt-3 categorysection">
    <div class="container pt-2">
        <div class="row" style="margin-top: 16px;">
            <div class="col-12 text-center">
                <div class="default-slider default-slider--hover-bg-red">
                    <div class="company-logo__area gap__col--20" appCompanySlider>
                        <div class=" " *ngFor="let option of myImages" (click)="onProducts(option)">
                            <div class="categories1" style="cursor: pointer;" (click)="onProducts(option)">
                                <div class="ImgSec">
                                    <img [src]="option.image" class="img-responsive2nd">
                                </div>

                                <div class="TextSec">
                                    <p class="smalldiv">{{option.name}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

  <!--PRODUCT CATEGORY START-->
  <div class="container mt-2  pb-2" *ngIf="bestSellerProducts.length > 0">
    <div class="row">

      <ng-conatiner>
        <div class="section-title mar-ten mt-2 ">
          <h2 class="mb-2">Bestsellers</h2>
        </div>
        <section class="slide-prod-section">

          <div class="row">
            <div class="col-12">
              <div class="default-slider default-slider--hover-bg-red">

                <div class="blog-feed-slider-3grid-best-seller gap__col--20 " appTestimonialSlider>
                  <div class=" " *ngFor="let option of bestSellerProducts">
                    <div class="product__box  mt-1" style="margin: 10px;">
                      <div class="product__default--singles">
                        <div>
                          <div class="pos-relative text-center pb-1">
                            <a routerlink="products" class="product__img--link">
                              <img [alt]="option.product_name" class="product__img img-fluid" [src]="option.product_image"></a>
                              <p *ngIf="option.package_highlight !=''" class="mb-0 heading-for-highlighter">{{option.package_highlight}}</p>
                            <ul class="product__action--link pos-absolute">
                            </ul>
                          </div>
                        </div>
                        <div class="product__content">
                          <div style="height: 60px;">
                            <a routerlink="products" class="product__link mt-0 mb-0">{{option.product_name}} </a>
                            <p class="graycolor color-primary mb-4"> {{option.brand_name}} </p>
                          </div>
                          <div style="height: 40px;">
                            <div class="product__price">
                              <span class="product__price">{{option.selling_amt }} <del class="mrp-cut" *ngIf="option.product_package_mrp > option.selling_amt">{{option.product_package_mrp}}</del> /{{option.unit_name}}</span><br>
                              <span class="package-contains">{{option.package_contains}}</span>
                            </div>
                            <!-- <p class="mb-0 discount-amt" *ngIf="option.product_package_mrp != option.selling_amt">
                              {{option.discount_per | roundoff}}% OFF
                            </p> -->
                          </div>
                          <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6 col-xs-6 pr-0 pl-0">
                              <div class="product-quantity
                              d-flex
                              align-items-center
                              flex-wrap
                              mt-2 notcenter" *ngIf="option.Added_In_Cart">
                                <!-- <a *ngIf="!option.Added_In_Cart" class="btn addtocart btn--black btn--black-hover-green"
                                  (click)="onAddToCartBestSeller(option)">Add To Cart</a>
                                  
                                <a *ngIf="option.Product_Package_Available_For_Subscription == 1" class="btn addtocart btn--black btn--black-hover-green"
                                  (click)="onSubscribe(option)">Subscribe</a> -->
                                <form class="modal-quantity-scale ml-1 onlyfortab" *ngIf="option.Added_In_Cart">
                                  <div class="value-button" id="modal-decrease"
                                    (click)="decreaseValueModalBestSeller(option)"> - </div>
                                  <input
                                    style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;"
                                    class="inputvalue" type="number" [value]="option.Cart_Qty"
                                    (change)="onCartQtyChangeBestSeller($event, option)" disabled />
                                  <div class="value-button" id="modal-increase"
                                    (click)="increaseValueModalBestSeller(option)"> + </div>
                                </form>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-6 mt-2 notcenter mobileview"
                              *ngIf="option.Product_Package_Available_For_Subscription == 1 && option.Added_In_Cart"
                              (click)="onSubscribe(option)">
                              <a class=" btn addtocart btn--black btn--black-hover-green">Subscribe</a>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6 col-xs-6 mt-2 notcenter nopadding"
                              *ngIf="!option.Added_In_Cart" (click)="onAddToCartBestSeller(option)">
                              <a class="
                                  btn
                                  addtocart
                                  btn--black btn--black-hover-green
                                 
                                ">Add To Cart</a>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-6 text-center mt-2 notcenter mobileview"
                              *ngIf="option.Product_Package_Available_For_Subscription == 1 && !option.Added_In_Cart"
                              (click)="onSubscribe(option)">
                              <a class="
                                  btn
                                  addtocart
                                  btn--black btn--black-hover-green
                                 
                                ">Subscribe</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ng-conatiner>

      <!-- <hr> -->
    </div>

  </div>

  <!--PRODUCT CATEGORY END-->



  <!--PRODUCT CATEGORY START-->
  <div class="container mt-3 mobilediv pb-2" *ngIf="mainCategoriesImages || mainCategoriesImagesDairy">
    <div class="row">
      <div class="row ">
        <div class="col-md-12 titlemargin padd-left">
          <h2><span class="back-title">Explore Our Range Of Products</span></h2>
        </div>
      </div>
      <ng-conatiner *ngFor="let option of activeMainCategorieList" style="margin: -3px 0px 3px 0px;">
        <div class="section-title mb-2 mt-2" *ngIf="option.Products.length > 0">
          <div class="row align-items-center">

            <div class="col-md-10 col-xs-8 padd-left">
              <h2 style="display: inline-block;">{{option.Category_Name}} </h2>
            </div>
            <div class="col-md-2 col-xs-4" style="text-align: right;">
              <a class="viewall-btn" href="javascript:void(0)" (click)="onProducts(option)">View All</a>
            </div>
          </div>
        </div>

        <section class="slide-prod-section">

          <div class="row">
            <div class="col-12">
              <div class="default-slider default-slider--hover-bg-red">

                <div class="blog-feed-slider-3grid-homepage-prod gap__col--20 " appTestimonialSlider>
                  <div class=" " *ngFor="let opt of option.Products">
                    <div class="product__box  mt-1" style="margin: 10px;">
                      <div class="product__default--singles">
                        <div>
                          <div class="pos-relative text-center pb-1">
                            <a routerlink="products" class="product__img--link">
                              <img [alt]="opt.product_name" class="product__img img-fluid" [src]="opt.product_image"></a>
                              <p *ngIf="opt.package_highlight !=''" class="mb-0 heading-for-highlighter">{{opt.package_highlight}}</p>
                            <ul class="product__action--link pos-absolute">
                            </ul>
                          </div>
                        </div>
                        <div class="product__content">
                          <div style="height: 60px;">
                            <a routerlink="products" class="product__link mt-0 mb-0">{{opt.product_name}} </a>
                            <p class="graycolor color-primary mb-4">
                              {{opt.brand_name}}
                            </p>
                          </div>
                          <div style="height: 40px;">
                            <div class="product__price ">
                              <span class="product__price">{{opt.selling_amt}} <del class="mrp-cut" *ngIf="opt.product_package_mrp > opt.selling_amt">{{opt.product_package_mrp}}</del> /{{opt.unit_name}}</span> <br>
                              <span class="package-contains">{{opt.package_contains}}</span>
                            </div>
                            <!-- <p class="mb-0 discount-amt" *ngIf="opt.discount_per > 0"> {{opt.discount_per | roundoff}}%
                              OFF 
                            </p> -->
                          </div>
                          <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6 col-xs-6 pr-0 pl-0">
                              <div class="product-quantity
                              d-flex
                              align-items-center
                              flex-wrap
                              mt-2 notcenter" *ngIf="opt.Added_In_Cart">

                                <form class="modal-quantity-scale ml-1 onlyfortab">
                                  <div class="value-button" id="modal-decrease"
                                    (click)="decreaseValueModal(opt, 'products')"> - </div>
                                  <input
                                    style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;"
                                    class="inputvalue" type="number" [value]="opt.Cart_Qty"
                                    (change)="onCartQtyChange($event, opt, 'products')" disabled />
                                  <div class="value-button" id="modal-increase"
                                    (click)="increaseValueModal(opt, 'products')"> + </div>
                                </form>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-6 mt-2 notcenter mobileview"
                              *ngIf="opt.Product_Package_Available_For_Subscription == 1 && opt.Added_In_Cart"
                              (click)="onSubscribeExplore(opt)">
                              <a class=" btn addtocart btn--black btn--black-hover-green">Subscribe</a>
                            </div>
                          </div>
                          <!-- <div class="text-center mt-2 notcenter">
                           <a *ngIf="!opt.Added_In_Cart" class="btn addtocart btn--black btn--black-hover-green "
                              (click)="onAddToCart(opt, 'products')">Add To Cart</a>
                              
                              <a *ngIf="opt.Product_Package_Available_For_Subscription == 1" class="btn addtocart btn--black btn--black-hover-green"
                              (click)="onSubscribeExplore(opt)">Subscribe</a> 
                            
                          </div> -->
                          <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6 col-xs-6 mt-2 notcenter nopadding" *ngIf="!opt.Added_In_Cart"
                              (click)="onAddToCart(opt, 'products')">
                              <a class="
                                  btn
                                  addtocart
                                  btn--black btn--black-hover-green
                                 
                                ">Add To Cart</a>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-6 text-center mt-2 notcenter mobileview"
                              *ngIf="opt.Product_Package_Available_For_Subscription == 1 && !opt.Added_In_Cart"
                              (click)="onSubscribeExplore(opt)">
                              <a class="
                                  btn
                                  addtocart
                                  btn--black btn--black-hover-green
                                 
                                ">Subscribe</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ng-conatiner>


      <!-- <div class="offerdiv hero mt-3 mb-5 p-0">
        <div class="hero-slider" *ngFor="let option of homepageOffer">
          <img [src]="option.Offer_Image" alt="">
        </div>
      </div> -->


      <!-- Static Images  -->
      <!-- <div class="container">
        <div class="row mt-2">
          <div class="col-md-10 col-xs-12 titlemargin ">
            <h2><span class="back-title smalltitle">Customer Testimonials</span></h2>
          </div>
          <div class="col-md-2 col-xs-12 mt-2" style="text-align: right;">
            <a class="viewall-btn" href="javascript:void(0)" routerLink="/testimonial">View All</a>
          </div>
        </div>
      </div> -->

    </div>
  </div>

  <!--PRODUCT CATEGORY END-->

  <!-- TESTIMONIALS START-->
  <!-- <div class="container">
    <div class="row testimonial-row">
      <div class="col-md-4  ">
        <div class="testiholder p-2">
          <div class="row align-items-center  ">
            <div class="col-md-5 col-xs-6">
              <div class="img-holder">
                <img src="assets/img/testimonial/Kautubh_Karale.jpg" class="img-responsive rounded-circle" />

              </div>
            </div>
            <div class="col-md-6 col-xs-6"> <span class="user-title">Kaustubh Karale</span></div>

          </div>
          <p class="mt-2">Since 2018, we have been having NSK Store Whole Cow Milk. This milk is pure and nutritious.
            So, we
            especially started taking this milk for our son. It will be good for his growth. What I like about
            this milk is that it is thick and creamy. </p>
          <p>I give my son 1 or 2 glasses of Whole Cow Milk daily and
            absolutely loves it.</p>
        </div>
      </div>

      <div class="col-md-4  ">
        <div class="testiholder p-2">
          <div class="row align-items-center  ">
            <div class="col-md-5 col-xs-6">
              <div class="img-holder">
                <img src="assets/img/testimonial/kalpesh.jpg" class="img-responsive rounded-circle" />

              </div>
            </div>
            <div class="col-md-6 col-xs-6"> <span class="user-title">Kalpesh Patil</span></div>

          </div>
          <p class="mt-2">We have subscribed to NSK Store Whole Buffalo Milk. Every day, exactly at 7.30 am
            the milk is delivered to home. Apart from this, we regularly place orders for paneer and curd, on
            the
            app. It is delivered within 2 hours. Their service is prompt. Plus, the delivery is free of cost</p>
        </div>
      </div>

      <div class="col-md-4  ">
        <div class="testiholder p-2">
          <div class="row align-items-center  ">
            <div class="col-md-5 col-xs-6">
              <div class="img-holder">
                <img src="assets/img/testimonial/Shamakant_Nangre.JPG" class="img-responsive rounded-circle" />

              </div>
            </div>
            <div class="col-md-6 col-xs-6"> <span class="user-title">Shamakant Nangre</span></div>

          </div>
          <p class="mt-2">I think we are regular customers of NSK Store. We get our daily milk from them,
            plus other food items like curd, ghee, and butter. I like their product quality and freshness. Also,
            the packaging is good. They deliver in insulated bags. It’s clean, hygienic, and convenient.</p>
        </div>
      </div>
    </div>
  </div> -->
  <!-- TESTIMONIALS END-->

</main>