import { DesifarmAck } from './../../models/desifarm-common/desifarmAck';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ResBannerImages } from 'src/app/models/desifarm-res/desifarm-homepage/desifarm-banner-images/resbannerimages';
import { ResMainCategories } from 'src/app/models/desifarm-res/desifarm-homepage/desifarm-categories-images/resmaincategoriesimages';
import { DesifarmLoginService } from 'src/app/services/desifarm-auth/desifarm-login/desifarm-login.service';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmBannerImagesService } from 'src/app/services/desifarm-homepage/desifarm-banner-images/desifarm-banner-images.service';
import { DesifarmMainCategoriesImagesService } from 'src/app/services/desifarm-homepage/desifarm-main-categories-images/desifarm-main-categories-images.service';
import { ObjectUnsubscribedError } from 'rxjs';
import { DesifarmFeatureProductsService } from 'src/app/services/desifarm-homepage/desifarm-feature-products/desifarm-feature-products.service';
import { DesifarmsShopallitemsComponent } from '../desifarms-shopallitems/desifarms-shopallitems.component';
import { DesifarmBlogsService } from 'src/app/services/desifarm-blogs/desifarm-blogs.service';
import { DesifarmProductDetailsComponent } from '../desifarms-shopallitems/desifarm-product-details/desifarm-product-details.component';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { DesifarmProductSubscribeDetailComponent } from '../desifarms-shopallitems/desifarm-product-subscribe-detail/desifarm-product-subscribe-detail.component';
import { DesifarmLoginRegisterComponent } from '../desifarm-login-register/desifarm-login-register.component';
import { DesifarmsHeaderfooterComponent } from '../desifarms-headerfooter/desifarms-headerfooter.component';
declare let $: any

@Component({
  selector: 'app-desifarms-homepage',
  templateUrl: './desifarms-homepage.component.html',
  styleUrls: ['./desifarms-homepage.component.scss']
})

export class DesifarmsHomepageComponent implements OnInit, OnDestroy {

  bannerImages: ResBannerImages[] = []
  mainCategoriesImages: ResMainCategories[] = []
  mainCategoriesImagesDairy: ResMainCategories[] = []
  AllmainCategoriesImagesDairy: ResMainCategories[] = []
  featureProducts: any[] = []
  bestSellerProducts: any[] = []
  blogs: any[] = []
  homepageOffer: any[] = []

  activeMainCategorieList: Array<any> = []

  cartProduct: any[] = []
  subscribeCartProduct: any[] = []
  products: any[] = []

  myImages = [
    { id: 1, image: "../../../assets/images/catgicon2.png", name: "Vegetables" },
    { id: 2, image: "../../../assets/images/Dairy.png", name: "Dairy" },
    { id: 3, image: "../../../assets/images/Fruits.png", name: "Fruits" },
    { id: 4, image: "../../../assets/images/Grocery.png", name: "Grocery" },
    { id: 4, image: "../../../assets/images/herbs.png", name: "Herbs" },
    { id: 5, image: "../../../assets/images/Salad.png", name: "Salad" },
    { id: 5, image: "../../../assets/images/Salad.png", name: "Salad" }
  ]

  colors = ['#43293c', '#212047', '#3f3e79', '#7c3623', '#5a025e', '#027f5b', '#605308', '#15500d']
  changeBackground(i): any {
    return { 'background-color': this.colors[i] };
  }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  loginData: any

  constructor(
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _authService: DesifarmLoginService,
    private _bannerImageService: DesifarmBannerImagesService,
    private _mainCategoriesImageService: DesifarmMainCategoriesImagesService,
    private _featureProductsService: DesifarmFeatureProductsService,
    private _desifarmsShopallitemsComponent: DesifarmsShopallitemsComponent,
    private _desifarmsheaderComponent: DesifarmsHeaderfooterComponent,
    private _blogService: DesifarmBlogsService,
    private _localCart: DesifarmLocalCartService,
    private _dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    this._localCart.getSelectedDistrict()
    this.getData()
  }

  getData() {

    this.get_banner_images()
    this.get_main_category_images()
    this.feature_products()
    this.get_blogs_list()

    this.getBestSellerProducts()
    this.getActiveMainCategory()
    this.getHomepageOffer()
    this._commonService.homePageMetaTags()
    this._commonService.homePageOgTag()
  }



  get_banner_images() {
    this._commonService._spinner.show()
    this._localCart.selectedDistrict.subscribe((res: any) => {
      this._bannerImageService.get_banner_images(res).subscribe((res: any) => {
        if (res.ack) {
          res.data.forEach(element => {
            element.slider = `<div class="hero-slider"><img src=${element.Slider_Image} alt=""></div>`
            var div = document.getElementById('heroSliderCustom');
            div.innerHTML += element.slider
          });
          this.bannerImages = res.data
          this.heroSliderScript()
        } else {
          this._commonService._spinner.hide()
        }

        this._commonService._spinner.hide()
      }, () => { }, () => {
        // this.heroSliderScript()
      })
    })
  }

  get_main_category_images() {
    // this._commonService.isMainCategoriesLoaded.next(false)
    this.AllmainCategoriesImagesDairy = []
    this._localCart.selectedDistrict.subscribe((res: any) => {
      this._mainCategoriesImageService.get_main_category_images(res).subscribe((res: any) => {
        if (res.ack) {
          let array = [{
            Business_Type_Id: 0,
            Category_Image: "../../../assets/img/all_products.png",
            Category_Name: "All Products",
            Main_Category_Id: 0.0,
            Rank_In_App: "1",
            Reg_Date: "",
            Staff_Id: 0,
            Status: 0,
          }]
          // this.AllmainCategoriesImagesDairy = array.concat(res.data)
          // this.AllmainCategoriesImagesDairy = res.data
          this.AllmainCategoriesImagesDairy = res.data.slice(0, 8);
          this.mainCategoriesImages = res.data.filter(e => e.Business_Type_Id == 2)
          this.mainCategoriesImagesDairy = res.data.filter(e => e.Business_Type_Id == 1)
          this._commonService.isMainCategoriesLoaded.next(true)
          // this.renderer.setStyle(this.categoryRef.nativeElement, 'background', `rgb('${(Math.floor((180 - 0) * Math.random()))}')`)
        }

      }, () => { })
    })
  }

  feature_products() {
    this._featureProductsService.feature_products().subscribe((res: any) => {
      this.featureProducts = res.data
    }, () => { }, () => { })
  }

  get_blogs_list() {
    this._blogService.get_blogs_list().subscribe((res: any) => {
      this.blogs = res.data
    })
  }

  getActiveMainCategory() {
    this._localCart.selectedDistrict.subscribe((District_Id) => {
      this._mainCategoriesImageService.getActiveMainCategory(District_Id).subscribe((res: any) => {
        if (res.ack) {
          this.activeMainCategorieList = res.data
          this.activeMainCategorieList.map(e => {
            let ary = ''
            ary = e.Products
            this.products.push(...ary)
          })
          this._commonService.isHomePageProductsLoaded.next(true)
          this.getCartProducts()
        }
      })
    })
  }

  getBestSellerProducts() {
    this._localCart.selectedDistrict.subscribe((res: any) => {
      this._mainCategoriesImageService.getBestSellerProducts(res).subscribe((res: any) => {
        this.bestSellerProducts = res.data
        this._commonService.isBestSellerLoaded.next(true)
      })
    })
  }

  getHomepageOffer() {
    this._localCart.selectedDistrict.subscribe((District_Id) => {
      this._bannerImageService.getHomepageOffer(District_Id).subscribe((res: any) => {
        if (res.ack) {
          this.homepageOffer = res.data
          // this.offerSliderScript()
          // this.heroSliderScript()
          // this._commonService.isHomePageOffer.next(true)
          // this._commonService.isHomePageOffer.subscribe(res => console.log('********** Offer *************', res))

        }
      })
    })

  }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()
    //Multiple Color Bg 
    $(document).ready(function () {
      var data = "<div class='default-slider'><div class='categories1'></div></div>";
      for (var i = 1; i <= 12; i++) {
        $('.default-slider').append(data);
      }

      // $('.categories1').each(function() {
      //     var colors = 'rgb(' +
      //         (Math.floor((180 - 0) * Math.random())) + ',' +
      //         (Math.floor((180 - 0) * Math.random())) + ',' +
      //         (Math.floor((180 - 0) * Math.random())) +
      //         ' )';
      //     $(this).css("background-color", colors);
      // });
    })
    //Multiple Color Bg End
  }

  heroSliderScript() {
    this._commonService._spinner.show()
    setTimeout(() => {
      try {
        $('.hero').slick({
          arrows: true,
          fade: true,
          // dots: true,
          easing: 'linear',
          autoplay: true,
          speed: 2000,
          prevArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--left"><i class="far fa-chevron-left"></i></button>',
          nextArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--right"><i class="far fa-chevron-right"></i></button>',
          responsive: [

            {
              breakpoint: 768,
              settings: {
                arrows: false,
              }
            },
          ]
        });
        this._commonService._spinner.hide()
      } catch (error) {

      }
    }, 1000)
  }
  // heroSliderScript() {
  //   setTimeout(() => {
  //     $('.hero').slick({
  //       arrows: true,
  //       fade: true,
  //       dots: true,
  //       easing: 'linear',
  //       autoplay: true,
  //       speed: 2000,
  //       prevArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--left"><i class="far fa-chevron-left"></i></button>',
  //       nextArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--right"><i class="far fa-chevron-right"></i></button>',
  //       responsive: [

  //         {
  //           breakpoint: 768,
  //           settings: {
  //             arrows: false,
  //           }
  //         },
  //       ]
  //     });
  //   }, 2000)
  // }



  onFeatures(option) {
    const { Category_Name, Main_Category_Id, Sub_Category_Id } = option
    this._commonService.router.navigate(['products', Category_Name, 0, Sub_Category_Id])
    // this._desifarmsShopallitemsComponent.onFeatureProducts(option)
  }

  onProducts(option) {
    const { Category_Name, Main_Category_Id, Sub_Category_Id } = option
    this._commonService.router.navigate(['products', Category_Name, Main_Category_Id, 0])
  }

  onDetail(option) {
    // this.blogs[option]
    // localStorage.setItem("blogs", JSON.stringify(option))
    localStorage.setItem("blogs", JSON.stringify(this.blogs[option]))
    this._commonService.router.navigate(['/blog-details'])
  }

  onProductDetail(option) {
    const dialogRef = this._dialog.open(DesifarmProductDetailsComponent, {
      width: '800px',
      height: '70vh',
      panelClass: 'myclass2',
      data: option
    })

  }

  updateCartStatus() {

    this.activeMainCategorieList.map(e => {
      e.Products.map((element) => {
        element.Added_In_Cart = false
      })
    })

    this.cartProduct.map((cp) => {
      this.activeMainCategorieList.map((aml) => {
        aml.Products.map((p) => {
          if (cp.product_packaging_rate_id == p.product_packaging_rate_id) {
            if (p.Avl_Qry > 0) {
              if (cp.Cart_Qty <= p.Avl_Qry) {
                p.Added_In_Cart = true
                p.Cart_Qty = cp.Cart_Qty
              } else {
                p.Added_In_Cart = true
                cp.Cart_Qty = p.Avl_Qry
                p.Cart_Qty = p.Avl_Qry
              }
            } else {
              let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == p.product_packaging_rate_id)
              this.cartProduct.splice(index, 1)
              this.updateCart()
            }
          }
        })
      })
    })

    this.bestSellerProducts.map(e => e.Added_In_Cart = false)
    this.cartProduct.map((cp) => {
      this.bestSellerProducts.map((p) => {
        if (cp.product_packaging_rate_id == p.product_packaging_rate_id) {
          if (p.Avl_Qry > 0) {
            if (cp.Cart_Qty <= p.Avl_Qry) {
              p.Added_In_Cart = true
              p.Cart_Qty = cp.Cart_Qty
            } else {
              p.Added_In_Cart = true
              cp.Cart_Qty = p.Avl_Qry
              p.Cart_Qty = p.Avl_Qry
            }
          } else {
            let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == p.product_packaging_rate_id)
            this.cartProduct.splice(index, 1)
            this.updateCart()
          }
        }
      })
    })
  }

  updateCart() {
    if (this.cartProduct.length > 0) {
      this.cartProduct.map((e) => {
        e.Product_Total = e.Cart_Qty * e.selling_amt
        e.Product_Total = e.Cart_Qty * e.selling_amt
        e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
        e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
        e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
      })
      this._localCart.setCartProducts(this.cartProduct)
    } else this._localCart.setCartProducts(this.cartProduct)
  }

  getCartProducts() {
    this._commonService._spinner.show()
    this._localCart.cartList.subscribe((res) => {
      this._commonService._spinner.hide()
      this.cartProduct = res
      this.updateCartStatus()
    }, () => { }, () => {
      this._commonService._spinner.hide()
    })
  }

  onAddToCart(option) {
    this.activeMainCategorieList.map(element => {
      element.Products.map(e => {
        if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
          e.Added_In_Cart = true
          e.Cart_Qty = 1
          e.Product_Total = e.Cart_Qty * e.selling_amt
          this._commonService.onSuccess("Product Added In Cart")
          let product = this.products.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
          this.cartProduct.push(product)
          this._localCart.setCartProducts(this.cartProduct)
        }
      })
    })

  }

  onAddToCartBestSeller(option) {
    this.bestSellerProducts.map(e => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        e.Added_In_Cart = true
        e.Cart_Qty = 1
        e.Product_Total = e.Cart_Qty * e.selling_amt
        this._commonService.onSuccess("Product Added In Cart")
        let product = this.bestSellerProducts.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
        this.cartProduct.push(product)
        this._localCart.setCartProducts(this.cartProduct)
      }
    })
  }

  decreaseValueModal(option) {

    this.activeMainCategorieList.map(element => {
      element.Products.map(e => {
        if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
          if (e.Cart_Qty == 1) {
            e.Added_In_Cart = false
            let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
            this.cartProduct.splice(index, 1)
            this.updateQty()
            this._commonService.onFailed("Product Removed Successfully..")
          } else {
            e.Cart_Qty = e.Cart_Qty - 1
            this.updateQty()
          }
        }
      })
    })
  }

  decreaseValueModalBestSeller(option) {

    // WORKING CODE 
    this.bestSellerProducts.map(e => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty == 1) {
          e.Added_In_Cart = false
          let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
          this.cartProduct.splice(index, 1)
          this.updateCartOfBestSeller()
          this._commonService.onFailed("Product Removed Successfully..")
        } else {
          e.Cart_Qty = e.Cart_Qty - 1
          this.updateCartOfBestSeller()
        }
      }
    })

  }

  increaseValueModal(option) {
    this.activeMainCategorieList.map(element => {
      element.Products.map(e => {
        if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
          if (e.Cart_Qty < e.Avl_Qry) {
            e.Cart_Qty = e.Cart_Qty + 1
          } else this._commonService.onFailed("Out Of Stock")
        }
      })
    })
    this.updateQty()
  }

  increaseValueModalBestSeller(option) {
    this.bestSellerProducts.map(e => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty < e.Avl_Qry) {
          e.Cart_Qty = e.Cart_Qty + 1
          this.updateCartOfBestSeller()
          this.ref.detectChanges()
        }
        else this._commonService.onFailed("Out Of Stock")
        this.updateCartOfBestSeller()
        this.ref.detectChanges()
      }
    })
  }

  onCartQtyChange(event, option) {
    let qty = event.target.value
    if (qty && qty > 0) {
      this.activeMainCategorieList.map(element => {
        element.Products.map(e => {
          if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
            e.Cart_Qty = parseInt(qty)
          }
        })
      })
    } else if (qty < 0 || qty == '' || qty == 0) {
      this.deleteProductFromCart(option)
    }
  }

  updateCartOfBestSeller() {
    this.bestSellerProducts.map(p => {
      this.cartProduct.map((cp) => {
        if (p.product_packaging_rate_id == cp.product_packaging_rate_id) {
          cp.Cart_Qty = p.Cart_Qty
          this.updateCart()
        }
      })
    })
  }

  updateQty() {
    this.activeMainCategorieList.map(element => {
      element.Products.map(p => {
        this.cartProduct.map((cp) => {
          if (p.product_packaging_rate_id == cp.product_packaging_rate_id)
            cp.Cart_Qty = p.Cart_Qty
        })
      })
    })
    this.updateCart()

    this.updateCartOfBestSeller()
  }

  deleteProductFromCart(option) {
    let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    this.cartProduct.splice(index, 1)
    this.updateQty()
  }

  subscribeProduct(option) {
    // let product = this.bestSellerProducts.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    let product = option
    product.Added_In_Cart = true
    product.Cart_Qty = 1
    product.Product_Total = product.Cart_Qty * product.selling_amt
    this.subscribeCartProduct.push(product)
    this._localCart.setSubscribeCartProducts(this.subscribeCartProduct)
    this._commonService.router.navigate(['subscribe-cart'])
  }

  onSubscribe(option) {
    this.loginData = this._commonService.getUser()
    if (this.loginData) {
      this.subscribeProduct(option)
    } else {
      // this._desifarmsheaderComponent.onSignIn()
      this.onSignIn(option)
    }

  }

  onSubscribeExplore(option) {
    this.loginData = this._commonService.getUser()
    if (this.loginData) this.subscribeProduct(option)
    else this.onSignIn(option)
  }

  onSignIn(option) {
    const dialogRef = this._dialog.open(DesifarmLoginRegisterComponent, {
      width: '800px',
      panelClass: 'loginRegisterComponent',
    })
    dialogRef.afterClosed().subscribe(() => {
      this._desifarmsheaderComponent.getData()
      this.loginData = this._commonService.getUser()
      if (this.loginData) this.subscribeProduct(option)

    })
  }

  ngOnDestroy() {
    this._commonService.isMainCategoriesLoaded.next(false)
    this._commonService.isBestSellerLoaded.next(false)
    this._commonService.isHomePageProductsLoaded.next(false)
    this._commonService.isHomePageOffer.next(false)
  }

}
