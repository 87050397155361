<div class="row">
    <div class="col-md-12" style="text-align: right;z-index: 999;">
        <span class="closecart" style="font-size:40px !important;
      color:gray !important;text-align: right !important;cursor: pointer;" aria-hidden="true"
            (click)="close()">&times;</span>
    </div>
    <div class="col-md-12">
        <h2 class="address-heading" style="margin-bottom: 0;">Send Us Complaints Or Suggesstion </h2>
    </div>

</div>

<div style="border: 3px solid lightgray;padding: 15px;text-align: center;" class="mymodal">
    <h2 style="margin-top: 0;margin-bottom: 0;">Your Opinion Is Important To
        Us.</h2>
    <h2 class="lasth2" style="margin-top: 0;">This Way We Can Keep Improving Our Services.</h2>
    <form [formGroup]="form">

        <div class="form-check form-check-inline">
            <input formControlName="Type" class="form-check-input" type="radio" name="Type" value="Complaint" (click)="onType($event)">
            <label class="form-check-label" for="inlineCheckbox1">Complaint</label>
        </div>
        <div class="form-check form-check-inline">
            <input formControlName="Type" class="form-check-input" type="radio" name="Type" value="Suggestion" (click)="onType($event)">
            <label class="form-check-label" for="inlineCheckbox2">Suggestion</label>
        </div>


        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="form-box__single-group registerform">
                    <!-- <label class="mylabel" for="form-register-mobileno">Mobile No *</label> -->
                    <textarea formControlName="Compaint_Suggestion_Detail" type="text"
                        [placeholder]="textAreaPlaceholder" required></textarea>
                </div>
            </div>
        </div>
        <br>
        <div class="text-center"><button
                class="btn btn--box btn--medium registerbtn btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"
                [disabled]="form.invalid" (click)="submit()">Send</button>
        </div>
    </form>
</div>