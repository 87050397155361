import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { ReqCheckOut } from 'src/app/models/desifarm-req/desifarms-mycart/desifarms-checkout/reqcheckout';
import { ResDistrict } from 'src/app/models/desifarm-res/desifarm-header/resdistrict';
import { ResSubArea } from 'src/app/models/desifarm-res/desifarm-header/ressubarea';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmWalletService } from 'src/app/services/desifarm-dashboard/desifarm-wallet/desifarm-wallet.service';
import { DesifarmHeaderService } from 'src/app/services/desifarm-header/desifarm-header.service';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { DesifarmAddressService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-address/desifarm-address.service';
import { DesifarmCheckoutService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-checkout.service';
import { IcustomeWindow, WindowrefService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-windowRef/windowref.service';
import { DesifarmSubscribeCartService } from 'src/app/services/desifarm-subscribe-cart/desifarm-subscribe-cart.service';
import { DesifarmVnfAreasService } from 'src/app/services/desifarm-vnf-areas/desifarm-vnf-areas.service';
import { environment } from 'src/environments/environment';
import { DesifarmWalletTopupComponent } from '../desifarms-dashboard/desifarms-mywallet/desifarm-wallet-topup/desifarm-wallet-topup.component';
import { DesifarmsHeaderfooterComponent } from '../desifarms-headerfooter/desifarms-headerfooter.component';
import { DesifarmAddressComponent } from '../desifarms-mycart/desifarms-checkout/desifarm-address/desifarm-address.component';
import { DesifarmsDeliveryTimeslotComponent } from '../desifarms-mycart/desifarms-checkout/desifarms-delivery-timeslot/desifarms-delivery-timeslot.component';
import { DesifarmProductDetailsComponent } from '../desifarms-shopallitems/desifarm-product-details/desifarm-product-details.component';
import { DesifarmProductSubscribeDetailComponent } from '../desifarms-shopallitems/desifarm-product-subscribe-detail/desifarm-product-subscribe-detail.component';
import { DesifarmSubscribeWalletTopupComponent } from './desifarm-subscribe-wallet-topup/desifarm-subscribe-wallet-topup.component';

@Component({
  selector: 'app-desifarm-subscribe-cart',
  templateUrl: './desifarm-subscribe-cart.component.html',
  styleUrls: ['./desifarm-subscribe-cart.component.scss']
})
export class DesifarmSubscribeCartComponent implements OnInit {

  subscribeCartProduct: any[] = []
  packages: any[] = []

  Value_Of_Products: number = 0
  Value_Of_Discount: number = 0
  Value_Of_Sub_Total: number = 0
  Value_Of_Total: number = 0
  Delivery_Chargeses: number = 0

  isOpenGstDetail: boolean = false

  location: Location

  subscriptionStartDate: any = ''
  subscriptionEndDate: any = ''

  options = {
    Main_Category_Id: 0,
    Sub_Category_Id: 0,
    Franchise_Id: 8,
    product_packaging_rate_id: '',
    District_Id: null
  }












  address: any[] = []
  districts: ResDistrict[] = []
  subAreas: ResSubArea[] = []
  serviceableAreasPinCode: any[] = []
  frequency: any[] = [
    { id: 1, value: "Everyday" },
    { id: 2, value: "On Interval" },
    // { id: 3, value: "One Time Order" },
    // { id: 4, value: "Customize" },
  ]

  cartProduct: any[] = []
  Delivery_Chargess: number = 0
  Wallet_Amount_Used: number = 0
  walletAmountCount: number = 0

  loginData: any

  form: FormGroup

  addressId: number = 0
  paymentMode: string
  isProceed: boolean = true
  isPinCode: boolean = false
  isFranchiseIdNotMatch: boolean = false
  isDeliveryTimeSlot: boolean = false
  isNthDay: boolean = false
  delivery_date_time: any = ''
  pinCode: string
  currentDate: Date = new Date()
  tomorrowDate: Date

  @ViewChild('closeModal') closeModal: ElementRef


  orderDetails: any
  _window: IcustomeWindow
  option: any
  rzp: any

  vnfareaslist: any
  selectedDistrict: any

  constructor(
    private _commonService: DesifarmCommonService,
    public _localCartService: DesifarmLocalCartService,
    private _headerFooterComp: DesifarmsHeaderfooterComponent,
    private _headerService: DesifarmHeaderService,
    private _checkOutService: DesifarmCheckoutService,
    private _fb: FormBuilder,
    private _walletServiceService: DesifarmWalletService,
    private _vnfareasServiceService: DesifarmVnfAreasService,
    private _addressService: DesifarmAddressService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private zone: NgZone,
    private winRef: WindowrefService,
    private _subscribeCartService: DesifarmSubscribeCartService,
    private _dialog: MatDialog
  ) {
    this._localCartService.selectedDistrict.subscribe((District_Id) => {
      this.selectedDistrict = District_Id
    })
    this._window = this.winRef.nativeWindow
  }

  getData() {
    this.location = this._commonService.getData(environment.locationKey)
    this.options.Franchise_Id = 8
    this._localCartService.subscribeCartList.subscribe((res) => {
      if (res.length == 0) this.redirectToHome()
      res.map(e => {
        // e.Product_Total = e.Cart_Qty * e.selling_amt
        // e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
        // e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
        // e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
        // e.Total_CGST_Amount = e.Cart_Qty * e.CGST_Amount
        // e.Total_SGST_Amount = e.Cart_Qty * e.SGST_Amount
        // e.Total_IGST_Amount = e.Cart_Qty * e.IGST_Amount
        e.Product_Total = e.Cart_Qty * e.selling_amt
        e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
        e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
        e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
        e.Total_Discount_Amount = ((e.product_package_mrp * e.Cart_Qty) * e.discount_per / 100)
        e.Total_Discount_Amount = e.Total_Discount_Amount.toFixed(2)

        e.Total_CGST_Amount = e.Cart_Qty * e.CGST_Amount
        e.Total_SGST_Amount = e.Cart_Qty * e.SGST_Amount
        e.Total_IGST_Amount = e.Cart_Qty * e.IGST_Amount
      })

      this.subscribeCartProduct = res

      this.Wallet_Amount_Used = 0

      this.loginData = this._commonService.getUser()
      this._vnfareasServiceService.get_vandfareas().subscribe((res: any) => {
        let data = res.data
        let result = data.map(e => e.Pincode_Area)
        this.vnfareaslist = result.toString().replace(/,/g, ", ")
      })

      this._addressService.get_customer_address(this.loginData.Cust_Id).subscribe((res: any) => {
        res.data.map(e => {
          e.isShow = false
          e.Selected_District_Id = this.selectedDistrict
          e.Invalid_City = e.District_Id == this.selectedDistrict ? false : true
        })
        this.address = res.data
      })

      this._headerService.get_district().subscribe((res: DesifarmAck) => {
        this.districts = res.data
      })

      this._checkOutService.check_pincode().subscribe((res: any) => {
        this.serviceableAreasPinCode = res.data
      })

      this.getWalletAmount()

      if (res.length > 0) {

        let result = this.subscribeCartProduct.map(a => a.product_packaging_rate_id)
        this.options.product_packaging_rate_id = result.toString()
        this._checkOutService.get_updated_product_for_cart(this.options).subscribe((res: any) => {
          res.data.map((up) => {
            this.subscribeCartProduct.map((cp) => {
              if (up.product_packaging_rate_id == cp.product_packaging_rate_id) {
                if (up.Avl_Qry > 0) {
                  if (cp.Cart_Qty > up.Avl_Qry) {
                    cp.Cart_Qty = up.Avl_Qry
                  } else { }
                } else {
                  let index = this.subscribeCartProduct.findIndex(e => e.product_packaging_rate_id == up.product_packaging_rate_id)
                  this.subscribeCartProduct.splice(index, 1)
                  this.updateCart()
                }
                cp.selling_amt = up.selling_amt
                cp.product_package_mrp = up.product_package_mrp
                cp.Avl_Qry = up.Avl_Qry
              }
            })
          })

        })

        this.Value_Of_Products = res
          .map(e => e.Value_Of_Product)
          .reduce((prev, next) => prev + next)

        this.Value_Of_Discount = res
          .map(e => e.Value_Of_Discount)
          .reduce((prev, next) => prev + next)

        this.Value_Of_Sub_Total = res
          .map(e => e.Value_Of_Sub_Total)
          .reduce((prev, next) => prev + next)

        this.Value_Of_Total = res
          .map(e => e.Value_Of_Sub_Total)
          .reduce((prev, next) => prev + next)

        this.Delivery_Chargeses = 0
        this.Value_Of_Total += this.Delivery_Chargeses

        this._checkOutService.get_packaging(8, this.options.District_Id).subscribe((res: any) => {
          this.packages = res.data
        })
      }
    }, () => { }, () => { })

    this.getSubscriptionOrderTime({District_Id: this.selectedDistrict})

    if (this.subscribeCartProduct.length == 0) this.redirectToHome()
  }

  getSubscriptionOrderTime(data) {
    // this._subscribeCartService.get_subscription_order_time(data).subscribe((res: any) => { 
    // })
  }

  getWalletAmount() {
    this._walletServiceService.get_wallet_amount(this.loginData.Cust_Id).subscribe((res: any) => {
      this._walletServiceService.walletAmountCount.next(res.data)
      this.walletAmountCount = res.data
    })
  }

  initForm() {
    this.form = this._fb.group({
      Company_Id: ['1'],
      Financial_Year_Id: ['1'],
      Cust_Id: [this.loginData.Cust_Id],
      Cust_Name: [this.loginData.Cust_Name],
      Mobile_Number: [this.loginData.Customer_Mobile_Number],
      Email_Id: [this.loginData.Email_Id],
      Product_Id: ['', [Validators.required]],
      Product_Packaging_Rate_Id: ['', [Validators.required]],
      Product_Name: ['', [Validators.required]],
      Package_Qty: ['', [Validators.required]],
      Unit_Name: ['', [Validators.required]],
      Unit_Id: [''],
      Short_Description: [''],
      CGST_Per: ['', [Validators.required]],
      SGST_Per: ['', [Validators.required]],
      IGST_Per: ['', [Validators.required]],
      HSN_Code: ['', [Validators.required]],
      Product_Package_MRP: ['', [Validators.required]],
      Discount_Per: ['', [Validators.required]],
      Selling_Amt: ['', [Validators.required]],
      Order_Qty: ['', [Validators.required]],
      Subscription_Start_Date: ['', [Validators.required]],
      Subscription_End_Date: [''],
      Subscription_Type: ['', [Validators.required]],
      Subscription_Current_Status: ['', [Validators.required]],
      Customer_Delivery_Address_State_Id: ['', [Validators.required]],
      Company_State_Id: ['', [Validators.required]],
      Order_From: ['Subscribe'],
      Delivery_Address_Id: ['', [Validators.required]],
      Full_Name: ['', [Validators.required]],
      Mobile_No_Address: ['', [Validators.required]],
      Flat_House_No: ['', [Validators.required]],
      Area_Colony_Sreet_Name: ['', [Validators.required]],
      Landmark: [''],
      Pincode: [''],
      City: [''],
      Area_Id: [''],
      Area_Name: ['', [Validators.required]],
      Sub_Area_Name: [''],
      Sub_Area_Id: [''],
      Address_Type: [''],
      Delivery_LT: ['0'],
      Delivery_LG: ['0'],
      Delivery_Slot_Id: ['0'],
      Slot_Name: ['', [Validators.required]],
      Slot_Start_Time: ['', [Validators.required]],
      Instruction: [''],
      Interval_Days: [''],
      Next_Delivery_Date: [''],
      Franchise_Id: [''],
      Apartment_Id: [''],
      Is_Verified: [''],
    })
  }

  get f() {
    return this.form
  }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()
    this.getData()
    this.initForm()
    this.initStartAndEndDate()
    this.updateForm()

    this.tomorrowDate = new Date();
    // add a day
    this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1);
  }

  updateForm() {
    try {
      this.form.controls.Subscription_End_Date.disable()
      let product = this.subscribeCartProduct[0]
      this.form.patchValue({
        Company_Id: 1,
        Financial_Year_Id: 1,
        Company_State_Id: 1,
        Cust_Id: this.loginData.Cust_Id,
        Cust_Name: this.loginData.Cust_Name,
        Mobile_Number: this.loginData.Customer_Mobile_Number,
        Email_Id: this.loginData.Email_Id,
        Subscription_Current_Status: "Active",
        Subscription_Type: "Everyday",
        Order_From: 'Subscribe',
        Delivery_LT: '0',
        Delivery_LG: '0',
        Delivery_Slot_Id: '0',
        Product_Id: product.product_id,
        Product_Packaging_Rate_Id: product.product_packaging_rate_id,
        Product_Name: product.product_name,
        Package_Qty: product.package_qty,
        Unit_Name: product.unit_name,
        Short_Description: product.short_description,
        CGST_Per: product.cgst_per,
        SGST_Per: product.sgst_per,
        IGST_Per: product.igst_per,
        HSN_Code: product.hsn_code,
        Product_Package_MRP: product.product_package_mrp,
        Discount_Per: product.discount_per,
        Selling_Amt: product.selling_amt,
        Order_Qty: product.Cart_Qty,
        Unit_Id: null,
        Interval_Days: 1
      })
    } catch (error) {

    }
  }

  updateIntervalDays() {
    let value = this.form.controls.Subscription_Type.value
    if (value == 'Everyday') {
      this.isNthDay = false
      this.form.patchValue({
        Interval_Days: 1
      })
    }
  }

  setIntervalDay(value) {
    if (value === 'n') {
      this.isNthDay = true
      this.form.controls.Interval_Days.reset()
    } else {
      this.isNthDay = false
      this.form.patchValue({ Interval_Days: value })
    }
  }

  initStartAndEndDate() {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    endDate.setDate(endDate.getDate() + 2);
    this.subscriptionStartDate = moment(startDate).format("YYYY-MM-DD")
    this.subscriptionEndDate = moment(endDate).format("YYYY-MM-DD")
  }


  startDateChange(event) {
    let value = event.target.value
    let newDate = new Date(value)
    newDate.setDate(newDate.getDate() + 1)
    this.subscriptionEndDate = moment(newDate).format("YYYY-MM-DD")
    this.form.controls.Subscription_End_Date.enable()
    this.checkProccedStatus()
  }

  onDelete(option) {
    let index = this.subscribeCartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    this.subscribeCartProduct.splice(index, 1)
    this._localCartService.setSubscribeCartProducts(this.subscribeCartProduct)
    this._commonService.onFailed("Product Deleted Successfully..")
  }

  onDeleteAddress(option) {
    if (confirm("Are You Sure To Delete This Address")) {
      this._commonService._spinner.show()
      this._addressService.delete_customer_address(option.Delivery_Address_Id).subscribe((res: any) => {
        this._commonService._spinner.hide()
        if (res.ack) {
          this.getData()
          this._commonService.onSuccess(res.msg)
        } else this._commonService.onFailed(res.msg)
      }, (err) => {
        this._commonService._spinner.hide()
        this._commonService.onFailed(err)
      })
    }
  }

  decreaseValue(option) {
    this.subscribeCartProduct.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty == 1) {
          this.onDelete(option)
          this._commonService.onFailed("Product Deleted Successfully..")
        } else {
          e.Cart_Qty = e.Cart_Qty - 1
          this.form.patchValue({ Order_Qty: e.Cart_Qty })
          this.updateCart()
        }
      }
    })
  }

  increaseValue(option) {
    this.subscribeCartProduct.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty < e.Avl_Qry) {
          e.Cart_Qty = e.Cart_Qty + 1
          this.form.patchValue({ Order_Qty: e.Cart_Qty })
        } else this._commonService.onFailed("Out Of Stock")
      }
    })
    this.updateCart()
  }

  updateCart() {
    this.subscribeCartProduct.map((e) => {
      e.Product_Total = e.Cart_Qty * e.selling_amt
      e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
      e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
      e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
    })
    this._localCartService.setSubscribeCartProducts(this.subscribeCartProduct)
    if (this.subscribeCartProduct.length == 0) this.redirectToHome()
  }

  emptyCart() {
    if (confirm("Are You Sure to Delete All Products From Cart ?")) {
      this._localCartService.resetSubscribeCartProducts()
      this.redirectToHome()
    }
  }

  closeGstDialog = () => this.isOpenGstDetail = !this.isOpenGstDetail

  onCheckOut() {
    if (this._headerFooterComp.loginData) {
      this._commonService.router.navigate(['checkout'])
    } else {
      this._headerFooterComp.onSignIn()
    }
  }

  onCartQtyChange(event, option) {
    let qty = event.target.value
    if (qty && qty > 0) {
      this.form.patchValue({ Order_Qty: qty })
      this.subscribeCartProduct.map((e) => {
        if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
          e.Cart_Qty = parseInt(qty)
        }
      })
    } else if (qty < 0 || qty == '' || qty == 0) {
      this.deleteProductFromCart(option)
    }

  }

  deleteProductFromCart(option) {
    let index = this.subscribeCartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    this.subscribeCartProduct.splice(index, 1)
    this.updateCart()
  }

  redirectToHome() {
    this._commonService.router.navigate([''])
  }

  addPackaging(pack) {
    pack.Added_In_Cart = true
    pack.Cart_Qty = 1
    pack.Product_Total = pack.Cart_Qty * pack.selling_amt
    this.subscribeCartProduct.push(pack)
    this.updateCart()
  }

  checkPackageInCart(pack) {
    let result = this.subscribeCartProduct.find(e => e.product_name == pack.product_name)
    return result ? true : false
  }

  onCity(event) {
    let district = this.districts.find(e => e.District_Id == event.target.value)
    this.form.patchValue({
      District_Id: district.District_Id
    })
    this.getSubArea(district.District_Id)
  }

  onArea(event) {
    let area = this.subAreas.find(e => e.Name == event.target.value)
    this.form.patchValue({
      Pincode: area.Picode,
      Sub_Area_Id: area.Sub_Area_Id
    })
  }

  getSubArea(District_Id) {
    this._headerService.get_sub_area(District_Id).subscribe((res: any) => {
      res.data.map(e => e.Name = `${e.Sub_Area_Name}, ${e.Area_Name}`)
      this.subAreas = res.data
    })
  }

  submit() {
    // this._commonService._spinner.show()
    // this._addressService.add_customer_address(this.form.value).subscribe((res: any) => {
    //   this._commonService._spinner.hide()
    //   if (res.ack) {
    //     this._commonService
    //   }
    // })
  }

  onAddress() {
    const dialogRef = this._dialog.open(DesifarmAddressComponent, {
      width: '1000px',
      height: '80vh',
      panelClass: 'checkout-address-modal',
    })
    dialogRef.afterClosed().subscribe(() => this.getData())
  }

  onDelivery() {
    const dialogRef = this._dialog.open(DesifarmsDeliveryTimeslotComponent, {
      width: '1000px',
      height: 'auto',
      panelClass: 'checkout-address-modal',
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.isDeliveryTimeSlot = false
        this.delivery_date_time = `${this.datePipe.transform(result.Delivery_Date, 'mediumDate')} ${result.Slot_Name}`
        this.form.patchValue({
          Delivery_Slot_Id: result.Delivery_Slot_Id,
          Slot_Name: result.Slot_Name,
          Customer_Delivery_Date: result.Delivery_Date,
          Slot_Start_Time: result.From_Time
        })
        // if (this.paymentMode != undefined && this.addressId != 0 && this.form.valid) this.isProceed = false
        // else this.isProceed = true
        this.checkProccedStatus()
      } else {
        this.isDeliveryTimeSlot = true
      }

    })
  }

  checkProccedStatus() {
    if (this.addressId != 0 && this.form.valid) this.isProceed = false
    else this.isProceed = true
  }

  onEdit(option) {
    const dialogRef = this._dialog.open(DesifarmAddressComponent, {
      width: '1000px',
      height: '80vh',
      panelClass: 'checkout-address-modal',
      data: option
    })
    dialogRef.afterClosed().subscribe(() => this.getData())
  }


  checkValue(event, value) {
    var date = new Date();

    // add a day
    date.setDate(date.getDate() + 1);
    if (value == 'address') {
      this.resetAddressErrMsg()
      this.isPinCode = false
      // this._commonService._spinner.show()
      this.isFranchiseIdNotMatch = false
      this.addressId = event.target.value
      let address = this.address.find(e => e.Delivery_Address_Id == event.target.value)
      this.isPinCode = false
      this.form.patchValue({
        Delivery_Address_Id: address.Delivery_Address_Id,
        Full_Name: address.Full_Name,
        Mobile_No_Address: address.Mobile_No_Address,
        Flat_House_No: address.Flat_House_No,
        Area_Colony_Sreet_Name: address.Area_Colony_Sreet_Name,
        Landmark: address.Landmark,
        Pincode: address.Pincode,
        City: address.District_Name,
        Area_Id: address.Area_Id,
        Area_Name: address.Area_Name,
        Sub_Area_Name: address.Sub_Area_Name,
        Sub_Area_Id: address.Sub_Area_Id,
        Address_Type: address.Address_Type,
        Franchise_Id: address.Franchise_Id,
        Customer_Delivery_Address_State_Id: address.Customer_Delivery_Address_State_Id,
        Apartment_Id: address.Apartment_Id,
        Delivery_Slot_Id: 1,
        Slot_Name: "6:00 AM TO 8:00 AM",
        Customer_Delivery_Date: moment(date).format('YYYY-MM-DD'),
        Slot_Start_Time: "06:00:00",
        Is_Verified: address.Is_Verified,
      })
      this.checkProccedStatus()
      // this._checkOutService.check_area(address.Area_Name).subscribe((res: any) => {
      //   this._commonService._spinner.hide()
      //   if (res.ack) {
      //     this.isPinCode = false
      //     this.form.patchValue({
      //       Delivery_Address_Id: address.Delivery_Address_Id,
      //       Full_Name: address.Full_Name,
      //       Mobile_No_Address: address.Mobile_No_Address,
      //       Flat_House_No: address.Flat_House_No,
      //       Area_Colony_Sreet_Name: address.Area_Colony_Sreet_Name,
      //       Landmark: address.Landmark,
      //       Pincode: address.Pincode,
      //       City: address.District_Name,
      //       Area_Id: address.Area_Id,
      //       Area_Name: address.Area_Name,
      //       Sub_Area_Name: address.Sub_Area_Name,
      //       Sub_Area_Id: address.Sub_Area_Id,
      //       Address_Type: address.Address_Type,
      //       Franchise_Id: address.Franchise_Id,
      //       Customer_Delivery_Address_State_Id: address.Customer_Delivery_Address_State_Id,
      //       Apartment_Id: address.Apartment_Id,
      //       Delivery_Slot_Id: 1,
      //       Slot_Name: "Tomorrow",
      //       Customer_Delivery_Date: moment(date).format('YYYY-MM-DD'),
      //       Slot_Start_Time: "11:30:00"
      //     })
      //     this.checkProccedStatus()
      //   } else {
      //     this.isPinCode = true
      //     this._commonService.onFailed(res.msg)
      //     this.form.controls.Delivery_Address_Id.reset()
      //     this.form.controls.Full_Name.reset()
      //     this.form.controls.Mobile_No_Address.reset()
      //     this.form.controls.Flat_House_No.reset()
      //     this.form.controls.Area_Colony_Sreet_Name.reset()
      //     this.form.controls.Landmark.reset()
      //     this.form.controls.Pincode.reset()
      //     this.form.controls.City.reset()
      //     this.form.controls.Area_Name.reset()
      //     this.form.controls.Sub_Area_Name.reset()
      //     this.form.controls.Sub_Area_Id.reset()
      //     this.form.controls.Address_Type.reset()
      //     this.form.controls.Customer_Delivery_Address_State_Id.reset()
      //     this.checkProccedStatus()

      //     //Show Err Msg
      //     this.showAddressErrMsg(event.target.value)
      //   }
      // }, (err) => {
      //   this._commonService._spinner.hide()
      //   this._commonService.onFailed("Internet Connection Failed")
      // })
    } else if (value == 'payment') {
      this.paymentMode = event.target.value
    }
    this.checkProccedStatus()
  }

  showAddressErrMsg(Delivery_Address_Id) {
    this.address.map(e => e.Delivery_Address_Id == Delivery_Address_Id ? e.isShow = true : e.isShow = false)
  }

  resetAddressErrMsg() {
    this.address.map(e => e.isShow = false)
  }

  onRedeem() {
    if (this.walletAmountCount > 0) {
      if (this.walletAmountCount <= this.Value_Of_Total) {
        let amountToBeMinus = this.walletAmountCount
        this.Value_Of_Total = this.Value_Of_Total - this.walletAmountCount
        this.walletAmountCount = this.walletAmountCount - amountToBeMinus
        this.Wallet_Amount_Used = amountToBeMinus
        this.form.patchValue({ Wallet_Amount_Used: this.Wallet_Amount_Used })
      } else if (this.walletAmountCount > this.Value_Of_Total) {
        let amountToBeMinus = this.walletAmountCount
        let valueToBeMinus = this.Value_Of_Total
        this.Wallet_Amount_Used = valueToBeMinus
        this.Value_Of_Total = this.Value_Of_Total - valueToBeMinus
        this.walletAmountCount = amountToBeMinus - valueToBeMinus
        this.form.patchValue({ Wallet_Amount_Used: this.Wallet_Amount_Used })
      }
    }
  }

  onRemove() {
    this.Value_Of_Total = this.cartProduct
      .map(e => e.Value_Of_Sub_Total)
      .reduce((prev, next) => prev + next)

    // if (this.Value_Of_Sub_Total > this.location.Min_Order_Amount == null ? 0 : this.location.Min_Order_Amount) {
    //   this.Delivery_Chargess = 0
    // } else {
    //   this.Delivery_Chargess = this.location.Delivery_Charges
    //   this.Value_Of_Total += this.Delivery_Chargess
    // }

    let amountToPlus = this.Wallet_Amount_Used
    this.walletAmountCount = this.walletAmountCount + amountToPlus
    this.Wallet_Amount_Used = 0
    this.form.patchValue({ Wallet_Amount_Used: this.Wallet_Amount_Used })
  }

  proceed() {
    if (this.walletAmountCount >= this.Value_Of_Total) {
      this.placeOrder()
    } else {
      this.onWalletTopup()
    }
  }

  // proceed() {
  //   if (this.paymentMode == 'Online') {
  //     this.razorpay()
  //   } else if (this.paymentMode == 'COD') {
  //     this.placeOrder()
  //   }
  // }

  onInstruction(event) {
    let instruction = event.target.value
    this.form.patchValue({ Instruction: instruction })
  }

  placeOrder() {
    this._commonService._spinner.show()
    this._subscribeCartService.subscribeOrderPlace(this.form.value).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this._commonService.setData("placed", JSON.stringify(res.data))
        this._commonService.onSuccess(res.msg)
        this._commonService.router.navigate(['order-success'])
      } else this._commonService.onFailed(res.msg)
    }, (err) => {
      this._commonService.onFailed("Internet Connection Failed")
      this._commonService._spinner.hide()
    })
  }

  private razorpay() {
    this.option = {
      // key: 'rzp_test_eUjDLjo7mWHo2R',//'rzp_test_xac0VPTP8XZjMi',
      key: environment.rzp_API,
      name: 'NSK Store',
      description: 'Shopping',
      amount: (this.Value_Of_Total * 100),
      // amount: 100,
      prefill: {
        name: this.loginData.Cust_Name,
        email: this.loginData.Email_Id,
        contact: this.loginData.Customer_Mobile_Number
      },
      notes: {},
      theme: {
        color: '#3880FF'
      },
      handler: ((res: any, err: any) => {
        if (res && res.razorpay_payment_id) {
          // this.paymentTransactionID = res.razorpay_payment_id;
          this.form.patchValue({ Payment_Transaction_ID: res.razorpay_payment_id })
          this.placeOrder();
        } else {
          this._commonService.onFailed("Transaction Failed.. Try Again")
          this._commonService.router.navigate(['/mycart'])

        }
      }),
      modal: {
        ondismiss: (() => {
          this.zone.run(() => {
            //window.location.reload();
            //add current page routing if payment fail
            // this.router.navigateByUrl('/shop/RefreshComponent', { skipLocationChange: true }).then(() => {
            //   this.router.navigate(['/shop/checkout']);
            // });
          })
        })
      }
    };

    this.rzp = new this.winRef.nativeWindow['Razorpay'](this.option);
    this.rzp.open();
  }


  onWalletTopup() {
    let data = { Recharge_Amount: Math.ceil(this.Value_Of_Total - this.walletAmountCount) }
    const dialogRef = this._dialog.open(DesifarmSubscribeWalletTopupComponent, {
      width: '500px',
      // height: '60vh',
      panelClass: 'wallet-topup',
      data: data
    })
    dialogRef.afterClosed().subscribe(() => { this.getWalletAmount() })
  }

  onProductDetail(option) {
    const dialogRef = this._dialog.open(DesifarmProductSubscribeDetailComponent, {
      width: '800px',
      height: '70vh',
      panelClass: 'myclass2',
      data: option
    })
    dialogRef.afterClosed().subscribe(() => { })
  }

  handleRecharge() {

    const dialogRef = this._dialog.open(DesifarmWalletTopupComponent, {
      width: '500px',
      // height: '60vh',
      panelClass: 'wallet-topup',
      // data: data
    })
    dialogRef.afterClosed().subscribe((res) => { if (res) this.getWalletAmount() })

    // let data = { Recharge_Amount: 100 }
    // const dialogRef = this._dialog.open(DesifarmSubscribeWalletTopupComponent, {
    //   width: '500px',
    //   // height: '60vh',
    //   panelClass: 'wallet-topup',
    //   data: data
    // })
    // dialogRef.afterClosed().subscribe((res) => {
    //   if (res) this.getWalletAmount()
    // })
  }

}
