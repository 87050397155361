<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb" style="margin-bottom: 0;">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="page-breadcrumb__menu">
            <li class="page-breadcrumb__nav" routerLink="/"><a>Home</a></li>
            <li class="page-breadcrumb__nav active">Customer Testimonials</li>
          </ul>
        </div>
      </div>
    </div>
  </div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<section class="mainsec">
    <div class="container">
        <div class="row testimonial-row mt-3 mb-3">
            <div class="col-md-4  ">
              <div class="testiholder p-2">
                <div class="row align-items-center  ">
                  <div class="col-md-5 col-xs-6">
                    <div class="img-holder">
                      <img src="assets/img/testimonial/Kautubh_Karale.jpg" class="img-responsive rounded-circle" />
      
                    </div>
                  </div>
                  <div class="col-md-6 col-xs-6"> <span class="user-title">Kaudtubh Karale</span></div>
      
                </div>
                <p class="mt-2">Since 2018, we have been having NSK Store Whole Cow Milk. This milk is pure and nutritious.
                  So, we
                  especially started taking this milk for our son. It will be good for his growth. What I like about
                  this milk is that it is thick and creamy. </p>
                <p>I give my son 1 or 2 glasses of Whole Cow Milk daily and
                  absolutely loves it.</p>
              </div>
            </div>
      
            <div class="col-md-4  ">
              <div class="testiholder p-2">
                <div class="row align-items-center  ">
                  <div class="col-md-5 col-xs-6">
                    <div class="img-holder">
                      <img src="assets/img/testimonial/kalpesh.jpg" class="img-responsive rounded-circle" />
      
                    </div>
                  </div>
                  <div class="col-md-6 col-xs-6"> <span class="user-title">Kalpesh Patil</span></div>
      
                </div>
                <p class="mt-2">We have subscribed to NSK Store Whole Buffalo Milk. Every day, exactly at 7.30 am
                  the milk is delivered to home. Apart from this, we regularly place orders for paneer and curd, on
                  the
                  app. It is delivered within 2 hours. Their service is prompt. Plus, the delivery is free of cost</p>
              </div>
            </div>
      
            <div class="col-md-4  ">
              <div class="testiholder p-2">
                <div class="row align-items-center  ">
                  <div class="col-md-5 col-xs-6">
                    <div class="img-holder">
                      <img src="assets/img/testimonial/Shamakant_Nangre.JPG" class="img-responsive rounded-circle" />
      
                    </div>
                  </div>
                  <div class="col-md-6 col-xs-6"> <span class="user-title">Shamakant Nangre</span></div>
      
                </div>
                <p class="mt-2">I think we are regular customers of NSK Store. We get our daily milk from them,
                  plus other food items like curd, ghee, and butter. I like their product quality and freshness. Also,
                  the packaging is good. They deliver in insulated bags. It’s clean, hygienic, and convenient.</p>
              </div>
            </div>
            <div class="col-md-4  ">
                <div class="testiholder p-2">
                  <div class="row align-items-center  ">
                    <div class="col-md-5 col-xs-6">
                      <div class="img-holder">
                        <img src="assets/img/testimonial/Poonam_Kubade.jpeg" class="img-responsive rounded-circle" />
        
                      </div>
                    </div>
                    <div class="col-md-6 col-xs-6"> <span class="user-title">Poonam  Kubade</span></div>
        
                  </div>
                  <p class="mt-2">We love NSK Store malai paneer. They have one branch in my area, from where I regularly get paneer. It's fresh, very soft, and tastes good. They make paneer from pure milk, so it's good for health. My family likes 
                    homemade paneer preparations. So every time I either go and get it from there or order online.</p>
                </div>
              </div>
              <div class="col-md-4  ">
                <div class="testiholder p-2">
                  <div class="row align-items-center  ">
                    <div class="col-md-5 col-xs-6">
                      <div class="img-holder">
                        <img src="assets/img/testimonial/Smita_Dhamdhere.jpeg" class="img-responsive rounded-circle" />
        
                      </div>
                    </div>
                    <div class="col-md-6 col-xs-6"> <span class="user-title">Smita  Dhamdhere </span></div>
        
                  </div>
                  <p class="mt-2">I saw their ad, saying they have preservative-free dairy products. So, I downloaded the app. I'm so happy with their products, 
                    I regularly order paneer, yogurts, and cheese using the app. <br>
                    They deliver in the early morning hours. On-time and free delivery! It's a relief actually. I don't have to worry about going to the shop to get it. I have been with them for 3+ years and I don't want to switch! NSK Store team members are very polite and professional when handling any situation. Thank you!</p>
                </div>
              </div>
        </div>
    </div>
    
</section>
