import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmMysubscriptionService {

  constructor(private http: HttpClient) { }

  get_subscribe_order(Cust_Id): Observable<any> {
    return this.http.get<any>(environment.url + 'get_subscribe_order/' + Cust_Id)
  }

  cancel_subscribe_order(data): Observable<any> {
    return this.http.post<any>(environment.url + 'cancel_subscribe_order', data)
  }

  update_subscribe_status(data): Observable<any> {
    return this.http.post<any>(environment.url + 'update_subscribe_status', data)
  }
}
