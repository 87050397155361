import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ResDistrict } from 'src/app/models/desifarm-res/desifarm-header/resdistrict';
import { ResSubArea } from 'src/app/models/desifarm-res/desifarm-header/ressubarea';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmHeaderService } from 'src/app/services/desifarm-header/desifarm-header.service';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { DesifarmAddressService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-address/desifarm-address.service';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from 'src/app/models/desifarm-common/desifarm-local/desifarm-location';
import { environment } from 'src/environments/environment';
import { DesifarmVnfAreasService } from 'src/app/services/desifarm-vnf-areas/desifarm-vnf-areas.service';

@Component({
  selector: 'app-desifarm-address',
  templateUrl: './desifarm-address.component.html',
  styleUrls: ['./desifarm-address.component.scss']
})
export class DesifarmAddressComponent implements OnInit {

  districts: ResDistrict[] = []
  areas: any[] = []
  subAreas: ResSubArea[] = []


  allAreas: any[] = []
  allSubAreas: ResSubArea[] = []



  locationDetails: Location

  loginData: any
  vnfareaslist: any

  form: FormGroup

  isEdit: boolean = false
  isPrefill: boolean = false
  isCount: number = 1

  constructor(
    public _dialogRef: MatDialogRef<DesifarmAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _localCart: DesifarmLocalCartService,
    private _addressService: DesifarmAddressService,
    private _headerService: DesifarmHeaderService,
    private _vnfareasServiceService: DesifarmVnfAreasService,
  ) {
    this.initForm()
    if (_data) {
      this.isEdit = true
    } else {
      this.isEdit = false
      this.isPrefill = true
    }
    this.getLocation()
  }

  getData() {
    this.loginData = this._commonService.getUser()
    this.form.patchValue({
      Cust_Id: this.loginData.Cust_Id,
      First_Name: this.loginData.First_Name,
      Last_Name: this.loginData.Last_Name,
      // Full_Name: this.loginData.Cust_Name,
      Mobile_No_Address: this.loginData.Customer_Mobile_Number,
    })
    this._headerService.get_district().subscribe((res: DesifarmAck) => {
      this.districts = res.data
    })

    this._headerService.get_all_areas().subscribe((res: DesifarmAck) => {
      this.allAreas = res.data
    })

    this._headerService.get_all_sub_areas().subscribe((res: DesifarmAck) => {
      this.allSubAreas = res.data

      // if (this.isEdit && this.isCount === 1) {
      //   this.editPreFillData()
      //   this._commonService._spinner.show()
      // } else if (this.isEdit === false && this.isPrefill) this.preFillData()

      if (this.isEdit && this.isCount === 1) {
        this.editPreFillData()
        this._commonService._spinner.show()
      } else if (this.isEdit === false && this.isPrefill) this.preFillData()
    })


    this._vnfareasServiceService.get_vandfareas().subscribe((res: any) => {
      let data = res.data
      let result = data.map(e => e.Pincode_Area)
      this.vnfareaslist = result.toString().replace(/,/g, ", ")
    })

  }

  getLocation() {
    if (this._commonService.getData(environment.locationKey) != null) {
      this.locationDetails = this._commonService.getData(environment.locationKey)
    }
  }

  initForm() {
    this.form = this._fb.group({
      Cust_Id: ['', [Validators.required]],
      First_Name: ['', [Validators.required]],
      Last_Name: ['', [Validators.required]],
      Full_Name: [''],
      Mobile_No_Address: ['',
        [
          Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.minLength(9),
          Validators.maxLength(9)
        ]],
      District_Id: ['', [Validators.required]],
      Area_Id: ['', [Validators.required]],
      Area_Name: ['', [Validators.required]],
      Sub_Area_Id: [''],
      Sub_Area_Name: [''],
      Flat_House_No: ['', [Validators.required]],
      Area_Colony_Sreet_Name: ['', [Validators.required]],
      Landmark: [''],
      Pincode: ['', [Validators.pattern('[0-9]*')]],
      Address_Type: ['Home', [Validators.required]],
      Delivery_Address_Id: ['']
    })

  }

  get f() {
    return this.form
  }

  ngOnInit(): void {
    this.getData()

    if (this._data?.Is_Verified == 1) {
      this.disableControls()
    } else this.enableControls()
  }

  disableControls() {
    this.form.controls.Full_Name.disable()
    this.form.controls.Mobile_No_Address.disable()
    this.form.controls.Address_Type.disable()
    this.form.controls.District_Id.disable()
    this.form.controls.Area_Name.disable()
    this.form.controls.Sub_Area_Name.disable()
    this.form.controls.Area_Colony_Sreet_Name.disable()
  }
  enableControls() {
    this.form.controls.Full_Name.enable()
    this.form.controls.Mobile_No_Address.enable()
    this.form.controls.Address_Type.enable()
    this.form.controls.District_Id.enable()
    this.form.controls.Area_Name.enable()
    this.form.controls.Sub_Area_Name.enable()
    this.form.controls.Area_Colony_Sreet_Name.enable()
  }

  onCity(event) {
    this.areas.length = 0
    this.subAreas.length = 0
    let district = this.districts.find(e => e.District_Id == event.target.value)
    this.form.controls.Area_Id.reset()
    this.form.controls.Area_Name.reset()
    this.form.patchValue({
      District_Id: district.District_Id,
      Sub_Area_Name: "",
      Sub_Area_Id: 0
    })
    this.isCount = 0
    this.getAreaList(district.District_Id)
  }

  getAreaList(District_Id) {
    this._headerService.get_area(District_Id).subscribe((res: any) => {
      this.areas = res.data
    })
  }

  onAreaSelection(event) {
    this.subAreas.length = 0
    this.form.controls.Sub_Area_Id.reset()
    this.form.controls.Sub_Area_Name.reset()
    let area = this.areas.find(e => e.Area_Name == event.target.value)
    if (area) {
      this.getSubArea(area.Area_Id)
      this.form.patchValue({
        Area_Id: area.Area_Id,
        Area_Name: area.Area_Name,
        Sub_Area_Id: 0
      })
    } else {
      this.form.patchValue({
        Sub_Area_Id: 0
      })
    }
  }

  getSubArea(Area_Id) {
    this._headerService.get_sub_area(Area_Id).subscribe((res: any) => {
      res.data.map(e => e.Name = `${e.Sub_Area_Name}`)
      this.subAreas = res.data
    })
  }

  onArea(event) {
    let subArea = this.subAreas.find(e => e.Name == event.target.value)
    if (subArea) {
      this.form.patchValue({
        Sub_Area_Id: subArea.Sub_Area_Id,
        Pincode: subArea.Picode ? subArea.Picode : "",
      })
    } else {
      this.form.patchValue({
        Sub_Area_Id: 0,
        Pincode: ""
      })
    }
  }

  preFillData() {
    this._commonService._spinner.show()
    this.getAreaList(this.loginData.District_Id)
    this.getSubArea(this.loginData.Area_Id)
    this.form.patchValue({
      District_Id: this.loginData.District_Id,
      Area_Id: this.loginData.Area_Id,
      Area_Name: this.loginData.Area_Name,
      Sub_Area_Id: this.loginData.Sub_Area_Id,
      Sub_Area_Name: this.loginData.Sub_Area_Name,
    })
    this.isPrefill = false
    this._commonService._spinner.hide()
  }


  editPreFillData() {
    this.getAreaList(this._data.District_Id)
    this.getSubArea(this._data.Area_Id)
    this.form.patchValue({
      Cust_Id: this._data.Cust_Id,
      First_Name: this._data.First_Name,
      Last_Name: this._data.Last_Name,
      // Full_Name: this._data.Full_Name,
      Mobile_No_Address: this._data.Mobile_No_Address,
      District_Id: this._data.District_Id,
      Sub_Area_Id: this._data.Sub_Area_Id,
      Sub_Area_Name: this._data.Sub_Area_Name,
      Flat_House_No: this._data.Flat_House_No,
      Area_Colony_Sreet_Name: this._data.Area_Colony_Sreet_Name,
      Landmark: this._data.Landmark,
      Pincode: this._data.Pincode,
      Address_Type: this._data.Address_Type,
      Delivery_Address_Id: this._data.Delivery_Address_Id,
      Area_Id: this._data.Area_Id,
      Area_Name: this._data.Area_Name
    })
    setTimeout(() => {
      this._commonService._spinner.hide()
    }, 100)
  }

  // preFillData() {
  //   this._commonService._spinner.show()
  //   this.getAreaList(this.locationDetails.District_Id)
  //   this.getSubArea(this.locationDetails.Area_Id)
  //   this.form.patchValue({
  //     District_Id: this.locationDetails.District_Id,
  //     Area_Id: this.locationDetails.Area_Id,
  //     Area_Name: this.locationDetails.Area_Name,
  //     Sub_Area_Id: this.locationDetails.Sub_Area_Id,
  //     Sub_Area_Name: this.locationDetails.Sub_Area_Name,
  //     Pincode: this.locationDetails.Pincode
  //   })
  //   this.isPrefill = false
  //   this._commonService._spinner.hide()
  // }

  // editPreFillData() {
  //   let subAreaDetail = this.allSubAreas.find(e => e.Sub_Area_Id == this._data.Sub_Area_Id)
  //   let areaDetail = this.allAreas.find(e => e.Area_Id == subAreaDetail.Area_Id)
  //   this.form.patchValue({
  //     Cust_Id: this._data.Cust_Id,
  //     Full_Name: this._data.Full_Name,
  //     Mobile_No_Address: this._data.Mobile_No_Address,
  //     District_Id: areaDetail.District_Id,
  //     Sub_Area_Id: subAreaDetail.Sub_Area_Id,
  //     Sub_Area_Name: subAreaDetail.Sub_Area_Name,
  //     Flat_House_No: this._data.Flat_House_No,
  //     Area_Colony_Sreet_Name: this._data.Area_Colony_Sreet_Name,
  //     Landmark: this._data.Landmark,
  //     Pincode: this._data.Pincode,
  //     Address_Type: this._data.Address_Type,
  //     Delivery_Address_Id: this._data.Delivery_Address_Id,
  //     Area_Id: areaDetail.Area_Id,
  //     Area_Name: areaDetail.Area_Name
  //   })
  //   setTimeout(() => {
  //     this._commonService._spinner.hide()
  //   }, 100)
  //   this.getAreaList(areaDetail.District_Id)
  //   this.getSubArea(areaDetail.Area_Id)
  // }

  close() {
    this._dialogRef.close()
  }

  submit() {
    if (this.isEdit) {
      // this.form.value.Sub_Area_Id = this.form.value.Sub_Area_Id == null ? 0 : this.form.value.Sub_Area_Id
      this._commonService._spinner.show()
      this.enableControls()
      this._addressService.update_customer_address(this.form.value).subscribe((res: any) => {
        this._commonService._spinner.hide()
        if (res.ack) {
          this.disableControls()
          this._commonService.onSuccess(res.msg)
          this._dialogRef.close()
        } else {
          this.disableControls()
          this._commonService.onFailed(res.msg)
        }
      }, (err) => { this._commonService._spinner.hide(); this._commonService.onFailed(err) })
    } else {
      this.form.value.Sub_Area_Id = this.form.value.Sub_Area_Id == null ? 0 : this.form.value.Sub_Area_Id
      // this.form.patchValue({
      //   District_Id: this.locationDetails.District_Id,
      //   Sub_Area_Id: this.locationDetails.Sub_Area_Id,
      //   Sub_Area_Name: this.locationDetails.Sub_Area_Name,
      //   Pincode: this.locationDetails.Pincode
      // })
      this._commonService._spinner.show()
      this._addressService.add_customer_address(this.form.value).subscribe((res: any) => {
        this._commonService._spinner.hide()
        if (res.ack) {
          this._commonService.onSuccess(res.msg)
          this._dialogRef.close()
        } else this._commonService.onFailed(res.msg)
      }, (err) => { this._commonService._spinner.hide(); this._commonService.onFailed(err) })
    }
  }


}
