import { DesifarmProductDetailsComponent } from './../desifarms-shopallitems/desifarm-product-details/desifarm-product-details.component';
import { BehaviorSubject } from 'rxjs';
import { ChangeDetectorRef, Component, NgZone, OnInit, HostListener } from '@angular/core';
import { Location } from 'src/app/models/desifarm-common/desifarm-local/desifarm-location';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmSearchProductsService } from 'src/app/services/desifarm-search-product/desifarm-search-products.service';
import { DesifarmsHeaderfooterComponent } from '../desifarms-headerfooter/desifarms-headerfooter.component';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { MatDialog } from '@angular/material/dialog';
import { DesifarmShopallitemsService } from 'src/app/services/desifarm-shopallitems/desifarm-shopallitems/desifarm-shopallitems.service';
import { environment } from 'src/environments/environment';
import { DesifarmLoginRegisterComponent } from '../desifarm-login-register/desifarm-login-register.component';
import { event } from 'jquery';

@Component({
  selector: 'app-desifarm-search-products',
  templateUrl: './desifarm-search-products.component.html',
  styleUrls: ['./desifarm-search-products.component.scss']
})
export class DesifarmSearchProductsComponent implements OnInit {

  loginData: any
  location: Location
  page: any
  skip: number = 0
  pageNo: number = 1
  prevPage: number
  take: number = 5000
  loadproduct: number = 9
  searchButton: boolean = false

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 992) this.searchButton = true
    else this.searchButton = false
  }


  searchOptions: any = {
    Franchise_Id: 8,
    Keyword: '',
    Start: this.skip,
    End: this.take,
    Business_Type_Id: environment.Business_Type_Id,
    District_Id: null
  }

  outOfStock: any = { Franchise_Id: 8, Cust_Id: 0, Product_Id: 0, Product_Packaging_Rate_Id: 0 }

  paginator: any[] = []
  searchProductList: any[] = []
  cartProduct: any[] = []
  subscribeCartProduct: any[] = []
  allProductList: any[] = []
  testEmitter$ = new BehaviorSubject<any[]>(this.searchProductList);

  product_count: number

  isGridView: boolean = false
  isProducts: boolean = false
  isKeywords: any

  constructor(
    private _commonService: DesifarmCommonService,
    private _searchProductsService: DesifarmSearchProductsService,
    public _localCartService: DesifarmLocalCartService,
    private _productsService: DesifarmShopallitemsService,
    private _dialog: MatDialog
  ) {
    this._localCartService.selectedDistrict.subscribe((District_Id) => {
      this.searchOptions.District_Id = District_Id
    })
  }

  getCartProducts() {
    this._commonService._spinner.show()
    this._localCartService.cartList.subscribe((res) => {
      this._commonService._spinner.hide()
      this.cartProduct = res
      this.updateCartStatus()
    }, () => { }, () => { })
  }

  ngOnInit(): void {
    this.getFranchise_Id()
    this.loginData = this._commonService.getUser()
    this.getSearchProductLocalList()
    this.getCartProducts()
    if (window.innerWidth < 992) this.searchButton = true
    else this.searchButton = false
    this.getAllProduct()
  }

  getAllProduct = () => {
    this._commonService._spinner.show()
    this._productsService.get_all_products(8, this.searchOptions.District_Id).subscribe((res: any) => {
      this._commonService._spinner.hide()
      this.allProductList = res.data
      this._localCartService.searProductList.next([...this.allProductList])
      this._localCartService.searProductListCount.next(this.allProductList.length)
    }, (err) => {
      this._commonService._spinner.hide()
    })
  }

  getSearchProductLocalList() {
    this._localCartService.searProductList.subscribe((res: any) => {
      res.map((e, i) => {
        e.Added_In_Cart = false;
        e.Cart_Qty = 0
      })
      this.paginator.length = 0
      this.searchProductList = res
      this.updateCartStatus()
    })
    this._localCartService.searProductListCount.subscribe((res: any) => {
      this.product_count = res
      for (let index = 1; index <= Math.ceil(this.product_count / 9); index++) {
        this.paginator.push(index)
      }
    })
  }

  getFranchise_Id() {
    this.location = this._commonService.getData(environment.locationKey)
    // this.searchOptions.Franchise_Id = this.location.Franchise_Id
    this.searchOptions.Franchise_Id = 8
  }

  public onSearch(keyword) {
    if (keyword && keyword.length >= 4) {
      this.searchOptions.Keyword = keyword
      this.isKeywords = this.searchOptions.Keyword
      this.getFranchise_Id()
      this.getSearchProduct(this.searchOptions)
    } else if (keyword.length == 0) {
      this.getAllProduct()
    } else {
      this._localCartService.searProductList.next([...this.allProductList])
      this._localCartService.searProductListCount.next(this.allProductList.length)
    }

  }

  getSearchProduct(searchOptions) {
    // this._commonService._spinner.show()
    this._searchProductsService.search_products(searchOptions).subscribe((res: any) => {
      // this._commonService._spinner.hide()
      if (res.ack) {
        this._localCartService.searProductList.next(res.data)
        this._localCartService.searProductListCount.next(res.data.length)
        // this._localCartService.searProductListCount.next(res.product_count)
      } else {
        this._localCartService.searProductList.next([...this.allProductList])
        this._localCartService.searProductListCount.next(this.allProductList.length)
        this._commonService.onFailed(res.msg)
      }
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed(err)
    }, () => { }
    )
  }

  decreaseValueModal(option) {
    this.searchProductList.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty == 1) {
          e.Added_In_Cart = false
          let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
          this.cartProduct.splice(index, 1)
          this.updateQty()
          this._commonService.onFailed("Product Removed Successfully..")
        } else {
          e.Cart_Qty = e.Cart_Qty - 1
          this.updateQty()
        }

      }
    })
  }

  increaseValueModal(option) {
    // this.searchProductList.map((e) => {
    //   if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
    //     // if (e.Cart_Qty < e.Avl_Qry) {
    //     //   e.Cart_Qty = e.Cart_Qty + 1
    //     //   this.updateQty()
    //     // } else this._commonService.onFailed("Out Of Stock")
    //     e.Cart_Qty = e.Cart_Qty + 1
    //     this.updateQty()
    //   }
    // })

    this.searchProductList.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty < e.Avl_Qry) {
          e.Cart_Qty = e.Cart_Qty + 1
        } else this._commonService.onFailed("Out Of Stock")
      }
    })
    this.updateQty()

  }

  onCartQtyChange(event, option) {
    let qty = event.target.value
    if (qty && qty > 0) {
      // let qty = event.target.value
      this.searchProductList.map((e) => {
        if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
          // if (qty < e.Avl_Qry) e.Cart_Qty = parseInt(qty)
          e.Cart_Qty = parseInt(qty)
        }
      })
    } else if (qty < 0 || qty == '' || qty == 0) {
      this.deleteProductFromCart(option)
    }
  }

  onAddToCart(option) {
    // this.searchProductList.map((e, i) => {
    //   if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
    //     e.Added_In_Cart = true
    //     e.Cart_Qty = 1
    //     e.Product_Total = e.Cart_Qty * e.selling_amt
    //     this._commonService.onSuccess("Product Added In Cart")
    //   }
    // })
    // let product = this.searchProductList.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    // this.cartProduct.push(product)
    // this._localCartService.setCartProducts(this.cartProduct)

    this.searchProductList.map((e, i) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Avl_Qry > 0) {
          e.Added_In_Cart = true
          e.Cart_Qty = 1
          e.Product_Total = e.Cart_Qty * e.selling_amt
          this._commonService.onSuccess("Product Added In Cart")
          let product = this.searchProductList.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
          this.cartProduct.push(product)
          this._localCartService.setCartProducts(this.cartProduct)
        } else {
          this._commonService.onFailed("Out Of Stock")
          this.outOfStock.Product_Id = option.product_id
          this.outOfStock.Product_Packaging_Rate_Id = option.product_packaging_rate_id
          this.outOfStock.Franchise_Id = 8
          this.outOfStockHit()
        }
      }
    })
  }

  outOfStockHit() {
    this._productsService.insert_out_of_stock(this.outOfStock).subscribe((res: any) => {
    })
  }

  updateCartStatus() {
    this.searchProductList.map((e) => e.Added_In_Cart = false)
    this.cartProduct.map((cp) => {
      this.searchProductList.map((p) => {
        if (cp.product_packaging_rate_id == p.product_packaging_rate_id) {
          p.Added_In_Cart = true
          p.Cart_Qty = cp.Cart_Qty

        }
      })
    })
  }

  updateQty() {
    this.searchProductList.map((e) => {
      this.cartProduct.map((e1) => {
        if (e.product_packaging_rate_id == e1.product_packaging_rate_id)
          e1.Cart_Qty = e.Cart_Qty
      })
    })
    this.updateCart()
  }

  updateCart() {
    this._localCartService.setCartProducts(this.cartProduct)
  }

  onProductDetail(option) {
    const dialogRef = this._dialog.open(DesifarmProductDetailsComponent, {
      width: '800px',
      height: '70vh',
      panelClass: 'myclass2',
      data: option
    })
    dialogRef.afterClosed().subscribe()
  }

  onSubscribe(option) {
    this.loginData = this._commonService.getUser()
    if (this.loginData) this.subscribeProduct(option)
    else this.onSignIn(option)

  }

  onSignIn(option) {
    const dialogRef = this._dialog.open(DesifarmLoginRegisterComponent, {
      width: '800px',
      panelClass: 'loginRegisterComponent',
    })
    dialogRef.afterClosed().subscribe(() => {
      this._commonService.isLoggedIn.next(true)
      this.onSubscribe(option)
    })
  }

  subscribeProduct(option) {
    let product = this.searchProductList.find(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    product.Added_In_Cart = true
    product.Cart_Qty = 1
    product.Product_Total = product.Cart_Qty * product.selling_amt
    this.subscribeCartProduct.push(product)
    this._localCartService.setSubscribeCartProducts(this.subscribeCartProduct)
    this._commonService.router.navigate(['subscribe-cart'])
  }

  deleteProductFromCart(option) {
    let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    this.cartProduct.splice(index, 1)
    this.updateQty()
  }

  setPage(page: number, buttonName: string) {
    this.prevPage = this.page
    this.pageNo = page;
    this.skip = (page - 1) * this.loadproduct;
    this.take = this.loadproduct;
    this.searchOptions.Start = this.skip
    this.searchOptions.End = this.take
    this.searchOptions.Keyword = JSON.parse(sessionStorage.getItem("keyword"))
    this.getSearchProduct(this.searchOptions)
  }

  checkFirstNumber() {
    let result = this.paginator[0]
    if (result == this.pageNo) return true
    else return false
  }

  checkLastNumber() {
    let result = this.paginator[this.paginator.length - 1]
    if (result == this.pageNo) return true
    else return false
  }
}
