<!-- ::::::  Start Header Section  ::::::  -->
<header>
  <!-- <div class="marquee">
    <div class="marquee-content">
      <span class="item-collection-1">

        <span *ngIf="selectedCity?.District_Name == 'Navi Mumbai'"> <i class="fas fa-circle"
            style="font-size: 10px;"></i> Subscribe and get 7 days milk free</span>
        <span *ngIf="selectedCity?.District_Name == 'Navi Mumbai'"> <i class="fas fa-circle"
            style="font-size: 10px;"></i> Free Home Delivery </span>

        <span *ngIf="selectedCity?.District_Name == 'Pune'"> <i class="fas fa-circle" style="font-size: 10px;"></i> Free
          Home Delivery</span>
        <span *ngIf="selectedCity?.District_Name == 'Pune'"> <i class="fas fa-circle" style="font-size: 10px;"></i> Cash
          On Delievry</span>
        <span *ngIf="selectedCity?.District_Name == 'Pune'"> <i class="fas fa-circle" style="font-size: 10px;"></i>
          Recharge & Get Upto AED 4000 Cashback</span>
      </span>
    </div>
  </div> -->
  <!--  Start Large Header Section   -->
  <div class="header d-none d-lg-block  sticky-header">
    <div class="header__center">
      <div class="container mycontainer myheadercontainer">

        <div class="row justify-content-between">
          <div class="col-md-2">
            <div class="header__logo">
              <a routerLink="/" class="header__logo-link img-responsive">
                <img class="header__logo-img img-fluid" src="assets/img/logo/desi.png" alt=""
                  style="margin: 15px 0px 0px 0px;" />
              </a>
            </div>
          </div>
          <div class="col-md-10 mt-2">
            <div class="row align-items-center">
              <div class="col-xl-3 col-lg-3" (mouseleave)="searchTerm = false">
                <div class="header-menu-vertical pos-relative">
                  <h4 class="menu-title link--icon-left" (click)="searchTermsbtn()" (mouseenter)="searchTerm = true">
                    <i class="far fa-align-left"></i>CATEGORIES
                  </h4>
                  <ul class="menu-content pos-absolute" *ngIf="searchTerm" (mouseleave)="searchTerm = false">
                    <li class="menu-item" *ngFor="let option of mainCategories" (click)="navigate(option)">
                      <a>{{ option.Category_Name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5">
                <form class="header-search">
                  <div class="header-search__content pos-relative">
                    <input type="search" name="header-search" placeholder="Search your products" required #search
                      (input)="searchProduct($event)" autocomplete="off" routerLink="search-products"
                      list="product_list" #searchInput />
                    <datalist id="product_list">
                      <option *ngFor="let option of searchProductList" [value]="option.product_name">
                    </datalist>

                    <button class="pos-absolute" type="submit" (click)="handleSeachBtn(searchInput.value)">
                      <i class="icon-search"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div class="col-md-4">
                <ul class="header__user-action-icon">
                  <div class="row align-items-center">
                    <div class="col-md-5">
                      <li *ngIf="loginFlag" (click)="onSignIn()">
                        <a style="font-size: 20px; font-weight: 500">
                          <i class="fas fa-sign-in-alt" style="color: #62aa00"></i>
                          Sign In
                        </a>
                      </li>
                      <li *ngIf="!loginFlag" class="header__nav-item pos-relative mylogindetails"
                        style="display: flex !important">
                        <a class="login-name" style="font-size: 17px; font-weight: 500; text-transform: capitalize;">
                          <i class="fas fa-user" style="color: #62aa00"></i>
                          {{ loginData?.Cust_Name }} &nbsp;
                          <i class="fal fa-chevron-down"></i>
                        </a>

                        <ul class="dropdown__menu pos-absolute" style="text-align: left">
                          <li class="dropdown__list">
                            <a class="dropdown__link" routerLink="myprofile">My Profile</a>
                          </li>
                          <li class="dropdown__list">
                            <a class="dropdown__link" routerLink="myorders">My Order</a>
                          </li>
                          <!-- <li class="dropdown__list">
                            <a class="dropdown__link" routerLink="mysubscription">My Subscription</a>
                          </li> -->
                          <li class="dropdown__list">
                            <a class="dropdown__link" routerLink="manageaddress">Manage Address</a>
                          </li>

                          <!-- <li class="dropdown__list" (click)="openComplaintSuggestion()">
                            <a class="dropdown__link">Complaints & Suggestions</a>
                          </li> -->
                          <li class="dropdown__list">
                            <a class="dropdown__link" routerLink="mywallet">My Wallet</a>
                          </li>
                          <!-- <li class="dropdown__list">
                            <a class="dropdown__link" routerLink="refer-and-earn">Refer & Earn</a>
                          </li> -->
                          <li class="dropdown__list">
                            <a class="dropdown__link" (click)="onSignOut()">Sign Out</a>
                          </li>
                        </ul>
                      </li>
                    </div>
                    <div class="col-md-7 pl-0">
                      <li class="header__nav-item pos-relative mylogindetails carticon"
                        [class.d-none]="getCurrentRoute()">
                        <a class="offcanvas-toggle" (click)="onCartButton()">
                          <i style="font-size: 28px" class="icon-shopping-cart"></i>
                          <span class="wishlist-item-count pos-absolute"
                            style="background: #62aa00;color: #fff;border:1px solid #62aa00;">{{ cartProduct.length
                            }}</span>
                        </a>

                        <ul class="dropdown__menu pos-absolute mydropdown-menu" style="text-align: left"
                          [ngClass]="{ 'd-none': cartProduct.length == 0 }">
                          <h3>Your Cart</h3>
                          <hr style="margin-bottom: 0;" />
                          <div class="p-1 center-cart">
                            <ng-container *ngIf="cartProduct.length > 0">
                              <div class="row mt-2" *ngFor="let option of cartProduct">
                                <div class="col-md-3">
                                  <img [src]="option.product_image" class="img-responsive" />
                                </div>
                                <div class="col-md-6">
                                  <h4>{{ option.product_name }}{{ option.package_qty }}{{ option.unit_name }}
                                  </h4>
                                  <p class="quantity">AED {{ option.Cart_Qty }} X{{ option.selling_amt | number :
                                    '1.0-2'}}
                                  </p>
                                  <div class=" product-quantity d-flex align-items-center flex-wrap ">
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <p class="deleteicon" (click)="onDelete(option)">
                                    <i class="far fa-trash-alt"></i>
                                  </p>
                                  <p class="amount">
                                    AED {{ option.Product_Total | number : '1.0-2'}}
                                  </p>
                                </div>
                              </div>
                            </ng-container>

                            <div class="row" *ngIf="cartProduct.length == 0">
                              <div class="col-md-12">
                                <p
                                  style=" font-size: 18px; text-align: center; border: 2px solid gray; margin: 10px; padding: 10px; border-radius: 10px; ">
                                  Your Cart is Empty......
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div class="cartfooter">
                            <div class="row">
                              <div class="col-md-8">
                                <p class="pl-4" style=" color: gray; font-size: 18px; margin-bottom: 0; "> Subtotal </p>
                                <p class="pl-4" style=" font-size: 20px; color: rgb(26, 26, 26); font-weight: 500; ">
                                  AED {{ Sub_Total_Amt | number : '1.0-2'}}
                                </p>
                              </div>
                              <div class="col-md-2">
                                <button (click)="onCartButton()"
                                  class="btn btn--box cart-btn btn--small btn--dark btn--black btn--black-hover-green font--semi-bold ">
                                  Cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </ul>
                      </li>
                      <li *ngIf="!loginFlag" style="cursor:pointer;    color: #62aa00;" routerLink="/mywallet">
                        <i class="fas fa-wallet"></i>
                        <span class="badge badge-light walleticons">{{ walletAmountCount | number : '1.2-2'}}</span>
                      </li>
                      <ng-container>
                        <li class="selectedCity" (click)="cityselection = true">
                          <i class="fas fa-map-marker" style="color: #62aa00;margin-left: 18px;"></i> <br>
                          <a style="color: #666;"> {{selectedCity?.District_Name}} </a>
                        </li>
                      </ng-container>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div class="header__bottom">
              <div class="">
                <nav class="">
                  <ul class="header__nav" style="display: inline-block;">
                    <!--Start Single Nav link-->
                    <!-- <li class="header__nav-item pos-relative" style="padding-left: 0">
                      <a routerLink="" class="header__nav-link">Home</a>
                    </li> -->
                    <!-- End Single Nav link-->

                    <li class="header__nav-item pos-relative">
                      <a (click)="orderNowMenu()" class="header__nav-link">Order Now
                      </a>
                    </li>

                    <!-- <li class="header__nav-item pos-relative">
                      <a class="header__nav-link " href="https://desifarms.web.milkmanapps.com/auth/login"
                        target="_blank">Milk Subscription </a>
                    </li> -->
                    <li class="header__nav-item pos-relative">
                      <a routerLink="/blogs" class="header__nav-link ">Blogs</a>
                    </li>
                    <li class="header__nav-item pos-relative">
                      <a routerLink="/contactus" class="header__nav-link ">Contact Us</a>
                    </li>
                    <!-- <li class="header__nav-item pos-relative">
                      <a routerLink="/termscondition" class="header__nav-link ">Terms & Conditions</a>
                    </li> -->

                    <li class="header__nav-item pos-relative">
                      <a routerLink="/aboutus" class="header__nav-link secondlastchild">About Us</a>
                    </li>
                    <!-- End Single Nav link-->

                    <!--Start Single Nav link-->

                    <!-- Start Single Nav link-->

                    <!--Start Single Nav link-->
                    <!-- <li class="header__nav-item pos-relative">
                      <a
                        href="https://desifarms.web.milkmanapps.com/home/index"
                        target="_blank"
                        class="header__nav-link"
                        >Subscribe Now</a
                      >
                    </li> -->
                    <!-- Start Single Nav link -->

                    <!--Start Single Nav link-->
                    <!-- <li class="header__nav-item pos-relative">
                      <a routerLink="/our-store" class="header__nav-link"
                        >Our Stores</a
                      >
                    </li> -->
                    <!-- End Single Nav link-->

                    <!--Start Single Nav link-->


                    <!-- <li class="header__nav-item pos-relative">
                      <a routerLink="refer-and-earn" class="header__nav-link secondlastchild">Refer & Earn</a>
                    </li> -->
                    <!-- End Single Nav link-->
                    <!--Start Single Nav link-->
                    <!-- <li class="header__nav-item pos-relative">

                      <a routerLink="/" class="header__nav-link mymainlink"
                        >Order Fresh Dairy Products</a
                      >
                    </li> -->
                    <!-- End Single Nav link-->
                  </ul>
                  <ul class="header__nav" style="display: inline-block;float: right;">
                    <li class="header__nav-item pos-relative">
                      <a href="tel:+971 56 683210" target="_blank" class="header__nav-link mymainlink">Contact your
                        relationship manager on &nbsp; <b>+971 56 6832100</b> </a>
                      <!-- <a href="tel:{{relationShipManagerDetail?.Mobile_No}}" target="_blank"
                        class="header__nav-link mymainlink">Contact your relationship manager on &nbsp; <b>{{relationShipManagerDetail?.Mobile_No}}</b> </a> -->
                      <!-- <a href="https://desifarms.web.milkmanapps.com/auth/login" target="_blank"
                        class="header__nav-link mymainlink">Order 100% Preservative Free Dairy Products</a> -->
                    </li>
                  </ul>
                  <div style="clear: both;display: none;"></div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="header__mobile mobile-header--1 d-block d-lg-none pt-2">
    <div class="container-fluid sticky-header">

      <div class="row align-items-center ">
        <div class="col-12 d-flex  justify-content-center">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-4 col-xs-4">
              <ul class="
                    header__mobile--leftside
                    d-flex
                    align-items-center
                    justify-content-start
                  ">
                <li>
                  <div class="header__mobile-logo">
                    <a routerLink="/" class="header__mobile-logo-link">
                      <img src="assets/img/logo/desi.png" alt="" class="header__mobile-logo-img" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-8 col-xs-8 ">
              <!-- Start User Action -->
              <ul class="
                  header__mobile--rightside header__user-action-icon
                  d-flex
                  align-items-center
                  justify-content-end
                " style="padding-left: 10px;">
                <!--SIGN UP MOBILE-->
                <li *ngIf="loginFlag" (click)="onSignIn()" style="margin-right: 15px">
                  <a style="font-size: 22px; font-weight: 500">
                    <!-- <i class="fas fa-sign-in-alt" ></i> Sign In -->
                    <i class="fas fa-user" style="color: #62aa00;"></i>
                  </a>
                </li>
                <li *ngIf="!loginFlag" class="header__nav-item pos-relative mylogindetails"
                  style="display: flex !important; margin-right: 15px">
                  <a class="login-name" style="
                      font-size: 17px;
                      font-weight: 600;
                      text-transform: capitalize;
                      font-family: 'MarlinGeo';
                    ">
                    <i class="fas fa-user" style="color: #62aa00"></i>
                    {{ loginData?.Cust_Name }} &nbsp;
                    <i class="fal fa-chevron-down"></i>
                  </a>

                  <ul class="dropdown__menu pos-absolute" style="text-align: left">
                    <li class="dropdown__list">
                      <a class="dropdown__link" routerLink="myprofile">My Profile</a>
                    </li>
                    <li class="dropdown__list">
                      <a class="dropdown__link" routerLink="myorders">My Order</a>
                    </li>
                    <!-- <li class="dropdown__list">
                      <a class="dropdown__link" routerLink="mysubscription">My Subscription</a>
                    </li> -->
                    <li class="dropdown__list">
                      <a class="dropdown__link" routerLink="manageaddress">Manage Address</a>
                    </li>

                    <li class="dropdown__list" (click)="openComplaintSuggestion()">
                      <a class="dropdown__link">Complaint & Suggestion</a>
                    </li>
                    <li class="dropdown__list">
                      <a class="dropdown__link" routerLink="mywallet">My Wallet</a>
                    </li>
                    <!-- <li class="dropdown__list">
                      <a class="dropdown__link" routerLink="refer-and-earn">Refer & Earn</a>
                    </li> -->
                    <li class="dropdown__list">
                      <a class="dropdown__link" (click)="onSignOut()">Sign Out</a>
                    </li>
                  </ul>
                </li>

                <!-- Start Header Add Cart Box -->
                <li class="my-carticon">
                  <a routerLink="/mycart" class="offcanvas-toggle">
                    <i class="icon-shopping-cart"></i>
                    <span class="wishlist-item-count pos-absolute"
                      style="background: #62aa00;color: #fff;border:1px solid #62aa00;">{{ cartProduct.length }}</span>
                  </a>
                </li>
                <!-- End Header Add Cart Box -->
                <li class="walletli" style="cursor:pointer;color: #62aa00;" routerLink="/mywallet">
                  <i class="fas fa-wallet"></i>
                  <span class="badge badge-light walleticons">AED.{{ walletAmountCount | number : '1.2-2'}}</span>
                </li>
                <li class="gift-icon" (click)="cityselection = true">
                  <i class="fas fa-map-marker" style="color: #62aa00;margin-left: 18px;"></i> <br>
                  <a>
                    {{selectedCity?.District_Name | slice:0:5}}
                    <span *ngIf="selectedCity?.District_Name.length > 5">...</span>
                  </a>
                </li>
                <li>
                  <a class="offcanvas-toggle" (click)="toggleSideNav($event)"><i class="far fa-bars"></i></a>
                </li>

              </ul>
              <div class="row align-items-center">
                <div class="col-md-2 col-xs-2" style="    text-align: right;">
                  <li style="color: #62aa00;" routerLink="search-products"
                    *ngIf="getCurrentUrl() !== '/search-products'"><i class="fas fa-search" style="    border: 1px solid;
                    padding: 5px;" aria-hidden="true"></i></li>
                </div>
                <div class="col-md-10 col-xs-10 mt-1">
                  <div class="header-menu-vertical pos-relative m-t-30">

                    <h4 class="menu-title link--icon-left" (click)="searchTermsbtn()" (mouseenter)="searchTerm = true">
                      <i class="far fa-align-left"></i>CATEGORIES
                    </h4>
                    <ul class="menu-content pos-absolute" *ngIf="searchTerm" (mouseleave)="searchTerm = false">
                      <li class="menu-item" *ngFor="let option of mainCategories" (click)="navigate(option)">
                        <a>{{ option.Category_Name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <!-- End User Action -->
        </div>
      </div>
    </div>
  </div>
  <!--  Start Mobile Header Section   -->

  <!--Side Menu-->

  <!--  Start Mobile-offcanvas Menu Section   -->

  <div id="offcanvas-mobile-menu" class="offcanvas offcanvas-mobile-menu" [class.offcanvas-open]="isSideNav">
    <div class="offcanvas__top">
      <button class="offcanvas-close" (click)="toggleSideNav($event)">
        <i class="fal fa-times"></i>
      </button>
    </div>

    <div class="offcanvas-inner">

      <!-- Start Mobile User Action -->
      <ul class="header__user-action-icon m-tb-15 text-center">

        <!-- Start Header Add Cart Box -->
        <li style="font-size: 18px; font-weight: 500">
          <i class="fas fa-mobile-alt" style="color: #62aa00"></i>
          {{ companyDetails?.Company_Contact_No1 }}
        </li>
        <!-- End Header Add Cart Box -->
      </ul>
      <!-- End Mobile User Action -->
      <div class="offcanvas-menu">
        <ul>
          <li>
            <a (click)="orderNowMenu()"><span>Order Now</span></a>
          </li>

          <li *ngIf="selectedCity?.District_Name == 'Pune'">
            <a href="https://desifarms.web.milkmanapps.com/auth/login" target="_blank">Milk Subscription</a>
          </li>
          <li>
            <a routerLink="/our-store"><span>Our Stores</span></a>
          </li>
          <!-- <li><a routerLink="refer-and-earn">Refer & Earn</a></li> -->
          <li>
            <a routerLink="/aboutus"><span>About Us</span></a>
          </li>
          <li>
            <a href="tel:{{selectedCity?.Relationship_Manager_No}}" target="_blank" class="mymainlink">Contact your
              relationship manager on <b>{{selectedCity?.Relationship_Manager_No}}</b>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <ul class="offcanvas__social-nav m-t-50">
      <li class="offcanvas__social-list">
        <a href="#" class="offcanvas__social-link"><i class="fab fa-facebook-f"></i></a>
      </li>
      <li class="offcanvas__social-list">
        <a href="#" class="offcanvas__social-link"><i class="fab fa-twitter"></i></a>
      </li>
      <li class="offcanvas__social-list">
        <a href="#" class="offcanvas__social-link"><i class="fab fa-instagram"></i></a>
      </li>
      <li class="offcanvas__social-list">
        <a href="#" class="offcanvas__social-link"><i class="fab fa-linkedin"></i></a>
      </li>
      <li class="offcanvas__social-list">
        <a href="#" class="offcanvas__social-link"><i class="fab fa-youtube"></i></a>
      </li>



    </ul>
  </div>
  <!--  End Mobile-offcanvas Menu Section   -->

  <!--  Start Popup Add Cart  -->
  <div id="offcanvas-add-cart__box" class="offcanvas offcanvas-cart offcanvas-add-cart" *ngIf="showCart">
    <div class="offcanvas__top">
      <span class="offcanvas__top-text"><i class="icon-shopping-cart"></i>Cart</span>
      <button class="offcanvas-close" (click)="closeCart()">
        <i class="fal fa-times"></i>
      </button>
    </div>
    <!-- Start Add Cart Item Box-->
    <ul class="offcanvas-add-cart__menu">
      <li class="offcanvas-add-cart__list pos-relative d-flex align-items-center justify-content-between "
        *ngFor="let option of cartProduct">
        <div class="offcanvas-add-cart__content d-flex align-items-start m-r-10">
          <div class="offcanvas-add-cart__img-box pos-relative">
            <a routerLink="/home/productdetails" class="offcanvas-add-cart__img-link img-responsive">
              <img [src]="option.product_image" alt="" class="add-cart__img" />
            </a>
            <span class="offcanvas-add-cart__item-count pos-absolute">{{ option.Cart_Qty }}</span>
            <div class="product-quantity product-var__item d-flex align-items-center ">
              <div class="quantity-scale m-l-20">
                <div class="value-button" id="decrease" (click)="decreaseValue(option)">
                  -
                </div>
                <input type="number" id="number" [value]="option.Cart_Qty" />
                <div class="value-button" id="increase" (click)="increaseValue(option)">
                  +
                </div>
              </div>
            </div>
          </div>
          <div class="offcanvas-add-cart__detail">
            <a routerLink="/home/productdetails" class="offcanvas-add-cart__link">{{ option.product_name }} {{
              option.package_qty }}
              {{ option.unit_name }}</a>
            <span class="offcanvas-add-cart__price">AED {{ option.selling_amt | number : '1.0-2'}}</span>
          </div>
        </div>
        <button class="offcanvas-add-cart__item-dismiss" (click)="onDelete(option)">
          <i class="fal fa-times"></i>
        </button>
        <br />
        <div class="pricing">
          <span>{{ option.Cart_Qty }}</span> x
          <span>AED {{ option.selling_amt | number : '1.0-2'}}</span>
        </div>

        <div class="total">
          <span>Total</span> x
          <span>AED {{ option.Product_Total | number : '1.0-2'}}</span>
        </div>
      </li>
    </ul>
    <!-- Start Add Cart Item Box-->
    <!-- Start Add Cart Checkout Box-->
    <div class="offcanvas-add-cart__checkout-box-bottom">
      <ul class="offcanvas-add-cart__checkout-info">
        <li class="offcanvas-add-cart__checkout-list">
          <span class="offcanvas-add-cart__checkout-left-info">Subtotal</span>
          <span class="offcanvas-add-cart__checkout-right-info">AED {{ Sub_Total_Amt | number : '1.0-2'}}</span>
        </li>
        <!-- End Single Add Cart Checkout Info-->
      </ul>
      <!-- End offcanvas Add Cart Checkout Info-->

      <div class="offcanvas-add-cart__btn-checkout">
        <a routerLink="checkout"
          class=" btn btn--block btn--radius btn--box btn--black btn--black-hover-green btn--large btn--uppercase font--bold "
          (click)="onCart()">Cart</a>
      </div>
    </div>
    <!-- End Add Cart Checkout Box-->
  </div>
  <!-- End Popup Add Cart -->

  <div class="offcanvas-overlay"></div>
</header>

<router-outlet></router-outlet>

<!-- ::::::  Start  Footer ::::::  -->
<footer class="footer p-t-30" id="myfooter">
  <div class="container">
    <!-- Start Footer Top Section -->
    <div class="footer__top">
      <div class="row " style="    align-items: baseline">
        <div class="col-lg-4 col-md-6">
          <div class="footer__about">
            <div class="footer__logo mb-3">
              <a routerLink="/" class="footer__logo-link">
                <img src="assets/img/logo/Logo---Blue-N-White.png" alt="" class="footer__logo-img" />
              </a>
            </div>
            <ul class="footer__address mt-4">
              <li class="footer__address-item">
                <a href="https://api.whatsapp.com/send?phone=97143202200" target="_blank" style="color: #fff;">
                  <i class="fab fa-whatsapp"></i>+97143202200
                </a>
              </li>
              <li class="footer__address-item">
                <a href="tel:+971522520001" style="color: #fff;">
                  <i class="fa fa-phone-alt"></i>+971522520001
                </a>
              </li>
              <!-- <li class="footer__address-item">
                <a href="tel:{{ companyDetails?.Company_Contact_No1 }}" style="color: #fff;">
                  <i class="fa fa-phone-alt"></i>{{ companyDetails?.Company_Contact_No1 }}
                </a>
              </li> -->
              <li class="footer__address-item">
                <a href="mailto:sales@nasiktrade.com" style="color: #fff;">
                  <i class="fa fa-envelope"></i>sales@nasiktrade.com
                </a>
              </li>
            </ul>

          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <!-- Start Footer Nav -->
          <div class="footer__menu">
            <h4 class=" footer__nav-title footer__nav-title--dash footer__nav-title--dash-red ">
              GET TO KNOW US
            </h4>
            <hr />
            <ul class="footer__nav">
              <li class="footer__list">
                <a routerLink="/aboutus" class="footer__link">About Us</a>
              </li>
              <li class="footer__list">
                <a routerLink="/myprofile" class="footer__link">Your Account</a>
              </li>
              <li class="footer__list">
                <a routerLink="/blogs" class="footer__link">Blogs</a>
              </li>
              <li class="footer__list">
                <a routerLink="/contactus" class="footer__link">Contact Us</a>
              </li>
            </ul>
          </div>
          <!-- End Footer Nav -->
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
          <div class="footer__menu">
            <h4 class=" footer__nav-title footer__nav-title--dash footer__nav-title--dash-red ">
              Our Policy
            </h4>
            <hr />
            <ul class="footer__nav">
              <!-- <li class="footer__list">
                <a routerLink="/shippingpolicy" class="footer__link">Shipping Policy</a>
              </li> -->
              <li class="footer__list">
                <a routerLink="/privacypolicy" class="footer__link">Privacy Policy</a>
              </li>
              <li class="footer__list">
                <a routerLink="/termscondition" class="footer__link">Terms & Conditions</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <!-- TODO: *ngIf="searchTerm" -->

          <div class="footer__menu">
            <h4 class=" footer__nav-title footer__nav-title--dash footer__nav-title--dash-red ">
              DOWNLOAD THE APP
            </h4>
            <hr />
            <a href="https://apps.apple.com/us/app/nasiktrade/id6471089554" target="_blank">
              <img src="assets/img/icon/iphone.png" class="img-responsive" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.nskstore.pro" target="_blank">
              <img src="assets/img/icon/playstore.png" class="img-responsive mt-2" />
            </a>
            <h4 class="mt-3 footer__nav-title footer__nav-title--dash footer__nav-title--dash-red">
              FOLLOW US
            </h4>
            <hr />

            <div class="row mt-2">
              <div class="col-md-12 socialicons">
                <a>
                  <i style="color: #fff;" class="fab fa-facebook-square fa-2x"></i>
                </a>
                <a> &nbsp;
                  <i style="color: #fff;" class="fab fa-twitter-square fa-2x"></i>
                </a>
                <a> &nbsp;
                  <i style="color: #fff;" class="fab fa-instagram-square fa-2x"></i>
                </a>
                <a> &nbsp;
                  <i style="color: #fff;" class="fab fa-linkedin fa-2x"></i>
                </a>
                <a> &nbsp;
                  <i style="color: #fff;" class="fab fa-youtube fa-2x"></i>
                </a>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <!-- End Footer Top Section -->
    <!-- Start Footer Bottom Section -->
    <div class="footer__bottom">
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="footer__copyright-text text-center">
            <p>
              Copyright 2022&copy; <a href="/" style="color: #fff !important;">NSK</a>. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- ::::::  End  Footer ::::::  -->

<!-- material-scrolltop button -->
<button class="material-scrolltop" type="button"></button>

<!-- Start Modal Location -->
<div class="modal fade" id="modalLocation" tabindex="-1" role="dialog" aria-hidden="true" #modalLocation>
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <img src="assets/img/user/1.jpg" alt="" class="footer__logo-img img-responsive" />
            </div>
            <div class="col-md-7">
              <h5 class="mt-2">PLEASE ENTER SERVICE AREA</h5>
              <p>Where do you want Delivery?</p>

              <div class="loction-content-input">
                <input class="p-1" size="50" type="text" style="display: none" />
                <input class="px-2" placeholder="Select City" type="text" style="display: inline" list="option"
                  autocomplete="off" (change)="onCity($event)" [(ngModel)]="District_Name" />
                <datalist id="option">
                  <option *ngFor="let option of districts" [value]="option.District_Name"></option>
                </datalist>
              </div>
              <br />

              <div class="loction-content-input">
                <input class="p-1" size="50" type="text" style="display: none" />
                <input class="px-2" placeholder="Select Sub Area" type="text" style="display: inline" list="option1"
                  autocomplete="off" (change)="onArea($event)" [(ngModel)]="Sub_Area_Name" />
                <datalist id="option1">
                  <option *ngFor="let option of subAreas" [value]="option.Name"></option>
                </datalist>
              </div>
              <button class="detect-location mt-2" (click)="submit()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Modal Location -->







<script>
  $(document).ready(function () {
    $("#show-hidden-menu").click(function () {
      $(".hidden-menu").slideToggle("slow");
      // Alternative animation for example
      // slideToggle("fast");
    });
  });
</script>


<!-- testing CODE -->
<ng-container *ngIf="locationModalFlag">
  <div style="
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1050;
      overflow: hidden;
      outline: 0;
      backdrop-filter: blur(1px);
    ">
    <div class="my-location-modal" style="
        position: fixed;
        top: 10%;
        left: 25%;
        width: 51%;
        height: 85%;
        z-index: 1050;
        overflow: hidden;
        outline: 0;
      " role="dialog">
      <form [formGroup]="form">
        <div class="modal-content resmodal">
          <div class="modal13" style="overflow: auto">
            <div class="modal-body" style="padding: 0">
              <div class="container-fluid mycon1">
                <div class="row locationmodal">
                  <div class="col-md-5" style="
                      padding-left: 0;
                      background: url('assets/img/signin/2.jpg');
                      background-size: cover;
                    "></div>
                  <div class="col-md-7 servicetext">
                    <button style="float: right; margin: 10px 10px 0 0" class="close" data-dismiss="modal"
                      aria-label="Close" (click)="isLocationSet()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h5 class="text-center">PLEASE ENTER SERVICE AREA</h5>
                    <p>Where do you want Delivery?</p>

                    <div class="loction-content-input">
                      <input class="p-1" size="50" type="text" style="display: none" />
                      <input class="px-2 form-control" placeholder="Select City" type="text"
                        style="display: inline; color: #000; font-weight: 500" list="option" autocomplete="off"
                        (change)="onCity($event)" formControlName="District_Name" />
                      <datalist id="option">
                        <option *ngFor="let option of districts" [value]="option.District_Name"></option>
                      </datalist>
                    </div>
                    <br />

                    <div class="loction-content-input">
                      <input class="p-1" size="50" type="text" style="display: none" />
                      <input class="px-2 form-control" placeholder="Select Area" type="text"
                        style="display: inline; color: #000; font-weight: 500" list="options" autocomplete="off"
                        (change)="onAreaSelection($event)" formControlName="Area_Name" />
                      <datalist id="options">
                        <option *ngFor="let option of areas" [value]="option.Area_Name"></option>
                      </datalist>
                    </div>
                    <div class="loction-content-input">
                      <p class="text-danger mt-2 pl-1" style="font-size: 13px;">
                        <!-- Delivery Available In (Aundh, Baner, Balewadi, Bavdhan, Pashan, Kothrud, Satara Road, Singhgad Road, Wakad, Bibvewadi) -->
                        Delivery Available In ({{vnfareaslist}})
                      </p>
                    </div>
                    <!-- <br /> -->

                    <div class="loction-content-input">
                      <input class="p-1" size="50" type="text" style="display: none" />
                      <input class="px-2 form-control" placeholder="Select Sub Area" type="text"
                        style="display: inline; color: #000; font-weight: 500" list="option1" autocomplete="off"
                        (change)="onArea($event)" formControlName="Sub_Area_Name" />
                      <datalist id="option1">
                        <option *ngFor="let option of subAreas" [value]="option.Name"></option>
                      </datalist>
                    </div>
                    <div class="loction-content-input">
                      <p class="text-danger mt-2 pl-1" style="font-size: 13px;">
                        <!-- Serviceable areas are (Aundh, Baner, Balewadi, Bavdhan, Pashan, Kothrud) -->
                        If your sub area is not available here then select <b class="text-success">Other</b>
                      </p>
                    </div>

                    <div class="text-center">
                      <button [disabled]="form.invalid" (click)="submit()" class="
                          btn btn--box
                          setlocation-btn
                          btn--medium
                          btn--radius
                          btn--dark
                          btn--black
                          btn--black-hover-green
                          font--semi-bold
                        ">
                        Set Location
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<!-- testing CODE  -->

<ng-container *ngIf="cityselection">
  <div
    style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 1050; overflow: hidden; outline: 0; backdrop-filter: blur(2px);">
    <div class="mobile-citymodal"
      style="position: fixed;top: 10%;left: 25%;width: 51%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
      role="dialog">
      <div class="inner-border modal-content d-block">
        <div class="modal-header">
          <h3 class="modal-title">Select Emirate</h3>
          <button type="button" (click)="closeCitySelection()" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal13 citymodal" style="height: calc(100vh - 300px);overflow: auto;">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12">
                <div class="modal-bg checkout-page">
                  <table class="table ">
                    <tbody>
                      <tr *ngFor="let option of districts; index as i">

                        <td style="cursor: pointer;border-bottom: 1px solid #dee2e6; border-top: none;"
                          (click)="handleCity(option)">
                          {{option.District_Name}}</td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="justify-content: flex-start !important;">
          <p class="modal-title text-justify text-danger" id="exampleModalLabel">Changing Location Could Also Delete
            Items In Your cart</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>