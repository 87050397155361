import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DesifarmLoginService } from 'src/app/services/desifarm-auth/desifarm-login/desifarm-login.service';
import { DesifarmCarrerService } from 'src/app/services/desifarm-carrer/desifarm-carrer.service';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';

@Component({
  selector: 'app-desifarms-career',
  templateUrl: './desifarms-career.component.html',
  styleUrls: ['./desifarms-career.component.scss']
})
export class DesifarmsCareerComponent implements OnInit {

  form: FormGroup
  msgFlag: boolean = false
  message: string = "Your Enquery is Submitted Successfully.."
  fileName: string = "No file Choosen"

  constructor(
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _authService: DesifarmLoginService,
    private _desifarmCarrerService: DesifarmCarrerService,
    private _dialog: MatDialog,
  ) { }

  initForm() {
    this.form = this._fb.group({
      Candidate_Name: ['', Validators.required],
      Position_Applying_For: ['', [Validators.required]],
      Email_Id: ['', [Validators.email]],
      Contact_No: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      Resume: ['', Validators.required],
      extension: [''],
    })
  }

  showMsg(message: string) {
    this.message = message
    this.msgFlag = true
    setTimeout(() => {
      this.msgFlag = false
    }, 2000)
  }

  ngOnInit(): void {
    this.initForm()
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  binaryString: any = null
  selectedFile = null
  url: any = null

  onResume(event) {

    this.selectedFile = event.target.files[0]
    this.fileName = this.selectedFile.name
    if (this.selectedFile.type === "application/pdf" || this.selectedFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      let reader = new FileReader()
      let me = this
      reader.onload = (readerEvt: any) => {
        if (readerEvt != null && readerEvt.target != null && readerEvt.target.result != null) {
          var binaryString = readerEvt.target.result
          me.binaryString = btoa(binaryString)
          this.form.patchValue({ Resume: this.binaryString, extension: this.selectedFile.name.split('.')[1] })
          
        }
      }
      reader.readAsBinaryString(this.selectedFile)
    } else {
      this.form.patchValue({ Resume: '' })
      this._commonService.onFailed("Please select doc or pdf file only")
    }

  }

  get f() {
    return this.form
  }

  onSubmit() {
    if (this.form.valid) {
      this._commonService._spinner.show()
      this._desifarmCarrerService.insertCarrer(this.form.value).subscribe((res: any) => {
        this._commonService._spinner.hide()
        if (res.ack) {
          this.form.reset()
          this.showMsg(res.msg)
          // this._commonService.onSuccess(res.msg)
        }
      }, (err) => {
        this._commonService._spinner.hide()
        this._commonService.onSuccess("Internet Connection Failed")
      })
    }
  }

}
