import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmOnlineInvoiceService {

  constructor(private http: HttpClient) { }

  Get_Invoice_Detail(Sale_Order_Id): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'Get_Invoice_Detail', { Sale_Order_Id: Sale_Order_Id })
  }

  Get_Franchise_Invoice_Detail(Franchise_Order_Id): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'Get_Franchise_Invoice_Detail', { Franchise_Order_Id: Franchise_Order_Id })
  }
}
