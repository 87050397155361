<section style="background: #f1f3f2;padding-top: 20px;padding-bottom: 50px;">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-xs-12 d-none d-lg-block">
        <img src="assets/img/order/desifarm_vegetables.jpg" class="img-responsive" style="box-shadow:3px 6px 12px #737373;">
      </div>
      <div class="col-lg-9 seconddiv">
        <h2 class="thankyou">Thank you,</h2>
        <h4 style="color: green;font-size: 18px;    margin-bottom: 0px;">Your order will be delivered.</h4>
        <!-- <h4 style="color: green;font-size: 18px;    margin-bottom: 0px;">Your order will be delivered in 24 hours.</h4> -->
        <p style="font-size: 13px;">An sms confirmation has been sent to +971-{{orderDetails?.Mobile_Number}}</p>
        <hr>
        <div class="row mt-2 mb-3" style="font-family: 'MarlinGeo';font-size: 15px;">
          <div class="col-md-3 col-xs-6">
            <p>Order Number</p>
            <p>Amount</p>
          </div>
          <div class="col-md-3 col-xs-6">
            <p><b>{{orderDetails?.Sale_Order_Id}}</b></p>
            <!-- <p><b>AED {{orderDetails?.Total_Selling_Amount + orderDetails?.Order_Delivery_Charges - orderDetails?.Wallet_Amount_Used}}</b></p> -->
            <p><b>AED {{orderValue | number : '1.0-2'}}</b></p>
          </div>
          <div class="col-md-3 col-xs-6">
            <p>Order Date</p>
            <p>Mode of Payment</p>
          </div>
          <div class="col-md-3 col-xs-6">
            <!-- <p><b>26/06/2021 7.50 AM</b> </p> -->
            <p><b>{{orderDetails?.Order_Date_Time | date:"mediumDate"}}
                {{orderDetails?.Order_Date_Time | date:"shortTime"}}</b> </p>
            <!-- <p><b>CASH ON DELIVERY</b></p> -->
            <p *ngIf="orderDetails?.Payment_Mode != 'Subscription'"><b>{{orderDetails?.Payment_Mode == "COD" ? "CASH ON DELIVERY" : "PAID ONLINE"}}</b></p>
            <p *ngIf="orderDetails?.Payment_Mode == 'Subscription'"><b>Subscription</b></p>
          </div>
        </div>
        <!-- <p class="text-center">
          <span style="font-family: 'MarlinGeo';font-size: 13px;
                border:1px solid gray;border-radius: 5px;padding: 3px;
                margin: 0 auto;">Selected Slots &nbsp; {{orderDetails?.Customer_Delivery_Date | date:"mediumDate"}}
            &nbsp;
            {{orderDetails?.Slot_Name}}</span>
        </p> -->
        <p class="text-center mt-4" style="font-family: 'MarlinGeo';font-size:16px;">
          <i style="color: gray;" class="fas fa-truck fa-lg"></i> &nbsp;
          <span style="font-weight: 500;" class="delivered-color"> Your order will be delivered.</span> &nbsp;
          <!-- <a style="color: red;text-decoration: underline !important;" (click)="viewMyOrders()">View Order</a></p> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center mt-4">
        <a routerLink="" type="submit" class="btn btn--box btn--small btn--transparent btn--border-green ">CONTINUE
          SHOPPING</a>
      </div>
    </div>
  </div>
</section>
