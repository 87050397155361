import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmWalletTopupService } from 'src/app/services/desifarm-dashboard/desifarm-wallet/desifarm-wallet-topup.service';
import { DesifarmWalletService } from 'src/app/services/desifarm-dashboard/desifarm-wallet/desifarm-wallet.service';
import { IcustomeWindow, WindowrefService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-windowRef/windowref.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-desifarm-wallet-topup',
  templateUrl: './desifarm-wallet-topup.component.html',
  styleUrls: ['./desifarm-wallet-topup.component.scss']
})
export class DesifarmWalletTopupComponent implements OnInit {

  loginData: any

  walletAmountCount: number = 0

  form: FormGroup


  _window: IcustomeWindow
  option: any
  rzp: any

  offers: any[] = []


  constructor(
    public _dialogRef: MatDialogRef<DesifarmWalletTopupComponent>,
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _walletServiceService: DesifarmWalletService,
    private _walletTopupServiceService: DesifarmWalletTopupService,
    private winRef: WindowrefService,
    private zone: NgZone,
    private _dialog: MatDialog,
    private _router: Router
  ) { this._window = this.winRef.nativeWindow }

  initForm() {
    this.form = this._fb.group({
      Cust_Id: ['', [Validators.required]],
      Franchise_Id: [''],
      Master_Ids: [''],
      Transaction_Type: [''],
      Business_Type_Id: [''],
      Transaction_Detail: [''],
      Cr_Amt: ['', [Validators.required]],
      Dr_Amt: [''],
      Trancation_Mode: [''],
      Entered_By: [''],
      Status: [''],
      Referral_Code: [''],
      Email_Id: [''],
      First_Name: [''],
      Last_Name: [''],
      Address: [''],
      City: [''],
      Current_Url: ['']
    })
  }

  updateForm() {
    this.form.reset()
    this.form.patchValue({
      Cust_Id: this.loginData.Cust_Id,
      Franchise_Id: 0,
      Master_Ids: 0,
      Transaction_Type: "Wallet Topup",
      Business_Type_Id: 1,
      Transaction_Detail: "",
      Dr_Amt: 0,
      Trancation_Mode: "Auto",
      Entered_By: 0,
      Status: "Approved",
      Referral_Code: this.loginData.Referral_Code,
      Email_Id: this.loginData.Email_Id,
      First_Name: this.loginData.First_Name,
      Last_Name: this.loginData.Last_Name,
      Address: this.loginData.Area_Name,
      City: this.loginData.District_Name,
      Current_Url: this._router.url
    })
  }

  getData() {
    this._walletServiceService.get_wallet_amount(this.loginData.Cust_Id).subscribe((res: any) => {
      this._walletServiceService.walletAmountCount.next(res.data % 1 != 0 ? Math.round(res.data) : res.data)
      this.walletAmountCount = res.data % 1 != 0 ? Math.round(res.data) : res.data
    })
    this.getWalletOffers(this.loginData.Cust_Id)
  }

  getWalletOffers(Cust_Id) {
    this._walletTopupServiceService.get_wallet_offers(Cust_Id).subscribe((res: any) => {
      this.offers = res.data
    })
  }



  ngOnInit(): void {
    this.loginData = this._commonService.getUser()

    this.initForm()
    this.getData()
    this.updateForm()
  }

  onSetValue(value) {
    this.form.patchValue({ Cr_Amt: value })
  }

  onTopup() {
    if (this.form.valid) {
      this.insertWalletTopup(this.form.value)
    }
  }

  //Working in live without credintal
  razorpay(data) {
    this.option = {
      key: environment.rzp_API,
      name: 'NSK Store',
      description: 'Wallet Topup',
      amount: (this.form.controls.Cr_Amt.value * 100),
      prefill: {
        name: this.loginData.Cust_Name,
        email: this.loginData.Email_Id,
        contact: this.loginData.Customer_Mobile_Number
      },
      notes: {
        WalletTransactionId: data.Transaction_id,
        CustId: data.Cust_Id,
        CustName: data.Cust_Name,
        CustomerMobileNo: data.Customer_Mobile_Number,
      },
      theme: {
        color: '#3880FF',

      },
      handler: ((res: any, err: any) => {
        if (res && res.razorpay_payment_id) {
          // this.form.patchValue({ Transaction_Detail: res.razorpay_payment_id })
          // this.walletTopup();
          this.updateWalletTopup({ razorpay_payment_id: res.razorpay_payment_id, Transaction_id: data.Transaction_id, Cust_Id: data.Cust_Id })
        } else {
          this._commonService.onFailed("Transaction Failed.. Try Again")
          // this._commonService.router.navigate(['/mycart'])

        }
      }),
      modal: {
        ondismiss: (() => {
          this.zone.run(() => {
            //window.location.reload();
            //add current page routing if payment fail
            // this.router.navigateByUrl('/shop/RefreshComponent', { skipLocationChange: true }).then(() => {
            //   this.router.navigate(['/shop/checkout']);
            // });
          })
        })
      }
    };

    this.rzp = new this.winRef.nativeWindow['Razorpay'](this.option);
    this.rzp.open();
  }

  // razorpay(data) {
  //   this.option = {
  //     key: environment.rzp_API,
  //     name: 'DesiFarm',
  //     description: 'Wallet Topup',
  //     amount: data.amount,
  //     order_id: data.razorpay_order_id,
  //     currency: data.currency,
  //     prefill: {
  //       name: this.loginData.Cust_Name,
  //       email: this.loginData.Email_Id,
  //       contact: this.loginData.Customer_Mobile_Number
  //     },
  //     theme: {
  //       color: '#3880FF',

  //     },
  //     handler: ((res: any, err: any) => {
  //       if (res && res.razorpay_payment_id) {
  //         this.updateWalletTopup({ razorpay_order_id: res.razorpay_order_id, razorpay_signature: res.razorpay_signature, razorpay_payment_id: res.razorpay_payment_id, Transaction_id: data.Transaction_id, Cust_Id: data.Cust_Id })
  //       } else {
  //         this._commonService.onFailed("Transaction Failed.. Try Again")

  //       }
  //     }),
  //     modal: {
  //       ondismiss: (() => {
  //         this.zone.run(() => { })
  //       })
  //     }
  //   };

  //   this.rzp = new this.winRef.nativeWindow['Razorpay'](this.option);
  //   this.rzp.open();
  // }

  walletTopup() {
    this._commonService._spinner.show()
    this._walletTopupServiceService.wallet_topup(this.form.value).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this._dialogRef.close(res.ack)
        this.updateForm()
        this.getData()
        this._commonService.onSuccess(res.msg)
      } else this._commonService.onFailed(res.msg)
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

  insertWalletTopup(data) {
    this._commonService._spinner.show()
    this._walletTopupServiceService.insert_wallet_topup(data).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        // this.razorpay(res.data)
        window.open(res.data.Payment_Url, '_self')
      } else this._commonService._spinner.hide()
    }, (err) => {
      this._commonService._spinner.hide()
    })
  }

  updateWalletTopup(data) {
    this._commonService._spinner.show()
    this._walletTopupServiceService.update_wallet_topup(data).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this._commonService.onSuccess(res.msg)
        this._dialogRef.close(res.ack)
        this.updateForm()
        this.getData()
      } else this._commonService.onFailed(res.msg)
    }, (err) => {
      this._commonService._spinner.hide()
    })
  }
}


