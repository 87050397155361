<div style="background: #ececec;    border-top: 2px dashed#ea6d14;">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center ">
        <h1 class="mt-5 mb-4 dash-title">My Dashboard</h1>
        <div class="borderbottom"></div>
      </div>
    </div>
    <div class="row mt-4">

      <div class="col-md-4 col-sm-6">
        <div class="dash1 cursor-pointer" style="padding-top: 0px;">
          <img src="assets/img/dashboard/profile.png" class="img-responsive" />
          <h3 style="margin: 8px;">{{loginData?.Cust_Name | titlecase}}</h3>
          <p>Phone:{{loginData?.Customer_Mobile_Number}}</p>
          <button
            class="btn btn--box cart-btn btn--small btn--dark btn--black btn--black-hover-green font--semi-bold"><a
              class="tablink" routerLink="/myprofile">Edit Info</a></button>
        </div>
      </div>
      <!-- <div class="col-md-4 col-sm-6">
        <div class="dash1 cursor-pointer" routerLink="/mywallet">
          <img src="assets/img/dashboard/wallet.png" class="img-responsive" />
          <h3 class="mt-5">My wallet</h3>
        </div>
      </div> -->
      <div class="col-md-4 col-sm-6">
        <div class="dash1 cursor-pointer" routerLink="/myorders">
          <img src="assets/img/dashboard/order.png" class="img-responsive" />
          <h3 class="mt-5">My Orders</h3>
        </div>
      </div>

      <div class="col-md-4 col-sm-6">
        <div class="dash1 cursor-pointer" routerLink="/mysubscription">
          <img src="assets/img/dashboard/order.png" class="img-responsive" />
          <h3 class="mt-5">My Subscription</h3>
        </div>
      </div>

      <div class="col-md-4 col-sm-6">
        <div class="dash1 cursor-pointer" routerLink="/manageaddress">
          <img src="assets/img/dashboard/location.png" class="img-responsive" />
          <h3 class="mt-5">Manage Location</h3>
        </div>
      </div>
    </div>
    <div class="row pb-5">

      <!-- <div class="col-md-4 col-sm-6">
        <div class="dash1 cursor-pointer" routerLink="/subscription">
          <img src="assets/img/dashboard/subscription.png" class="img-responsive" />
          <h3 class="mt-5">My Subscription</h3>

        </div>
      </div> -->
      <!-- <div class="col-md-4 col-sm-6">
        <div class="dash1 cursor-pointer" routerLink="/manageaddress">
          <img src="assets/img/dashboard/location.png" class="img-responsive" />
          <h3 class="mt-5">Manage Location</h3>
        </div>
      </div> -->
      <div class="col-md-4 col-sm-6">
        <div class="dash1 cursor-pointer" routerLink="/myprofile">
          <img src="assets/img/dashboard/profile.png" class="img-responsive" />
          <h3 class="mt-5">My Info</h3>
        </div>
      </div>
    </div>
  </div>
</div>
