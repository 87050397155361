import { DesifarmHeaderService } from 'src/app/services/desifarm-header/desifarm-header.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from 'src/app/models/desifarm-common/desifarm-local/desifarm-location';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { DesifarmsHeaderfooterComponent } from '../desifarms-headerfooter/desifarms-headerfooter.component';
import { DesifarmCheckoutService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-checkout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-desifarms-mycart',
  templateUrl: './desifarms-mycart.component.html',
  styleUrls: ['./desifarms-mycart.component.scss']
})
export class DesifarmsMycartComponent implements OnInit {

  cartProduct: any[] = []
  packages: any[] = []

  Value_Of_Products: number = 0
  Value_Of_Discount: number = 0
  Value_Of_Base_Amt: number = 0
  Value_Of_VAT_Amt: number = 0
  Value_Of_Sub_Total: number = 0
  Value_Of_Total: number = 0
  Delivery_Charges: number = 0

  isOpenGstDetail: boolean = false

  location: Location

  options = {
    Main_Category_Id: 0,
    Sub_Category_Id: 0,
    Franchise_Id: 8,
    product_packaging_rate_id: '',
    District_Id: null
  }

  selectedCity: any = {}

  constructor(
    private _commonService: DesifarmCommonService,
    public _localCartService: DesifarmLocalCartService,
    private _headerFooterComp: DesifarmsHeaderfooterComponent,
    private _headerService: DesifarmHeaderService,
    private _checkOutService: DesifarmCheckoutService,
    private _dialog: MatDialog
  ) {
    this._localCartService.selectedDistrict.subscribe((District_Id) => {
      this.options.District_Id = District_Id
    })

    this._localCartService.selectedCity.subscribe((district) => {
      this.selectedCity = district
    })
  }

  getData() {
    this.location = this._commonService.getData(environment.locationKey)
    this.options.Franchise_Id = 8
    this._localCartService.cartList.subscribe((res) => {
      if (res.length == 0) this.redirectToHome()
      res.map(e => {
        e.Product_Total = e.Cart_Qty * e.selling_amt
        e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
        e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
        e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
        e.Total_CGST_Amount = e.Cart_Qty * e.CGST_Amount
        e.Total_SGST_Amount = e.Cart_Qty * e.SGST_Amount
        e.Total_IGST_Amount = e.Cart_Qty * e.IGST_Amount
      })


      this.calculate(res)

      this.cartProduct = res

      if (res.length > 0) {

        let result = this.cartProduct.map(a => a.product_packaging_rate_id)
        this.options.product_packaging_rate_id = result.toString()
        this._checkOutService.get_updated_product_for_cart(this.options).subscribe((res: any) => {
          res.data.map((up) => {
            this.cartProduct.map((cp) => {
              if (up.product_packaging_rate_id == cp.product_packaging_rate_id) {
                if (up.Avl_Qry > 0) {
                  if (cp.Cart_Qty > up.Avl_Qry) {
                    cp.Cart_Qty = up.Avl_Qry
                  } else { }
                } else {
                  let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == up.product_packaging_rate_id)
                  this.cartProduct.splice(index, 1)
                  this.updateCart()
                }
                cp.selling_amt = up.selling_amt
                cp.product_package_mrp = up.product_package_mrp
                cp.Avl_Qry = up.Avl_Qry
                cp.Product_Total = cp.Cart_Qty * up.selling_amt
                cp.Value_Of_Product = cp.Cart_Qty * up.product_package_mrp
                cp.Value_Of_Discount = cp.Cart_Qty * up.discount_amt
                cp.Value_Of_Sub_Total = cp.Cart_Qty * up.selling_amt
                cp.Total_CGST_Amount = cp.Cart_Qty * up.CGST_Amount
                cp.Total_SGST_Amount = cp.Cart_Qty * up.SGST_Amount
                cp.Total_IGST_Amount = cp.Cart_Qty * up.IGST_Amount
              }
            })
            // this.calculate(res)
          })
          // this.Value_Of_Products = res
          //   .map(e => e.Value_Of_Product)
          //   .reduce((prev, next) => prev + next)

          // this.Value_Of_Discount = res
          //   .map(e => e.Value_Of_Discount)
          //   .reduce((prev, next) => prev + next)

          // this.Value_Of_Sub_Total = res
          //   .map(e => e.Value_Of_Sub_Total)
          //   .reduce((prev, next) => prev + next)

          // this.Value_Of_Total = res
          //   .map(e => e.Value_Of_Sub_Total)
          //   .reduce((prev, next) => prev + next)

          // this.Delivery_Charges = 0
          // this.Value_Of_Total += this.Delivery_Charges

        })

        // this.Value_Of_Products = res
        //   .map(e => e.Value_Of_Product)
        //   .reduce((prev, next) => prev + next)

        // this.Value_Of_Discount = res
        //   .map(e => e.Value_Of_Discount)
        //   .reduce((prev, next) => prev + next)

        // this.Value_Of_Sub_Total = res
        //   .map(e => e.Value_Of_Sub_Total)
        //   .reduce((prev, next) => prev + next)

        // this.Value_Of_Total = res
        //   .map(e => e.Value_Of_Sub_Total)
        //   .reduce((prev, next) => prev + next)

        // this.Delivery_Charges = 0
        // this.Value_Of_Total += this.Delivery_Charges

        // if (this.Value_Of_Sub_Total > this.location.Min_Order_Amount) {
        //   this.Delivery_Charges = 0
        // } else {
        //   this.Delivery_Charges = this.location.Delivery_Charges
        //   this.Value_Of_Total += this.Delivery_Charges
        // }

        this._checkOutService.get_packaging(8, this.options.District_Id).subscribe((res: any) => {
          this.packages = res.data
        })
      }
    }, () => { }, () => { })


    if (this.cartProduct.length == 0) this.redirectToHome()
  }

  calculate(res) {
    this.Value_Of_Products = res
      .map(e => e.Value_Of_Product)
      .reduce((prev, next) => prev + next, 0).toFixed(2)

    this.Value_Of_Discount = res
      .map(e => e.Value_Of_Discount)
      .reduce((prev, next) => prev + next, 0).toFixed(2)

    this.Value_Of_Sub_Total = res
      .map(e => e.Value_Of_Sub_Total)
      .reduce((prev, next) => prev + next, 0).toFixed(2)

    this.Value_Of_Total = res
      .map(e => e.Value_Of_Sub_Total)
      .reduce((prev, next) => prev + next, 0)

    this.Value_Of_Base_Amt = res.map(e => e.base_amt * e.Cart_Qty).reduce((prev, next) => prev + next, 0).toFixed(2)
    this.Value_Of_VAT_Amt = res.map(e => e.vat_amt * e.Cart_Qty).reduce((prev, next) => prev + next, 0).toFixed(2)

    this.Delivery_Charges = 0
    this.Value_Of_Total = this.Value_Of_Total + this.Delivery_Charges
    this.Value_Of_Total.toFixed(2)
  }

  ngOnInit(): void {
    this.getData()
  }

  onDelete(option) {
    let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    this.cartProduct.splice(index, 1)
    this._localCartService.setCartProducts(this.cartProduct)
    this._commonService.onFailed("Product Deleted Successfully..")
  }

  decreaseValue(option) {
    this.cartProduct.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty == 1) {
          this.onDelete(option)
          this._commonService.onFailed("Product Deleted Successfully..")
        } else {
          e.Cart_Qty = e.Cart_Qty - 1
          this.updateCart()
        }
      }
    })
  }

  increaseValue(option) {
    // this.cartProduct.map((e) => {
    //   if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
    //     e.Cart_Qty = e.Cart_Qty + 1
    //     this.updateCart()
    //     // if (e.Cart_Qty < e.Avl_Qry) {
    //     //   e.Cart_Qty = e.Cart_Qty + 1
    //     //   this.updateCart()
    //     // } else this._commonService.onFailed("Out Of Stock")
    //   }
    // })
    this.cartProduct.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty < e.Avl_Qry) {
          e.Cart_Qty = e.Cart_Qty + 1
        } else this._commonService.onFailed("Out Of Stock")
      }
    })
    this.updateCart()
  }

  updateCart() {
    this.cartProduct.map((e) => {
      e.Product_Total = e.Cart_Qty * e.selling_amt
      e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
      e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
      e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
    })
    this._localCartService.setCartProducts(this.cartProduct)
    if (this.cartProduct.length == 0) this.redirectToHome()
  }

  emptyCart() {
    if (confirm("Are You Sure to Delete All Products From Cart ?")) {
      this._localCartService.resetCartProducts()
      // this._commonService.router.navigate([''])
      this.redirectToHome()
    }
  }

  closeGstDialog = () => this.isOpenGstDetail = !this.isOpenGstDetail

  onCheckOut() {
    if (this._headerFooterComp.loginData) {
      this._commonService.router.navigate(['checkout'])
    } else {
      this._headerFooterComp.onSignIn()
    }
  }

  onCartQtyChange(event, option) {
    // let qty = event.target.value
    // this.cartProduct.map((e) => {
    // 	if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
    // 		// if (qty < e.Avl_Qry) e.Cart_Qty = parseInt(qty)
    // 		// else this._commonService.onFailed("Out Of Stock")
    // 		e.Cart_Qty = parseInt(qty)
    // 	}
    // })
    let qty = event.target.value
    if (qty && qty > 0) {
      // let qty = event.target.value
      this.cartProduct.map((e) => {
        if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
          // if (qty < e.Avl_Qry) e.Cart_Qty = parseInt(qty)
          // else this._commonService.onFailed("Out Of Stock")
          e.Cart_Qty = parseInt(qty)
        }
      })
    } else if (qty < 0 || qty == '' || qty == 0) {
      // this.products.map((e) => {
      // 	if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
      // 		e.Cart_Qty = e.Cart_Qty
      // 	}
      // })
      this.deleteProductFromCart(option)
    }

  }

  deleteProductFromCart(option) {
    let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    this.cartProduct.splice(index, 1)
    this.updateCart()
  }

  redirectToHome() {
    this._commonService.router.navigate([''])
  }

  addPackaging(pack) {
    pack.Added_In_Cart = true
    pack.Cart_Qty = 1
    pack.Product_Total = pack.Cart_Qty * pack.selling_amt
    this.cartProduct.push(pack)
    this.updateCart()
  }

  checkPackageInCart(pack) {
    let result = this.cartProduct.find(e => e.product_name == pack.product_name)
    return result ? true : false
  }

}
