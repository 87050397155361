import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appColorChanger]'
})
export class ColorChangerDirective {

  colors = ['#43293c', '#212047', '#3f3e79', '#7c3623', '#5a025e', '#027f5b', '#605308', '#15500d']

  constructor(elRef: ElementRef) {
    let color = Math.floor(Math.random() * 16777215).toString(16)
    elRef.nativeElement.style.backgroundColor = this.colorGenerator();
    console.log(elRef.nativeElement.style.backgroundColor)
    // this.colors.forEach(e => elRef.nativeElement.style.backgroundColor = e)
    // for (let i = 0; i < this.colors.length; i++) {
    //   elRef.nativeElement.style.backgroundColor = this.colors[i]
    // }
  }

  colorGenerator = () => {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return "rgb(" + red + ", " + green + ", " + blue + ")";
    // return 'hsla(' + Math.floor(Math.random()*360) + ', 100%, 70%, 1)';
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //   color += Math.floor(Math.random() * 10);
    // }
    // return color;
  }


}
