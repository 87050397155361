<div style="background: #ececec;    border-top: 2px dashed #ea6d14;">
  <div class="container pt-2 pb-4">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        
        <app-desifarm-dashboard-side-menu></app-desifarm-dashboard-side-menu>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <div class="tab-heading  ">
                <div class="row">
                  <div class="col-lg-8 col-md-7 ">
                    <h1>Manage Address</h1>
                  </div>
                  <div class="col-lg-4 col-md-5">
                    <button class="btn btn--small addnewadd btn--border-green btn--border-green-hover-green"><a class=""
                        (click)="onAddress()">Add New Address</a></button>
                  </div>
                </div>
              </div>
              <div class=" m-3 ml-5 mr-5 mt-5 text-center" *ngIf="address.length == 0">
                <h3 style="background: cornflowerblue;padding: 10px;color: #fff;font-weight: 500;font-size: 18px;">No
                  Address Found...Add New Address</h3>
              </div>
              <div class="item-holder m-3 " *ngFor="let option of address">
                <!-- <span class="custom-badge" *ngIf="option.Is_Verified =='1'">🗸 Verified</span> -->
                <div class="row align-items-center">
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-1 manageadd-firstdiv" style="padding-left: 30px;">
                    <input type="radio" class="shipping-select" name="shipping-cost" value="Express" id="add-express">
                  </div>
                  <div class="col-lg-7 col-md-9 col-sm-7 col-xs-8">

                    <p>{{option.Address_Type}}</p>
                    <h2 style="margin-bottom: 5px;">{{option.Full_Name}}</h2>
                    <p>
                      {{option.Flat_House_No}}, {{option.Area_Colony_Sreet_Name}}, {{option.Landmark}},
                      {{option.Pincode}}
                    </p>
                    <p>
                      <b>Emirates</b> : {{option.District_Name}} <b>Area</b> : {{option.Sub_Area_Name}}
                      {{option.Area_Name}}
                    </p>
                  </div>
                  <div class="col-lg-4 col-md-2 col-sm-3 col-xs-3" style="text-align: right;">
                    <p class="deleteicon mb-4" (click)="onDelete(option)"><i class="far fa-trash-alt"></i></p>
                    <a class="btn btn--box btn--extrasmall btn--transparent btn--border-green mtbtn "
                      (click)="onEdit(option)">Edit</a>
                  </div>
                </div>
              </div>
              <!-- <div class="item-holder m-3 ml-5 mr-5 mb-5">
                <div class="row align-items-center">
                  <div class="col-md-1" style="padding-left: 30px;">
                    <input type="radio" class="shipping-select" name="shipping-cost" value="Express" id="add-express">
                  </div>
                  <div class=" col-sm-7">

                    <p>Home</p>
                    <h2 style="margin-bottom: 5px;">USER FULL ANME</h2>
                    <p>Flat No. 102 Area- Pathrdi,Deolali Road, Near Shell Petrol Pump, Nashik 422010</p>
                  </div>
                  <div class="col-md-4" style="text-align: right;">
                    <p class="deleteicon mb-4"><i class="far fa-trash-alt"></i></p>
                    <a class="btn btn--box btn--extrasmall btn--transparent btn--border-green " type="submit">Edit</a>
                  </div>
                </div>
              </div> -->

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
