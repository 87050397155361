import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desifarms-about',
  templateUrl: './desifarms-about.component.html',
  styleUrls: ['./desifarms-about.component.scss']
})
export class DesifarmsAboutComponent implements OnInit {

  slides1 = [
    { img: "assets/img/company-logo/company-logo-1.png" },
    { img: "assets/img/company-logo/company-logo-2.png" },
    { img: "assets/img/company-logo/company-logo-3.png" },
    { img: "assets/img/company-logo/company-logo-4.png" },
    { img: "assets/img/company-logo/company-logo-5.png" },
    { img: "assets/img/company-logo/company-logo-6.png" },
    { img: "assets/img/company-logo/company-logo-7.png" },
  ];


  slideConfig1 = {
    "slidesToShow": 6,
    "slidesToScroll": 1,
    'swipeToSlide': true,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": false,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 1000,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  slickInit(e) {
  }

  breakpoint(e) {
  }
  breakpoint1(e) {
  }
  afterChange(e) {
  }

  beforeChange(e) {
  }
  constructor() { }

  ngOnInit(): void {
  }

}
