<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb mb-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"><a href="#">Home</a></li>
                    <li class="page-breadcrumb__nav active">About Us</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<!-- ::::::  Start  Main Container Section  ::::::  -->
<main id="main-container" class="main-container">
    <div class="container">
        <div class="row justify-content-center mt-5 mb-5">
            <h1 class="text-center primary-colors">About Us</h1>
            <div class="col-md-8">
                <div class="poselemnet" style="position: relative;">
                    <img src="assets/img/about/twosuare.png" class="img-responsive img1" alt="About Us"/>
                    <p style="color:#395a26;font-size: 20px;">
                        We <b> NASIK FRUITS AND VEGETABLE TRADING LLC</b>, is Dubai based
                        HACCP certified company in the field of Import, Export, Hotel Supplies and
                        Ship Chandelling.
                    </p>
                    <p style="color:#395a26;font-size: 20px;">
                        We are a young and dynamic company in the field of supply of fresh
                        fruits and vegetable since last 8 years. We have been bringing finest and
                        freshest quality fruits and vegetables around the world.
                    </p>
                    <p style="color:#395a26;font-size: 20px;">
                        For us quality and service is passion and we understand exact need
                        of our customers. After all who can understand a chef better than a chef?
                        Founder of this company himself is well known chef and started this
                        company after 18 years of experience as Chef.
                    </p>
                    <p style="color:#395a26;font-size: 20px;">
                        We believe in long term relationship and all our business practices
                        are based on strong corporate ethics. We always maintain high quality
                        standards followed by HACCP. We always safeguard our customer’s
                        interest through timely and flexible deliveries and quality service.
                    </p>
                    <p style="color:#395a26;font-size: 20px;">
                        Our products are well selected and handled by well trained staff in
                        complete hygienic manner. Our fleet of vehicles are well monitored by real
                        time tracking system in order to ensure timely deliveries. We have gained
                        the confidence of our customers through our expertise in providing
                        complete satisfaction for the goods they order.
                    </p>
                    <p style="color:#395a26;font-size: 20px;">
                        It is combination of experience and resources that made us different
                        than others in this industry. We are always trying to provide best out of the
                        best. For more information about us please visit www.nasiktrade.com
                    </p>
                    <img src="assets/img/about/twosuare.png" class="img-responsive img2" />
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-center mt-5 mb-5">
            <div class="col-md-8">
                <h1 class="text-center primary-colors">Our Purpose</h1>
                <div class="poselemnet" style="position: relative;">
                    <img src="assets/img/about/twosuare.png" class="img-responsive img1" />
                    <p style="color:#395a26;font-size: 20px;">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident delectus tenetur, id enim
                        sed laboriosam exercitationem aspernatur aliquid inventore eveniet quisquam veniam magnam,
                        molestias, nesciunt est neque vero consequatur praesentium iste voluptatem esse. Culpa,
                        distinctio quis! Nisi enim, labore repellat officiis delectus quod explicabo velit ipsa
                        consequuntur unde quas reiciendis vero voluptatibus repellendus eius est ipsum iure architecto
                        porro quibusdam consectetur illum atque! Dolores quaerat sint tenetur totam fugiat voluptas
                        minus ratione! Illo ducimus recusandae quibusdam, aliquam voluptas libero fugiat consequuntur
                        sapiente deleniti soluta tenetur quis? Eum sit amet et id obcaecati maiores veritatis ex iure!
                        Quaerat facere provident non?
                    </p>
                    <img src="assets/img/about/twosuare.png" class="img-responsive img2" />
                </div>
            </div>
        </div> -->
        <!-- <div class="row justify-content-center mt-5 mb-5">
            <div class="col-md-8">
                <h1 class="text-center primary-colors">Our Goal</h1>
                <div class="poselemnet" style="position: relative;">
                    <img src="assets/img/about/twosuare.png" class="img-responsive img1" />
                    <p style="color:#395a26;font-size: 20px;">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident delectus tenetur, id enim
                        sed laboriosam exercitationem aspernatur aliquid inventore eveniet quisquam veniam magnam,
                        molestias, nesciunt est neque vero consequatur praesentium iste voluptatem esse. Culpa,
                        distinctio quis! Nisi enim, labore repellat officiis delectus quod explicabo velit ipsa
                        consequuntur unde quas reiciendis vero voluptatibus repellendus eius est ipsum iure architecto
                        porro quibusdam consectetur illum atque! Dolores quaerat sint tenetur totam fugiat voluptas
                        minus ratione! Illo ducimus recusandae quibusdam, aliquam voluptas libero fugiat consequuntur
                        sapiente deleniti soluta tenetur quis? Eum sit amet et id obcaecati maiores veritatis ex iure!
                        Quaerat facere provident non?
                    </p>
                    <img src="assets/img/about/twosuare.png" class="img-responsive img2" />
                </div>
            </div>
        </div> -->

    </div>
</main>