<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb" style="margin-bottom: 10px;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"><a routerLink="/">Home</a></li>
                    <li class="page-breadcrumb__nav active">Career</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<!-- :::::: Start Main Container Wrapper :::::: -->
<main id="main-container" class="main-container">

    <div class="container mb-5">
        <h2 style="text-align: center; margin-bottom: 5px;">Find your NSK Store Journey Today!</h2>
        <div class="row" style="justify-content: center;align-items: center;">

            <div class="col-lg-4 col-md-6 col-sm-12 ">

                <div class="contact-info-wrap blue-bg m-t-10 text-center" style="font-size: 18px;">
                    <div class="contact-info mt-4">
                        <p style="font-size: 18px;font-weight: 500;">OUR CULTURE</p>
                        <p class="mb-5" style="font-size: 16px;">We offer all our employees
                            a work environment that is:</p>
                    </div>
                    <div class="contact-info">

                        <i class="far fa-heart fa-3x"></i>

                        <div class="">
                            <p class="mt-2 mb-5" style="font-size: 18px;font-weight: 500;"> People-Centric</p>

                        </div>
                    </div>
                    <div class="contact-info mb-5">
                        <img src="assets/img/career/center.png" class="img-responsive" alt="Purpose driven">
                        <p style="font-size: 18px;font-weight: 500;">Purpose driven</p>
                    </div>
                    <div class="contact-info mb-4">
                        <img src="assets/img/career/growth.png" width="77px;" class="img-responsive" alt="Growth">
                        <div class="contact-info-dec">
                            <p class="mt-2 mb-0" style="font-size: 18px;font-weight: 500;">Growth-Focused</p>

                        </div>

                    </div>
                </div>

            </div>
            <div class="col-lg-7 col-md-6 col-sm-12">
                <div class="contact-form gray-bg new-bg m-t-10">
                    <div class="alert alert-primary" role="alert" *ngIf="msgFlag">
                        {{message}}
                    </div>


                    <form class="contact-form-style" id="contact-form" [formGroup]="form">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-box__single-group">
                                    <input formControlName="Candidate_Name" type="text" placeholder="Candidate Name">
                                    <span class="text-danger"
                                        *ngIf="f.get('Candidate_Name').touched && f.get('Candidate_Name').invalid &&f.get('Candidate_Name').hasError('required')">Candidate
                                        Name
                                        is required</span>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-box__single-group">
                                    <input formControlName="Position_Applying_For" type="text"
                                        placeholder="Post Applied For">
                                    <span class="text-danger"
                                        *ngIf="f.get('Position_Applying_For').touched && f.get('Position_Applying_For').invalid &&f.get('Position_Applying_For').hasError('required')">Post
                                        Applied For is required</span>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-box__single-group">
                                    <input formControlName="Email_Id" type="email" placeholder="Email ID">
                                    <span class="text-danger"
                                        *ngIf="f.get('Email_Id').touched && f.get('Email_Id').invalid &&f.get('Email_Id').hasError('email')">Incorrect
                                        Email Id</span>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-box__single-group">
                                    <input formControlName="Contact_No" type="text" placeholder="Contact Number">
                                    <span class="text-danger"
                                        *ngIf="f.get('Contact_No').touched && f.get('Contact_No').invalid &&f.get('Contact_No').hasError('required')">Contact
                                        Number
                                        is required</span>
                                </div>
                            </div>


                            <div class="col-lg-12">

                                <button (click)="file.click()"
                                    class="btn btn--box btn--medium uploadbtn btn--black btn--black-hover-green btn--uppercase font--bold m-t-30"
                                    type="submit">Upload Resume</button> <span style="    vertical-align: bottom;
                                margin-left: 17px;">{{fileName}}</span>
                                <p>(Allowed file types are doc, docx, pdf and Maximum upload size is 5 MB.)</p>
                                <input type="file" accept=".docx,.doc,.pdf" hidden #file (change)="onResume($event)">
                            </div>
                            <div class="col-lg-12">

                                <button
                                    class="btn btn--box btn--medium btn--black btn--black-hover-green btn--uppercase font--bold m-t-30"
                                    type="submit" [disabled]="form.invalid" (click)="onSubmit()">Submit</button>

                            </div>
                            <p class="text-center mt-5" style="font-size: 18px;">You can also share your profile with us
                                directly on
                                <a href="mailto:careers@desifarmsindia.com"><b>careers@desifarmsindia.com</b></a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main> <!-- :::::: End MainContainer Wrapper :::::: -->