import { DesifarmsMyorderDetailsComponent } from './desifarms-myorder-details/desifarms-myorder-details.component';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserOrderGroup } from 'src/app/models/desifarm-res/desifarm-dashboard/desifarm-myorders/userordergroup';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmMyordersService } from 'src/app/services/desifarm-dashboard/desifarm-myorders/desifarm-myorders.service';
import { DesifarmsDeliveryTimeslotComponent } from '../../desifarms-mycart/desifarms-checkout/desifarms-delivery-timeslot/desifarms-delivery-timeslot.component';
import {
  IcustomeWindow,
  WindowrefService,
} from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-windowRef/windowref.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-desifarms-myorders',
  templateUrl: './desifarms-myorders.component.html',
  styleUrls: ['./desifarms-myorders.component.scss'],
})
export class DesifarmsMyordersComponent implements OnInit {
  userOrderGroup: any[] = [];
  orders: any[] = [];
  public orderColor: string[] = [
    '#ffbc00',
    '#0400ff',
    '#ff00bc',
    '#00ff00',
    '#ff0000',
    '#0095ff',
  ];

  loginData: any;
  deliverySlotDetail: any;

  isOrderCancelFlag: boolean = false;

  orderCancelDetail: any;
  form: FormGroup;
  form1: FormGroup;

  _window: IcustomeWindow;

  option: any;
  rzp: any;

  printInvoiceData: any;
  totalDiscount: number = 0;
  // Order_Delivery_Charges: number = 0;

  Total_Discount_Amount: number = 0
  Sub_Total_Amount: number = 0
  Grand_Total_Amount: number = 0
  Order_Delivery_Charges: number = 0
  Wallet_Amount_Used: number = 0
  SubTotalPlusDeliveryCharges: number = 0
  Base_Amt: number = 0
  VAT_Amt: number = 0

  constructor(
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _myOrdersService: DesifarmMyordersService,
    private zone: NgZone,
    private winRef: WindowrefService,
    private _dialog: MatDialog
  ) {
    this._window = this.winRef.nativeWindow;
  }

  initForm() {
    this.form = this._fb.group({
      Order_Cancelation_Reason: ['', [Validators.required]],
      Sale_Order_Id: ['', [Validators.required]],
    });

    this.form1 = this._fb.group({
      Payment_Transaction_ID: ['', [Validators.required]],
      Sale_Order_Id: ['', [Validators.required]],
    });
  }

  getData() {
    this.userOrderGroup = [];
    this.orders = [];
    this._myOrdersService
      .my_orders(this.loginData.Cust_Id)
      .subscribe((res: any) => {
        res.data.map((e) => (e.OrderStatusColorCode = ''));
        this.orders = res.data;
        this.orders.forEach((element) => {
          let orderGroup = new UserOrderGroup();

          if (element.Order_status == 'New')
            element.OrderStatusColorCode = this.orderColor[0];
          if (element.Order_status == 'Accept')
            element.OrderStatusColorCode = this.orderColor[1];
          if (element.Order_status == 'Packed')
            element.OrderStatusColorCode = this.orderColor[2];
          if (element.Order_status == 'Delivered')
            element.OrderStatusColorCode = this.orderColor[3];
          if (element.Order_status == 'Cancelled')
            element.OrderStatusColorCode = this.orderColor[4];

          if (this.userOrderGroup.length == 0) {
            orderGroup.Customer_Delivery_Date = element.Customer_Delivery_Date;
            orderGroup.Slot_Name = element.Slot_Name;
            orderGroup.Slot_Start_Time = element.Slot_Start_Time;
            orderGroup.Sale_Order_Id = element.Sale_Order_Id;
            // orderGroup.Order_Amount = element.Total_Selling_Amount;
            orderGroup.Order_Amount = element.Total_Amount;
            orderGroup.Orders.push(JSON.parse(JSON.stringify(element)));
            this.userOrderGroup.push(orderGroup);
          } else {
            let index = this.userOrderGroup.findIndex(
              (c) => c.Sale_Order_Id == element.Sale_Order_Id
            );
            if (index >= 0) {
              this.userOrderGroup[index].Order_Amount +=
                element.Total_Selling_Amount;
              this.userOrderGroup[index].Orders.push(
                JSON.parse(JSON.stringify(element))
              );
            } else {
              orderGroup.Customer_Delivery_Date =
                element.Customer_Delivery_Date;
              orderGroup.Slot_Name = element.Slot_Name;
              orderGroup.Slot_Start_Time = element.Slot_Start_Time;
              orderGroup.Sale_Order_Id = element.Sale_Order_Id;
              orderGroup.Order_Amount = element.Total_Selling_Amount;
              orderGroup.Order_Amount = element.Total_Amount;
              orderGroup.Orders.push(JSON.parse(JSON.stringify(element)));
              this.userOrderGroup.push(orderGroup);
            }
          }
        });
      });
  }

  ngOnInit(): void {
    this.initForm();
    this.loginData = this._commonService.getUser();
    this.getData();
  }

  orderDetail(order) {
    const dialogRef = this._dialog.open(DesifarmsMyorderDetailsComponent, {
      width: '1000px',
      height: '80vh',
      panelClass: 'myclass2',
      data: order,
    });
    dialogRef.afterClosed().subscribe();
  }

  onDelivery(data) {
    if (data.Orders[0].Order_status == 'New') {
      data.Customer_Delivery_Date = moment(data.Customer_Delivery_Date).format(
        'YYYY-MM-DD'
      );
      this.deliverySlotDetail = data;
      var updatedData = {
        Delivery_Slot_Id: '',
        Slot_Name: '',
        Customer_Delivery_Date: '',
        Slot_Start_Time: '',
        Sale_Order_Id: data.Sale_Order_Id,
      };
      const dialogRef = this._dialog.open(DesifarmsDeliveryTimeslotComponent, {
        width: '1000px',
        height: 'auto',
        panelClass: 'checkout-address-modal',
        data: data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined) {
          updatedData.Delivery_Slot_Id = result.Delivery_Slot_Id;
          updatedData.Slot_Name = result.Slot_Name;
          updatedData.Customer_Delivery_Date = result.Delivery_Date;
          updatedData.Slot_Start_Time = result.From_Time;
          this.update_delivery_slot_from_customer(updatedData);
        }
      });
    } else
      this._commonService.onFailed(
        'Order is prepared You Can Not Change Delivery Slot'
      );
  }

  update_delivery_slot_from_customer(data) {
    this._commonService._spinner.show();
    this._myOrdersService.update_delivery_slot_from_customer(data).subscribe(
      (res: any) => {
        this._commonService._spinner.hide();
        if (res.ack) {
          this._commonService.onSuccess(res.msg);
          this.getData();
        } else this._commonService.onFailed(res.msg);
      },
      (err) => {
        this._commonService._spinner.hide();
        this._commonService.onFailed('Internet Problem');
      }
    );
  }

  toggleCancelFlag = (data) => {
    this.form.patchValue({
      Sale_Order_Id: data.Sale_Order_Id,
    });
    this.orderCancelDetail = data;
    this.isOrderCancelFlag = !this.isOrderCancelFlag;
  };

  onClose = () => (this.isOrderCancelFlag = !this.isOrderCancelFlag);

  submit() {
    if (!this.form.invalid) {
      this._commonService._spinner.show();
      this._myOrdersService
        .cancel_order_from_customer(this.form.value)
        .subscribe(
          (res: any) => {
            this._commonService._spinner.hide();
            if (res.ack) {
              this._commonService.onSuccess(res.msg);
              this.getData();
              this.onClose();
            } else this._commonService.onFailed(res.msg);
          },
          (err) => {
            this._commonService._spinner.hide();
            this._commonService.onFailed('Internet Conection Failed');
          }
        );
    } else this._commonService.onFailed('Please Type Cancellation Reason');
  }

  payOnline(userOrder) {
    let totalDeliveryCharges = userOrder.Orders.map((e) => e.Order_Delivery_Charges).reduce((prev, next) => prev + next);

    let totalOrder = userOrder.Orders
      .map((e) => e.Total_Selling_Amount).reduce((prev, next) => prev + next);

    this.form1.patchValue({
      Sale_Order_Id: userOrder.Sale_Order_Id,
    });

    this.razorpay(userOrder.Order_Amount);
    // this.razorpay(totalDeliveryCharges + totalOrder);
  }

  razorpay(Value_Of_Total) {
    this.option = {
      key: environment.rzp_API,
      name: 'NSK Store',
      description: 'Shopping',
      amount: Value_Of_Total * 100,
      prefill: {
        name: this.loginData.Cust_Name,
        email: this.loginData.Email_Id,
        contact: this.loginData.Customer_Mobile_Number,
      },
      notes: {},
      theme: {
        color: '#3880FF',
      },
      handler: (res: any, err: any) => {
        if (res && res.razorpay_payment_id) {
          // this.paymentTransactionID = res.razorpay_payment_id;
          this.form1.patchValue({
            Payment_Transaction_ID: res.razorpay_payment_id,
          });
          this.paymentPaid(this.form1.value);
        } else {
          this._commonService.onFailed('Transaction Failed.. Try Again');
          // this._commonService.router.navigate(['/mycart'])
        }
      },
      modal: {
        ondismiss: () => {
          this.zone.run(() => {
            //window.location.reload();
            //add current page routing if payment fail
            // this.router.navigateByUrl('/shop/RefreshComponent', { skipLocationChange: true }).then(() => {
            //   this.router.navigate(['/shop/checkout']);
            // });
          });
        },
      },
    };

    this.rzp = new this.winRef.nativeWindow['Razorpay'](this.option);
    this.rzp.open();
  }

  paymentPaid(data) {
    this._commonService._spinner.show();
    this._myOrdersService.customer_pay_online(data).subscribe(
      (res: any) => {
        this._commonService._spinner.hide();
        if (res.ack) {
          this._commonService.onSuccess(res.msg);
          this.getData();
        } else this._commonService.onFailed(res.msg);
      },
      (err) => {
        this._commonService._spinner.hide();
        this._commonService.onFailed('Internet Connection Failed');
      }
    );
  }

  print(userOrder) {
    this.Base_Amt = userOrder.Orders.map(e => e.Base_Amt).reduce((prev, next) => prev + next, 0)
    this.VAT_Amt = userOrder.Orders.map(e => e.VAT_Amt).reduce((prev, next) => prev + next, 0)
    this.Sub_Total_Amount = userOrder.Orders.map(e => e.Total_Selling_Amount).reduce((prev, next) => prev + next)
    this.Total_Discount_Amount = userOrder.Orders.map(e => e.Total_Discount_Amount).reduce((prev, next) => prev + next)
    this.Order_Delivery_Charges = userOrder.Orders[0].Order_Delivery_Charges
    this.Wallet_Amount_Used = userOrder.Orders[0].Wallet_Amount_Used

    let Total = this.Order_Delivery_Charges + this.Sub_Total_Amount
    this.SubTotalPlusDeliveryCharges = Total
    Total -= this.Wallet_Amount_Used
    this.Grand_Total_Amount = Total


    this.printInvoiceData = userOrder;
    // this.totalDiscount = userOrder.Orders.map(e => e.Total_Discount_Amount).reduce((prev, next) => prev + next)
    // this.Order_Delivery_Charges = userOrder.Orders.map(e => e.Order_Delivery_Charges).reduce((prev, next) => prev + next)
    this.printInvoice();
  }

  printInvoice() {
    setTimeout(() => {
      let popupWin = window.open(
        '',
        '_blank',
        'top=0,left=0,height=100%,width=auto'
      );
      let printContents = document.getElementById('invoice').innerHTML;
      popupWin.document.write(`
        <html>
            <head>
                <title style="font-size: 50px;"></title>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
                <style>
                table{
                  width:100%;
                  border: 1px solid rgba(0,0,0,.12);
                }
                tr{
                  border: 1px solid rgba(0,0,0,.12);
                }
                td{
                  border-right: 1px solid rgba(0,0,0,.12);
                }
                </style>

            </head>
            <body onload="window.print();window.close()">
              ${printContents}
            </body>
        </html>
      `);
      popupWin.document.close();
    }, 1000);
  }
}
