<div class="container" style="border: 2px solid lightgray;">
    <div class="row">
        <!-- <div class="col-md-5 col-sm-12" style="background: url('assets/img/signin/1.jpg');    background-size: cover;">
            <div></div>
        </div> -->
        <div class="col-md-12 col-sm-12">
            <div class="mylogin" *ngIf="loginFlag">
                <h1>Welcome to NSK Store</h1>
                <div class="bottomline"></div>
                <div class="login-register-form">
                    <form [formGroup]="form">
                        <div class="form-box__single-group mb-4 first-div-modal" style="margin-top: 30px;">
                            <label for="form-username" class="mylabel">Email *</label>
                            <input autocomplete="off" formControlName="Email_Id" type="email" id="form-username"
                                placeholder="Email">
                        </div>
                        <div class="form-box__single-group mb-4 first-div-modal">
                            <label for="form-password" class="mylabel">Password *</label>
                            <div class="password__toggle">
                                <input [type]="passwordType ? 'text' : 'password'" id="form-register-username-password"
                                    placeholder="Enter Password" formControlName="Password">
                                <span (click)="passwordToggle()" data-toggle="#form-register-username-password"
                                    class="password__toggle--btn fa" [ngClass]="{
                                    'fa-eye-slash': !passwordType,
                                    'fa-eye': passwordType
                                    }"></span>
                            </div>
                            <p class="forget-password-link" (click)="handleForgetPassword()">Forget Password ?</p>
                        </div>


                        <div class="text-center mt-3 mb-4">
                            <button (click)="submit()"
                                class="btn btn--box loginbtn btn--medium btn--radius btn--dark btn--black btn--black-hover-green  font--semi-bold"
                                [disabled]="form.invalid">Login</button>
                            <p class="registerLink" (click)="loginFlag = false; signUpFlag=true;">Click here to register
                            </p>
                        </div>
                        <div class="row mb-5 ml-2 mr-2 pt-3 pb-1 second-div-modal"
                            style="background:lightgray;text-align: center;margin-top: 90px;">

                            <div class="col-md-4">
                                <div class="logintext">
                                    <i class="fas fa-shipping-fast"></i>
                                    <p>Fast Delivery</p>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="logintext">
                                    <!-- <i class="fas fa-store"></i> -->
                                    <img src="assets/img/signin/1.png" class="img-responsive" />
                                    <p>Fresh To Door</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="logintext">
                                    <!-- <i class="fas fa-handshake"></i> -->
                                    <img src="assets/img/signin/2.png" class="img-responsive" />
                                    <p>No Middlemen </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="login-form-container mylogin" *ngIf="signUpFlag">
                <h1 style="margin-top:10px;">Customer Registration</h1>
                <div class="login-register-form mt-2">
                    <form [formGroup]="form1">
                        <div class="form-box__single-group registerform">
                            <div class="prefix">
                                <input value="+971" disabled type="text" placeholder="Country Code">
                            </div>
                            <input formControlName="Customer_Mobile_Number" appOnlynumbers minlength="9" maxlength="9"
                                type="text" placeholder="Mobile No *" class="custom-mobile-no">
                            <span
                                *ngIf="f1.get('Customer_Mobile_Number').touched && f1.get('Customer_Mobile_Number').dirty && f1.get('Customer_Mobile_Number').invalid  && f1.get('Customer_Mobile_Number').hasError('minlength') "
                                class="text-danger">Minimum 9 Digits are required</span>
                            <span
                                *ngIf="f1.get('Customer_Mobile_Number').touched && f1.get('Customer_Mobile_Number').dirty && f1.get('Customer_Mobile_Number').invalid  && f1.get('Customer_Mobile_Number').hasError('maxlength') "
                                class="text-danger">Maximum 9 Digits are allowed</span>
                            <span
                                *ngIf="f1.get('Customer_Mobile_Number').touched && f1.get('Customer_Mobile_Number').invalid  && f1.get('Customer_Mobile_Number').hasError('required') "
                                class="text-danger">Mobile No is required</span>
                        </div>
                        <div class="form-box__single-group registerform">
                            <input formControlName="Email_Id" type="text" placeholder="Email *">
                            <span
                                *ngIf="f1.get('Email_Id').touched && f1.get('Email_Id').invalid  && f1.get('Email_Id').hasError('email') "
                                class="text-danger">Invalid Email</span>
                            <span
                                *ngIf="f1.get('Email_Id').touched && f1.get('Email_Id').invalid  && f1.get('Email_Id').hasError('required') "
                                class="text-danger">Email is required</span>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-box__single-group registerform">
                                    <div class="password__toggle">
                                        <input [type]="passwordType ? 'text' : 'password'"
                                            id="form-register-username-password" placeholder="Enter password *"
                                            formControlName="Password">
                                        <span (click)="passwordToggle()" data-toggle="#form-register-username-password"
                                            class="password__toggle--btn fa" [ngClass]="{
                                            'fa-eye-slash': !passwordType,
                                            'fa-eye': passwordType
                                            }"></span>
                                    </div>
                                    <span
                                        *ngIf="f1.get('Password').touched && f1.get('Password').dirty && f1.get('Password').invalid  && f1.get('Password').hasError('minlength') "
                                        class="text-danger">Minimum 6 characters are required</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-box__single-group registerform">
                                    <div class="password__toggle">
                                        <input [type]="passwordType ? 'text' : 'password'"
                                            id="form-register-username-password" placeholder="Enter Confirm password *"
                                            formControlName="Cnf_Password">
                                        <span (click)="passwordToggle()" data-toggle="#form-register-username-password"
                                            class="password__toggle--btn fa" [ngClass]="{
                                            'fa-eye-slash': !passwordType,
                                            'fa-eye': passwordType
                                            }"></span>
                                    </div>
                                    <!-- <span class="text-danger" *ngIf="f.Cnf_Password.errors.confirmedValidator">Confirm Password Not Match</span> -->
                                    <span class="text-danger"
                                        *ngIf="f1.get('Cnf_Password').touched && f1.get('Cnf_Password').dirty && form1.errors?.notSame">Confirm
                                        Password Not Match</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-box__single-group registerform ">
                                    <input formControlName="First_Name" type="text" placeholder="First Name *"
                                        spanceNotAllowed>
                                </div>
                                <span
                                    *ngIf="f1.get('First_Name').touched && f1.get('First_Name').invalid  && f1.get('First_Name').hasError('required') "
                                    class="text-danger">First Name is required</span>
                            </div>
                            <div class="col-6">
                                <div class="form-box__single-group registerform ">
                                    <input formControlName="Last_Name" type="text" placeholder="Last Name *">
                                    <!-- <input formControlName="Cust_Name" type="text" placeholder="Last Name *"> -->
                                </div>
                                <span
                                    *ngIf="f1.get('Last_Name').touched && f1.get('Last_Name').invalid  && f1.get('Last_Name').hasError('required')"
                                    class="text-danger">Last Name is required</span>
                            </div>
                        </div>
                        <ng-container>

                        </ng-container>
                        <!-- <div class="row"> -->
                        <div class="form-box__single-group registerform">
                            <select formControlName="Gender">
                                <option value="" disabled selected>Select Gender *</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <span
                                *ngIf="f1.get('Gender').touched && f1.get('Gender').invalid  && f1.get('Gender').hasError('required') "
                                class="text-danger">Gender is required</span>
                        </div>
                        <!-- </div> -->


                        <!-- <div class="form-box__single-group registerform">
                            <select formControlName="Source">
                                <option value="" disabled selected>How Did You Find Us *</option>
                                <option *ngFor="let option of sources" [value]="option">{{option}}</option>
                            </select>
                        </div> -->
                        <!-- <div class="form-box__single-group registerform">
                            <input list="city" formControlName="District_Name" type="text"
                                placeholder="Select Emirate *" required (change)="onCity($event)">
                            <datalist id="city">
                                <option *ngFor="let options of districts" [value]="options.District_Name">
                            </datalist>
                            <span
                                *ngIf="f1.get('District_Name').touched && f1.get('District_Name').invalid  && f1.get('District_Name').hasError('required') "
                                class="text-danger">Emirate is required</span>
                        </div>
                        <div class="form-box__single-group registerform">
                            <input list="Area" formControlName="Area_Name" type="text" placeholder="Select Area *"
                                required (change)="onArea($event)">
                            <datalist id="Area">
                                <option *ngFor="let options of areaList" [value]="options.Area_Name">
                            </datalist>
                            <span
                                *ngIf="f1.get('Area_Name').touched && f1.get('Area_Name').invalid  && f1.get('Area_Name').hasError('required') "
                                class="text-danger">Area is required</span>
                        </div>
                        <div class="form-box__single-group registerform">
                            <input list="subArea" formControlName="Sub_Area_Name" type="text"
                                placeholder="Sub Area (Optional)" (change)="onSubArea($event)">
                            <datalist id="subArea">
                                <option *ngFor="let options of subAreas" [value]="options.Sub_Area_Name">
                            </datalist>
                        </div> -->

                        <!-- <div class="form-box__single-group registerform">
                            <input (change)="checkReferCode($event)" formControlName="Temp_Referral_Code" type="text"
                                placeholder="Referal Code">
                            <p *ngIf="refercodeDetail" class="text-success">Refer By - {{refercodeDetail}}</p>
                        </div> -->
                        <br>

                        <!-- <br>
                        <div class="form-box__single-group m-b-20">
                            <label for="form-register-username-password">Password *</label>
                            <div class="password__toggle">
                                <input [type]="passwordType ? 'text' : 'password'"
                                    id="form-register-username-password" placeholder="Enter password"
                                    required>
                                <span (click)="passwordToggle()"
                                    data-toggle="#form-register-username-password"
                                    class="password__toggle--btn fa" [ngClass]="{
                                    'fa-eye-slash': !passwordType,
                                    'fa-eye': passwordType
                                    }"></span>
                            </div>
                        </div> -->
                        <div class="text-center">
                            <button [disabled]="form1.invalid"
                                class="btn btn--box btn--medium registerbtn btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"
                                (click)="onRegister()">
                                GET OTP
                            </button> <br>
                            <span class="text-danger text-center">All * fields are mandatory</span>
                        </div>
                    </form>
                </div>
            </div>
            <div class="login-form-container mylogin mb-5" *ngIf="otpFlag">
                <h1> OTP VERIFICATION</h1>
                <div class="bottomline"></div>
                <div class="login-register-form mt-5 ">
                    <form [formGroup]="otpForm">

                        <div class="form-box__single-group">
                            <!-- <label for="form-username">OTP *</label> -->
                            <h3 class="first-div-modal" style="margin-bottom: 0;margin-top: 60px;">OTP Sent on Your
                                Mobile No Ending with
                                **{{Customer_Mobile_Number | slice:-3 }}</h3>
                            <input type="text" appOnlynumbers maxlength="6" formControlName="OTP" placeholder="OTP"
                                autofocus required (input)="onOtpChange($event)" #OtpInput>
                        </div> <br>
                        <div class="text-center"> <button [disabled]="this.otpForm.invalid"
                                (click)="verifyCustomerOTP()"
                                class="btn mb-5 btn--box btn--medium otpbtn btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"
                                type="submit">VERIFY OTP</button><br>
                            <span style="font-size: 18px;">Didn't receive the OTP?</span><br><br>
                            <p *ngIf="isResending">Resend OTP in {{ remainingTime }} seconds</p>
                            <button *ngIf="!isResending" [disabled]="isResending" (click)="resendOtp()" class="mb-5 resend" style="color: #ff9800; font-size: 20px;">RESEND
                                OTP</button>
                            <!-- <button [disabled]="isResending" (click)="resendOTP()" class="mb-5 resend" style="color: #ff9800; font-size: 20px;">Resend OTP</button> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>