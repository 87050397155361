<section style="background: #e2e0e0;padding-top: 20px;border: 1px solid rgb(236, 236, 236);">
  <div class="row">
    <div class="col-md-8" style="font-size: 16px;">
      <span class="ml-2"><b>Order No: </b></span>
      <span>{{_data.Sale_Order_Id}}</span> &nbsp;
      <!-- <a style="color:green;text-decoration:underline !important">Invoice</a> -->
    </div>
    <div class="col-md-4 text-center">
      <a class="btn btn--small btn--transparent btn--border-green btn--border-green-hover-green" (click)="back()">Return
        to Order
        Summary</a>
    </div>
  </div>
  <table class="table-responsive table-bordered" style="text-align: center;">
    <tr class="mytable-head">
      <th>Product</th>
      <th>Price</th>
      <th>QTY</th>
      <th>Subtotal</th>
      <th>Saved</th>
    </tr>
    <!-- <tr>
      <td>Desi Milk </td>
      <td>. 16.00</td>
      <td>2</td>
      <td style="color: orangered;">. 18.00</td>
      <td>--</td>
    </tr> -->
    <tr *ngFor="let option of _data.Orders">
      <td>{{option.Product_Name}} {{option.Package_Qty}} {{option.Unit_Name}}</td>
      <td> <del class="muted mrp-cut" *ngIf="option.Product_Package_MRP != option.Selling_Amt"> {{option.Product_Package_MRP }}</del>  {{option.Selling_Amt }}</td>
      <td>{{option.Order_Qty}}</td>
      <td style="color: orangered;"> {{option.Total_Selling_Amount}}</td>
      <td> {{option.Total_Discount_Amount}}</td>
    </tr>
    <!-- <tr>
      <td>Desi Milk </td>
      <td>. 16.00</td>
      <td>2</td>
      <td style="color: orangered;">. 18.00</td>
      <td>--</td>
    </tr> -->

  </table>
  <div class="pr-2" style="text-align: right;font-family: 'MarlinGeo';">
    <p *ngIf="Total_Discount_Amount > 0"><b>You Saved</b> &nbsp;  {{Total_Discount_Amount | number : '1.0-2'}}</p>
    <p><b>Base Amt</b> &nbsp;  {{Value_Of_Base_Amt | number : '1.0-2'}}</p>
    <p><b>VAT Amt</b> &nbsp;  {{Value_Of_VAT_Amt | number : '1.0-2'}}</p>
    <p><b>Sub Total</b> &nbsp;  {{Sub_Total_Amount | number : '1.0-2'}}</p>
    <!-- <p><b>Subtotal</b> &nbsp; {{_data.Order_Amount }}</p> -->
    <!-- <p *ngIf="_data.Orders[0].Order_Delivery_Charges > 0"><b>Delivery Charge</b> &nbsp;
      {{_data.Orders[0].Order_Delivery_Charges }}</p> -->

    <!-- <p style="color: red;"><b>Grand Total</b> &nbsp;
        {{_data.Order_Amount + _data.Orders[0].Order_Delivery_Charges }}</p> -->

    <p><b>Delivery Charge (+)</b> &nbsp;
       {{_data.Orders[0].Order_Delivery_Charges  | number : '1.0-2'}}</p>

      <p><b>Subtotal + Delivery</b> &nbsp;
         {{SubTotalPlusDeliveryCharges | number : '1.0-2'}}</p>

    <p *ngIf="_data.Orders[0].Wallet_Amount_Used != 0"><b>Wallet Redeem (-)</b> &nbsp;
       {{_data.Orders[0].Wallet_Amount_Used | number : '1.0-2'}}</p>

    <p style="color: red;"><b>Grand Total</b> &nbsp;
       {{_data.Order_Amount| number : '1.0-2' }}</p> 


  </div>
  <div class="row pt-3 pb-4" style="background: #fff;    margin-left: 0px;
  margin-right: 0;">
    <div class="col-md-4 firstdiv">
      <p class="mb-0 headings"><b>Shipping Address</b></p>
      <p>{{_data.Orders[0].Full_Name}},<br>
        <span *ngIf="_data.Orders[0].Area_Colony_Sreet_Name">
          {{_data.Orders[0].Area_Colony_Sreet_Name}},</span>
        <span *ngIf="_data.Orders[0].Landmark"> {{_data.Orders[0].Landmark}}</span>
        <span *ngIf="_data.Orders[0].Pincode"> - {{_data.Orders[0].Pincode}},<br></span>
        <span *ngIf="_data.Orders[0].City"> Emirate : {{_data.Orders[0].City}}</span>
        <span *ngIf="_data.Orders[0].Customer_State_Name">, State :
          {{_data.Orders[0].Customer_State_Name}}</span>
        <span *ngIf="_data.Orders[0].Mobile_No_Address"><br>
          {{_data.Orders[0].Mobile_No_Address}}</span>
      </p>
    </div>
    <div class="col-md-4 middlediv">
      <p class="headings"><b>Order Status</b></p>
      <p [ngClass]="{
        'delivered-color':_data.Orders[0].Order_status == 'Delivered',
        'cancelled-color':_data.Orders[0].Order_status == 'Cancel',
        'assign-color':_data.Orders[0].Order_status == 'Assign',
        'new-color':_data.Orders[0].Order_status == 'New',
        'moving-color':_data.Orders[0].Order_status == 'Moving'
      }"><b>{{_data.Orders[0].Order_status}}</b></p>
      <!-- <p class="mt-2 headings"><b>Selected Slot</b></p> -->
      <!-- <p>{{_data.Orders[0].Customer_Delivery_Date | date:"mediumDate"}} - {{_data.Orders[0].Slot_Name}}</p> -->
      <!-- <p>{{_data.Orders[0].Slot_Name}}</p> -->
    </div>
    <div class="col-md-4 middlediv">
      <p class="headings"><b>Payment Information</b></p>
      <p>{{_data.Orders[0].Payment_Mode == "COD" ? "CASH ON DELIVERY" : "PAID ONLINE"}}</p>
      <!-- <p class="headings" *ngIf="_data.Orders[0].Payment_Mode != 'COD'"><b>Transaction ID</b></p>
      <p *ngIf="_data.Orders[0].Payment_Mode != 'COD'">{{_data.Orders[0].Payment_Transaction_ID}}</p> -->
      <a style="color: red;margin-top: 10px;"
        class="btn btn--small btn--transparent btn--border-green btn--border-green-hover-green"
        *ngIf="_data.Orders[0].Order_status == 'New'" (click)="toggleCancelFlag(_data)">Cancel Order</a>
    </div>
  </div>
</section>


<!-- Cancel Order Modal Start -->
<ng-container *ngIf="isOrderCancelFlag">
  <div
    style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(1px);">
    <div class="my-location-modal"
      style="position: fixed;top: 10%;left: 25%;width:53%;height: 85%;z-index: 1050;overflow: hidden;outline: 0;"
      role="dialog">
      <div class="modal-content resmodal">

        <div class="modal13" style="overflow: auto;">

          <div class="modal-body" style="padding:0;">

            <div class="container-fluid">
              <div class="row locationmodal">
                <div class="row">
                  <div class="col-md-12" style="text-align: right; z-index: 999;    margin-top: 11px;">
                    <span aria-hidden="true" class="closecart"
                      style="font-size: 40px !important; color: gray !important; text-align: right !important; cursor: pointer;"
                      (click)="onClose()">×</span>
                  </div>
                  <div class="col-md-12">
                    <h3 style="    margin-top: -35px;color: #e6531b;
                  font-weight: 500;
                  font-size: 18px;
                  font-family: 'MarlinGeo';">Are you sure you want to cancel this order ?</h3>
                  </div>
                </div>
                <div class="col-md-12">


                  <div class="container-fluid pb-2" style="border: 1px solid lightgray;font-family: 'MarlinGeo';">
                    <div class="row"
                      style="background: lightgray;  padding-top: 5px;  margin-right: -15px;margin-left: -15px;">
                      <div class="col-md-3">
                        <p><b>Order No: </b>{{orderCancelDetail.Sale_Order_Id}}</p>
                      </div>
                      <div class="col-md-6">
                        <p><b>Order Placed:</b> {{orderCancelDetail.Orders[0].Order_Date_Time | date:"medium"}}</p>
                      </div>
                      <div class="col-md-3">
                        <p><b>Order Status: </b> {{orderCancelDetail.Orders[0].Order_status}}</p>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <!-- <div class="col-lg-10 col-md-8">
                        <p><b>Items Ordered</b></p>
                      </div>
                      <div class="col-lg-2 col-md-4">
                        <p><b>Order Value</b></p>
                      </div>
                      <hr>
                      <div class="col-lg-10 col-md-8">
                        <p><b>Mango Milk</b></p>
                        <p><b>Shrikhand </b></p>
                        <p><b>Panner Desi Farms</b></p>
                      </div>
                      <div class="col-lg-2 col-md-4">
                        <p><b> 438.00</b></p>
                      </div> -->
                      <div class="col-md-12">
                        <form [formGroup]="form">
                          <textarea formControlName="Order_Cancelation_Reason" class="form-control" rows="4" cols="80"
                            placeholder="Reason for cancellation">
                          </textarea>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2 mb-4" style="text-align: right;"><a
                      class="btn cancelbtn btn--black btn--black-hover-green" (click)="submit()">Cancel</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>
<!-- Cancel Order Modal End -->