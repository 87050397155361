<!-- <ngx-spinner
  bdColor="rgba(0,0,0,0.8)"
  template="<img src='assets/img/logo/Logo---Blue-N-White.png' height='100px' />"
>
<br>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> -->

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise-fade-rotating" [fullScreen] = "true"><p style="color: white" > Please Wait.... </p></ngx-spinner>

<router-outlet></router-outlet>
<ngx-scrolltop
  [backgroundColor]="'#212121'"
  [symbolColor]="'#fafafa'"
  [size]="40"
  [mode]="'classic'"
  position="right"
  theme="gray">
</ngx-scrolltop>