import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { DesifarmDeliveryTimeslotService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-delivery-timeslot/desifarm-delivery-timeslot.service';
import * as moment from 'moment';
import { NumberFormatStyle } from '@angular/common';

@Component({
  selector: 'app-desifarms-delivery-timeslot',
  templateUrl: './desifarms-delivery-timeslot.component.html',
  styleUrls: ['./desifarms-delivery-timeslot.component.scss']
})
export class DesifarmsDeliveryTimeslotComponent implements OnInit {

  deliveryTimes: any[] = []

  constructor(
    public _dialogRef: MatDialogRef<DesifarmsDeliveryTimeslotComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _localCart: DesifarmLocalCartService,
    private _deliveryTimeSlotService: DesifarmDeliveryTimeslotService,
  ) { }

  getData() {
    this._deliveryTimeSlotService.get_delivery_slot().subscribe((res: any) => {
      res.data.map((element, index) => {
        let option = {
          Delivery_Slot_Id: '',
          Delivery_Date: '',
          Delivery_Time_slot: []
        }
        if (this.deliveryTimes.length == 0) {
          option.Delivery_Date = moment(element.Delivery_Date).format('YYYY-MM-DD')
          option.Delivery_Slot_Id = element.Delivery_Slot_Id
          option.Delivery_Time_slot.push(JSON.parse(JSON.stringify(element)))
          this.deliveryTimes.push(option)
        } else {
          let index = this.deliveryTimes.findIndex(c => c.Delivery_Date == moment(element.Delivery_Date).format('YYYY-MM-DD'))
          if (index >= 0) {
            this.deliveryTimes[index].Delivery_Time_slot.push(JSON.parse(JSON.stringify(element)));
          } else {
            option.Delivery_Date = moment(element.Delivery_Date).format('YYYY-MM-DD')
            option.Delivery_Slot_Id = element.Delivery_Slot_Id
            option.Delivery_Time_slot.push(JSON.parse(JSON.stringify(element)))
            this.deliveryTimes.push(option)
          }
        }
      })
    })
  }

  ngOnInit(): void {
    this.getData()
  }

  close = () => this._dialogRef.close()

  onTimeSelect(timeSlot) {
    this._dialogRef.close(timeSlot)
  }

  getStatus(option) {


    // if (option.Delivery_Date == this._data.Customer_Delivery_Date && option.From_Time <= this._data.Slot_Start_Time) {
    //   return true
    // } else if (option.Delivery_Date < this._data.Customer_Delivery_Date && this._data.Slot_Start_Time <= option.From_Time) {
    //   return true
    // } else return false

    if (option.Delivery_Date < this._data.Customer_Delivery_Date) {
      //For Prevvious Date
      if (option.From_Time <= this._data.Slot_Start_Time) return true
      else return false
    } else if (option.Delivery_Date == this._data.Customer_Delivery_Date && option.From_Time <= this._data.Slot_Start_Time) {
      //For todats Date
      return true
    } else return false

  }


  // dateCompare(time1, time2) {
  //   var t1 = new Date();
  //   var parts = time1.split(":");
  //   t1.setHours(parts[0], parts[1], parts[2], 0);
  //   var t2 = new Date();
  //   parts = time2.split(":");
  //   t2.setHours(parts[0], parts[1], parts[2], 0);

  //   // returns 1 if greater, -1 if less and 0 if the same
  //   if (t1.getTime() > t2.getTime()) return 1;
  //   if (t1.getTime() < t2.getTime()) return -1;
  //   return 0;
  // }

}
