export const environment = {
  production: true,
  // url: 'http://localhost:5001/desi_farm/web_api/', // Local
  // url: 'https://newnsknode.staggingservers.in/desi_farm/web_api/', // Stagging
  url: 'https://nsknode.nasiktrade.com/desi_farm/web_api/', // Live
  deliveryChargesKey: "nskchargesForProd",
  rzp_API: "rzp_test_l9r9MmOfKMmHBI", //Razorpay_Test_sb
  rzp_Secret_Key: "EHLtrrhqJKNHNw8EezZmd444", //Razorpay_Test_sb
  
  // Business_Type_Id:1
  Business_Type_Id: 2,
  locationKey: "vegeLocation",
  loginKey: "nskstorelg",
  selectedCity:"SelectedCityNsk"
};
