<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb mb-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"><a routerLink="/">Home</a></li>
                    <li class="page-breadcrumb__nav active">Our Blogs</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->
<section class="pb-5 pt-1">
    <div class="container">
        <div class="row justify-content-center ">

            <!-- <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let option of blogs">
                
               <div class="blog-feed">
                  
                   <div class="blog-feed__img-box" >
                   <a class="blog-feed__img--link" (click)="onDetail(option)" style="height: 204px; width: 100%;">
                       <img class="img-fluid img-responsive"  [src]="option.Blog_Image" alt="">
                   </a>
                       <div class="blog-title" >
                           <p>{{option.Blog_Title}}</p>
                       </div>
                   </div>
               </div>
           </div> -->
            <div class="col-md-11">
                <!-- START SINGLE BLOG-->
                <div class="row blog-row" *ngFor="let option of blogs">
                    <div class="col-md-4">
                        <img [src]="option.Blog_Image" class="img-responsive">
                    </div>
                    <div class="col-md-8">
                        <h2>{{option.Blog_Title}}</h2>
                        <!-- <h3><i>The many benefits of the glass of milk mom forced you to have!</i></h3> -->
                        <p>{{option.Blog_Short_Description}} </p>
                        <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"
                            (click)="onDetail(option)">Read More</a>
                    </div>
                </div>
                <!-- END SINGLE BLOG-->
                <!-- START SINGLE BLOG-->
                
                <!-- END SINGLE BLOG-->
            </div>

            <!-- <div class="col-md-11">
               START SINGLE BLOG
               <div class="row blog-row">
                    <div class="col-md-4">
                        <img src="assets/img/blog/1.jpg" class="img-responsive">
                    </div>
                    <div class="col-md-8">
                        <h2>5 ways Cow Milk helps you Stay Strong</h2>
                        <h3><i>The many benefits of the glass of milk mom forced you to have!</i></h3>
                        <p>Milk is a staple beverage in Indian homes and an essential part of our daily 
                        nutrition. As an Indian, it is impossible to imagine a diet not comprised of milk, a portion of food that we attribute most of our child’s growth and development to. </p>
                        <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted">Read More</a>
                    </div>
               </div>
                END SINGLE BLOG
                START SINGLE BLOG
               <div class="row blog-row ">
                <div class="col-md-4">
                    <img src="assets/img/blog/2.jpg" class="img-responsive">
                </div>
                <div class="col-md-8">
                    <h2>A2 Milk: The Better Choice </h2>
                    <p>The world of dairy offers us a plethora of variants within it. In spite of essentially being from the same source, different dairy products have different effects on every individual. Be it in the form of ice cream, paired with fruits or guzzled down with your morning cereal, milk the that element that binds it all together. </p>
                    <a  class="btn addtocart btn--black btn--black-hover-green ng-star-inserted" tabindex="0">Read More</a>
                </div>
           </div>
            END SINGLE BLOG
           </div> -->

        </div>
    </div>
</section>