import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DesifarmLoginService } from 'src/app/services/desifarm-auth/desifarm-login/desifarm-login.service';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';

@Component({
  selector: 'app-desifarm-product-subscribe-detail',
  templateUrl: './desifarm-product-subscribe-detail.component.html',
  styleUrls: ['./desifarm-product-subscribe-detail.component.scss']
})
export class DesifarmProductSubscribeDetailComponent implements OnInit {

  cartProduct: any[] = []

  constructor(
    public _dialogRef: MatDialogRef<DesifarmProductSubscribeDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _commonService: DesifarmCommonService,
    private _authService: DesifarmLoginService,
    private _localCart: DesifarmLocalCartService,
  ) { }

  getCartProducts() {
    this._commonService._spinner.show()
    this._localCart.cartList.subscribe((res) => {
      this._commonService._spinner.hide()
      this.cartProduct = res
      // this.updateCartStatus()
    }, () => { }, () => { })
  }

  ngOnInit(): void {
    this.getCartProducts()
  }

  decreaseValueModal(option) {
    // this.products.map((e) => {
    //   if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
    //     if (e.Cart_Qty == 1) {
    //       e.Added_In_Cart = false
    //       let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    //       this.cartProduct.splice(index, 1)
    //       this.updateQty()
    //       this._commonService.onFailed("Product Removed Successfully..")
    //     } else {
    //       e.Cart_Qty = e.Cart_Qty - 1
    //       this.updateQty()
    //     }

    //   }
    // })
    if (this._data.Cart_Qty == 1) {
      this._data.Added_In_Cart = false
      let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == this._data.product_packaging_rate_id)
      this.cartProduct.splice(index, 1)
      this._commonService.onFailed("Product Removed ")
    } else {
      this.cartProduct.map((e) => {
        if (e.product_packaging_rate_id == this._data.product_packaging_rate_id) {
          e.Cart_Qty = e.Cart_Qty - 1
          this._localCart.setCartProducts(this.cartProduct)
        }
      })
    }
  }

  increaseValueModal(option) {
    // this._data.Cart_Qty = this._data.Cart_Qty + 1
    // this.cartProduct.map((e) => {
    //   if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
    //     e.Cart_Qty = e.Cart_Qty + 1
    //     this._localCart.setCartProducts(this.cartProduct)
    //   }
    // })

    if (this._data.Cart_Qty < this._data.Avl_Qry) {
      this._data.Cart_Qty = this._data.Cart_Qty + 1
      this.cartProduct.map((e) => {
        if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
          e.Cart_Qty = e.Cart_Qty + 1
          this._localCart.setCartProducts(this.cartProduct)
        }
      })
    } else this._commonService.onFailed("Out Of Stock")

  }

  onCartQtyChange(event, option) {
    // let qty = event.target.value
    // if (qty && qty > 0) {
    //   // let qty = event.target.value
    //   this.products.map((e) => {
    //     if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
    //       // if (qty < e.Avl_Qry) e.Cart_Qty = parseInt(qty)
    //       // else this._commonService.onFailed("Out Of Stock")
    //       e.Cart_Qty = parseInt(qty)
    //     }
    //   })
    // } else if (qty < 0 || qty == '' || qty == 0) {
    //   // this.products.map((e) => {
    //   // 	if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
    //   // 		e.Cart_Qty = e.Cart_Qty
    //   // 	}
    //   // })
    //   this.deleteProductFromCart(option)
    // }
    this.cartProduct.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        e.Cart_Qty = event.target.value
        this._localCart.setCartProducts(this.cartProduct)
      }
    })
  }


  onAddToCart(option) {
    // this._data.Added_In_Cart = true
    // this._data.Cart_Qty = 1
    // this._commonService.onSuccess("Product Added In Cart")
    // this.cartProduct.push(this._data)
    // this._localCart.setCartProducts(this.cartProduct)
    if (this._data.Avl_Qry > 0) {
      this._data.Added_In_Cart = true
      this._data.Cart_Qty = 1
      this._commonService.onSuccess("Product Added In Cart")
      this.cartProduct.push(this._data)
      this._localCart.setCartProducts(this.cartProduct)
    } else if (this._data.Avl_Qry == 0) this._commonService.onFailed("Out Of Stock")
  }

  onClose() { this._dialogRef.close() }

}
