<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb" style="margin-bottom: 0;">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb__menu">
          <li class="page-breadcrumb__nav" routerLink="/"><a>Home</a></li>
          <li class="page-breadcrumb__nav active">Cart</li>
        </ul>
      </div>
    </div>
  </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->
<!-- <div style="background: #f6f6f6;">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-md-7 text-center mt-4 mb-4 p-3" style="border: 2px solid orange; border-radius: 20px;">
                <h1 style="    font-weight: 600;font-family:Verdana, Geneva, Tahoma, sans-serif">Your Cart is Currently  Empty</h1>
                <img src="assets/img/logo/empty.jpg" class="img-responsive"/>

                <p style="font-size: 18px;">Please add something in your cart</p>
                <a routerLink="/" style="width: 200px;"
                        class="btn btn--box btn--small  btn--green btn--green-hover-black btn--uppercase font--semi-bold"
                        type="submit">SHOP NOW</a>
            </div>
        </div>
    </div>
</div> -->
<!-- ::::::  Start  Main Container Section  ::::::  -->
<main id="main-container" class="main-container pb-2" style="background: #f1f3f2;">
  <div class="container">

    <div class="row">

      <div class="col-md-8 col-sm-12 mt-2">
        <div class="section-content">
          <h5 class="section-content__title">Your cart items</h5>
        </div>
        <div class="item-holder mt-1" *ngFor="let option of cartProduct">
          <div class="row align-items-center">
            <div class="col-lg-2 col-md-3 col-sm-2 col-xs-3">
              <a><img class="img-fluid" [src]="option.product_image" alt=""></a>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-8 col-xs-7 d-none d-sm-block">
              <p class="color-primary">{{option.brand_name}}</p>
              <h2 style="margin: 0;">{{option.product_name}} </h2>
              <span class="package-contains">{{option.package_contains}}</span>
              <div class="product-quantity  d-flex align-items-center flex-wrap mt-4" *ngIf="option.Added_In_Cart">
                <span class="product-var__text graycolor">Quantity: &nbsp; </span>
                <form class="modal-quantity-scale ml-1">
                  <div class="value-button" id="modal-decrease" (click)="decreaseValue(option)">-
                  </div>
                  <input style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;"
                    type="number" [value]="option.Cart_Qty" (change)="onCartQtyChange($event, option)" disabled />
                  <div class="value-button" id="modal-increase" (click)="increaseValue(option)">+
                  </div>
                </form>
              </div>
            </div>

            <!--VISIBLE ONLY FOR MOBILE DEVICES START-->
            <div class="col-xs-7 d-block d-sm-none ">
              <p class="graycolor">{{option.brand_name}}</p>
              <h2 class="product-name">{{option.product_name}}</h2>
              <span class="package-contains">{{option.package_contains}}</span>
              <div class="product-quantity  d-flex align-items-center flex-wrap mb-2" *ngIf="option.Added_In_Cart">
                <!-- <span class="product-var__text graycolor">Quantity: &nbsp; </span> -->
                <form class="modal-quantity-scale ml-1">
                  <div class="value-button" id="modal-decrease" (click)="decreaseValue(option)">-
                  </div>
                  <input class="inputvalue"
                    style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;" type="number"
                    [value]="option.Cart_Qty" (change)="onCartQtyChange($event, option)" disabled />
                  <div class="value-button" id="modal-increase" (click)="increaseValue(option)">+
                  </div>
                </form>
              </div>
              <span class="product__price">
                <del class="mrp-cut" *ngIf="option.product_package_mrp != option.selling_amt">
                  {{option.product_package_mrp }}</del> &nbsp;
                {{option.selling_amt| number : '1.0-2' }}</span>
              <p class="graycolor" style="margin-top:5px;">{{option.Cart_Qty}} {{option.unit_name}} x
                {{option.selling_amt | number : '1.0-2'}} &nbsp; &nbsp; <span class="amount product__price"> {{option.Product_Total | number : '1.0-2'}}</span></p>

            </div>
            <div class="col-xs-2 d-block d-sm-none">
              <p class="deleteicon mb-4" (click)="onDelete(option)"><i style="    margin-bottom: 100px;"
                  class="far fa-trash-alt"></i></p>

            </div>
            <!--VISIBLE ONLY FOR MOBILE DEVICES END-->

            <div class="col-lg-4 col-md-3 col-sm-2 col-xs-2 d-none d-sm-block" style="text-align: right;">
              <p class="deleteicon mb-4" (click)="onDelete(option)"><i class="far fa-trash-alt"></i></p>
              <span class="product__price" style="margin: 10px 0;">
                <del class="mrp-cut" *ngIf="option.product_package_mrp != option.selling_amt">
                  {{option.product_package_mrp }}</del> &nbsp;
                {{option.selling_amt}}</span>
              <p class="graycolor" style="margin: 10px 0;">{{option.Cart_Qty}} {{option.unit_name}} x
                {{option.selling_amt| number : '1.0-2' }}</p>
              <p class="amount product__price" style="margin: 10px 0;"> {{option.Product_Total | number : '1.0-2'}}</p>
            </div>
          </div>
        </div>
        <div class="section-content" style="margin: 8px 0px 6px 0px;">
          <!-- <h5 class="section-content__title"></h5> -->
          <!-- <p style="margin: 0; font-size: 18px;">Select Bag if required</p> -->
        </div>
        <div class="row justify-content-between" style="width: 60%;">
          <div class="col-md-4 col-sm-12 mt-2" *ngFor="let pack of packages">
            <div class="text-center mb-1">
              <a (click)="addPackaging(pack)" style="width: 300px;"
                class="btn btn--box btn--small editwidth btn--green btn--green-hover-black btn--uppercase font--semi-bold"
                [class.disabledATag]="checkPackageInCart(pack)">{{pack.product_name}} {{pack.selling_amt }}</a>
            </div> &nbsp;&nbsp;&nbsp;
          </div>
        </div>
        <div style="text-align: right;" class="mt-2 mb-2" *ngIf="cartProduct.length > 0">

          <a (click)="emptyCart()"
            class="btn  btn--small  btn--transparent btn--border-green btn--border-green-hover-green ">Empty
            Cart</a>

        </div>
      </div>
      <div class="col-md-4 col-sm-12 mt-2">
        <div class="section-content">
          <h5 class="section-content__title">Order Summary</h5>
        </div>
        <div class="sidebar__widget mt-1 p-4">
          <!-- <span class=" mt-1 mb-1" style="font-size: 18px;">Have a Coupon Code?</span>
          <form class="mt-1 mb-4">
            <input id="couponCodeTextBox" name="search" placeholder="Enter Coupon Code" type="search">
            <button type="submit">Apply</button>
          </form>
          <hr> -->
          <div class="row">
            <div class="col-md-7 col-sm-6 col-xs-8 mt-3 mb-3">
              <p>Value of Products : </p>
              <p>Discount (-) : </p>
              <p>Base Amt : </p>
              <p>VAT Amt : </p>
              <p>Subtotal : </p>
              <!-- <p *ngIf="Delivery_Charges > 0">Delivery Charges : </p> -->
              <p>Delivery Charges : </p>
            </div>
            <div class="col-md-5 col-sm-6 col-xs-4 mt-3 mb-3">
              <p> {{Value_Of_Products }}</p>
              <p style="color: #62aa00;"> {{Value_Of_Discount}}</p>
              <p> {{Value_Of_Base_Amt }}</p>
              <p> {{Value_Of_VAT_Amt }}</p>
              <p> {{Value_Of_Sub_Total }}</p>
              <!-- <p *ngIf="Delivery_Charges > 0"> {{Delivery_Charges | number}}</p> -->
              <p> {{Delivery_Charges }}</p>
            </div>
            <hr>
            <div class="col-md-7 col-xs-8">
              <p class="product-value">Total <br><span style="color:gray;font-size:16px; cursor: pointer;">(Inclusive of
                  all taxes)</span></p>
              <!-- (click)="closeGstDialog()" -->

            </div>
            <div class="col-md-5 col-xs-4">
              <p class="product__price product-value"> {{Value_Of_Total | number : '1.0-2'}}</p>
            </div>
            <p *ngIf="Value_Of_Sub_Total < selectedCity.Min_Order_Amount" class="text-danger">Minimum Subtotal Value is {{selectedCity.Min_Order_Amount}}</p>
          </div>


        </div>
        <div class="text-center mb-3">
          <a (click)="onCheckOut()" style="width: 300px;"
            class="btn btn--box btn--small editwidth btn--green btn--green-hover-black btn--uppercase font--semi-bold"
            type="submit" [ngClass]="{'disabledATag': Value_Of_Sub_Total < selectedCity.Min_Order_Amount }">CHECKOUT NOW</a>
        </div>
        <div class="text-center mt-2">
          <a [routerLink]="['/products', 'All Products', 0, 0]" style="width: 300px;"
            class="btn btn--box btn--small editwidth btn--transparent btn--border-green mtbtn " type="submit">CONTINUE
            SHOPPING</a>
        </div>
      </div>
    </div>



  </div>
</main> <!-- ::::::  End  Main Container Section  ::::::  -->


<ng-container *ngIf="isOpenGstDetail">
  <div
    style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
    <div style="position: fixed;top: 30%;left: 25%;width: 51%;height: 82%;z-index: 1050;overflow: hidden;outline: 0;"
      role="dialog">
      <div class="modal-content">
        <div class="modal-body modal13">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12">
                <p class="text-right"><i class="fa fa-times" style="cursor: pointer;" (click)="closeGstDialog()"></i>
                </p>
              </div>
              <div class="col-12">
                <div class="modal-bg checkout-page">
                  <div class="age-content checkout-form">
                    <div class="grid-container" style="    background-color: #dddddd;">
                      <div class="item1">
                        Product Name
                      </div>
                      <div class="item1">
                        VAT on Price
                      </div>
                    </div>
                    <div class="grid-container" *ngFor="let option of cartProduct">
                      <div>
                        {{option.product_name}}
                        <!-- {{option.package_qty}}{{option.unit_name}} -->
                      </div>
                      <div>
                        {{option.Total_CGST_Amount + option.Total_SGST_Amount }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>