import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmShopallitemsService {

  constructor(private http: HttpClient) { }

  get_all_categories_list(District_Id): Observable<any> {
    return this.http.get<DesifarmAck>(`${environment.url}get_all_categories_list/${environment.Business_Type_Id}/${District_Id}`)
  }

  get_products(data): Observable<any> {
    return this.http.post<any>(environment.url + 'get_products', data)
  }

  insert_out_of_stock(data): Observable<any> {
    return this.http.post<any>(environment.url + 'insert_out_of_stock', data)
  }

  get_all_products(Franchise_Customer_Id, District_Id): Observable<any> {
    return this.http.get<any>(`${environment.url}get_all_products/${Franchise_Customer_Id}/${environment.Business_Type_Id}/${District_Id}`)
    // return this.http.get<any>(environment.url + 'get_all_products' + '/' + Franchise_Customer_Id + '/' + environment.Business_Type_Id)
  }

}
