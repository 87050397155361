import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desifarms-blogdetails',
  templateUrl: './desifarms-blogdetails.component.html',
  styleUrls: ['./desifarms-blogdetails.component.scss'],
})
export class DesifarmsBlogdetailsComponent implements OnInit {
  detail: any;

  constructor() {}

  ngOnInit(): void {
    this.detail = JSON.parse(localStorage.getItem('blogs'));
  }
}
