<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb" style="margin-bottom: 0;">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb__menu">
          <li class="page-breadcrumb__nav" routerLink="/"><a>Home</a></li>
          <li class="page-breadcrumb__nav active">Subscribe</li>
        </ul>
      </div>
    </div>
  </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<!-- <div style="background: #f6f6f6;">
      <div class="container">
          <div class="row" style="justify-content: center;">
              <div class="col-md-7 text-center mt-4 mb-4 p-3" style="border: 2px solid orange; border-radius: 20px;">
                  <h1 style="    font-weight: 600;font-family:Verdana, Geneva, Tahoma, sans-serif">Your Cart is Currently  Empty</h1>
                  <img src="assets/img/logo/empty.jpg" class="img-responsive"/>
  
                  <p style="font-size: 18px;">Please add something in your cart</p>
                  <a routerLink="/" style="width: 200px;"
                          class="btn btn--box btn--small  btn--green btn--green-hover-black btn--uppercase font--semi-bold"
                          type="submit">SHOP NOW</a>
              </div>
          </div>
      </div>
  </div> -->

<form [formGroup]="form">
  <!-- ::::::  Start  Main Container Section  ::::::  -->
  <main id="main-container" class="main-container pb-2" style="background: #f1f3f2;">
    <div class="container">

      <div class="row">

        <div class="col-md-8 col-sm-12 mt-2">
          <div class="section-content">
            <h5 class="section-content__title">Your Subscribe items</h5>
          </div>
          <div class="item-holder mt-1 pb-0" *ngFor="let option of subscribeCartProduct">
            <div class="row align-items-center">
              <div class="col-lg-3 col-md-3 col-sm-2 col-xs-3 pr-0">
                <a><img class="img-fluid" [src]="option.product_image" alt="" (click)="onProductDetail(option)"></a>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-8 col-xs-7 d-none d-sm-block">
                <p class="graycolor" (click)="onProductDetail(option)" style="cursor: pointer;">{{option.brand_name}}
                </p>
                <h2 class="mb-0" (click)="onProductDetail(option)" style="cursor: pointer;">{{option.product_name}}
                  {{option.package_qty}}{{option.unit_name}}</h2>
                <p style="cursor: pointer;" class="mb-2 product-description" (click)="onProductDetail(option)"
                  [innerHTML]="option?.short_description"></p>
                <!-- <p class="mb-2 product-description" (click)="onProductDetail(option)">Lorem Ipsum is simply dummy text
                  of the printing and typesetting industry. Lorem printing and typesetting industr</p> -->
                <div class="form-box__single-group mt-0">
                  <div class="row align-items-center mb-1">
                    <div class="col-md-4">
                      <label for="form-freq" class="mb-0"
                    style="color: #62aa00;font-weight:500;font-size: 17px;">Frequency:</label>
                    </div>
                    <div class="col-md-8">
                      <select id="form-freq" formControlName="Subscription_Type" (change)="updateIntervalDays()">
                        <option value="select-freq" selected disabled>Select a Frequency</option>
                        <option *ngFor="let options of frequency" [value]="options.value">{{options.value}}</option>
                      </select>
                    </div>
                  </div>
                  
                </div>

                <div class="interval-selections" *ngIf="f.get('Subscription_Type').value == 'On Interval'">
                  <div class="row mybtnrow">
                    <div class="col-sm-3">
                      <button (click)="setIntervalDay(2)" [ngClass]="{
                          'btn--green--selected--subscribe' : f.get('Interval_Days').value == '2',
                          'btn--green--subscribe' : f.get('Interval_Days').value != '2'
                        }"
                        class="btn btn--box ">
                        Every 2<span>nd</span> Day
                      </button>
                    </div>
                    <div class="col-sm-3">
                      <button (click)="setIntervalDay(3)" [ngClass]="{
                        'btn--green--selected--subscribe' : f.get('Interval_Days').value == '3',
                        'btn--green--subscribe' : f.get('Interval_Days').value != '3'
                      }"
                        class="btn btn--box">
                        Every 3<span>rd</span> Day
                      </button>
                    </div>
                    <div class="col-sm-3">
                      <button  (click)="setIntervalDay(4)" [ngClass]="{
                        'btn--green--selected--subscribe' : f.get('Interval_Days').value == '4',
                        'btn--green--subscribe' : f.get('Interval_Days').value != '4'
                      }"
                        class="btn btn--box ">
                        Every 4<span>th</span> Day
                      </button>
                    </div>
                    <div class="col-sm-3">
                      <button  (click)="setIntervalDay('n')" [ngClass]="{
                        'btn--green--selected--subscribe' : isNthDay == true,
                        'btn--green--subscribe' : isNthDay == false
                      }"
                        class="btn btn--box ">
                        Every N<sup>th</sup> Day
                      </button>
                    </div>
                  </div>
                </div>

                
                <div class="row">
                  
                  <div class="col-md-6 pr-0">
                    <div class="product-quantity  d-flex align-items-center flex-wrap mt-1" *ngIf="option.Added_In_Cart">
                        <span class="product-var__text graycolor">Quantity:</span>
                    
                        <form class="modal-quantity-scale ml-1">
                          <div class="value-button" id="modal-decrease" (click)="decreaseValue(option)">-
                          </div>
                          <input style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;"
                            type="number" class="newinput" [value]="option.Cart_Qty" (change)="onCartQtyChange($event, option)" />
                          <div class="value-button" id="modal-increase" (click)="increaseValue(option)">+
                          </div>
                        </form>
                      
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="product-quantity  d-flex align-items-center flex-wrap mt-1" *ngIf="isNthDay">
                        <span class="product-var__text graycolor"> N<sup>th</sup> Interval:</span>
                        
                          <input style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;" type="text"
                          maxlength="2" class="newinput" formControlName="Interval_Days" appNthdaySelection  />
                      
                    </div>
                  </div>
                </div>
                

              </div>

              <!--VISIBLE ONLY FOR MOBILE DEVICES START-->
              <div class="col-xs-8 d-block d-sm-none ">
                <p class="graycolor">{{option.brand_name}}</p>
                <h2 class="product-name mb-0">{{option.product_name}} {{option.package_qty}}{{option.unit_name}}
                </h2>
                <p class="mb-2 product-description" [innerHTML]="option?.short_description"></p>
                <!-- <p class="mb-2 product-description">Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem printing and typesetting industr</p> -->
                <div class="form-box__single-group mt-0">
                  <label for="form-freq"
                    style="color: #62aa00;font-weight:500;font-size: 15px;margin-bottom: 5px;">Frequency:</label>
                  <select id="form-freq" formControlName="Subscription_Type" style="margin-bottom: 2px;">
                    <option value="select-freq" selected disabled>Select a Frequency</option>
                    <option *ngFor="let options of frequency" [value]="options.value">{{options.value}}</option>

                  </select>
                  <div class="interval-selections" *ngIf="f.get('Subscription_Type').value == 'On Interval'">
                    <div class="row mybtnrow">
                      <div class="col-xs-6">
                        <button (click)="setIntervalDay(2)" [ngClass]="{
                            'btn--green--selected--subscribe' : f.get('Interval_Days').value == '2',
                            'btn--green--subscribe' : f.get('Interval_Days').value != '2'
                          }"
                          class="btn btn--box ">
                          Every 2<span>nd</span> Day
                        </button>
                      </div>
                      <div class="col-xs-6">
                        <button (click)="setIntervalDay(3)" [ngClass]="{
                          'btn--green--selected--subscribe' : f.get('Interval_Days').value == '3',
                          'btn--green--subscribe' : f.get('Interval_Days').value != '3'
                        }"
                          class="btn btn--box">
                          Every 3<span>rd</span> Day
                        </button>
                      </div>
                      <div class="col-xs-6 mt-1">
                        <button  (click)="setIntervalDay(4)" [ngClass]="{
                          'btn--green--selected--subscribe' : f.get('Interval_Days').value == '4',
                          'btn--green--subscribe' : f.get('Interval_Days').value != '4'
                        }"
                          class="btn btn--box ">
                          Every 4<span>th</span> Day
                        </button>
                      </div>
                      <div class="col-xs-6 mt-1">
                        <button  (click)="setIntervalDay('n')" [ngClass]="{
                          'btn--green--selected--subscribe' : isNthDay == true,
                          'btn--green--subscribe' : isNthDay == false
                        }"
                          class="btn btn--box ">
                          Every N<sup>th</sup> Day
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-7 pl-0 pr-0">
                    <div class="product-quantity  d-flex align-items-center flex-wrap"
                  *ngIf="option.Added_In_Cart">
                  <span class="product-var__text graycolor">Quantity:</span>
                  <form class="modal-quantity-scale ml-1">
                    <div class="value-button" id="modal-decrease" (click)="decreaseValue(option)">-
                    </div>
                    <input class="inputvalue"
                      style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;" type="number"
                      [value]="option.Cart_Qty" (change)="onCartQtyChange($event, option)" />
                    <div class="value-button" id="modal-increase" (click)="increaseValue(option)">+
                    </div>
                  </form>
                </div>
                  </div>
                  <div class="col-xs-5">
                    <div class="product-quantity  d-flex align-items-center flex-wrap" *ngIf="isNthDay">
                      <span class="product-var__text graycolor"> N<sup>th</sup> Interval:</span>
                      
                        <input style="text-align: center;border: 1px solid #aaabaa;padding: 2px;font-weight: 600;" type="text"
                        maxlength="2" class="newinput" formControlName="Interval_Days" appOnlynumbers />
                    
                    </div>
                  </div>
                </div>
                
                
                <span class="product__price"> <del *ngIf="option.product_package_mrp != option.selling_amt"> AED {{option.product_package_mrp }}</del> &nbsp; AED {{option.selling_amt}}</span>
                <p class="graycolor" style="margin-top:5px;">{{option.Cart_Qty}} x  AED {{option.selling_amt}} &nbsp; &nbsp; <span class="amount product__price">AED {{option.Product_Total }}</span></p>

              </div>
              <div class="col-xs-1 d-block d-sm-none">
                <p class="deleteicon mb-4" (click)="onDelete(option)"><i style="    margin-bottom: 100px;"
                    class="far fa-trash-alt"></i></p>

              </div>
              <!--VISIBLE ONLY FOR MOBILE DEVICES END-->

              <div class="col-lg-3 col-md-3 col-sm-2 col-xs-2 d-none d-sm-block" style="text-align: right;">
                <p class="deleteicon mb-4" (click)="onDelete(option)"><i class="far fa-trash-alt"></i></p>
                <span class="product__price" style="margin: 10px 0;"> 
                  <del *ngIf="option.product_package_mrp != option.selling_amt"> 
                    AED {{option.product_package_mrp }}</del> &nbsp; AED {{option.selling_amt }}</span>
                <p class="graycolor" style="margin: 10px 0;">{{option.Cart_Qty}} x AED {{option.selling_amt }}</p>
                <p class="amount product__price" style="margin: 10px 0;">AED {{option.Product_Total  }}</p>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-md-3"></div>
              <div class="col-md-9 selectdate">
                <div class="row">
                  <div class="col-md-6 col-xs-6">
                    <label for="start">Start on:</label>
                    <input formControlName="Subscription_Start_Date" type="date" id="start" name="trip-start" value=""
                      [min]="subscriptionStartDate" (change)="startDateChange($event)">
                  </div>
                  <div class="col-md-6 col-xs-6">
                    <label for="start">Ends on (Optional):</label>
                    <input formControlName="Subscription_End_Date" type="date" id="start" name="trip-start" value=""
                      [min]="subscriptionEndDate">
                  </div>
                </div>


              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3"></div>
              <div class="col-md-9">
                <p style="    color: #62aa00;">Order by <b>11.00 PM Today </b> & get delivery by <b>{{tomorrowDate|date :"dd-MMM-yyyy "}}</b></p>
                <!-- <p style="    color: #62aa00;">Order by <b>11.00 PM Today {{currentDate | date:"EEEE"}}</b> & get delivery by <b>{{tomorrowDate|date :"dd-MMM-yyyy "}}</b></p> -->
              </div>
            </div>
            <div class="row mt-4 justify-content-center">

              <!-- <div class="col-md-6 text-center">
              <a style="width: 200px; color: #62aa00;"
                class="btn btn--box btn--small   btn--transparent btn--border-green " type="submit">SUBSCRIBE</a>
            </div> -->

            </div>
          </div>
          <br>
          <div class="section-content mt-4">
            <h5 class="section-content__title">Address List</h5>
          </div>
          <p class="text-danger" *ngIf="isFranchiseIdNotMatch">Selected Address Not Match with Selected Service Location
          </p>
          <div class="item-holder mt-1" *ngFor="let option of address" [style.opacity]="option.Invalid_City ? '0.6': '1'">
            <span class="custom-badge" *ngIf="option.Is_Verified =='1'">🗸 Verified</span>
            <div class="row align-items-center">
              <div class="col-lg-1 col-md-1 firstdiv col-xs-1" style="padding-left: 30px;">
                <input type="radio" class="shipping-select" name="address" [value]="option.Delivery_Address_Id"
                  id="add-express" (input)="checkValue($event, 'address')" [disabled]="selectedDistrict != option.District_Id" [style.opacity]="option.Invalid_City ? '0.9': '1'">
              </div>
              <div class="col-lg-7 col-sm-8 col-xs-8">

                <p>{{option.Address_Type}}</p>
                <h2 style="margin-bottom: 5px;">{{option.Full_Name}}</h2>
                <p>
                  {{option.Flat_House_No}}, {{option.Area_Colony_Sreet_Name}}, {{option.Landmark}},
                  {{option.Pincode}}
                </p>
                <p>
                  <b>City</b> : {{option.District_Name}} <b>Area</b> : {{option.Sub_Area_Name}}
                  {{option.Area_Name}}
                </p>
                <p style="margin: 5px 0px 0px 0px;" class="text-danger" *ngIf="option.isShow">
                  Selected address not in our serviceable area
                </p>
              </div>
              <div class="col-lg-4 col-md-3 col-xs-3" style="text-align: right;">
                <p class="deleteicon mb-4" (click)="onDeleteAddress(option)"><i class="far fa-trash-alt"></i></p>
                <a class="btn btn--box btn--extrasmall btn--transparent btn--border-green mtbtn "
                  (click)="onEdit(option)">Edit</a>
              </div>
              <div class="col-lg-12 col-md-12 col-xs-12" style="padding-left: 30px;">
                <p class="text-danger" *ngIf="option.Flat_House_No == '' || 
                option.Area_Colony_Sreet_Name == ''" style="padding:15px 0px 5px 45px;">Please update address fields Building No/Flat/House No, Apartment/Society Name 
                </p>
                <p class="text-danger" *ngIf="option.Invalid_City"
                  style="padding:15px 0px 5px 45px;">
                  Address Does Not Belong To Your Selected City
                </p>
              </div>
            </div>
          </div>

          <!-- <p class="text-danger">
            Delivery Available In ({{vnfareaslist}})
          </p> -->

          <div class="mt-2 mb-2">
            <a (click)="onAddress()"
              class="btn btn--box btn--small  btn--green btn--green-hover-black btn--uppercase font--semi-bold">Add
              New Delivery Address</a>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 mt-2">
          <div class="section-content">
            <h5 class="section-content__title">Subscribe Summary</h5>
          </div>
          <div class="sidebar__widget mt-1 p-4">
            <!-- <span class=" mt-1 mb-1" style="font-size: 18px;">Have a Coupon Code?</span>
            <form class="mt-1 mb-4">
              <input id="couponCodeTextBox" name="search" placeholder="Enter Coupon Code" type="search">
              <button type="submit">Apply</button>
            </form>
            <hr> -->
            <div class="row">

              <!-- <div class="form-box__single-group mt-0">
                <label for="form-freq" style="color: #62aa00;font-weight:500;font-size: 18px;">Frequency:</label>
                <select id="form-freq" formControlName="Subscription_Type">
                  <option value="select-freq" selected disabled>Select a Frequency</option>
                  <option *ngFor="let options of frequency" [value]="options.value">{{options.value}}</option>
                  
                </select>

              </div> -->
              <!-- <div class="col-md-7 col-sm-6 col-xs-8 mt-3 mb-3">
                <p>Value of Products : </p>
                <p>Discount (-) : </p>
                <p>Subtotal : </p>
                <p>Delivery Charges : </p>
              </div>
              <div class="col-md-5 col-sm-6 col-xs-4 mt-3 mb-3">
                <p>AED {{Value_Of_Products | number}}</p>
                <p style="color: #62aa00;">AED {{Value_Of_Discount | number}}</p>
                <p>AED {{Value_Of_Sub_Total | number}}</p>
                <p>AED {{Delivery_Chargeses | number}}</p>
              </div>
              <hr>
              <div class="col-md-7 col-xs-8">
                <p class="product-value">Total <br><span style="color:gray;font-size:16px; cursor: pointer;"
                    (click)="closeGstDialog()">(Inclusive of all taxes)</span></p>

              </div>
              <div class="col-md-5 col-xs-4">
                <p class="product__price product-value">{{Value_Of_Total }}</p>
              </div> -->
              <div class="col-lg-7 col-md-8 col-xs-8 mt-1 mb-1">

                <p>Value of Products </p>
                <p>Discount (-) </p>
                <p>Subtotal </p>
                <p>Delivery Charges (+) </p>
                <p *ngIf="Wallet_Amount_Used != 0">Wallet (-) <span (click)="onRemove()" class="text-danger"
                    style="cursor: pointer;" *ngIf="Wallet_Amount_Used != 0">Remove</span></p>
              </div>
              <div class="col-lg-5 col-md-4 col-xs-4 mt-1 mb-1">
                <p>AED {{Value_Of_Products  }}</p>
                <p style="color: #62aa00;">AED {{Value_Of_Discount  }}</p>
                <p>AED {{Value_Of_Sub_Total  }}</p>
                <p>AED {{Delivery_Chargess | number}}</p>
                <p *ngIf="Wallet_Amount_Used != 0">AED {{Wallet_Amount_Used | number}}</p>
              </div>
              <hr class="mt-0 ">
              <div class="col-md-7 col-xs-8">
                <p class="totamt" style="font-size: 22px; cursor: pointer;">Total <br><span
                    style="color:gray;font-size:16px;" (click)="closeGstDialog()">(Inclusive of
                    all taxes)</span></p>

              </div>
              <div class="col-md-5 col-xs-4">
                <p class="product__price totamt" style="font-size: 22px ;">AED {{Value_Of_Total}}
                </p>
              </div>
              <hr>
              <div class="col-md-7 col-xs-8">
                <p>
                  <i class="fas fa-wallet" style="color: #62aa00;"></i> &nbsp; Wallet
                  <!-- <i class="fas fa-wallet" style="color: #62aa00;"></i> {{walletAmountCount }} -->
                </p>

              </div>
              <div class="col-md-5 col-xs-4">
                <!-- <p (click)="onRedeem()" *ngIf="Wallet_Amount_Used == 0">
                  <span style="cursor:pointer;color: #62aa00;">Redeem</span>
                </p> -->
                <p>

                  AED {{walletAmountCount}}
                </p>
              </div>
              <hr>
              <div class="col-md-12 col-xs-12">
                <p class="totamt" (click)="handleRecharge()">
                  <a class="rechargeLink">Recharge Your Wallet</a>
                </p>
              </div>
              <!-- <hr> -->
              <!-- <div class="col-md-12 col-xs-12">
                <p class="totamt">
                  Instructions
                </p>
                <textarea class="form-control" placeholder="Enter Your Instructions"
                  (input)="onInstruction($event)"></textarea>
                <br>
              </div> -->

              <!-- <hr> -->
              <!-- <p style="font-size: 22px;" class="mt-1 mb-1">Payment</p>
              <ul class="shipping-cost">
                <li *ngIf="Value_Of_Total > 0">
                  <label for="ship-standard">
                    <input type="radio" class="shipping-select" name="shipping-cost" value="Online"
                      id="ship-standard" (input)="checkValue($event, 'payment')"><span>Online Payment</span>
                    (UPI, Net Banking, Debit/Credit Card etc.)
                  </label>

                </li>
                <li>
                  <label for="ship-express">
                    <input type="radio" class="shipping-select" name="shipping-cost" value="COD" id="ship-express"
                      (input)="checkValue($event, 'payment')"><span>Cash On
                      Delivery</span>
                  </label>

                </li>
                <span class="text-danger" style="font-size: 18px;" *ngIf="Value_Of_Sub_Total < 199">Minimum Order
                  Value
                  is
                  {{199  }}</span> <br>

              </ul> -->


            </div>
            <!-- <div class="text-center mb-3">
              <button style="width: 300px;"
                class="btn btn--box btn--small editwidth btn--green btn--green-hover-black btn--uppercase font--semi-bold"
                [disabled]="isProceed" (click)="proceed()">SUBSCRIBE</button>
            </div> -->
          </div>
          <div class="text-center mb-3">
            <button style="width: 300px;"
              class="btn btn--box btn--small editwidth btn--green btn--green-hover-black btn--uppercase font--semi-bold"
              [disabled]="isProceed" (click)="proceed()">SUBSCRIBE</button>
          </div>
          <!-- <div class="text-center mb-3">
            <button style="width: 300px;"
              class="btn btn--box btn--small editwidth btn--green btn--green-hover-black btn--uppercase font--semi-bold"
              (click)="handleRecharge()">Recharge Your Wallet</button>
          </div> -->
          <!-- <div class="outsection">
            <p class="sec-title">Offers</p>
            <div class="first-sec">
              <div class="p-2 text-center">
                <input type="radio" class="shipping-select" name="address" id="offerselect">
                <span class="fristchild">Subscribe and get 7 days milk free </span> <br> <strong><span> + </span><br>
                  <span>Insulated Bag</span></strong>
              </div>
              <div class="mt-5 pr-3" style="text-align: end;">
                <p style=" color: #62aa00;">(Worth AED 500)</p>
              </div>
              <div class="p-3"
                style="border:1px solid #62aa00;
                position: relative;background-color: #62aa00;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                <img src="assets/img/subscribe/bag.png" class="img-responsive bagimg" />
              </div>
            </div>
            <div class="second-sec">
              <div class="p-2 text-center">
                <input type="radio" class="shipping-select" name="address" id="offerselect">
                <span class="fristchild">Get AED 1000 cashback
                  on a recharge of AED 3000</span><br> <strong><span> + </span><br>
                  <span>Insulated Bag</span></strong>
              </div>
              <div class="mt-5 pr-3" style="text-align: end;">
                <p style=" color: #62aa00;">(Worth AED 500)</p>
              </div>
              <div class="p-3" style="border:1px solid #62aa00;background-color: #62aa00;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;
                  position: relative;">
                <img src="assets/img/subscribe/bag.png" class="img-responsive bagimg" />
              </div>
            </div>
          </div> -->
        </div>
        <div class="col-md-8 col-sm-12 mt-1">
          <!-- <div class="section-content">
            <h5 class="section-content__title">Address List</h5>
          </div>
          <p class="text-danger" *ngIf="isFranchiseIdNotMatch">Selected Address Not Match with Selected Service Location
          </p>
          <div class="item-holder mt-1" *ngFor="let option of address">
            <div class="row align-items-center">
              <div class="col-lg-1 col-md-1 firstdiv col-xs-1" style="padding-left: 30px;">
                <input type="radio" class="shipping-select" name="address" [value]="option.Delivery_Address_Id"
                  id="add-express" (input)="checkValue($event, 'address')">
              </div>
              <div class="col-lg-7 col-sm-8 col-xs-8">

                <p>{{option.Address_Type}}</p>
                <h2 style="margin-bottom: 5px;">{{option.Full_Name}}</h2>
                <p>
                  {{option.Flat_House_No}}, {{option.Area_Colony_Sreet_Name}}, {{option.Landmark}},
                  {{option.Pincode}}
                </p>
                <p>
                  <b>City</b> : {{option.District_Name}} <b>Area</b> : {{option.Sub_Area_Name}}
                  {{option.Area_Name}}
                </p>
                <p style="margin: 5px 0px 0px 0px;" class="text-danger" *ngIf="option.isShow">
                  Selected address not in our serviceable area
                </p>
              </div>
              <div class="col-lg-4 col-md-3 col-xs-3" style="text-align: right;">
                <p class="deleteicon mb-4" (click)="onDelete(option)"><i class="far fa-trash-alt"></i></p>
                <a class="btn btn--box btn--extrasmall btn--transparent btn--border-green mtbtn "
                  (click)="onEdit(option)">Edit</a>
              </div>
            </div>
          </div>

          <p class="text-danger">
            
            Delivery Available In ({{vnfareaslist}})
          </p> -->

          <!-- <div class="mt-2 mb-2">
            <a (click)="onAddress()"
              class="btn btn--box btn--small  btn--green btn--green-hover-black btn--uppercase font--semi-bold">Add
              New Delivery Address</a>
          </div> -->
        </div>
        <div class="col-md-4 col-sm-12 mt-1">
          <!-- <div class="section-content">

            <h5 class="section-content__title">Order Summary</h5>
          </div> -->

          <!-- <div class="sidebar__widget mt-1 p-4 pb-2">
            <div class="row">
              <div class="col-lg-7 col-md-8 col-xs-8 mt-1 mb-1">
                <p>Value of Products </p>
                <p>Discount (-) </p>
                <p>Subtotal </p>
                <p>Delivery Charges (+) </p>
                <p *ngIf="Wallet_Amount_Used != 0">Wallet (-) <span (click)="onRemove()" class="text-danger"
                    style="cursor: pointer;" *ngIf="Wallet_Amount_Used != 0">Remove</span></p>
              </div>
              <div class="col-lg-5 col-md-4 col-xs-4 mt-1 mb-1">
                <p>{{Value_Of_Products  }}</p>
                <p style="color: #62aa00;">{{Value_Of_Discount  }}</p>
                <p>{{Value_Of_Sub_Total  }}</p>
                <p>AED {{Delivery_Chargess | number}}</p>
                <p *ngIf="Wallet_Amount_Used != 0">AED {{Wallet_Amount_Used | number}}</p>
              </div>
              <hr class="mt-0 ">
              <div class="col-md-7 col-xs-8">
                <p class="totamt" style="font-size: 22px; cursor: pointer;">Total <br><span
                    style="color:gray;font-size:16px;" (click)="closeGstDialog()">(Inclusive of
                    all taxes)</span></p>

              </div>
              <div class="col-md-5 col-xs-4">
                <p class="product__price totamt" style="font-size: 22px ;">{{Value_Of_Total }}
                </p>
              </div>
              <hr>
              <div class="col-md-7 col-xs-8">
                <p><i class="fas fa-wallet" style="color: #62aa00;"></i> {{walletAmountCount}} </p>

              </div>
              <div class="col-md-5 col-xs-4">
                <p (click)="onRedeem()" *ngIf="Wallet_Amount_Used == 0"><span
                    style="cursor:pointer;color: #62aa00;">Redeem</span></p>
              </div>
              <hr>
              
              <div class="col-md-12 col-xs-12">
                <p class="totamt">
                  Instructions
                </p>
                <textarea class="form-control" placeholder="Enter Your Instructions"
                  (input)="onInstruction($event)"></textarea>
                <br>
              </div>
              
              <hr>
              <p style="font-size: 22px;" class="mt-1 mb-1">Payment</p>
              <ul class="shipping-cost">
                <li *ngIf="Value_Of_Total > 0">
                  <label for="ship-standard">
                    <input type="radio" class="shipping-select" name="shipping-cost" value="Online" id="ship-standard"
                      (input)="checkValue($event, 'payment')"><span>Online Payment</span>
                    (UPI, Net Banking, Debit/Credit Card etc.)
                  </label>

                </li>
                <li>
                  <label for="ship-express">
                    <input type="radio" class="shipping-select" name="shipping-cost" value="COD" id="ship-express"
                      (input)="checkValue($event, 'payment')"><span>Cash On
                      Delivery</span>
                  </label>

                </li>
                <span class="text-danger" style="font-size: 18px;" *ngIf="Value_Of_Sub_Total < 199">Minimum Order Value
                  is
                  {{199  }}</span> <br>
                
              </ul>
            </div>
          </div> -->

          <!-- <div class="text-center mt-2">
          <a [routerLink]="['/products', 'All Products', 0, 0]" style="    width: 300px;"
            class="btn btn--box btn--small  editwidth btn--transparent btn--border-green mtbtn " type="submit">CONTINUE
            SHOPPING</a>
        </div> -->
        </div>
      </div>


    </div>
  </main>
  <!-- ::::::  End  Main Container Section  ::::::  -->


</form>


<ng-container *ngIf="isOpenGstDetail">
  <div
    style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
    <div style="position: fixed;top: 30%;left: 25%;width: 51%;height: 82%;z-index: 1050;overflow: hidden;outline: 0;"
      role="dialog">
      <div class="modal-content">
        <div class="modal-body modal13">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12">
                <p class="text-right"><i class="fa fa-times" style="cursor: pointer;" (click)="closeGstDialog()"></i>
                </p>
              </div>
              <div class="col-12">
                <div class="modal-bg checkout-page">
                  <div class="age-content checkout-form">
                    <div class="grid-container" style="    background-color: #dddddd;">
                      <div class="item1">
                        Product Name
                      </div>
                      <div class="item1">
                        GST on Price
                      </div>
                    </div>
                    <div class="grid-container" *ngFor="let option of subscribeCartProduct">
                      <div>
                        {{option.product_name}}{{option.package_qty}}{{option.unit_name}}
                      </div>
                      <div>
                        {{option.Total_CGST_Amount + option.Total_SGST_Amount |
                        currency:'INR':'symbol'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>