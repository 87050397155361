<div style="background: #ececec;    border-top: 2px dashed#ea6d14;">
  <div class="container pt-2 pb-4">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <app-desifarm-dashboard-side-menu></app-desifarm-dashboard-side-menu>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <h1 class="tab-heading">Edit Profile</h1>
              <div class="editform">
                <form [formGroup]="form">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-box__single-group ">
                        <label class="mylabel" for="form-register-name">First Name *</label>
                        <input formControlName="First_Name" type="text" placeholder="Your First Name">
                        <span class="text-danger" *ngIf="f.get('First_Name').hasError('pattern')">Only Characters are allowed</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="form-box__single-group ">
                        <label class="mylabel" for="form-register-no">Last Name *</label>
                        <input formControlName="Last_Name" type="text" placeholder="Your Last Name">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12">
                      <div class="form-box__single-group ">
                        <label class="mylabel" for="form-register-email">Email</label>
                        <input formControlName="Email_Id" type="email" placeholder="Your Email ID" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-box__single-group ">
                        <label class="mylabel" for="form-uregister-sername-gender">Gender</label>
                        <select formControlName="Gender">
                          <option value="" disabled selected>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="form-box__single-group ">
                        <label class="mylabel" for="form-register-no">Mobile No *</label>
                        <input formControlName="Customer_Mobile_Number" type="text" placeholder="Mobile Number" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-box__single-group ">
                        <label class="mylabel" for="form-register-name">Date Of Birth</label>
                        <input formControlName="DOB" type="date" placeholder="Date Of Birth">
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="form-box__single-group ">
                        <label class="mylabel" for="form-register-no">Date Of Anniversary</label>
                        <input formControlName="Anniversary_Date" type="date" placeholder="Date Of Anniversary">
                      </div>
                    </div>
                  </div>
                  <div class="text-center mt-4"><button style="font-size: 18px;"
                      class="btn btn--box btn--medium btn--radius btn--black btn--black-hover-green font--semi-bold"
                      (click)="submit()" [disabled]="form.invalid">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
