import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmWalletService {

  walletAmountCount = new BehaviorSubject<any>(0);

  constructor(private http: HttpClient) { }

  get_wallet_amount(Cust_Id): Observable<any> {
    return this.http.get<any>(environment.url + 'get_wallet_amount/'+Cust_Id)
  }

  get_wallet_history(Cust_Id): Observable<any> {
    return this.http.get<any>(environment.url + 'get_wallet_history/'+Cust_Id)
  }
}
