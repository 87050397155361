import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmSearchProductsService {

  constructor(private http: HttpClient) { }

  search_products(data: any): Observable<any> {
    return this.http.post<any>(environment.url + 'search_products', data)
  }
}
