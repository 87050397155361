<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb mb-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"><a routerLink="/">Home</a></li>
                    <li class="page-breadcrumb__nav active">Refund  Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<section class="mysec mb-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Milk Unfit for Consumption</h2>
                <p>Our milk has no preservatives or additives. Maintaining optimum temperature, hence, is key to keeping the milk fresh and fit to be consumed. However, if the cold chain inadvertently breaks at our end, causing the milk to sour on the day of 
                    delivery, you can request a refund for the said lot.<br>
                    Refund is initiated once we receive a complaint along with the batch details photograph i.e. the date printed on the pouch or bottle by call or email. Our policy lasts for 24 hours; if it goes beyond 24 hours since your purchase, 
                    unfortunately we can’t provide  you a refund.</p>
                <!-- <p>Thanks for shopping at Desi farms </p>
                <p>If you are not entirely satisfied with your purchase, we're here to help. Because of the nature of our goods (perishable food items) our policy lasts 12 hours from the day of delivery (including it). If 12 hours have gone by since your purchase, unfortunately we can’t offer you a refund or replacement.</p>
                <h3>Replacement</h3>
                <p>You will have to initiate a replacement within 12 hours from the date you received it.
 
                    To be eligible for a replacement, you must share images of the product with invoice image which you have received.
                    </p>
                <p>Your item needs to have proof of purchase (order no or snapshot of the order from the app/website indicating the items and date of purchase).</p>
                <p>Once we have confirmed our purchase, we will begin the process of replacement for the item you are not satisfied with.</p>
                <p>Your replacement will reach you within 48 hours of the date of complain raised.</p>
                <h3>Refunds</h3>
                <p>Refunds can be requested if:</p>
                <ul>
                    <li>Items are missing from your order</li>
                    <li>Quality is not as per your expectation</li>
                    <li>Order or item from the order is not delivered</li>
                </ul>
                <p>If your complain falls within the above 3 listed causes, we will refund the order amount or the item amount depending on the complaint.</p>
                <p>Your order/item needs to have proof of purchase (order no or snapshot of the order from the app/website indicating the items and date of purchase).
                </p>
                <p>Your refund will be initiated immediately once we have confirmed the purchase and established the cause of refund.
                </p>
                <p>You can opt for Desi Farms Loyalty Points or choose to get the refund in your original source of payment or in Desi Farms wallet.</p>
                     
                <p>We will immediately initiate the refunds from our end once we have established proof of purchase but each bank has a different duration to credit the refund amount. We would request you to check with your bank in case of any delays.
                    </p>
                    <h3>Late or missing refunds (if applicable)</h3>
                <p>If you haven’t received a refund yet, first check your bank account again. Then contact your credit/debit card company, it may take some time before your refund is officially posted. Next contact your bank. There is often some processing time before a refund is posted. If you’ve done all of this and you still have not received your refund yet, please contact us at 
                    Cancellation
                    </p>
                <p>Orders once placed cannot be cancelled yet there can be special circumstances under which cancellation becomes necessary. If this is the case, you can WhatsApp or call us on 2 to request for cancellation of your order</p>
                <h3>Shipping and Delivery charges</h3>
                <p>All our orders are delivered free without any additional charges*.</p>
                <p>Desi farms ‘reserves the right to reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website.</p>
                <h3>Contact Us</h3>
                <p>If you have any questions on how to get refunds or replacement, call or WhatsApp us on.</p> -->
                    <h3>Exchanges (if applicable)</h3>
                <p>We only replace/ refund items if they are damaged at the time of delivery.</p>
                    <h3>Post Discontinuation</h3>
                <p>If you have discontinued your milk supply/ relocated/ your area which does not come under service area then your refund will be processed, and a credit will automatically be applied to your bank account within 10 business days. You will have to raise a refund request over call or email at info@desifarmsindia.in, sharing your bank details for the same.</p>
                <h3>Late or missing refunds (if applicable)</h3>
                <p>If you haven’t received a refund yet, first check your bank account again. Next contact your bank. There is often some processing time before a refund is posted.</p>
                <p>If you’ve done all of this and you still have not received your refund yet, please contact us at info@desifarmsindia.com.</p>
                   
                <h3>Erroneous Delivery</h3>
                <p>A delivery will be considered erroneous at our end if you have intimated us in advance and you receive a delivery despite this, you are eligible for a reverse pickup. You will have to inform us within 2 hrs of delivery to arrange for a reverse pickup of the product.</p>
                <p>Refusing delivery without prior information does not qualify for refund.</p>

                <p>The following are the only admissible ways of prior intimation:</p>
                <ul>
                    <li>Call our customer care department before 4:00 PM</li>
                    <li>Update necessary information in your account on the APP before 4:55 PM
                        </li>
                </ul>

                <p>Please note, informing the delivery boy is not admissible as formal intimation of order. The customer care department is the only authorised channel for this.</p>

            </div>
        </div>
    </div>
</section>
