<div class="row">
  <div class="col-md-12" style="text-align: right;z-index: 999;">
    <span class="closecart" style="font-size:40px !important;
      color:gray !important;text-align: right !important;cursor: pointer;" aria-hidden="true"
      (click)="close()">&times;</span>
  </div>
  <div class="col-md-12">
    <h2 class="address-heading">Select Delivery Time Slot </h2>

  </div>

</div>

<div style="border: 3px solid lightgray;
              padding: 15px;" class="mymodal" *ngIf="_data == null">

  <div *ngFor="let option of deliveryTimes">
    <div class="text-left" *ngIf="option.From_Time != ''">
      <p class="timeslot-heading">{{option.Delivery_Date | date:"mediumDate"}}</p>
      <button *ngFor="let timeSlot of option.Delivery_Time_slot"
        class="btn btn--box btn--medium timeslot-btn btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"
        (click)="onTimeSelect(timeSlot)">
        {{timeSlot.Slot_Name }}
      </button>
    </div>
  </div>


</div>

<div style="border: 3px solid lightgray;
              padding: 15px;" class="mymodal" *ngIf="_data != null ">

  <div *ngFor="let option of deliveryTimes">
    <div class="text-left" *ngIf="option.From_Time != ''">
      <p class="timeslot-heading">{{option.Delivery_Date | date:"mediumDate"}}</p>
      <button *ngFor="let timeSlot of option.Delivery_Time_slot"
        class="btn btn--box btn--medium timeslot-btn btn--radius btn--black btn--black-hover-green btn--uppercase font--semi-bold"
        (click)="onTimeSelect(timeSlot)" [disabled]="getStatus(timeSlot)">
        <!-- [disabled]="option.Delivery_Date == _data.Customer_Delivery_Date && timeSlot.From_Time <= _data.Slot_Start_Time" -->
        <span>{{timeSlot.Slot_Name }}</span>
      </button>
    </div>
  </div>


</div>
