<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb" style="margin-bottom: 0;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav" routerLink="/"><a>Home</a></li>
                    <li class="page-breadcrumb__nav active">Our Stores</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<section style="background-color: #62aa00;">

    <div class="marquee">
        <div class="track">
            <div class="content"><img src="assets/img/store/01.jpg" />
                <img src="assets/img/store/02.jpg" />
                <img src="assets/img/store/03.jpg" />
                <img src="assets/img/store/04.jpg" />
                <img src="assets/img/store/05.jpg" />
                <img src="assets/img/store/06.jpg" />
                <img src="assets/img/store/07.jpg" />
                <img src="assets/img/store/08.jpg" />
                <img src="assets/img/store/09.jpg" />
                <img src="assets/img/store/10.jpg" />
            </div>
        </div>
    </div>
</section>

<!--STORE ADD START-->
<section>
    <div class="container">

        <div class="btndesign text-center">
            <button class="btn btn-default filter-button active" id="all" onclick="call(this.id)">All</button>
            <button class="btn btn-default filter-button" id="pune" onclick="call(this.id)">Pune</button>
            <button class="btn btn-default filter-button" id="mumbai" onclick="call(this.id)">Navi Mumbai</button>

        </div>

        <div class="row mt-3">
            <div class="col-md-3 col-xs-6 glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Baner</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">SAITEJDHAN, Shop No. - 1, Sr. No. - 113/1, Near Ganaraj Chowk, Veerbhadra
                        Nagar, Baner, Pune, 411045</p>
                    <!-- <p  style="    padding-bottom: 25px;">Mr. Sunil Sinha- 9006298792</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6 glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Mohammed Wadi</a>
                            </div>
                        </div>

                    </div>

                    <p class="pt-2 pb-5">Shop no 10 Nyati county Nyati Estate road Mohammed wadi Pune 411060 </p>
                    <!-- <p  style="    padding-bottom: 25px;">Mr. John Martin - 8668783963</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Pimple Saudagar</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">S NO.68/69, Shop No.7, Ground Floor, Palm Breeze, Commercial Unit ,
                        Pimple Saudagar, Pune-411027

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Ajmera</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop no:1, D building, Prem Park Avenue, Near Union Bank,
                        Masurkar Colony, Ajmera,
                        Pimpri, Pune

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Rahul Gaikwad - 8862084045</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>


        </div>


        <div class="row">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <!-- <div class="col-md-2 col-xs-3"><i style="color: #00659c;" class="fas fa-store fa-2x"></i></div> -->
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Viman Nagar</a>
                            </div>
                        </div>
                    </div>
                    <p class="pt-2 pb-5">New Shop: B/9, Ground Building Siddesh Optimus,
                        Viman Nagar, Pune 411 014</p>
                    <!-- <p style="padding-bottom: 25px;">Mob. No- 7219747788</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Karve Nagar</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No.29,B4, Girija Shankar Vihar Karvenagar,Pune 411052</p>
                    <!-- <p  style="    padding-bottom: 25px;">Mob.-9850204783</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Hadapsar</a>
                            </div>
                        </div>

                    </div>

                    <p class="pt-2 pb-5">Shop No 20, Bhosale Garden Society, Hadapsar,411028. Pune </p>
                    <!-- <p  style="    padding-bottom: 25px;">Ganesh- +91 9423245843</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Katraj </a>
                            </div>
                        </div>

                    </div>

                    <p class="pt-2 pb-5">Shop no 2, Sahyadri complex, Phale Nagar, Datta Nagar Road, Behind Reliance
                        Fresh, Katraj Pune- 411046 </p>
                    <!-- <p  style="    padding-bottom: 25px;">Mobile - 9420111251</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Anand Nagar</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Kiara Society , Shop No: 8 Suncity police station road, Sinhgad Rd, Sun City,
                        Anand Nagar, Pune, Maharashtra 411051</p>
                    <!-- <p  style="    padding-bottom: 25px;">Deepak Adane- 8999225668</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, NIBM</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No. 6, Sunshree D2 Building, Nibm Post Office Road, Pune- 411048</p>
                    <!-- <p  style="    padding-bottom: 25px;">Ashipal Bawa- 9422517244</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <!-- <div class="col-md-3 col-xs-6">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Manjri</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No. H.No.1007, Kamal Niwas, Mahdev Nagar, Manjri, Pune – 412307

                    </p>
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div> -->

            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Bavdhan </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Sai Velocity, Phase-2, A wing, Shop no.7, Dattamandir Chowk, Jadhav Wasti,
                        Bavdhan Pune 411021</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Kharadi</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">EON Free Zone, Kharadi, Pune
                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Mahadev Mundhe- 7030918266</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Pimpri</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Near Baliraj Mangal Karyalay, Nakhate Wasti, Rahatani, Pimpri, Pune</p>
                    <!-- <p  style="    padding-bottom: 25px;">Rajendra Thombre-  8329045890</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Nigdi</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Pride Residency, Shop No. 4 Behind Kohinoor Arcade, Near Sai Mandir, Nigdi,
                        Pune</p>
                    <!-- <p  style="    padding-bottom: 25px;">Anuradha Manivannan- 7387099219</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Vadgaon Sheri</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Ground Floor, Ganesh Nivas,
                        Sr. No. 48/4, Shivshakti Chowk,
                        Ganesh Nagar, Vadgaon Sheri,
                        Pune – 411014

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Mobile 9371192726</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Narhe</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">S. No 38/2/2 Shop A-03, Mahalaxmi Palace Haveli, Opposite Venkatesh Sharvil,
                        Last Bus Stop, Narhe Pune-411041
                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Ruturaj Chitale- 9923617771</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Chikhali</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Near Baliraj Mangal Karyalay, Nakhate Wasti, Rahatani, Pimpri, Pune
                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Vaibhav Fartade - 8975727405 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Thergaon</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">SR NO 33/4 TO 9 SHOP NO 8,
                        GANGA AASHIYANA,
                        OPP. ADITYA BIRLA HOSPITAL,
                        THERGAON, PUNE.411033
                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Prashant Takwale - 8793196196</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Wakad</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Sr No. 173/2/2A-1, Shop No.16,
                        Windsor Park, Wakad- Hinjewadi Road, Wakad, Pune-411057</p>
                    <!-- <p  style="    padding-bottom: 25px;">Sunil Chillore - 8329418136</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Warje</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop no. 2, Shreeyash Apartment,Near Pashchim rang Society, Warje, Pune 411052

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Gajanan Chavan - 9823640115 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Kothrud</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No.12,Rahul complex,
                        Next to New Poona Bakery,
                        Paud Road, Kothrud, Pune

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Ashish Mishra - 9356759742 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted" href="https://goo.gl/maps/F25J6i2vEeMWeQWx6" target="_blank"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Nanded City </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No. 3, Adityaraj Apartment, Rajyog Society Road,Opp. Vasant vihar,Lagad
                        Mala, Vadgaon Khurd, Pune-411041
                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Hemendra Joshi - 8275395772 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Moshi</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No.A-3, Privya Business Centre, Wing A, Near New RTO Office, Moshi,
                        Pune-412105

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Ashish Babar - 8600782927 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Dighi </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Sr No.80, Datta Nagar, Alandi Road, Water Pump, Dighi, Pune- 411015</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" class="img-responsive" />
                                <a class="store-title pl-1">Desi Farms, Charholi Budruk</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No 12. Gate No 03. Tanishka Orchid Building, Charholi Budruk,
                        Pune 412105</p>
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Mundhwa</a>
                            </div>
                        </div>

                    </div>

                    <p class="pt-2 pb-5">Shop no - 16, Mantra Insignia, near Renuka Mata Temple, Keshav Nagar, Mundhwa,
                        Pune, 411036</p>
                    <!-- <p  style="    padding-bottom: 25px;">Mob - 9619236555</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Wagholi</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No.C/07 Gat No. 715 To 717/2, IVY Botanica, Ivy Estate Rd,
                        Wagholi, Pune-412207

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Rakesh Gaikwad - 8856862282</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Koregaon Park</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">B 24/A Ragvilas Society.,
                        North Main Road lane
                        C Opposite lane No. 2,
                        Near Buddha Paradise
                        Hotel Koregaon Park, Pune
                        410001</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" class="img-responsive" />
                                <a class="store-title pl-1">Desi Farms, West Wagholi</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No. 12, B.A Hub, Opp.Savannah Society, Baif Road, Wagholi, Pune-412207</p>
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Aundh</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No.A-10, Sr No. 55 Pl No. 1 & 2, A Bldg, Kumar Classic, Aundh,
                        Pune - 411007

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Rahul Gaikwad - 8862084045</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Chinchwad</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No.2, H Building Kakade Township Opp.Shani Temple Keshav Nagar, Chinchwad
                        Pune - 411033

                    </p>
                    <!-- <p  style="    padding-bottom: 25px;">Rakesh Gaikwad - 8856862282</p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Balewadi</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No.1, Sr.No.16/4/1/1, Near Bravuria Society, Baner-Balewadi Rd, Balewadi,
                        Pune-411045</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
        </div>

        <div class="row justify-content-center ">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Pimple-Nilakh</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No. 06, New Dp Road, Opp Shivsagar hotel, near 24k Opula Society, Vishal
                        Nagar, Pimple Nilakh Pune-41027</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Handewadi, Pune </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Binavat Complex, Hadapsar-Handewadi Road, Opp.ECP Vastu Society, Sattav Nagar,
                        Hadapsar, Haveli, Pune-411028.</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Punawale, Pune </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">arvdnya Enterprises - Shop No.E04, 18Longitude Phase 3, Sr. No 25/1,
                        18Longitude, Latitude Road, Punawale - 411033</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms,Chikhali, Pune </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No.11, Near Yamunanagar Police Station, Yamunanagar Mini Market,
                        Yamunanagar, Nigdi, Pune - 411044</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Sus Gaon</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Oxford Paradise, Shop No. 04, Vidyavally Road, Sr No.127/6/2, Sus Gaon, Pune-
                        411021</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Pimple Gurav</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No. 05, Dhanlaxmi Apartments, Opp. Shanti Mandir, Near Kate Puram Chowk,
                        Pimple Gurav, Pimpri-Chinchwad, Maharashtra 411061</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Hinjewadi</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No. 1 Oneness New Wakad-Hinjewadi Link Road Pune-411057</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Sahakar Nagar</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Bldg. No. A-3, Shop no. 18, Gajalxmi Society, Sahakar Nagar , Pune. 411009 </p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">

            <div class="col-md-3 col-xs-6  glightbox filter mumbai">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Sec 19 Kharghar, Navi Mumbai </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No 3, Royal Plaza, Plot No 42, Near Reliance Fresh, Sector 19, Kharghar,
                        Navi Mumbai 410210</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6  glightbox filter mumbai">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Seawoods, Navi Mumbai </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No. 03, Plot no.14, CIDCO Complex, Sector 48, Seawoods - 400706, Navi
                        Mumbai, Maharashtra</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6 glightbox filter mumbai">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Nerul East!, Navi Mumbai </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop Number 3, Dream Heaven Building, Plot Number 18, Sector 25, Near Haware
                        Mall, Nerul East - 400706</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>
            <div class="col-md-3 col-xs-6 glightbox filter mumbai">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Sec 35 Kharghar, Navi Mumbai </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Paramjeet Enterprises - Proviso Complex, Ground Floor, Sector 35 E, Kharghar -
                        410210</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

        </div>

        <div class="row justify-content-center">
            <div class="col-md-3 col-xs-6  glightbox filter pune">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Hadapsar</a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Siyona Heights, Shree Pandharinath Hole Rd, opposite BLOCK-B, Bhosale Nagar,
                        Hadapsar, Pune, Maharashtra 411028</p>
                    <!-- <p  style="    padding-bottom: 25px;">Kiran Chikate - 7391808404 </p> -->
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6 glightbox filter mumbai">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Ulwe </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Mahavir Mannat, Shop 22, Plot no 168, Sector 9, Ulwe 410206</p>
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6 glightbox filter mumbai">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Kamothe </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">Shop No 23, Pratik Gardens, Plot No 153 to 165, Sec 34, Kamothe Navi Mumbai
                        410209</p>
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

            <div class="col-md-3 col-xs-6 glightbox filter mumbai">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <div style="position: relative;">
                                <img src="assets/img/store/icon.png" />
                                <a class="store-title pl-1">Desi Farms, Kharghar Sector - 8  </a>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2 pb-5">ARV Global Brands LLP Shop no 16, Anmol Plaza, Plot no 7, Sector 8, Kharghar, Navi Mumbai 410210</p>
                    <a class="btn addtocart btn--black btn--black-hover-green ng-star-inserted"><i
                            class="fas fa-map-marker-alt"></i> &nbsp;LOCATION</a>
                </div>
            </div>

        </div>
    </div>
</section>


<!--MAP SECTION-->
<section class="mt-5" style="border-top: 3px solid #62aa00;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1884.644343685268!2d74.70276153661067!3d19.138836533021014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdcba3c7354c6cf%3A0xe813f68d90795dd2!2sDesi%20Farms!5e0!3m2!1sen!2sin!4v1634892402543!5m2!1sen!2sin"
                    width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
    </div>
</section>