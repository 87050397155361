<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb mb-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"><a href="#">Home</a></li>
                    <li class="page-breadcrumb__nav active">Terms & Conditions</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<section class="mysec mb-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mt-0">Delete Account Policy</h3>
                <ul>
                    <li>We offer a delete account policy, giving you the flexibility to delete your account at any time.
                    </li>
                    <li>Please be aware that if you have any outstanding amount or balance, contacting us is necessary
                        for processing a refund upon account deletion.</li>
                    <li>Kindly note that once your account is deleted, you won't be able to log or Sign Up in using the
                        same email credentials.</li>
                    <li>Our account deletion process is designed to be user-friendly and accessible whenever you decide
                        to make this choice.</li>
                    <li>For any financial matters such as outstanding balances, we recommend reaching out to our support
                        team to facilitate a smooth refund process.</li>
                    <li>Keep in mind that account deletion is a permanent action, and we encourage you to review your
                        account thoroughly before proceeding.</li>
                </ul>
                <h3 class="mt-0">Terms Of Use</h3>
                <p>If you continue to browse and use this mobile app, you are agreeing to comply
                    with the following terms of use, which together with our Privacy Policy are
                    governed by the NSK Store that is Trademark of SNA Milk and Milk Products
                    Private Limited having CIN- U74999PN2016PTC164715 hereinafter referred to
                    as the Company in relation to this website/ Mobile app.</p>
                <h3>SUBSCRIBING WITH US</h3>
                <p>When placing an order with us, you are required to select the milk variant of
                    your choice and start the subscription from the wished date. You have to make
                    an advance payment for your milk subscription, which will reflect in your app
                    or can be requested by an email.</p>
                <p>The advance payment can be made via NEFT transfer/ cash/ cheque at the
                    outlet/ application. Please mention your registered contact number in the
                    narration of any mode of transaction.</p>
                <p>If advance payment is not made then the supply will not start and will be
                    on hold until the advance is received since this is a Prepaid service system.</p>
                <p>Delivery will be made as per our fixed delivery timings between 6 am and 8 am.
                    We do not commit to delivering in fixed time slots. Any complaint regarding
                    non-deliverance/ delays/ damage must be informed on the same day betwee
                    8 am and 10 am.</p>
                <h3>OFFERS, BENEFITS, AND REFERRAL PROGRAMME</h3>
                <p>The offers are eligible to new and old customers for the schemes going on
                    based on set criteria.</p>
               
                <ul>
                    <li>The cashback offers or milk benefits cannot be refunded.</li>
                    <li>One offer cannot be clubbed with any other offer.</li>
                    <li>Same offer cannot be given again or repeated at the same address.</li>
                    <li>One user can refer to as many customers as possible, but if the referred
                        addresses are similar then NSK Store holds the right to cancel/ remove the
                        benefits and hold the orders.</li>
                        <li>Bonus amounts can be used to purchase any products from the NSK Store App.</li>
                </ul>
                <h3>PRICING</h3>
                <p>The prices are reviewed periodically and are subject to change.</p>
                <h3>ADVANCE PAYMENT</h3>
                <p>Customers must make the payment whenever their wallet balance is exhausted
                    to enjoy hassle-free services. Our team sends a notification along with a call to
                    remind you of the same.</p>
                <p>Payment can be made online through our Mobile App. This is an automated
                    payment system. Payment can also be made by handing over the cheque/ cash
                    o your nearest outlet and collecting a receipt of the same. Cheques should be
                    in favour of SNA Milk and Milk Products Private Limited.</p>
                    <p>The physical collection of payment (Cash/ Cheque) is chargeable i.e. AED 20 per
                    invoice and is done strictly in the morning at the time of delivery. Customers
                    must take the receipt of payment from the delivery boy on payment.</p>
                    <p> If a consumer makes payment at our nearest outlet then the customer doesn’t
                    have to pay an additional AED 20 charge.</p>
                    <p>In case of payment failure, we are not liable for the same.</p>
                    <p>If your cheque happens to bounce due to insuffcient funds or any other
                    technical reason like a signature mismatch, you would have to additionally
                    bear AED 200 as penalty fee charges.</p>
                <h3>CANCELLATIONS/ RESCHEDULING</h3>
                <p>If you no longer require milk because of any reason please call us on
                    +91 9260605353. If there is any outstanding, settlement needs to be done
                    within 10 working days.</p>
                <p>If a customer wants to reschedule, kindly inform us between 9 am and 5 pm
                through any of the following modes:</p>
                <ul>
                    <li>NSK Store Mobile App</li>
                    <li>+91 9260605353</li>
                    <li>Please avoid using any digital platform like Facebook, Twitter, Instagram for
                        rescheduling of milk orders. Kindly do not email us the order changes or give
                        any information/ order changes to the delivery boys.</li>
                        <li>If you don’t receive milk, kindly call us on +91 9260605353 between 8 am and
                        10 am the same day only. Complaints regarding non-delivery will not be
                        accepted the next day.</li>
                </ul>
            <p>NSK Store holds the right to cancel or hold supply if multiple accounts are
                found with the same address.</p>
                <h3>SERVICE ACCESS</h3>
                <p>We make every effort to keep the mobile app/ website up and running smoothly.
                    While we endeavour to ensure that this mobile app/ website is normally available
                    24 hours a day, we will not be liable if for any reason the mobile app/ website is
                    unavailable at any time or for any period.</p>
                <p>Access to the website/ mobile app may be suspended temporarily and without
                    notice in case of system failure, maintenance, or repair, or for reasons beyond
                    our control.</p>
                <h3>REGISTRATION</h3>
                    <p>Each registration is for a single user only. We do not advise you to share your
                        user name and password with any other person nor with multiple users on a
                        network</p>
                    <p>Responsibility for the security of any passwords issued rests with you and if you
                        know or suspect that someone else knows your password, you should contact
                        us immediately.</p>
                    <p>We may suspend or cancel your registration immediately at our reasonable
                        discretion or if you breach any of your obligations under these terms and
                        conditions.</p>
                    <p>By registration on NSK Store Platform, you give us the permission to send you
                        notifications on SMS/ Whatsapp/ Email or by calling.</p>
                <h3>DISCLAIMER</h3>
                <p>While we endeavour to ensure that the information on this website/ mobile app
                    is correct, we do not warrant the accuracy and completeness of the material
                    on this website/ mobile app. We may make changes to the material on this
                    website/ mobile app or to the products and prices described in it. There will
                    be a notice issued on the site regarding the same.</p>
                <p>NOTHING IN THESE TERMS AND CONDITIONS AFFECTS YOUR STATUTORY RIGHTS.</p>
                <h3>LIABILITY</h3>
                <p>Nothing in these terms and conditions shall exclude or limit our liability for</p>
                <ul class="romanul">
                    <li>death or personal injury caused by negligence;</li>
                    <li>fraud;</li>
                    <li>misrepresentation as to a fundamental matter; or</li>
                    <li>any liability which cannot be excluded or limited under applicable law.</li>
                </ul>
                <p>If your use of material on this website/ mobile app results in the need for
                    service, repair, or correction of equipment, software, or data, you assume all
                    costs thereof.</p>
                <p>You agree to indemnify us fully, defend, and hold us, and our officers, directors,
                    employees, and agents, harmless from and against all claims, liability, damages,
                    losses, costs (including reasonable legal fees) arising out of any breach of the
                    terms and conditions by you, or your use of this website/ mobile app, or the use
                    by any other person using your registration details.</p>
            </div>
        </div>
    </div>
</section>