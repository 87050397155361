<h2 style="border: 1px solid gray;padding: 5px;">Your wallet has insufficient balance, kindly recharge your wallet by
    AED {{_data.Recharge_Amount}} To Conitue</h2>
    <!-- <h2 class="current-blce">Current Balance: &nbsp; &nbsp; {{walletAmountCount  }} </h2> -->
    
    <div class="row align-items-center">
        <div class="col-sm-12 mb-2">
            <p class="wallettop-p" style="font-size: 18px;margin: 0; color: #003c5c;">Topup Your NSK Store Wallet</p>
        </div>
        <div class="col-md-8 col-sm-7 col-xs-7">
            <form [formGroup]="form">
                <input type="number" style=" color: #003c5c;" formControlName="Cr_Amt" class="form-control"
                    placeholder="AED. Enter Amount" />
            </form>
        </div>
        <div class="col-md-4 col-sm-5 col-xs-5">
            <button [disabled]="form.invalid" (click)="onTopup()" class="btn cart-btn btn--small btn--dark btn--black addmoney-btn
        "> Add Money </button>
        </div>
    </div>
    
    <!-- <p class="mt-3 offerback" *ngIf="offers.length > 0">NSK Store Cashback Offers</p>
    
    <div class="offer-wrap">
    
        <ng-container *ngIf="offers.length > 0">
    
            <ng-container *ngFor="let option of offers">
                <img src="../../../../../assets/img/dashboard/offer.png" class="img-responsive" />
                <a class="offer-btn" (click)="onSetValue(option.Recharge_Amount)"><span [ngClass]="{'selected':form.get('Cr_Amt').value == option.Recharge_Amount}" class="cashbackline">Get <strong> AED {{option.Cashback_Amount}}</strong> cashback</span><br>
                    <span class="rechargeline">on a recharge of <strong>AED {{option.Recharge_Amount}}</strong></span>
                    <span (click)="onSetValue(option.Recharge_Amount)" class=" applybtn">APPLY</span></a>
            </ng-container>
        </ng-container>
    
    </div> -->
