import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desifarms-mywhishlist',
  templateUrl: './desifarms-mywhishlist.component.html',
  styleUrls: ['./desifarms-mywhishlist.component.scss']
})
export class DesifarmsMywhishlistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
