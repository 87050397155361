<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
<section class="invoce">
    <div class="container-fluid">
        <div class="print-dwndbtn">
            <!-- <a href="javascript:void(0)" (click)="handlePrint()"><span class="glyphicon glyphicon-print"></span></a> &nbsp; &nbsp;
                <a href="javascript:void(0)" (click)="handlePrint()"><i class="fas fa-download"></i></a> -->
        </div>
        <hr>
        <div class="row">
            <div class="col-md-12 texr-center addressdetail">
                <h2>NSK Store</h2>
                <p>F-12 First Floor Phoenix East Court,
                    Viman Nagar, Pune,<br>
                    Maharashtra 411014</p>
                <p>PH.NO. +91-9260605353</p>
            </div>
        </div>
        <hr>
        <div class="row mb-5">
            <div class="col-md-12">
                <p>Order Id : {{data?.Franchise_Order_Id}}</p>
                <p>Time : {{data?.Delivery_Challan_Date_Time | date: 'hh:mm:ss a'}}</p>
                <p>Date : {{data?.Delivery_Challan_Date_Time | date: 'dd-MMM-yyyy'}}</p>
                <p>Customer : {{data?.Franchise_Nick_Name}}</p>
                <p>Contact No : +91-{{data?.Contact_No1}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 texr-center">
                <p class="order-detail ">Order Detail</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table>
                    <tr>

                        <th>Order Qty</th>
                        <th>{{data?.Franchise_Order_status == 'Dispatched' ? 'Dispatch Qty' : 'Accepted Qty'}}</th>
                        <th>FPP</th>
                        <th>Gross</th>
                    </tr>
                    <ng-container *ngFor="let option of data?.Product_List;">
                        <!-- <tr>
                            <td colspan="4">{{option.Product_Name}} {{option.Package_Qty}} {{option.Base_Unit}} </td>
                        </tr>
                        <tr>

                            <td>{{option.Order_Qty}}</td>
                            <td>{{option.Actual_Delivery_Qty}}</td>
                            <td>AED{{option.Single_Selling_Amount}}</td>
                            <td>AED{{option.Total_Selling_Amount}}</td>
                        </tr> -->
                        <tr >
                            <td colspan="4">{{option.Product_Name}} {{option.Package_Qty}} {{option.Base_Unit}}</td> 
                         </tr>
                         <tr>
                            
                             <td>{{option.Order_Qty}}</td>
                             <td>{{option.Actual_Delivery_Qty}}</td>
                             <td>AED{{option.Single_Selling_Amount}}</td>
                             <td>AED{{option.Total_Selling_Amount}}</td>
                         </tr>
                    </ng-container>
                    <tr>
                        <td colspan="4" style="border-bottom: none;padding-bottom: 0;">Total Products :
                            {{data?.Product_List.length}}</td>
                    </tr>
                    <tr class="totalrow">
                        <td colspan="3"><strong>Total</strong></td>
                        <td>AED {{PayableAmount}}</td>
                    </tr>
                </table>
                <!-- <table>
                    <tr>
                        
                        <th>Order Qty</th>
                        <th>Deliverd Qty</th>
                        <th>FPP</th>
                        <th>Gross</th>
                    </tr>
                    <tr >
                       <td colspan="4">NSK Store cow milk cold 500ml</td> 
                    </tr>
                    <tr>
                       
                        <td>2</td>
                        <td>2</td>
                        <td>AED 2</td>
                        <td>AED 1000</td>
                    </tr>
                    <tr >
                    <td colspan="4">NSK Store cow milk cold 500ml</td> 
                    </tr>
                    <tr>
                       
                        <td>1</td>
                        <td>1</td>
                        <td>AED 1</td>
                        <td>AED 500</td>
                    </tr>
                    <tr>
                        <td colspan="4" style="border-bottom: none;padding-bottom: 0;">Total Products : 2</td>
                    </tr>
                    <tr class="totalrow">
                        <td colspan="3"><strong>Total</strong></td>
                        <td>AED 1500</td>
                    </tr>
                </table> -->
            </div>
        </div>
    </div>

</section>