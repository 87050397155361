import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { ResDistrict } from 'src/app/models/desifarm-res/desifarm-header/resdistrict';
import { ResSubArea } from 'src/app/models/desifarm-res/desifarm-header/ressubarea';
import { DesifarmLoginService } from 'src/app/services/desifarm-auth/desifarm-login/desifarm-login.service';
import { DesifarmSignupService } from 'src/app/services/desifarm-auth/desifarm-signup/desifarm-signup.service';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { PassworValidator } from 'src/app/shared/validators/cnf-password.validators';
import { environment } from 'src/environments/environment';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-nsk-login',
  templateUrl: './nsk-login.component.html',
  styleUrls: ['./nsk-login.component.scss']
})
export class NskLoginComponent implements OnInit, OnDestroy {

  private countdownSubscription: Subscription;
  remainingTime: number;
  isResending: boolean = false;


  @ViewChild('OtpInput') OtpInput: ElementRef<HTMLInputElement>

  form: FormGroup
  form1: FormGroup
  otpForm: FormGroup
  password: string = "password"
  passwordType: boolean = false
  loginFlag: boolean = true
  otpFlag: boolean = false
  signUpFlag: boolean = false



  districts: ResDistrict[] = []
  areaList: any[] = []
  subAreas: ResSubArea[] = []
  sources: any[] = []


  Customer_Mobile_Number: any
  otp: any
  District_Id: any
  Sub_Area_Id: any
  serviceLocation: any
  receivedOTP: any
  refercodeDetail: any

  constructor(
    public _dialogRef: MatDialogRef<NskLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _authService: DesifarmLoginService,
    private _signUpService: DesifarmSignupService,

  ) { }
  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  initForm() {
    this.form = this._fb.group({
      // Customer_Mobile_Number: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      Email_Id: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required]],
    })

    this.form1 = this._fb.group({
      First_Name: ['', [Validators.required]],
      Last_Name: ['', [Validators.required]],
      Cust_Name: [''],
      Customer_Mobile_Number: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      Email_Id: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required, Validators.minLength(6)]],
      Cnf_Password: ['', [Validators.required]],
      Gender: ['', [Validators.required]],
      Sub_Area_Id: [''],
      Sub_Area_Name: [''],
      Source: [''],
      Referral_Code: [''],
      Temp_Referral_Code: [''],
      Business_Type_Id: [''],
      Area_Id: [''],
      Area_Name: [''],
      Apartment_Id: [''],
      District_Id: [''],
      District_Name: [''],
      App_Type:['Web']
    }, { validator: this.checkPasswords.bind(this) })

    this.otpForm = this._fb.group({
      Email_Id: [''],
      Customer_Mobile_Number: ['', [Validators.required]],
      OTP: ['', [Validators.required]],
    })
  }



  get f1() {
    return this.form1
  }

  get f() {
    return this.form1.controls;
  }

  getData() {
    this._signUpService.get_district().subscribe((res: DesifarmAck) => {
      this.districts = res.data
      this.updateFormValue()
    })

    this._signUpService.get_source().subscribe((res: any) => {
      this.sources = res.data
    })

    if (this._commonService.getData(environment.locationKey) != null) {
      this.serviceLocation = this._commonService.getData(environment.locationKey)
      this.District_Id = this.serviceLocation.District_Id
      this.Sub_Area_Id = this.serviceLocation.Sub_Area_Id
    }
  }

  onCity(event) {
    let city = this.districts.find(e => e.District_Name == event.target.value)
    if (city) {
      this.form1.patchValue({
        District_Id: city.District_Id,
        District_Name: city.District_Name,
      })
      this.getAreaList(city.District_Id)
    }

    this.form1.controls.Area_Id.reset()
    this.form1.controls.Area_Name.reset()
    this.form1.controls.Sub_Area_Id.reset()
    this.form1.controls.Sub_Area_Name.reset()
  }

  getAreaList(District_Id) {
    this._signUpService.get_area(District_Id).subscribe((res: any) => {
      this.areaList = res.data
    })
  }

  onArea(event) {
    let area = this.areaList.find(e => e.Area_Name == event.target.value)
    if (area) {
      this.form1.patchValue({
        Area_Id: area.Area_Id,
        Area_Name: area.Area_Name,
      })
      this.getSubArea(area.Area_Id)
    } else {
    }
    this.form1.controls.Sub_Area_Id.reset()
    this.form1.controls.Sub_Area_Name.reset()
  }

  getSubArea(Area_Id) {
    this._signUpService.get_sub_area(Area_Id).subscribe((res: any) => {
      this.subAreas = res.data
    })
  }

  onSubArea(event) {
    let subArea = this.subAreas.find(e => e.Sub_Area_Name == event.target.value)
    if (subArea) {
      this.form1.patchValue({
        Sub_Area_Id: subArea.Sub_Area_Id,
        Sub_Area_Name: subArea.Sub_Area_Name
      })
    } else {
      this.form1.patchValue({
        Sub_Area_Id: 0,
        Sub_Area_Name: ""
      })
    }
  }

  ngOnInit(): void {
    this.initForm()
    this.getData()
    this.startCountdown();
  }

  updateFormValue() {
    this.form.patchValue({
      District_Id: this.districts[0].District_Id,
      District_Name: this.districts[0].District_Name,
    })

    this.getAreaList(this.districts[0].District_Id)
  }

  onOtpChange(event) {
    this.otp = event.target.value
  }

  verifyOTP() {
    if (this.otp == this.receivedOTP || this.otp == 662200) {
      this.login()
    } else this._commonService.onFailed("Wrong OTP")
  }

  submit() {
    this.Customer_Mobile_Number = this.form.value.Customer_Mobile_Number
    // this.sendOTP()
    this.login()
  }

  sendOTP() {
    this._commonService._spinner.show()
    this._authService.sendOTP(this.form.value.Customer_Mobile_Number).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.receivedOTP = res.OTP
        this.otpFlag = true
        this.loginFlag = false
        this._commonService.onSuccess(res.msg)
      } else this._commonService.onFailed("Unable to Send OTP")
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

  login() {
    this.Customer_Mobile_Number = this.form.value.Customer_Mobile_Number
    this.form1.patchValue({ Customer_Mobile_Number: this.Customer_Mobile_Number, Business_Type_Id: environment.Business_Type_Id })
    this._commonService._spinner.show()
    this._authService.sign_in(this.form.value).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.loginFlag = false
        console.log(res.data[0].OTP_Verification)
        if (res.data[0].OTP_Verification == 0) {
          this._commonService._spinner.show()
          this._authService.resendOtp({ Email_Id: this.form.value.Email_Id }).subscribe((res: any) => {
            this._commonService._spinner.hide()
            if (res.ack) {
              this._commonService.onSuccess(res.msg, 2000)
              this.otpForm.patchValue({ Customer_Mobile_Number: res.data[0].Customer_Mobile_Number, Email_Id: res.data[0].Email_Id })
              this.Customer_Mobile_Number = res.data[0].Customer_Mobile_Number
              this.getOtp()
            } else {
              this._commonService.onFailed(res.msg, 5000)
            }
          })
        } else {
          this.otpFlag = false
          this._commonService.setUser(res.data[0])
          this._commonService.onSuccess(res.msg, 2000)
          this._dialogRef.close()
        }
      } else {
        this._commonService.onFailed(res.msg, 5000)
        if (res.res_code == -4) {
          this.loginFlag = true
          this.otpFlag = false
          this.signUpFlag = false
        }
      }
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

  passwordToggle() {
    this.passwordType = !this.passwordType
  }

  handleForgetPassword = () => {
    if (this.form.controls.Email_Id.valid) {
      this._commonService._spinner.show()
      this._authService.check_email({ Email_Id: this.form.controls.Email_Id.value }).subscribe((res: any) => {
        this._commonService._spinner.hide()
        if (res.ack) {
          this._commonService.onSuccess(res.msg, 6000)
        } else {
          this._commonService.onFailed(res.msg, 6000)
        }
      }, (err) => {
        this._commonService._spinner.hide()
      })
    } else {
      this._commonService.onFailed("Please Enter Valid Email", 2000)
    }
  }

  onRegister() {
    let data = this.form1.value
    data.Business_Type_Id = environment.Business_Type_Id
    data.Apartment_Id = 0
    data.Sub_Area_Id = data.Sub_Area_Id == null ? 0 : data.Sub_Area_Id
    data.Source = 'Search Engine'
    this.Customer_Mobile_Number = data.Customer_Mobile_Number
    this.otpForm.patchValue({ Customer_Mobile_Number: data.Customer_Mobile_Number, Email_Id: data.Email_Id })
    this._commonService._spinner.show()
    this._authService.sign_up(data).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        // this._commonService.setUser(res.data[0])
        this._commonService.onSuccess(res.msg, 2000)
        // this._dialogRef.close()
        this.getOtp()
      } else this._commonService.onFailed(res.msg)
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

  checkReferCode(event) {

    if (event.target.value.length >= 5) {

      let value = event.target.value.substring(0, 4)
      if (value == "DESI" || value == "desi") {
        this._commonService._spinner.show()
        let referCode = event.target.value
        let code = referCode.slice(4)
        this._authService.check_refer_code(code).subscribe((res: any) => {
          this._commonService._spinner.hide()
          if (res.ack) {
            this.refercodeDetail = res.data[0].Cust_Name
            this._commonService.onSuccess(res.msg)
            this.form1.patchValue({ Referral_Code: code })
          } else {
            this.refercodeDetail = null
            this.form1.controls.Temp_Referral_Code.reset()
            this.form1.controls.Referral_Code.reset()
            this._commonService.onFailed(res.msg)
          }
        }, (err) => {
          this._commonService._spinner.hide()
          this._commonService.onFailed("Internet Connection Failed")
        })
      } else {
        this.refercodeDetail = null
        this._commonService.onFailed("Invalid Refer Code")
        this.form1.controls.Temp_Referral_Code.reset()
        this.form1.controls.Referral_Code.reset()
      }
    } else {
      this.refercodeDetail = null
      this._commonService.onFailed("Invalid Refer Code")
      this.form1.controls.Temp_Referral_Code.reset()
      this.form1.controls.Referral_Code.reset()
    }
  }

  // ConfirmedValidator(controlName: string, matchingControlName: string) {
  //   return (formGroup: FormGroup) => {
  //     const control = formGroup.controls[controlName];
  //     const matchingControl = formGroup.controls[matchingControlName];
  //     if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
  //       return;
  //     }
  //     if (control.value !== matchingControl.value) {
  //       matchingControl.setErrors({ confirmedValidator: true });
  //     } else {
  //       matchingControl.setErrors(null);
  //     }
  //   }
  // }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('Password').value;
    let confirmPass = group.get('Cnf_Password').value
    return pass === confirmPass ? null : { notSame: true }
  }

  getOtp() {
    this.startCountdown();
    this.signUpFlag = false
    this.loginFlag = false
    this.otpFlag = true
  }

  verifyCustomerOTP() {
    this._commonService._spinner.show()
    console.log(this.otpForm.value)
    this._authService.verifyOtp(this.otpForm.value).subscribe((result: any) => {
      if (result.ack) {
        this._commonService._spinner.hide()
        this._commonService.setUser(result.data[0])
        this._commonService.onSuccess(result.msg, 2000)
        this._dialogRef.close()
      } else {
        this._commonService._spinner.hide()
        this._commonService.onFailed(result.msg, 2000)
      }
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

  resendOtp() {
    this.startCountdown();
    this._commonService._spinner.show()
    this._authService.resendOtp({ Email_Id: this.form.value.Email_Id }).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.getOtp()
        this._commonService.onSuccess(res.msg, 2000)
        this.otpForm.patchValue({ Customer_Mobile_Number: res.data[0].Customer_Mobile_Number })
      } else {
        this._commonService.onFailed(res.msg, 5000)
      }
    })
  }

  startCountdown() {
    this.remainingTime = 60;
    this.isResending = true;

    this.countdownSubscription = interval(1000).subscribe(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        this.isResending = false;
        this.countdownSubscription.unsubscribe();
      }
    });
  }

}
