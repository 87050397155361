<div style="background: #ececec; border-top: 2px dashed #ea6d14">
  <div class="container pt-2 pb-4">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <app-desifarm-dashboard-side-menu></app-desifarm-dashboard-side-menu>
      </div>
      <!-- //TODO: Working Code  START-->
      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12" *ngIf="userOrderGroup.length == 0">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <h1 class="tab-heading">My Orders</h1>
              <div class="text-center p-4 pt-0">
                <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-8">
                    <img src="assets/img/dashboard/emptyorder.png" class="img-responsive" />
                  </div>
                </div>

                <h3 class="mt-3" style="font-size: 30px; color: #00679f; font-weight: 500">
                  Empty Orders
                </h3>
                <!-- <p style="font-size: 18px">You have no Orders. Start adding!</p> -->
                <button class="
                    btn
                    btn--box
                    btn--small
                    btn--green
                    btn--green-hover-black
                    btn--uppercase
                    font--semi-bold
                  " routerLink="/">
                  START SHOPPING
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //TODO: Working Code  END-->

      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12" *ngIf="userOrderGroup.length > 0">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <h1 class="tab-heading">My Orders</h1>

              <div class="order-holder mb-0" *ngFor="let userOrder of userOrderGroup">
                <div style="background: #e2e0e0; padding: 10px 5px 0px">
                  <div class="row orderheader">
                    <div class="col-md-8">
                      <span><b>Order No: </b></span>
                      <span>{{ userOrder.Sale_Order_Id }}</span>
                    </div>
                    <div class="col-md-4">
                      <span><b>Status: </b></span>
                      <span [ngClass]="{
                        'delivered-color':userOrder.Orders[0].Order_status == 'Delivered',
                        'cancelled-color':userOrder.Orders[0].Order_status == 'Cancel',
                        'cancelled-color':userOrder.Orders[0].Order_status == 'Pending',
                        'assign-color':userOrder.Orders[0].Order_status == 'Assign',
                        'new-color':userOrder.Orders[0].Order_status == 'New',
                        'new-color':userOrder.Orders[0].Order_status == 'Preparing',
                        'moving-color':userOrder.Orders[0].Order_status == 'Moving'
                      }"><b>{{ userOrder.Orders[0].Order_status }}</b></span>
                    </div>
                  </div>
                  <div class="row order-detail">
                    <div class="col-md-4">
                      <p>
                        <b>Order Placed:</b>
                        {{ userOrder.Orders[0].Order_Date_Time | date: "mediumDate" }}
                        {{ userOrder.Orders[0].Order_Date_Time | date: "shortTime" }}
                      </p>
                    </div>
                    <div class="col-md-4">
                      <p>
                        <b>Order Value:
                          <span style="color: orangered">
                            {{ userOrder.Order_Amount | number : '1.0-2'}}</span>
                        </b>
                      </p>
                    </div>
                    <div class="col-md-4">
                      <a style="
                          color: #0b9bca;
                          text-decoration: underline !important;
                        " (click)="orderDetail(userOrder)">Order Details</a>
                      &nbsp; &nbsp;
                      <a style="
                          color: #0b9bca;
                          text-decoration: underline !important;" (click)="print(userOrder)"
                        *ngIf="userOrder.Orders[0].Order_status == 'Delivered'">Invoice</a>

                    </div>
                    <hr />
                    <div class="col-md-4 firstdiv">
                      <p class="mb-0 headings"><b>Shipping Address</b></p>
                      <p>
                        {{ userOrder.Orders[0].Full_Name }},<br />
                        <span *ngIf="userOrder.Orders[0].Area_Colony_Sreet_Name">
                          {{
                          userOrder.Orders[0].Area_Colony_Sreet_Name
                          }},</span>
                        <span *ngIf="userOrder.Orders[0].Landmark">
                          {{ userOrder.Orders[0].Landmark }}</span>
                        <span *ngIf="userOrder.Orders[0].Pincode">
                          - {{ userOrder.Orders[0].Pincode }},<br /></span>
                        <span *ngIf="userOrder.Orders[0].City">
                          Emirate : {{ userOrder.Orders[0].City }}</span>
                        <span *ngIf="userOrder.Orders[0].Customer_State_Name">, State :
                          {{ userOrder.Orders[0].Customer_State_Name }}</span>
                        <span *ngIf="userOrder.Orders[0].Mobile_No_Address"><br />
                          {{ userOrder.Orders[0].Mobile_No_Address }}</span>
                      </p>
                    </div>
                    <div class="col-md-4 middlediv">
                      <p class="headings"><b>Payment Information</b></p>
                      <p *ngIf="userOrder.Orders[0].Order_status != 'Pending'"> {{ userOrder.Orders[0].Payment_Mode}}
                      </p>
                      <p *ngIf="userOrder.Orders[0].Order_status == 'Pending'"> Payment Pending </p>


                      <p class="headings" *ngIf="userOrder.Orders[0].Order_status != 'Delivered'"><b>Expected
                          Delivery</b></p>
                      <p *ngIf="userOrder.Orders[0].Order_status != 'Delivered'">
                        {{userOrder.Orders[0].Customer_Delivery_Date }} - {{userOrder.Orders[0].Slot_Name}} </p>

                      <p class="headings" *ngIf="userOrder.Orders[0].Order_status == 'Delivered'"><b>Order Delivered
                          On</b></p>
                      <p *ngIf="userOrder.Orders[0].Order_status == 'Delivered'">
                        {{userOrder.Orders[0].Actual_Delivery_Date_Time }}
                        <!-- {{userOrder.Orders[0].Actual_Delivery_Date_Time | date: 'dd-MM-yyyy hh:mm a' }} -->
                      </p>


                      <!-- <p class="headings" *ngIf="userOrder.Orders[0].Payment_Mode != 'COD'"><b>Transaction ID</b></p>
                      <p *ngIf="userOrder.Orders[0].Payment_Mode != 'COD'"> {{ userOrder.Orders[0].Payment_Transaction_ID }} </p> -->
                      <!-- <p style="
                          color: #0b9bca;
                          text-decoration: underline !important;
                          cursor: pointer;
                        " *ngIf="
                          userOrder.Orders[0].Payment_Mode == 'COD' &&
                          userOrder.Orders[0].Order_status != 'Delivered' &&
                          userOrder.Orders[0].Order_status != 'Cancel'
                        " (click)="payOnline(userOrder)">
                        Click Here To Pay Online
                      </p> -->
                      <!-- <p class="mt-2 headings"><b>Selected Slot</b></p> -->
                      <!-- <p
                        (click)="onDelivery(userOrder)"
                        style="cursor: pointer"
                      >
                        {{
                          userOrder.Orders[0].Customer_Delivery_Date
                            | date: "mediumDate"
                        }}
                        -
                        {{ userOrder.Orders[0].Slot_Name }}
                      </p> -->
                      <!-- <p (click)="onDelivery(userOrder)" style="cursor: pointer;">{{userOrder.Orders[0].Slot_Name}}</p> -->
                      <!-- <p
                        (click)="onDelivery(userOrder)"
                        style="cursor: pointer"
                        *ngIf="
                          userOrder.Orders[0].Order_status == 'New' ||
                          userOrder.Orders[0].Order_status == 'Preparing' ||
                          userOrder.Orders[0].Order_status == 'Assign'
                        "
                      >
                        <a
                          style="
                            color: #0b9bca;
                            text-decoration: underline !important;
                          "
                          >Click To Change The Slot</a
                        >
                      </p> -->
                      <p class="mt-2 headings" *ngIf="
                          userOrder.Orders[0].Delivery_Boy_Full_Name != null
                        ">
                        <b>Delivery Boy</b>
                      </p>
                      <p style="cursor: pointer" *ngIf="
                          userOrder.Orders[0].Delivery_Boy_Full_Name != null
                        ">
                        <a style="
                            color: #0b9bca;
                            text-decoration: underline !important;
                          " href="tel:+91{{
                            userOrder.Orders[0].Delivery_Boy_Contact_No1
                          }}">{{ userOrder.Orders[0].Delivery_Boy_Full_Name }} -
                          {{ userOrder.Orders[0].Delivery_Boy_Contact_No1 }}
                        </a>
                      </p>
                    </div>
                    <div class="col-md-4 text-center">
                      <a class="
                          btn
                          btn--small
                          btn--transparent
                          btn--border-green
                          btn--border-green-hover-green
                        " *ngIf="userOrder.Orders[0].Order_status == 'New'"
                        (click)="toggleCancelFlag(userOrder)">Cancel Order</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Order Modal Start -->
<ng-container *ngIf="isOrderCancelFlag">
  <div style="
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1050;
      overflow: hidden;
      outline: 0;
      backdrop-filter: blur(1px);
    ">
    <div class="my-location-modal" style="
        position: fixed;
        top: 10%;
        left: 25%;
        width: 53%;
        height: 85%;
        z-index: 1050;
        overflow: hidden;
        outline: 0;
      " role="dialog">
      <div class="modal-content resmodal">
        <div class="modal13" style="overflow: auto">
          <div class="modal-body" style="padding: 0">
            <div class="container-fluid">
              <div class="row locationmodal">
                <div class="row">
                  <div class="col-md-12" style="text-align: right; z-index: 999; margin-top: 11px">
                    <span aria-hidden="true" class="closecart" style="
                        font-size: 40px !important;
                        color: gray !important;
                        text-align: right !important;
                        cursor: pointer;
                      " (click)="onClose()">×</span>
                  </div>
                  <div class="col-md-12">
                    <h3 style="
                        margin-top: -35px;
                        color: #e6531b;
                        font-weight: 500;
                        font-size: 18px;
                        font-family: 'MarlinGeo';
                      ">
                      Are you sure you want to cancel this order ?
                    </h3>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="container-fluid pb-2" style="border: 1px solid lightgray; font-family: 'MarlinGeo'">
                    <div class="row" style="
                        background: lightgray;
                        padding-top: 5px;
                        margin-right: -15px;
                        margin-left: -15px;
                      ">
                      <div class="col-md-3">
                        <p>
                          <b>Order No: </b>{{ orderCancelDetail.Sale_Order_Id }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <p>
                          <b>Order Placed:</b>
                          {{
                          orderCancelDetail.Orders[0].Order_Date_Time
                          | date: "mediumDate"
                          }}
                          {{
                          orderCancelDetail.Orders[0].Order_Date_Time
                          | date: "shortTime"
                          }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Order Status: </b>
                          {{ orderCancelDetail.Orders[0].Order_status }}
                        </p>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <!-- <div class="col-lg-10 col-md-8">
                        <p><b>Items Ordered</b></p>
                      </div>
                      <div class="col-lg-2 col-md-4">
                        <p><b>Order Value</b></p>
                      </div>
                      <hr>
                      <div class="col-lg-10 col-md-8">
                        <p><b>Mango Milk</b></p>
                        <p><b>Shrikhand </b></p>
                        <p><b>Panner Desi Farms</b></p>
                      </div>
                      <div class="col-lg-2 col-md-4">
                        <p><b> 438.00</b></p>
                      </div> -->
                      <div class="col-md-12">
                        <form [formGroup]="form">
                          <textarea formControlName="Order_Cancelation_Reason" class="form-control" rows="4" cols="80"
                            placeholder="Reason for cancellation">
                          </textarea>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2 mb-4" style="text-align: right">
                    <a class="btn cancelbtn btn--black btn--black-hover-green" (click)="submit()">Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- Cancel Order Modal End -->

<!-- <div class="card card-custom" style="display: none">
  <div class="card-body table-body">
    <div class="aaa" id="invoice">
      <br />
      <div class="row justify-content-center">
        <div class="col-md-2">
          <img src="../../../../assets/img/logo/desi.png" alt="" style="width: 82%" />
        </div>
        <div class="col-md-8">
          <h1 style="color: black">
            Desifarms
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            +91-9260605353
          </h1>
          <p>
            SNA Milk & Milk Products Pvt. Ltd. Plot M-25, MIDC Ahmednagar,
            Maharashtra – 414111
          </p>
        </div>
      </div>
      <br />
      <div class="row justify-content-center">
        <div class="col-md-3">
          <p style="color: black">
            Name : {{ printInvoiceData?.Orders[0]?.Full_Name | titlecase }}
          </p>
        </div>
        <div class="col-md-3">
          <p style="color: black">
            Contact No.: {{ printInvoiceData?.Orders[0]?.Mobile_No_Address }}
          </p>
        </div>
        <div class="col-md-3">
          <p style="color: black">
            Payment Mode: {{ printInvoiceData?.Orders[0]?.Payment_Mode }}
          </p>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <p style="color: black">
            Order Date & Time :
            {{
            printInvoiceData?.Orders[0]?.Order_Date_Time[0] | date: "medium"
            }}
          </p>
        </div>
        <div class="col-md-3">
          <p style="color: black">
            No. Of Item : {{ printInvoiceData?.Orders.length }}
          </p>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-10">
          <table class="table">
            <tr>
              <td class="text-center">Sr.No</td>
              <td class="text-center">Items</td>
              <td class="text-center">Packing</td>
              <td class="text-center">MRP</td>
              <td class="text-center">Disc %</td>
              <td class="text-center">Disc Amt</td>
              <td class="text-center">Selling Amt</td>
              <td class="text-center">Qty</td>
              <td class="text-center">Net Amt</td>
            </tr>
            <tr *ngFor="let option of printInvoiceData?.Orders; let i = index">
              <td class="text-center">{{ i + 1 }}</td>
              <td class="text-center">{{ option.Product_Name }}</td>
              <td class="text-center">
                {{ option.Package_Qty }} {{ option.Unit_Name }}
              </td>
              <td class="text-center">
                {{ option.Product_Package_MRP  }}
              </td>
              <td class="text-center">{{ option.Discount_Per }}</td>
              <td class="text-center">
                {{ option.Total_Discount_Amount   }}
              </td>
              <td class="text-center">
                {{ option.Selling_Amt  }}
              </td>
              <td class="text-center">{{ option.Order_Qty }}</td>
              <td class="text-center">
                {{ option.Order_Qty * option.Selling_Amt  }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-3">
          <p style="color: black">
            <b>Delivery Address :</b> <br />
            {{ printInvoiceData?.Orders[0]?.Flat_House_No }}
            {{ printInvoiceData?.Orders[0]?.Area_Colony_Sreet_Name }}
            {{ printInvoiceData?.Orders[0]?.Area_Name }}
            {{ printInvoiceData?.Orders[0]?.Landmark }}
            {{ printInvoiceData?.Orders[0]?.Pincode }} <br />
            <b>Sub Area : </b>{{ printInvoiceData?.Orders[0]?.Sub_Area_Name }}
          </p>
        </div>
        <div class="col-md-4">
        </div>
        <div class="col-md-3">
          <p style="color: black; text-align: right">
            <b>Sub Total :
              {{ printInvoiceData?.Order_Amount }}</b>
          </p>

          <p style="color: black; text-align: right">
            <b>You Save : {{ totalDiscount  }}</b>
          </p>
          <p style="color: black; text-align: right" *ngIf="printInvoiceData?.Orders[0]?.Order_Delivery_Charges > 0">
            <b>Delivery Charges :
              {{ Order_Delivery_Charges }}</b>
          </p>
          <p style="color: black; text-align: right">
            <b>Payable Amount :
              {{
              printInvoiceData?.Order_Amount + Order_Delivery_Charges
               
              }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="card card-custom" style="display: none;">
  <div class="card-body table-body">
    <div class="aaa" id="invoice">
      <br>
      <div class="row justify-content-center">
        <div class="col-md-4">
          <img src="http://nasiktrade.com/assets/img/logo/desi.png" alt="" style="max-width: 342px;
            max-height: 167px;
            margin: -45px -2px -3px -19px" />
        </div>
        <div class="col-md-8">
          <h1 style="color: #db5a00;
            font-size: 28px;
            font-weight: 800;
            margin: 0px 0px 0px -47px;">
            NASIK FRUITS & VEGETABLE TRADING (L.L.C.)
          </h1>
          <!-- &nbsp;&nbsp;  -->
          <p style="margin: 7px 0px -2px -21px; font-size: 15px;">Shop No. 21 - Block No 1
            Central Fruit & Veg. Market, Al Aweer, Dubai - U.A.E. .</p>
          <p style="margin: 7px 2px -3px -57px; font-size: 15px;"> Tel. : 04-3202200, Fax :
            04-3202200, Mob. : 056-6832100, 050-4989108, P.O. Box : 294680</p>
          <p style="margin: 7px 0px 0px -18px;
            font-size: 15px;">Email : sales@nasiktrade.com, nasiktrade@gmail.com,
            www.nasiktrade.com</p>
        </div>

      </div>
      <div class="row justify-content-center" style="margin: 33px 0px 0px 0px;">
        <div class="col-md-4" style="background-color: #db5a00;
        height: 9px;"></div>
        <div class="col-md-4" style="text-align: center;
        font-size: 19px;
        margin: -3px 0px 0px 0px;"><b>TAX INVOICE</b> CASH/CREDIT</div>
        <div class="col-md-4" style="background-color: #db5a00;
        height: 9px;"></div>
        <div class="col-md-4" style="background-color: #db5a00;
        height: 5px;
        margin: -13px 0px 0px 0px;"></div>
        <div class="col-md-4" style="text-align: center;
        font-size: 19px;
        margin: -8px 0px 0px 0px;"></div>
        <div class="col-md-4" style="background-color: #db5a00;
        height: 5px;
        margin: -13px 0px 0px 0px;"></div>
    </div>
      <br />

      <div class="row justify-content-center">
        <div class="col-md-3">
          <p style="color: black">
            To <br>
            <b>{{ printInvoiceData?.Orders[0]?.Full_Name | titlecase}} </b> <br>
            {{ printInvoiceData?.Orders[0]?.Flat_House_No }},
            {{ printInvoiceData?.Orders[0]?.Area_Colony_Sreet_Name }},
            {{ printInvoiceData?.Orders[0]?.Landmark }},
            {{ printInvoiceData?.Orders[0]?.Pincode }} <br />
            Emirate : <b>{{ printInvoiceData?.Orders[0]?.City }}</b> Area : <b> {{
              printInvoiceData?.Orders[0]?.Sub_Area_Name }} {{
              printInvoiceData?.Orders[0]?.Area_Name }}</b> <br>
            <b> Contact No.: {{ printInvoiceData?.Orders[0]?.Mobile_No_Address }}</b> <br>
          </p>
        </div>
        <div class="col-md-2">
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-3">
          <p style="color: black">
            Date: {{ printInvoiceData?.Orders[0]?.Order_Date_Time[0] | date:"dd-MMM-yyyy" }} <br>
            <b>Order No .: {{printInvoiceData?.Orders[0]?.Sale_Order_Id}}</b> <br>
            Payment Mode: <b>{{ printInvoiceData?.Orders[0]?.Payment_Mode }}</b><br>
            TRN : <b>100287655300003</b>
          </p>
        </div>
      </div>


      <div class="row justify-content-center">
        <div class="col-md-10">
          <table class="table">
            <tr>
              <td class="text-center">Sr.No</td>
              <td class="text-center">Items</td>
              <td class="text-center">Packing</td>
              <td class="text-center">MRP</td>
              <td class="text-center">Disc %</td>
              <td class="text-center">Disc Amt</td>
              <td class="text-center">Selling Amt</td>
              <td class="text-center">Qty</td>
              <td class="text-center">Net Amt</td>
            </tr>
            <tr *ngFor="let option of printInvoiceData?.Orders; let i = index">
              <td class="text-center">{{ i + 1 }}</td>
              <td class="text-center">{{ option.Product_Name }}</td>
              <td class="text-center">
                {{ option.Package_Qty }} {{ option.Unit_Name }}
              </td>
              <td class="text-center">
                {{ option.Product_Package_MRP | number:'1.2-2' }}
              </td>
              <td class="text-center">{{ option.Discount_Per | number:'1.2-2'}}</td>
              <td class="text-center">
                {{ option.Total_Discount_Amount | number:'1.2-2'}}
              </td>
              <td class="text-center">
                {{ option.Selling_Amt | number:'1.2-2'}}
              </td>
              <td class="text-center">{{ option.Order_Qty }}</td>
              <td class="text-center">
                {{ option.Order_Qty * option.Selling_Amt | number:'1.2-2'}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-5">
        </div>


        <div class="col-md-5">
          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">Base Amt : {{ Base_Amt | number:'1.2-2'}}</b>
          </p>
          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">VAT Amt : {{ VAT_Amt | number:'1.2-2'}}</b>
          </p>
          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">Sub Total : {{ Sub_Total_Amount | number:'1.2-2'}}</b>
          </p>

          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">You Save : {{ Total_Discount_Amount | number:'1.2-2'}}</b>
          </p>
          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">Delivery Charges :
              {{ Order_Delivery_Charges | number:'1.2-2'}}</b>
          </p>

          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">Sub Total + Delivery :
              {{ SubTotalPlusDeliveryCharges | number:'1.2-2'}}</b>
          </p>

          <p style="color: black; text-align: right" *ngIf="printInvoiceData?.Orders[0]?.Wallet_Amount_Used != 0">
            <b style="padding: 5px 6px;">Wallet Redeem (-) :
              {{ printInvoiceData?.Orders[0]?.Wallet_Amount_Used | number:'1.2-2'}}</b>
          </p>


          <p style="color: black; text-align: right;">
            <b style="border: 1px solid black;
            padding: 5px 6px;    font-size: initial;">Payable Amount :
              {{ printInvoiceData?.Order_Amount | number:'1.2-2'}}</b>
          </p>
          
        </div>
        
      </div>
      <div class="footer row justify-content-center" style="margin-top: 44px;">
        <div class="col-md-12" style="background-color: black;
        height: 3px;
        position: sticky;">
         
        </div>
    </div>
    <div class="footer row justify-content-center" style="margin-top: 32px; font-size: 15px;">
        <div class="col-md-5">
            <div style="margin: 145px 1px 1px 8px;">
                <p><b>Prepared By</b></p>
                <p>For NASIK FRUITS & VEGETABLE TRADING (L.L.C.)</p>
            </div>
        </div>
        <div class="col-md-2">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAXPPLrx1LloV62vflo60UzND0-FgpVNu_pw&usqp=CAU" alt=""
            style="    height: 146px;">
        </div>
        <div class="col-md-5">
            <ul>
                <li>Received the above goods order and conditions</li>
                <li>Payment by bank transfer or A/C payee crossed chqs in favor of NASIK FRUITS & VEGETABLE TRADING L.L.C.</li>
                <li>Standerd VAT rate applied as per UAE FTA law</li>
            </ul>
            <p><b>Received By </b> </p>
            <div class="row">
                <div class="col-md-6">Signature: <span><hr style="margin: -4px 0px 0px 74px;
                    width: 149px;"></span></div>
                <div class="col-md-6">Name: <span><hr style="margin: -5px 0px 0px 50px;
                    width: 160px;
                "></span></div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-4" style="background-color: #db5a00;
        height: 29px;"></div>
        <div class="col-md-4" style="background-color: green;
        height: 29px;"></div>
        <div class="col-md-4" style="background-color: #db5a00;
        height: 29px;"></div>
    </div>
    </div>
  </div>
</div>