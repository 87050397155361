import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmMyordersService {

  constructor(private http: HttpClient) { }

  my_orders(Cust_Id): Observable<any> {
    return this.http.get<any>(environment.url + 'my_orders/' + Cust_Id + '/' + environment.Business_Type_Id)
  }

  update_delivery_slot_from_customer(data): Observable<any> {
    return this.http.post<any>(environment.url + 'update_delivery_slot_from_customer', data)
  }

  cancel_order_from_customer(data): Observable<any> {
    return this.http.post<any>(environment.url + 'cancel_order_from_customer', data)
  }

  customer_pay_online(data): Observable<any> {
    return this.http.post<any>(environment.url + 'customer_pay_online', data)
  }
}
