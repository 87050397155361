import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './modules/app-routing.module'
import { MaterialModule } from './modules/material.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { AppComponent } from './app.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxOtpInputModule } from 'ngx-otp-input';
// import { NgSelect2Module } from 'ng-select2';
import { IvyCarouselModule } from 'angular-responsive-carousel';


import { DesifarmsHomepageComponent } from './components/desifarms-homepage/desifarms-homepage.component';
import { DesifarmsContactComponent } from './components/desifarms-contact/desifarms-contact.component';
import { DesifarmsHeaderfooterComponent } from './components/desifarms-headerfooter/desifarms-headerfooter.component';
import { DesifarmsAboutComponent } from './components/desifarms-about/desifarms-about.component';
import { DesifarmsShopallitemsComponent } from './components/desifarms-shopallitems/desifarms-shopallitems.component';
import { DesifarmsProductdetailsComponent } from './components/desifarms-productdetails/desifarms-productdetails.component';
import { DesifarmsMycartComponent } from './components/desifarms-mycart/desifarms-mycart.component';
import { DesifarmsMywhishlistComponent } from './components/desifarms-mywhishlist/desifarms-mywhishlist.component';
import { DesifarmsMyaccountComponent } from './components/desifarms-myaccount/desifarms-myaccount.component';
// import { DesifarmsLoginComponent } from './components/desifarms-login/desifarms-login.component';
import { DesifarmsCheckoutComponent } from './components/desifarms-mycart/desifarms-checkout/desifarms-checkout.component';
import { DesifarmsCompareproductComponent } from './components/desifarms-compareproduct/desifarms-compareproduct.component';
import { ProductSlidersDirective } from './directives/product-details/product-sliders/product-sliders.directive';
import { HeroSliderDirective } from './directives/home-page/hero-slider/hero-slider.directive';
import { ProductSliderDirective } from './directives/home-page/product-slider/product-slider.directive';
import { NewProductDirective } from './directives/home-page/new-product/new-product.directive';
import { TestimonialSliderDirective } from './directives/home-page/testimonial-slider/testimonial-slider.directive';
import { BlogNewsDirective } from './directives/home-page/blog-news/blog-news.directive';
import { CompanySliderDirective } from './directives/home-page/company-slider/company-slider.directive';
import { ProductImageZoomDirective } from './directives/product-details/product-image-zoom/product-image-zoom.directive';
import { DesifarmLoginRegisterComponent } from './components/desifarm-login-register/desifarm-login-register.component';
import { DesifarmPaginationComponent } from './components/desifarm-pagination/desifarm-pagination.component';
import { DesifarmProductDetailsComponent } from './components/desifarms-shopallitems/desifarm-product-details/desifarm-product-details.component';
import { DesifarmAddressComponent } from './components/desifarms-mycart/desifarms-checkout/desifarm-address/desifarm-address.component';
import { DesifarmsDashboardComponent } from './components/desifarms-dashboard/desifarms-dashboard.component';
import { DesifarmsMyprofileComponent } from './components/desifarms-dashboard/desifarms-myprofile/desifarms-myprofile.component';
import { DesifarmsManageaddressComponent } from './components/desifarms-dashboard/desifarms-manageaddress/desifarms-manageaddress.component';
import { DesifarmsMywalletComponent } from './components/desifarms-dashboard/desifarms-mywallet/desifarms-mywallet.component';
import { DesifarmsSubscriptionsComponent } from './components/desifarms-dashboard/desifarms-subscriptions/desifarms-subscriptions.component';
import { DesifarmsMyordersComponent } from './components/desifarms-dashboard/desifarms-myorders/desifarms-myorders.component';
import { DesifarmDashboardSideMenuComponent } from './components/desifarms-dashboard/desifarm-dashboard-side-menu/desifarm-dashboard-side-menu.component';
import { DesifarmSearchProductsComponent } from './components/desifarm-search-products/desifarm-search-products.component';
import { DesifarmsMyorderDetailsComponent } from './components/desifarms-dashboard/desifarms-myorders/desifarms-myorder-details/desifarms-myorder-details.component';
import { DesifarmsDeliveryTimeslotComponent } from './components/desifarms-mycart/desifarms-checkout/desifarms-delivery-timeslot/desifarms-delivery-timeslot.component';
import { DatePipe } from '@angular/common';
import { DesifarmsOrderSuccessComponent } from './components/desifarms-mycart/desifarms-checkout/desifarms-order-success/desifarms-order-success.component';
import { DesifarmsOurstoreComponent } from './components/desifarms-ourstore/desifarms-ourstore.component';
import { DesifarmsFranchiseoutletComponent } from './components/desifarms-ourstore/desifarms-franchiseoutlet/desifarms-franchiseoutlet.component';
import { DesifarmsCareerComponent } from './components/desifarms-career/desifarms-career.component';
import { DesifarmsBlogdetailsComponent } from './components/desifarms-blogdetails/desifarms-blogdetails.component';
import { DesifarmsBlogsComponent } from './components/desifarms-blogs/desifarms-blogs.component';
import { DesifarmsShippingPolicyComponent } from './components/desifarms-shipping-policy/desifarms-shipping-policy.component';
import { DesifarmsPrivacypolicyComponent } from './components/desifarms-privacypolicy/desifarms-privacypolicy.component';
import { DesifarmsRefundpolicyComponent } from './components/desifarms-refundpolicy/desifarms-refundpolicy.component';
import { DesifarmsTermconditionComponent } from './components/desifarms-termcondition/desifarms-termcondition.component';
import { DesifarmComplaintAndSuggestionComponent } from './components/desifarm-complaint-and-suggestion/desifarm-complaint-and-suggestion.component';
import { DesifarmWalletTopupComponent } from './components/desifarms-dashboard/desifarms-mywallet/desifarm-wallet-topup/desifarm-wallet-topup.component';
import { DesifarmReferAndEarnComponent } from './components/desifarms-dashboard/desifarm-refer-and-earn/desifarm-refer-and-earn.component';
import { DesifarmsTestimonialComponent } from './components/desifarms-testimonial/desifarms-testimonial.component';
import { RoundoffPipe } from './shared/pipes/roundoff.pipe';
import { DesifarmProductSubscribeDetailComponent } from './components/desifarms-shopallitems/desifarm-product-subscribe-detail/desifarm-product-subscribe-detail.component';
import { DesifarmSubscribeCartComponent } from './components/desifarm-subscribe-cart/desifarm-subscribe-cart.component';
import { DesifarmSubscribeWalletTopupComponent } from './components/desifarm-subscribe-cart/desifarm-subscribe-wallet-topup/desifarm-subscribe-wallet-topup.component';
import { OnlynumbersDirective } from './directives/numbers/onlynumbers.directive';
import { NthdaySelectionDirective } from './directives/nthdays/nthday-selection.directive';
import { DesifarmMySubscriptionComponent } from './components/desifarms-dashboard/desifarm-my-subscription/desifarm-my-subscription.component';
import { DesifarmOnlineInvoiceComponent } from './components/desifarm-online-invoice/desifarm-online-invoice.component';
import { DesifarmNewinvoiceComponent } from './components/desifarm-newinvoice/desifarm-newinvoice.component';
import { NskLoginComponent } from './components/nsk-login/nsk-login.component';
import { ColorChangerDirective } from './directives/color-changer/color-changer.directive';
import { NoSpaceDirective } from './directives/no-space.directive';
import { DesifarmsPayOnlineComponent } from './components/desifarms-mycart/desifarms-checkout/desifarms-pay-online/desifarms-pay-online.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';


@NgModule({
  declarations: [
    AppComponent,
    DesifarmsHomepageComponent,
    DesifarmsContactComponent,
    DesifarmsHeaderfooterComponent,
    DesifarmsAboutComponent,
    DesifarmsShopallitemsComponent,
    DesifarmsProductdetailsComponent,
    DesifarmsMycartComponent,
    DesifarmsMywhishlistComponent,
    DesifarmsMyaccountComponent,
    // DesifarmsLoginComponent,
    DesifarmsCheckoutComponent,
    DesifarmsCompareproductComponent,
    ProductSlidersDirective,
    HeroSliderDirective,
    ProductSliderDirective,
    NewProductDirective,
    TestimonialSliderDirective,
    BlogNewsDirective,
    CompanySliderDirective,
    ProductImageZoomDirective,
    DesifarmLoginRegisterComponent,
    DesifarmPaginationComponent,
    DesifarmProductDetailsComponent,
    DesifarmAddressComponent,
    DesifarmsDashboardComponent,
    DesifarmsMyprofileComponent,
    DesifarmsManageaddressComponent,
    DesifarmsMywalletComponent,
    DesifarmsSubscriptionsComponent,
    DesifarmsMyordersComponent,
    DesifarmDashboardSideMenuComponent,
    DesifarmSearchProductsComponent,
    DesifarmsMyorderDetailsComponent,
    DesifarmsDeliveryTimeslotComponent,
    DesifarmsOrderSuccessComponent,
    DesifarmsOurstoreComponent,
    DesifarmsFranchiseoutletComponent,
    DesifarmsCareerComponent,
    DesifarmsBlogdetailsComponent,
    DesifarmsBlogsComponent,
    DesifarmsShippingPolicyComponent,
    DesifarmsPrivacypolicyComponent,
    DesifarmsRefundpolicyComponent,
    DesifarmsTermconditionComponent,
    DesifarmComplaintAndSuggestionComponent,
    DesifarmWalletTopupComponent,
    DesifarmReferAndEarnComponent,
    DesifarmsTestimonialComponent,
    RoundoffPipe,
    DesifarmProductSubscribeDetailComponent,
    DesifarmSubscribeCartComponent,
    DesifarmSubscribeWalletTopupComponent,
    OnlynumbersDirective,
    NthdaySelectionDirective,
    DesifarmMySubscriptionComponent,
    DesifarmOnlineInvoiceComponent,
    DesifarmNewinvoiceComponent,
    NskLoginComponent,
    ColorChangerDirective,
    NoSpaceDirective,
    DesifarmsPayOnlineComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    NgxScrollTopModule,
    CarouselModule,
    NgxImageZoomModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxOtpInputModule,
    // NgSelect2Module,
    IvyCarouselModule
  ],
  providers: [
    DesifarmSearchProductsComponent,
    DesifarmsShopallitemsComponent,
    DatePipe,
    DesifarmsMyordersComponent,
    DesifarmsHeaderfooterComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
