<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb__menu">
          <li class="page-breadcrumb__nav" routerLink="">
            <a routerLink="/">Home</a>
          </li>
          <li class="page-breadcrumb__nav active">{{ Category_Name }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- ::::::  End  Breadcrumb Section  ::::::  -->

<!-- :::::: Start Main Container Wrapper :::::: -->
<main id="main-container" class="main-container">
  <div class="container">
    <div class="row flex-column-reverse flex-lg-row">
      <!-- Start Leftside - Sidebar Widget -->
      <div class="col-lg-3 col-md-4 myfisrtsidebar">
        <div class="sidebar">
          <!-- Start Single Sidebar Widget - Filter [Catagory] -->
          <div class="sidebar__widget">
            <div class="sidebar__box">
              <h5 class="sidebar__title">PRODUCT CATEGORIES</h5>
            </div>
            <ul class="sidebar__menu">
              <li>
                <ul class="sidebar__menu-collapse">

                  <!-- <li class="sidebar__menu-collapse-list">
                    <div class="accordion">
                      <a class="accordion__title category_list">
                        <span (click)="getAllProduct()">All Product</span>
                        <i class="far fa-chevron-down"></i></a>

                    </div>
                  </li> -->
                  <li class="sidebar__menu-collapse-list" *ngFor="let option of categoriesList">
                    <div class="accordion">
                      <a class="accordion__title category_list"
                        [class.collapsed]="option.Category_Name == tempMainCategory" data-toggle="collapse"
                        [attr.data-target]="'#maincategories' + option.Main_Category_Id"
                        [attr.aria-expanded]="option.Category_Name == tempMainCategory ? 'true' : 'false' ">
                        <span (click)="onCategoryExpand(option)">{{ option.Category_Name }}</span>
                        <i class="far fa-chevron-down"></i></a>
                      <div [id]="'maincategories' + option.Main_Category_Id" class="collapse"
                        [class.show]="option.Category_Name == tempMainCategory">
                        <ul class="accordion__category-list">
                          <li *ngFor="
                              let option1 of option.All_Sub_Categories_List
                            ">
                            <a style="
                                font-size: 16px;
                                font-weight: 500;
                                color: #707475;
                              " (click)="onSubCategories(option1)">{{ option1.Sub_Category_Name }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- End SSingle Sidebar Widget - Filter [Catagory] -->

          <!-- ::::::  Start Single Sidebar Widget - Filter [Size] ::::::  -->
          <!-- <div class="sidebar__widget">
                        <div class="sidebar__box">
                            <h5 class="sidebar__title">Size</h5>
                        </div>
                        <ul class="sidebar__size list-space--small">
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">S</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">M</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">L</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">XL</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">XXL</a></li>
                        </ul>
                    </div>  -->
          <!-- ::::::  Start ingle Sidebar Widget - Filter [Size]  ::::::  -->

          <!-- Start ingle Sidebar Widget - Filter [Color] -->
          <!-- <div class="sidebar__widget">
                        <div class="sidebar__box">
                            <h5 class="sidebar__title">FILTER BY Color</h5>
                        </div>
                        <div class="sidebar__color-filter ">
                            <label class="product-color"><input type="checkbox" class="product-color-select"
                                    value="color-red"><span></span></label>
                            <label class="product-color"><input type="checkbox" class="product-color-select"
                                    value="color-green" checked=""><span></span></label>
                            <label class="product-color"><input type="checkbox" class="product-color-select"
                                    value="color-blue"><span></span></label>
                        </div>
                    </div>  -->
          <!-- End ingle Sidebar Widget - Filter [Color] -->

          <!-- Start Single Sidebar Widget - Filter [Brand] -->
          <!-- <div class="sidebar__widget">
                        <div class="sidebar__box m-t-40">
                            <h5 class="sidebar__title">FILTER BY Brand</h5>
                        </div>
                        <ul class="sidebar__menu-filter ">
                            
                            <li class="sidebar__menu-filter-list">
                                <label for="graphic-corner"><input type="checkbox" name="brand" value="graphic-corner"
                                        id="graphic-corner"><span>Graphic Corner</span></label>
                            </li> 
                            
                            <li class="sidebar__menu-filter-list">
                                <label for="studio-design"><input type="checkbox" name="brand" value="studio-design"
                                        id="studio-design"><span>Studio Design</span></label>
                            </li> 
                            
                        </ul>
                    </div>  -->

          <!-- Start Single Sidebar Widget - Filter [Price] -->
          <!-- <div class="sidebar__widget">
                        <div class="sidebar__box">
                            <h5 class="sidebar__title">FILTER BY Price ($)</h5>
                        </div>
                        <div class="sidebar__price-filter ">
                            <div id="slider-range" class="price-filter-range"></div>
                            <div class="slider__price-filter-input d-flex justify-content-between">
                                <input type="number" min=0 max="9900" oninput="validity.valid||(value='0');"
                                    id="min_price" class="price-range-field" />
                                <input type="number" min=0 max="10000" oninput="validity.valid||(value='1000');"
                                    id="max_price" class="price-range-field" />
                            </div>
                        </div>
                    </div>  -->
          <!-- Start Single Sidebar Widget - Filter [Price] -->

          <!-- ::::::  Start Sidebar Banner Widget  ::::::  -->
          <!-- <div class="sidebar__widget">
                        <div class="row">
                            <div class="col-12">
                                <div class="sidebar__banner">
                                    <a routerLink="/home/productdetails" class="banner__link text-center">
                                        <img class="img-fluid" src="assets/img/banner/img-sidebar.jpg" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>  -->
          <!-- ::::::  End Sidebar Banner Widget  ::::::  -->

          <!-- ::::::  Start Sidebar Widget - Top Product   ::::::  -->
          <!-- <div class="sidebar__widget">
                        <div class="sidebar__box">
                            <h5 class="sidebar__title">TOP RATED PRODUCTS</h5>
                        </div>
                        <ul class="sidebar__post-product list-space--medium ">
                            <li class="d-flex align-items-center">
                                <a routerLink="/home/productdetails" class="sidebar__product-img img-responsive">
                                    <img class="product__img img-fluid" src="assets/img/product/d6.jpg" alt="">
                                </a>
                                <div class="product__content">
                                    <a routerLink="/home/productdetails" class="product__link">Fresh Eggs</a>

                                    <div class="product__price">
                                        <span class="product__price">$10.00</span>
                                    </div>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <a routerLink="/home/productdetails" class="sidebar__product-img img-responsive">
                                    <img class="product__img img-fluid" src="assets/img/product/d4.jpg" alt="">
                                </a>
                                <div class="product__content">
                                    <a routerLink="/home/productdetails" class="product__link">Fresh Fruits</a>

                                    <div class="product__price">
                                        <span class="product__price">$17.00</span>
                                    </div>
                                </div> 
                            </li>
                            <li class="d-flex align-items-center">
                                <a routerLink="/home/productdetails" class="sidebar__product-img img-responsive">
                                    <img class="product__img img-fluid" src="assets/img/product/d2.jpg" alt="">
                                </a>
                                <div class="product__content">
                                    <a routerLink="/home/productdetails" class="product__link">Natural Juice</a>

                                    <div class="product__price">
                                        <span class="product__price">$25.00</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>  -->
          <!-- ::::::  End Sidebar Widget - Top Product  ::::::  -->

          <!-- ::::::  Start Sidebar Widget - Tag  ::::::  -->
          <!-- <div class="sidebar__widget">
                        <div class="sidebar__box">
                            <h5 class="sidebar__title">Tags</h5>
                        </div>
                        <ul class="sidebar__tag list-space--small">
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">blue</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">gold</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">gray</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">green</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">maroon</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">red</a></li>
                            <li><a class="btn btn--box btn--tiny btn--radius-tiny btn--border-gray btn--border-gray-hover-green"
                                    href="#">white</a></li>
                        </ul>
                    </div> -->
          <!-- ::::::  Start Sidebar Widget - Tag  ::::::  -->
        </div>
      </div>
      <!-- End Left Sidebar Widget -->

      <!-- Start Rightside - Product Type View -->
      <div class="col-lg-9 col-md-8">
        <!-- ::::::  Start Sort Box Section  ::::::  -->
        <div class="sort-box m-t-40">
          <div class="sort-box-item">
            <div class="sort-box__tab">
              <ul class="sort-box__tab-list nav">
                <li>
                  <a class="sort-nav-link active" data-toggle="tab" href="#sort-grid"><i class="fas fa-th"></i>
                  </a>
                </li>
                <li>
                  <a class="sort-nav-link" data-toggle="tab" href="#sort-list"><i class="fas fa-list-ul"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <!-- Start Sort Left Side -->

          <div class="sort-box-item d-flex align-items-center flex-warp">

          </div>

          <div class="sort-box-item">
            <!-- <span>Showing {{local_product_count}} of {{product_count}} result</span> -->
            <span>{{ product_count }} Products</span>
            <!-- <span>Showing {{startPageNo}} - {{endPageNo}} of {{product_count}} result</span> -->
          </div>
        </div>

        <!-- ::::::  Start Sort Box Section  ::::::  -->

        <div class="product-tab-area">
          <div class="tab-content tab-animate-zoom">
            <div class="tab-pane show active shop-grid" id="sort-grid" *ngIf="product_count > 0">
              <div class="row">
                <div class="product__box col-md-4 col-xs-6 mt-1" style="margin: 10px" *ngFor="let option of products">
                  <!-- Start Single Default Product -->
                  <div class="product__default--singles d-none d-sm-block">
                    <div>


                      <!-- Start Product Image -->
                      <div class="pos-relative text-center pb-1">
                        <a class="product__img--link">
                          <img #elevatezoomBig [attr.data-zoom-image]="option.product_image"
                            class="product__img img-fluid" [src]="option.product_image" [alt]="option.product_name" 
                            [alt]="option.product_name" />
                        </a>

                        <p *ngIf="option.package_highlight !=''" class="mb-0 heading-for-highlighter">
                          {{option.package_highlight}}</p>

                        <!-- Start Product Action Link-->
                        <ul class="product__action--link pos-absolute">
                          <!-- <li><a href="#modalAddCart" data-toggle="modal"><i
                                                            class="icon-shopping-cart"></i></a></li> -->
                          <!-- <li (click)="onProductDetail(option)">
                                                    <a href="#modalQuickView" data-toggle="modal"><i
                                                            class="icon-eye"></i>
                                                    </a>
                                                </li> -->
                          <li (click)="onProductDetail(option)">
                            <a><i class="icon-eye"></i> </a>
                          </li>
                        </ul>
                        <!-- End Product Action Link -->
                      </div>
                    </div>
                    <!-- Start Product Content -->
                    <div class="product__content">
                      <div style="height: 60px">
                        <!-- <p
                          class="mb-0 graycolor"
                          style="font-size: 17px; margin-bottom: 7px !important"
                        >
                          {{ option.brand_name }}
                        </p> -->
                        <a class="product__link mt-0 mb-0">{{ option.product_name }} </a>
                        <p class="graycolor color-primary mb-4">
                          {{ option.brand_name }}
                        </p>
                      </div>
                      <div style="height: 40px">
                        <div class="product__price m-t-5">
                          <span class="product__price">{{ option.selling_amt }} <del class="mrp-cut"
                              *ngIf="option.product_package_mrp > option.selling_amt">{{option.product_package_mrp}}</del>
                            /{{ option.unit_name }}
                          </span><br>
                          <span class="package-contains">{{option.package_contains}}</span>
                        </div>
                        <!-- <p class="mb-0 discount-amt" *ngIf="option.discount_per > 0">
                          {{ option.discount_per| roundoff }}% OFF
                        </p> -->
                      </div>
                      <!-- <select class="mt-2 mb-2">
                                                <option>6 PC</option>
                                                <option>6 PC</option>
                                         </select> -->
                      <div class="row">
                        <div class="col-lg-6 col-md-11">
                          <div class="
                          product-quantity
                          d-flex
                          align-items-center
                          flex-wrap
                          mt-2
                        " *ngIf="option.Added_In_Cart">
                            <!-- <span class="product-var__text graycolor">Quantity: &nbsp; </span> -->
                            <form class="modal-quantity-scale ml-1 onlyfortab">
                              <div class="value-button" id="modal-decrease" (click)="decreaseValueModal(option)">
                                -
                              </div>
                              <input style="
                              text-align: center;
                              border: 1px solid #aaabaa;
                              padding: 6px;
                              font-weight: 600;
                            " class="inputtextbox inputvalue" type="number" [value]="option.Cart_Qty"
                                (change)="onCartQtyChange($event, option)" disabled />
                              <div class="value-button" id="modal-increase" (click)="increaseValueModal(option)">
                                +
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="col-lg-5 col-md-11 text-center mt-2 notcenter"
                          *ngIf="option.Product_Package_Available_For_Subscription == 1 && option.Added_In_Cart"
                          (click)="onSubscribe(option)">
                          <a class=" btn addtocart btn--black btn--black-hover-green">Subscribe</a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-11 text-center mt-2 notcenter" *ngIf="!option.Added_In_Cart"
                          (click)="onAddToCart(option)">
                          <a class=" btn addtocart btn--black btn--black-hover-green">Add To Cart</a>
                        </div>

                        <div class="col-lg-5 col-md-11 text-center mt-2 notcenter"
                          *ngIf="option.Product_Package_Available_For_Subscription == 1 && !option.Added_In_Cart"
                          (click)="onSubscribe(option)">
                          <a class=" btn addtocart btn--black btn--black-hover-green">Subscribe</a>
                        </div>
                      </div>

                      <!-- <p>Available : {{option.Avl_Qry}}</p> -->
                    </div>
                    <!-- End Product Content -->
                  </div>
                  <!-- End Single Default Product -->

                  <!--VISIBLE ONLY ON MOBILE STRAT-->

                  <!-- Start Single Default Product -->
                  <div class=" row product__box product__default--singles d-block d-sm-none "
                    style="background-color: #fff; border-radius: 10px;">
                    <!-- Start Product Image -->
                    <div class="row align-items-center rowforsmall" style="margin: 0;">
                      <div class="pos-relative text-center col-12 imgholder onlysmallview"
                        style="height: 88px;padding-top: 3px;">
                        <a class="product__img--link">
                          <img class="product__img img-fluid" [src]="option.product_image" [alt]="option.product_name" />
                        </a>
                        <p *ngIf="option.package_highlight !=''" class="mb-0 heading-for-highlighter">
                          {{option.package_highlight}}</p>

                        <!-- Start Product Action Link-->
                        <ul class="product__action--link pos-absolute">
                          <!-- <li><a href="#modalAddCart" data-toggle="modal"><i
                                                              class="icon-shopping-cart"></i></a></li> -->
                          <!-- <li (click)="onProductDetail(option)">
                                                      <a href="#modalQuickView" data-toggle="modal"><i
                                                              class="icon-eye"></i>
                                                      </a>
                                                  </li> -->
                          <li (click)="onProductDetail(option)">
                            <a><i class="icon-eye"></i> </a>
                          </li>
                        </ul>
                        <!-- End Product Action Link -->
                      </div>
                      <!-- Start Product Content -->
                      <div class="product__content col-12">
                        <div style="    height: 49px;">
                          <!-- <p
                          class="mb-0 graycolor"
                          style="font-size: 17px; "
                        >
                          {{ option.brand_name }}
                        </p> -->
                          <a class="product__link mt-0 mb-2">{{ option.product_name }}
                          </a>
                          <p class="graycolor color-primary mt-1 mb-4">
                            {{ option.brand_name }}
                          </p>
                        </div>
                        <div style="height: 40px;" class="pricediv">
                          <div class="product__price m-t-5">
                            <span class="product__price">{{ option.selling_amt }} <del class="mrp-cut"
                                *ngIf="option.product_package_mrp > option.selling_amt">{{option.product_package_mrp}}</del>
                              /{{ option.unit_name }}</span> <br>
                            <span class="package-contains">{{option.package_contains}}</span>
                          </div>
                          <!-- <p class="mb-0 discount-amt" *ngIf="option.discount_per > 0">
                            {{ option.discount_per| roundoff }}% OFF
                          </p> -->
                        </div>
                        <!-- <select class="mt-2 mb-2">
                                                  <option>6 PC</option>
                              
                                                  <option>6 PC</option>
                           
                                           </select> -->

                        <div class="row">
                          <div class="col-xs-6 pr-0 pl-0">
                            <div class="
                            product-quantity
                            d-flex
                            align-items-center
                            flex-wrap
                            mt-2
                          " *ngIf="option.Added_In_Cart">
                              <!-- <span class="product-var__text graycolor">Quantity: &nbsp; </span> -->
                              <form class="modal-quantity-scale ">
                                <div class="value-button" id="modal-decrease" (click)="decreaseValueModal(option)">
                                  -
                                </div>
                                <input style="
                                text-align: center;
                                border: 1px solid #aaabaa;
                                padding: 6px 2px;
                                font-weight: 600;
                              " class="inputvalue" type="number" [value]="option.Cart_Qty"
                                  (change)="onCartQtyChange($event, option)" disabled />
                                <div class="value-button" id="modal-increase" (click)="increaseValueModal(option)">
                                  +
                                </div>
                              </form>
                            </div>
                          </div>
                          <div class="col-xs-6 text-center mt-2 notcenter"
                            *ngIf="option.Product_Package_Available_For_Subscription == 1 && option.Added_In_Cart"
                            (click)="onSubscribe(option)">
                            <a class=" btn addtocart btn--black btn--black-hover-green">Subscribe</a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xs-6 text-center mt-2 notcenter" *ngIf="!option.Added_In_Cart"
                            (click)="onAddToCart(option)">
                            <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                             
                            ">Add To Cart</a>
                          </div>
                          <div class="col-xs-6 text-center mt-2 notcenter"
                            *ngIf="option.Product_Package_Available_For_Subscription == 1 && !option.Added_In_Cart"
                            (click)="onSubscribe(option)">
                            <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                             
                            ">Subscribe</a>
                          </div>
                        </div>
                      </div>
                      <!-- End Product Content -->
                    </div>
                  </div>
                  <!-- End Single Default Product -->

                  <!--VISIBLE ONLY ON MOBILE END-->
                </div>
              </div>
            </div>
            <div class="tab-pane shop-list" id="sort-list" *ngIf="product_count > 0">
              <div class="row">
                <!-- Start Single List Product -->
                <div class="col-12 d-none d-sm-block" *ngFor="let option of products">
                  <div class="product__box product__box--list align-items-center">
                    <!-- Start Product Image -->

                    <div class="product__img-box pos-relative text-center">
                      <a routerLink="/home/productdetails" class="product__img--link">
                        <img (click)="onProductDetail(option)" class="product__img img-fluid mt-3"
                          [src]="option.product_image" [alt]="option.product_name" />
                      </a>
                      <p *ngIf="option.package_highlight !=''" class="mb-0 heading-for-highlighter">
                        {{option.package_highlight}}</p>
                    </div>
                    <!-- End Product Image -->
                    <!-- Start Product Content -->
                    <div class="product__content list_product_content" style="margin-right: -1px; margin-top: -1px">
                      <!-- <p
                        class="mb-0 graycolor"
                        style="font-size: 17px; margin-bottom: 7px !important"
                      >
                        {{ option.brand_name }}
                      </p> -->
                      <a class="product__link mt-0 mb-2 list_product_link">{{
                        option.product_name }} &nbsp;
                        {{ option.brand_name }}
                      </a>


                      <div class="product__price m-t-5">
                        <span class="product__price">{{ option.selling_amt }} <del class="mrp-cut" class="mrp-cut"
                            *ngIf="option.product_package_mrp > option.selling_amt">{{option.product_package_mrp}}</del>
                          /{{ option.unit_name }}</span><br>
                        <span class="package-contains">{{option.package_contains}}</span>
                      </div>
                      <!-- <p class="mb-0 discount-amt" *ngIf="option.discount_per > 0">
                        {{ option.discount_per | roundoff}}% OFF
                      </p> -->

                      <!-- Start Product Action Link-->
                      <ul class="product__action--link-list m-t-30">
                        <div class="
                            product-quantity
                            d-flex
                            align-items-center
                            
                            flex-wrap
                            mt-4
                          " *ngIf="option.Added_In_Cart">
                          <span class="product-var__text graycolor">Quantity: &nbsp;
                          </span>
                          <form class="modal-quantity-scale ml-1">
                            <div class="value-button" id="modal-decrease" (click)="decreaseValueModal(option)">
                              -
                            </div>
                            <input style="
                                text-align: center;
                                border: 1px solid #aaabaa;
                                padding: 6px;
                                font-weight: 600;
                                width: 65%;
                              " type="number" [value]="option.Cart_Qty" (change)="onCartQtyChange($event, option)"
                              disabled />
                            <div class="value-button" id="modal-increase" (click)="increaseValueModal(option)">
                              +
                            </div>
                          </form>
                        </div>
                        <div class="mt-4 notcenter" *ngIf="!option.Added_In_Cart" (click)="onAddToCart(option)">
                          <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                              
                            ">Add To Cart</a>
                        </div>
                        <div class="mt-4 notcenter" *ngIf="option.Product_Package_Available_For_Subscription == 1"
                          (click)="onSubscribe(option)">
                          <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                              
                            ">SUBSCRIBE</a>
                        </div>
                      </ul>
                      <!-- End Product Action Link -->
                    </div>
                    <!-- End Product Content -->
                  </div>
                </div>
                <!-- End Single List Product -->

                <!--VISIBLE ONLY ON MOBILE START-->

                <!-- Start Single List Product -->
                <div class="col-12 d-block d-sm-none" *ngFor="let option of products">
                  <div class="product__box row align-items-center mb-1">
                    <!-- Start Product Image -->

                    <div class="col-xs-4 text-center">
                      <a routerLink="/home/productdetails" class="product__img--link">
                        <img (click)="onProductDetail(option)" class="product__img img-fluid mt-3"
                          [src]="option.product_image" [alt]="option.product_name" />
                      </a>
                      <p *ngIf="option.package_highlight !=''" class="mb-0 heading-for-highlighter">
                        {{option.package_highlight}}</p>
                    </div>
                    <!-- End Product Image -->
                    <!-- Start Product Content -->
                    <div class="product__content col-xs-8" style="
                        margin-right: -1px;
                        margin-top: -1px;
                        width: 66.66666667% !important ;
                      ">
                      <p class="mb-0 graycolor" style="font-size: 17px; margin-bottom: 7px !important">
                        {{ option.brand_name }}
                      </p>
                      <a class="product__link mt-0 mb-2">{{ option.product_name }}
                        &nbsp;
                        {{ option.brand_name }}
                      </a>

                      <div class="product__price m-t-5">
                        <span class="product__price">{{ option.selling_amt }} <del class="mrp-cut"
                            *ngIf="option.product_package_mrp > option.selling_amt">{{option.product_package_mrp}}</del>
                          /{{ option.unit_name }} </span><br>
                        <span class="package-contains">{{option.package_contains}}</span>
                      </div>
                      <!-- <p class="mb-0 discount-amt" *ngIf="option.discount_per > 0">
                        {{ option.discount_per | roundoff}}% OFF
                      </p> -->

                      <!-- Start Product Action Link-->
                      <ul class="product__action--link-list m-t-30">
                        <div class="
                            product-quantity
                            d-flex
                            align-items-center
                            justify-content-center
                            flex-wrap
                            mt-4
                          " *ngIf="option.Added_In_Cart">
                          <span class="product-var__text graycolor">Quantity: &nbsp;
                          </span>
                          <form class="modal-quantity-scale ml-1">
                            <div class="value-button" id="modal-decrease" (click)="decreaseValueModal(option)">
                              -
                            </div>
                            <input style="
                                text-align: center;
                                border: 1px solid #aaabaa;
                                padding: 6px;
                                font-weight: 600;
                              " type="number" [value]="option.Cart_Qty" (change)="onCartQtyChange($event, option)"
                              disabled />
                            <div class="value-button" id="modal-increase" (click)="increaseValueModal(option)">
                              +
                            </div>
                          </form>
                        </div>
                        <div class="text-center mt-4" *ngIf="!option.Added_In_Cart" (click)="onAddToCart(option)">
                          <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                             
                            ">Add To Cart</a>
                        </div>
                        <div class="text-center mt-4" *ngIf="option.Product_Package_Available_For_Subscription == 1"
                          (click)="onSubscribe(option)">
                          <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                             
                            ">SUBSCRIBE</a>
                        </div>
                      </ul>
                      <!-- End Product Action Link -->
                    </div>
                    <!-- End Product Content -->
                  </div>
                </div>
                <!-- End Single List Product -->
                <!--VISIBLE ONLY ON MOBILE END-->
              </div>
            </div>
          </div>
          <div *ngIf="products.length == 0" class="row noprow" style="
              background: #faf9f5;
              padding: 60px 40px;
              align-items: center;
              margin-top: 30px;
            ">
            <div class="col-md-7">
              <h1 class="mb-5 sorryclass" style="
                  font-family: 'MarlinGeo';
                  font-size: 37px;
                  font-weight: 600;
                  line-height: 1.2;
                  color: #62aa00;
                ">
                No product available in selected category...
              </h1>
              <h4 style="font-size: 18px; font-weight: 600">
                We're sorry — Please continue shopping with other products.
              </h4>
              <p style="font-size: 17px">
                We will deliver your product within 2,3 working days.
              </p>
            </div>
            <div class="col-md-5">
              <img src="assets/img/product/noproduct.png" class="img-responsive" />
            </div>
          </div>

          <div class="col-12">
            <div [innerHTML]="productInfo"> </div>
          </div>
        </div>

        <!-- <div class="page-pagination" *ngIf="product_count > 0 && isPagination">
          <ul class="page-pagination__list">
            <li class="page-pagination__item">
              <a class="page-pagination__link" (click)="setPage(this.pageNo - 1, 'Prev')"
                [class.disabledATag]="paginator.length == 1 || checkFirstNumber()">Prev</a>
            </li>

            <li class="page-pagination__item" *ngFor="let option of paginator">
              <a class="page-pagination__link" [class.active]="option == pageNo" (click)="setPage(option, 'number')">{{
                option }}</a>
            </li>
            <li class="page-pagination__item">
              <a class="page-pagination__link" (click)="setPage(this.pageNo + 1, 'Next')"
                [class.disabledATag]="paginator.length == 1 || checkLastNumber()">Next</a>
            </li>
          </ul>
        </div> -->


      </div>
      <!-- Start Rightside - Product Type View -->
    </div>
  </div>
</main>
<!-- :::::: End MainContainer Wrapper :::::: -->