<div style="background: #ececec;    border-top: 2px dashed#ea6d14;">
  <div class="container pt-2 pb-4">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <app-desifarm-dashboard-side-menu></app-desifarm-dashboard-side-menu>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <h1 class="tab-heading">Refer And Earn</h1>
              <div class="p-4 nopadding">


                <img src="assets/img/refer/1.png" class="img-responsive">
                <div class="">
                  <div class="greenback mb-3">
                    <p class="firstp" style="font-size: 22px;margin-bottom: 5px;">INVITE YOUR FRIENDS TO ORDER</p>
                    <p class="secondp" style="font-size: 18px;">BY SHARING YOUR REFERRAL CODE & WIN EXCITING CASHBACK!
                    </p>
                  </div>
                  <p class="mt-2 mb-2" style="font-weight: 600;font-size: 16px;margin-bottom: 5px;text-align: center;">
                    STEPS to Refer Your Friends & Earn Exciting Cashbacks:</p>
                  <p class="mt-2 mb-2"><i style="color:#62aa00;" class="fas fa-hand-point-right fa-lg"></i> &nbsp; Copy
                    the below referral code on this page, and share it with your friends</p>
                  <p class="mt-2 mb-2"><i style="color:#62aa00;" class="fas fa-hand-point-right fa-lg"></i> &nbsp; Ask
                    your friends to register on our website, and enter your referral code upon registration </p>
                  <p class="mt-2 mb-2"><i style="color:#62aa00;" class="fas fa-hand-point-right fa-lg"></i> &nbsp; On
                    your friend’s first purchase of AED 199 using your referral code, you earn AED 50 and they earn AED 50
                    as cash back</p>

                </div>
                <h3 class="mt-4 mb-3"
                  style="font-size: 18px;padding:10px;background-color: #62aa00; color: #fff;text-align: center;">So,
                  what are you waiting for? Hurry and Refer Your Friends Today!</h3>
                <!-- <h3 class="mt-2 mb-1" style="font-size: 25px;color: #00679f;font-weight: 500;">Hi {{loginData?.Cust_Name |
                  titlecase}}, you can invite your friends to shop on Desifarm!</h3>
                <h3 class="mt-0 mb-1" style="font-size: 20px;color: #838181;font-weight: 500;">Your referel code is DESI{{loginData.Cust_Id}}</h3>  -->
                <!-- <h3 class="mt-3" style="font-size: 20px;color: #838181;font-weight: 500;">Get {{150 }} off on purchased items</h3> <br> -->
                <div class="text-center mb-3">
                  <!-- <a style="width: 300px;"
                    class="btn btn--box btn--small editwidth btn--green btn--green-hover-black btn--uppercase font--semi-bold"
                    type="submit">DESI1</a> <i class="fas fa-copy"></i> -->
                  <!-- <button type="button" class="btn btn-outline-primary"
                    style="padding:10px;background-color: #62aa00;color: #fff;">Your Referral Code is </button> -->
                  Your Refferal Code is <strong>DESI{{loginData.Cust_Id}}</strong>
                </div>
                <div class="text-center mb-3">
                  <!-- <a style="width: 300px;"
                    class="btn btn--box btn--small editwidth btn--green btn--green-hover-black btn--uppercase font--semi-bold"
                    type="submit">DESI1</a> <i class="fas fa-copy"></i> -->
                  <button type="button" class="btn btn-outline-primary"
                    style="padding:10px;background-color: #62aa00;color: #fff;" (click)="copyToClipboard()">REFER
                    NOW</button>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>