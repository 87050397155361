import { AfterViewInit, Directive } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
declare var $: any

@Directive({
  selector: '[appTestimonialSlider]'
})
export class TestimonialSliderDirective implements AfterViewInit {

  constructor(
      private router:Router,
      private _commonService :DesifarmCommonService
      ) { }

    //   setTimeout(() => {
    //     this._commonService._spinner.show()
    //     try {
    //         $('.blog-feed-slider-3grid').slick({
    //             slidesToShow: 4,
    //             infinite: false,
    //             slidesToScroll: 1,
    //             easing: 'linear',
    //             speed: 1000,
    //             prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
    //             nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
    //             responsive: [
        
    //                 {
    //                     breakpoint: 1470,
    //                     settings: {
    //                         slidesToShow: 4
    //                     }
    //                 },
    //                 {
    //                     breakpoint: 992,
    //                     settings: {
    //                         slidesToShow: 3,
    //                     }
    //                 },
    //                 {
    //                     breakpoint: 768,
    //                     settings: {
    //                         arrows: false,
    //                         slidesToShow: 2,
    //                         autoplay: true,
    //                     }
    //                 },
    //                 {
    //                     breakpoint: 480,
    //                     settings: {
    //                         slidesToShow:2,
    //                         arrows: false,
    //                         autoplay: true,
    //                     }
    //                 }
    //             ]
    //         });
    //         this._commonService._spinner.hide()
    //     } catch (error) {
    //         this._commonService._spinner.hide()
    //     }
        
    // }, 100)

  ngAfterViewInit() {

    /************************************************
     * Blog Slider - Style: Feed [3 Grid, 1 Rows]
     ***********************************************/
    this._commonService.isBestSellerLoaded.subscribe(res => {
        if(res){
            setTimeout(() => {
                this._commonService._spinner.show()
                try {
                    $('.blog-feed-slider-3grid-best-seller').slick({
                        slidesToShow: 4,
                        infinite: false,
                        slidesToScroll: 1,
                        easing: 'linear',
                        speed: 1000,
                        prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
                        nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
                        responsive: [
                
                            {
                                breakpoint: 1470,
                                settings: {
                                    slidesToShow: 4
                                }
                            },
                            {
                                breakpoint: 992,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    arrows: false,
                                    slidesToShow: 2,
                                    autoplay: true,
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow:2,
                                    arrows: false,
                                    autoplay: true,
                                }
                            }
                        ]
                    });
                    this._commonService._spinner.hide()
                } catch (error) {
                    this._commonService._spinner.hide()
                }
                
            }, 100)
        }
    })

    this._commonService.isHomePageProductsLoaded.subscribe(res => {
        if(res){
            setTimeout(() => {
                this._commonService._spinner.show()
                try {
                    $('.blog-feed-slider-3grid-homepage-prod').slick({
                        slidesToShow: 4,
                        infinite: false,
                        slidesToScroll: 1,
                        easing: 'linear',
                        speed: 1000,
                        prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
                        nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
                        responsive: [
                
                            {
                                breakpoint: 1470,
                                settings: {
                                    slidesToShow: 4
                                }
                            },
                            {
                                breakpoint: 992,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    arrows: false,
                                    slidesToShow: 2,
                                    autoplay: true,
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow:2,
                                    arrows: false,
                                    autoplay: true,
                                }
                            }
                        ]
                    });
                    this._commonService._spinner.hide()
                } catch (error) {
                    this._commonService._spinner.hide()
                }
            }, 100)
        }
    })
}

}
