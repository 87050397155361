import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmComplaintsandsuggesstionService } from 'src/app/services/desifarm-complaints-suggesstion/desifarm-complaintsandsuggesstion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-desifarm-complaint-and-suggestion',
  templateUrl: './desifarm-complaint-and-suggestion.component.html',
  styleUrls: ['./desifarm-complaint-and-suggestion.component.scss']
})
export class DesifarmComplaintAndSuggestionComponent implements OnInit {

  textAreaPlaceholder: any = ""
  form: FormGroup

  loginData: any

  constructor(
    public dialogRef: MatDialogRef<DesifarmComplaintAndSuggestionComponent>,
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _ComplaintAndSuggesstionService: DesifarmComplaintsandsuggesstionService,
  ) { }


  initForm() {
    this.form = this._fb.group({
      Cust_Id: [''],
      Business_Type_Id: [''],
      Type: [''],
      Compaint_Suggestion_Detail: ['', [Validators.required]],
      Status: [''],
    })
  }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()
    this.initForm()
    // this.form.patchValue({
    //   Cust_Id: this.loginData.Cust_Id,
    //   Business_Type_Id: environment.Business_Type_Id,
    //   Status: "New"
    // })
    this.updateForm()
  }

  updateForm() {
    this.form.reset()
    this.form.patchValue({
      Cust_Id: this.loginData.Cust_Id,
      Business_Type_Id: environment.Business_Type_Id,
      Status: "New"
    })
  }

  close = () => this.dialogRef.close()

  onType(event) {
    this.textAreaPlaceholder = `Enter You ${event.target.value} Here`
    // this.form.patchValue({ Type: event.target.value })
  }

  submit() {
    this._commonService._spinner.show()
    this._ComplaintAndSuggesstionService.insert_complaints(this.form.value).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.updateForm()
        this.close()
        this._commonService.onSuccess(res.msg)
      } else this._commonService.onFailed(res.msg)
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Internet Connection Failed")
    })
  }

}
