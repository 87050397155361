import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmMainCategoriesImagesService {

  location: any

  constructor(private http: HttpClient) {
    if (localStorage.getItem(environment.selectedCity)) {
      this.location = JSON.parse(localStorage.getItem(environment.selectedCity))
    }
  }

  get_main_category_images(District_Id): Observable<any> {
    return this.http.get<DesifarmAck>(`${environment.url}get_main_category_images/${environment.Business_Type_Id}/${District_Id}`)
  }

  getActiveMainCategory(District_Id): Observable<any> {
    return this.http.get<any>(`${environment.url}getActiveMainCategory/${environment.Business_Type_Id}/${District_Id}`)
  }

  getBestSellerProducts(District_Id): Observable<any> {
    return this.http.get<any>(`${environment.url}getBestSellerProducts/${environment.Business_Type_Id}/${District_Id}`)
  }

  // getActiveMainCategoriesProduct(main_category_id): Observable<any> {
  //   return this.http.get<any>(environment.url + 'getActiveMainCategory/'+main_category_id)
  // }
}
