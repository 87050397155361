import { AfterViewInit, Directive, OnInit } from '@angular/core';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
declare var $: any

@Directive({
    selector: '[appCompanySlider]'
})
export class CompanySliderDirective implements OnInit, AfterViewInit {

    constructor(private _commonService: DesifarmCommonService) { }

    ngOnInit() {

    }

    ngAfterViewInit() {

        this._commonService.isMainCategoriesLoaded.subscribe(res => {
            if (res) {
                this._commonService._spinner.show()
                setTimeout(() => {
                    try {
                        $('.company-logo__area').slick({
                            arrows: true,
                            infinite: true,
                            slidesToShow: 8,
                            slidesToScroll: 2,
                            easing: 'linear',
                            speed: 1000,
                            prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
                            nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
                            responsive: [
                                {
                                    breakpoint: 1440,
                                    settings: {
                                        slidesToShow: 7
                                    }
                                },
                                {
                                    breakpoint: 1200,
                                    settings: {
                                        slidesToShow: 6
                                    }
                                },
                                {
                                    breakpoint: 992,
                                    settings: {
                                        slidesToShow: 5,
                                        autoplay: true,
                                    }
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 5,
                                        autoplay: true,
                                    }
                                },
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 5,
                                        autoplay: true,
                                    }
                                }
                            ]
                        });
                    } catch (error) {
                    }
                    this._commonService._spinner.hide()
                }, 1500)
            }
        })
    }

}
