import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DesifarmBlogsService } from 'src/app/services/desifarm-blogs/desifarm-blogs.service';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';

@Component({
  selector: 'app-desifarms-blogs',
  templateUrl: './desifarms-blogs.component.html',
  styleUrls: ['./desifarms-blogs.component.scss']
})
export class DesifarmsBlogsComponent implements OnInit {

  blogs:any[] = []

  constructor(
    private _commonService: DesifarmCommonService,
    private _blogService: DesifarmBlogsService,
    private _dialog: MatDialog
  ) { }

  getData(){
    this._blogService.get_blogs_list().subscribe((res:any) => {
      this.blogs = res.data
    })
  }


  ngOnInit(): void {
    this.getData()
  }

  onDetail(option){
    localStorage.setItem("blogs", JSON.stringify(option))
    this._commonService.router.navigate(['/blog-details'])
  }

}
