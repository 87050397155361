import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-desifarms-productdetails',
  templateUrl: './desifarms-productdetails.component.html',
  styleUrls: ['./desifarms-productdetails.component.scss']
})
export class DesifarmsProductdetailsComponent implements OnInit {

  myThumbnail: string = "assets/img/product/6.jpg"
  myThummyFullresImagebnail: string = "assets/img/product/6.jpg"

  slides1 = [
    { img: "assets/img/company-logo/company-logo-1.png" },
    { img: "assets/img/company-logo/company-logo-2.png" },
    { img: "assets/img/company-logo/company-logo-3.png" },
    { img: "assets/img/company-logo/company-logo-4.png" },
    { img: "assets/img/company-logo/company-logo-5.png" },
    { img: "assets/img/company-logo/company-logo-6.png" },
    { img: "assets/img/company-logo/company-logo-7.png" },
  ];


  slideConfig1 = {
    "slidesToShow": 6,
    "slidesToScroll": 1,
    'swipeToSlide': true,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": false,
    "infinite" : true,
    "autoplay" : true,
    "autoplaySpeed" : 1000,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  


  slickInit(e) {
  }

  breakpoint(e) {
  }
  breakpoint1(e) {
  }
  afterChange(e) {
  }

  beforeChange(e) {
  }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    navText: [ '<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>' ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  constructor() { }

  ngOnInit(): void {
  }

  

}
