<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb mb-0">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb__menu">
          <li class="page-breadcrumb__nav"><a routerLink="/">Home</a></li>
          <li class="page-breadcrumb__nav"><a href="/blogs">Blogs</a></li>
          <li class="page-breadcrumb__nav active">{{ detail?.Blog_Title }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- ::::::  End  Breadcrumb Section  ::::::  -->

<section class="pt-5 pb-5 myblog" >
  <div class="container" >
    <div class="row " style="justify-content: center;">
      <div class="col-md-10" style="background: #f8f8f8;border:2px solid #ececec;padding: 5px;">
          <div style="text-align: center;">
            <img
          [src]="detail?.Blog_Image"
          class="img-responsive"
          alt="blog image" />
      </div>
        <h3 class="blogtitle">{{ detail?.Blog_Title }}</h3>
        <p class="description">
          The many benefits of the glass of milk mom forced you to have!</p>
        <div class="content" [innerHTML]="detail?.Blog_Details"> </div>
      </div>
    </div>
  </div>
</section>


<!-- <section class="pt-5 pb-5 myblog" style="background: #ecececbd">
  <div class="container">
    <div class="row">
      <div class="col-md-9 col-sm-12" style="border-right: 1px solid lightgray">
       
        <h3 class="blogtitle mt-2">{{ detail?.Blog_Title }}</h3>
        <hr />
        <img
          [src]="detail?.Blog_Image"
          class="img-responsive"
          alt="blog image"
        />
        <p >
          <i>{{detail?.Blog_Short_Description}} </i>
        </p>

        <div class="content" [innerHTML]="detail?.Blog_Details">

        </div>

      
      </div> -->

      <!--SIDE DIV START-->
      <!-- <div class="col-md-3 col-sm-12">
        <div class="top1 mt-4">
          <p
            class="mt-5"
            style="font-size: 20px; margin-bottom: 0; line-height: 1"
          >
            YOUR QUALITY ASSURED DOODHWALA
          </p>
          <div class="shortborder2"></div>
          <img class="mt-3 img-responsive" src="assets/img/blog/sideimg2.jpg" />
          <p
            class="mt-5"
            style="font-size: 20px; margin-bottom: 0; line-height: 1"
          >
            LIKE US ON FACEBOOK
          </p>
          <div class="shortborder2"></div>
          <img src="assets/img/blog/sideimg.jpg" class="mt-3 img-responsive" />

          <p
            class="mt-5"
            style="font-size: 20px; margin-bottom: 0; line-height: 1"
          >
            About Desi Farms
          </p>
          <div class="shortborder2"></div>
          <p class="mt-3">“Premium quality products for one and all.”</p>
          <p>
            We aim to bring prosperity to local farmers and promote a healthy
            lifestyle amongst urban consumers to win their trust.
          </p>
          <p>
            The company’s ‘Farm-to-Table’ model promises 100% preservative and
            chemical-free milk and dairy products. Our ISO Certified
            state-of-the-art processing plant has a capacity of 100,000 litres,
            and every batch of milk goes through 20+ quality tests at 4 stages
            daily.
          </p>
        </div>
      </div> 
    </div>
  </div>
</section> -->
