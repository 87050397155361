
<div class="tab-lists pt-4 d-none d-sm-block">
  <img src="assets/img/dashboard/userprofile.png" class="img-responsive" />
  <h3 class="mt-3">{{loginData?.Cust_Name | titlecase}}</h3>
  <p> Phone: {{loginData?.Customer_Mobile_Number}}</p>
  <button class="btn btn--box cart-btn btn--small btn--dark btn--black btn--black-hover-green font--semi-bold"><a
      class="tablink" routerLink="/myprofile">Edit Info</a></button>
  <hr>
  <!-- <a routerLink="/dashboard" routerLinkActive="active-menu">Dashboard</a>
  <hr> -->
  <a routerLink="/myprofile" routerLinkActive="active-menu">My Profile</a>
  <hr>
  <a routerLink="/myorders" routerLinkActive="active-menu">My Orders</a>
  <hr>
  <!-- <a routerLink="/mysubscription" routerLinkActive="active-menu">My Subscription</a>
  <hr> -->
  <a routerLink="/mywallet" routerLinkActive="active-menu">My Wallet</a>
  <hr>
  <!-- <a routerLink="/refer-and-earn" routerLinkActive="active-menu">Refer & Earn</a>
  <hr> -->
  <!-- <a routerLink="/subscription" routerLinkActive="active-menu">My Subscriptions</a>
  <hr> -->
  <a routerLink="/manageaddress" routerLinkActive="active-menu">Manage Address</a>

</div>
