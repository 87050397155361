<div class="container ">
    <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-12 designdiv">

            <img src="assets/img/logo/desi.png" alt="logo" class="mylogo img-responsive">

            <div class="contentdiv">
                <h1 class="mb-0 mt-3">Hello {{data?.Cust_Name}},</h1>
                <!-- <h4>Farm Fresh Deliverd Daily !!</h4>
                <img src="assets/img/invice/banner.png" alt="banner" class="img-responsive"> -->
            </div>
            <div class="recipt-sec mt-4  pl-0 pr-0">
                <p class=" p-2 pb-0" style="font-size: 16px;color: #62aa00;"><i class="fas fa-receipt"></i> &nbsp;
                    &nbsp; Recent Transaction</p>
                <hr>
                <div class="p-2 invoicediv">
                    <h3 class="mb-0">Invoice #{{data?.Sale_Order_Id}}</h3>
                    <p>{{data?.Flat_House_No}}, {{data?.Area_Colony_Sreet_Name}},
                        {{data?.Landmark}} <br>
                        <span *ngIf="data?.Sub_Area_Name">{{data?.Sub_Area_Name}},</span>
                        <span *ngIf="data?.Area_Name">{{data?.Area_Name}},</span>
                        <span *ngIf="data?.City">{{data?.City}} {{data?.Pincode}}</span>

                    </p>
                    <p>{{data?.Actual_Delivery_Date_Time | date: 'dd-MMM-yyyy'}} | {{data?.Actual_Delivery_Date_Time | date: 'hh:mm:ss a'}} </p>
                    <!--TAB SECTION-->
                    <nav class="mt-3">
                        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home"
                                role="tab" aria-controls="nav-home" aria-selected="true">Purchases</a>
                            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
                                role="tab" aria-controls="nav-profile" aria-selected="false">Customer Info</a>

                        </div>
                    </nav>
                    <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
                            aria-labelledby="nav-home-tab">
                            <!--ACCORDIAN START-->
                            <div class="accordion" id="accordionExample" >
                                <div class="card" *ngFor="let option of data?.Product_List; let i = index">
                                    <div class="card-header" [attr.id]="i">

                                        <button class=" btn-link  text-left" type="button" data-toggle="collapse"
                                            [attr.data-target]="'#collapse'+ option.Sale_Order_Detail_ID" aria-expanded="false" [attr.aria-controls]="'collapse'+option.Sale_Order_Detail_ID">
                                            {{option.Product_Name}}
                                        </button>

                                    </div>

                                    <div [attr.id]="'collapse' + option.Sale_Order_Detail_ID" class="collapse show" [attr.aria-labelledby]="'collapse' + option.Sale_Order_Detail_ID"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p><span class="fistsec">Pack Qty : </span>  <span class="sec-section">{{option.Package_Qty}} {{option.Unit_Name}}</span></p>
                                            <p><span class="fistsec">MRP : </span> <span class="sec-section">AED {{option.Product_Package_MRP}}</span></p>
                                            <p><span class="fistsec" *ngIf="option.Discount_Per > 0">Dist Per : </span> <span class="sec-section" *ngIf="option.Discount_Per > 0"> {{option.Discount_Per}}</span></p>
                                            <p><span class="fistsec" *ngIf="option.Total_Discount_Amount >0">Dist Amt : </span> <span class="sec-section" *ngIf="option.Total_Discount_Amount >0">AED {{option.Total_Discount_Amount}}</span></p>
                                            <p><span class="fistsec">Selling Amt : </span> <span class="sec-section">AED {{option.Selling_Amt}}</span></p>
                                            <p><span class="fistsec">Qty : </span> <span class="sec-section">{{option.Order_Qty}}</span></p>
                                            <p><span class="fistsec">Net Amt : </span> <span class="sec-section">{{option.Selling_Amt}} * {{option.Order_Qty}} =   AED {{option.Selling_Amt * option.Order_Qty}}</span></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="headingTwo">

                                        <button class="btn-link text-left collapsed" type="button"
                                            data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                                            aria-controls="collapseTwo">
                                            Invoice Value
                                        </button>

                                    </div>
                                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                                        data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p><span class="fistsec">Sub Total : </span><span class="sec-section">AED {{SubTotal}}</span></p>
                                            <p><span class="fistsec">You Save : </span><span class="sec-section">AED {{SavedAmt}}</span></p>
                                            <p><span class="fistsec">Delivery Charges (+) : </span><span class="sec-section">AED {{DeliveryCharges}}</span></p>
                                            <p><span class="fistsec" *ngIf="WalletAmount > 0">Wallet (-) : </span><span class="sec-section" *ngIf="WalletAmount > 0">AED {{WalletAmount}}</span></p>
                                            <p><span class="fistsec">Sub Total + Delivery : </span><span class="sec-section">AED {{SubTotal + DeliveryCharges}}</span></p>
                                            <p><span class="fistsec">Payable Amount : </span><span class="sec-section">AED {{PayableAmount}}</span></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!--ACCORDIAN ENDS-->
                        </div>
                        <!--SECOND TAB START-->
                        <div class="tab-pane fade p-2" id="nav-profile" role="tabpanel"
                            aria-labelledby="nav-profile-tab">
                            <p><span class="info1">Customer Name : </span><span class="info2"> {{data?.Cust_Name}}</span></p>
                            <p><span class="info1">Email: </span><span class="info2">+91 {{data?.Email_Id}}</span></p>
                            <p><span class="info1">Address: </span><span style="color: rgb(41, 40, 40); text-align: right !important;">{{data?.Flat_House_No}}, {{data?.Area_Colony_Sreet_Name}} {{data?.Landmark}} {{data?.Sub_Area_Name}} {{data?.Area_Name}} {{data?.City}} - {{data?.Pincode}} </span></p>
                        </div>

                    </div>
                    <hr>
                </div>

            </div>
            <h3 class="mt-2">Shop safe with us</h3>
        </div>
    </div>
</div>