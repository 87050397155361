import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmWalletTopupService } from 'src/app/services/desifarm-dashboard/desifarm-wallet/desifarm-wallet-topup.service';
import { DesifarmWalletService } from 'src/app/services/desifarm-dashboard/desifarm-wallet/desifarm-wallet.service';
import { DesifarmWalletTopupComponent } from './desifarm-wallet-topup/desifarm-wallet-topup.component';

@Component({
  selector: 'app-desifarms-mywallet',
  templateUrl: './desifarms-mywallet.component.html',
  styleUrls: ['./desifarms-mywallet.component.scss']
})
export class DesifarmsMywalletComponent implements OnInit {

  loginData: any

  walletAmountCount: number = 0

  walletHistory: any[] = []

  constructor(
    private _commonService: DesifarmCommonService,
    private _walletServiceService: DesifarmWalletService,
    private _walletTopupServiceService: DesifarmWalletTopupService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
  ) { }


  getParams = () => {
    this._route.queryParams.subscribe((params) => {
      if (params.ref) {
        this.updateWalletTopup({ Payment_Reference_Id: params.ref, Cust_Id: this.loginData.Cust_Id })
      } else if (params.msg) {
        params.ack == 'true' ? this._commonService.onSuccess(params.msg, 6000) : this._commonService.onFailed(params.msg, 6000)
      }
    })
  }

  getData() {
    this._walletServiceService.get_wallet_history(this.loginData.Cust_Id).subscribe((res: any) => {
      this.walletHistory = res.data
    })

    this._walletServiceService.get_wallet_amount(this.loginData.Cust_Id).subscribe((res: any) => {
      this._walletServiceService.walletAmountCount.next(res.data.toFixed(2))
      this.walletAmountCount = res.data.toFixed(2)
      // res.data % 1 != 0 ? Math.round(res.data) : res.data
      // this._walletServiceService.walletAmountCount.next(res.data % 1 != 0 ? Math.round(res.data) : res.data)
      // this.walletAmountCount = res.data % 1 != 0 ? Math.round(res.data) : res.data
    })
  }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()
    this.getParams()
    this.getData()
  }


  onWalletTopup() {
    const dialogRef = this._dialog.open(DesifarmWalletTopupComponent, {
      width: '500px',
      // height: '60vh',
      panelClass: 'wallet-topup',
      // data: data
    })
    dialogRef.afterClosed().subscribe(() => { this.getData() })
  }

  updateWalletTopup(data) {
    this._commonService._spinner.show()
    this._walletTopupServiceService.update_wallet_topup(data).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this._commonService.onSuccess(res.msg, 5000)
        this.getData()
      } else this._commonService.onFailed(res.msg)
    }, (err) => {
      this._commonService._spinner.hide()
    })
  }

}
