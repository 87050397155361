<!-- :::::: Start Main Container Wrapper :::::: -->
<main id="main-container" class="main-container">
  <div class="container">
    <div class="row flex-column-reverse flex-lg-row">

      <!-- Start Rightside - Product Type View -->
      <div class="col-lg-12">

        <br>
        <div class="header-search__content pos-relative" *ngIf="searchButton">
          <input type="search" name="header-search" placeholder="Search for Products" required #search
            (keyup)="onSearchTarget($event)" />
          <!-- (input)="onSearch($event.target.value)" -->
          <button class="pos-absolute" type="submit">
            <i class="icon-search"></i>
          </button>
        </div>

        <!-- ::::::  Start Sort Box Section  ::::::  -->
        <div class="sort-box m-t-20">
          <div class="sort-box-item">
            <div class="sort-box__tab">
              <ul class="sort-box__tab-list nav">
                <li><a class="sort-nav-link active" data-toggle="tab" href="#sort-grid"><i class="fas fa-th"></i> </a>
                </li>
                <li><a class="sort-nav-link" data-toggle="tab" href="#sort-list"><i class="fas fa-list-ul"></i></a></li>
              </ul>
            </div>
          </div> <!-- Start Sort Left Side -->

          <div class="sort-box-item d-flex align-items-center flex-warp">
            <!-- <span>Sort By:</span>
                        <div class="sort-box__option">
                            <label class="select-sort__arrow">
                                <select name="select-sort" class="select-sort">
                                    <option value="1">Relevance</option>
                                    <option value="2">Name, A to Z</option>
                                    <option value="3"> Name, Z to A </option>
                                    <option value="4"> Price, low to high</option>
                                    <option value="5">Price, high to low</option>
                                </select>
                            </label>
                        </div> -->
          </div>

          <div class="sort-box-item">
            <!-- <span>Showing {{local_product_count}} of {{product_count}} result</span> -->
            <span>{{product_count}} Products</span>
            <!-- <span>Showing {{startPageNo}} - {{endPageNo}} of {{product_count}} result</span> -->
          </div>
        </div>
        <br>
        <!-- ::::::  Start Sort Box Section  ::::::  -->



        <div class="product-tab-area">
          <div class="tab-content tab-animate-zoom">
            <div class="tab-pane show active shop-grid" id="sort-grid" *ngIf="product_count > 0">
              <div class="row formobileviewonly">
                <div class=" col-lg-3 col-md-3 col-xs-6 mt-3" *ngFor="let option of searchProductList">
                  <!-- Start Single Default Product -->
                  <div class="product__box product__default--singles pt-2  d-none d-sm-block">

                    <!-- Start Product Image -->
                    <div class="pos-relative text-center">
                      <a routerLink="/home/shopallitems" class="product__img--link ">
                        <img class="product__img img-fluid" [src]="option.product_image" [alt]="option.product_name">
                      </a>
                      <p *ngIf="option.package_highlight !=''" class="mb-0 heading-for-highlighter">
                        {{option.package_highlight}}</p>

                      <!-- Start Product Action Link-->
                      <ul class="product__action--link pos-absolute">
                        <!-- <li><a href="#modalAddCart" data-toggle="modal"><i
                                                            class="icon-shopping-cart"></i></a></li> -->
                        <!-- <li (click)="onProductDetail(option)">
                                                    <a href="#modalQuickView" data-toggle="modal"><i
                                                            class="icon-eye"></i>
                                                    </a>
                                                </li> -->
                        <li (click)="onProductDetail(option)">
                          <a><i class="icon-eye"></i>
                          </a>
                        </li>
                      </ul>
                      <!-- End Product Action Link -->
                    </div>
                    <!-- Start Product Content -->
                    <div class="product__content mt-4">
                      <div style="height:60px">
                        <!-- <p class="mb-0 graycolor" style="font-size: 17px;margin-bottom: 7px !important;">
                          {{option.brand_name}}</p> -->
                        <a routerLink="/home/shopallitems" class="product__link mt-0 mb-0">{{option.product_name}}
                        </a>
                        <p class="graycolor mb-4 color-primary">{{option.brand_name}}
                        </p>
                      </div>
                      <div style="height: 40px">
                        <div class="product__price">
                          <span class="product__price">{{option.selling_amt }} <del class="mrp-cut"
                              *ngIf="option.product_package_mrp > option.selling_amt">{{option.product_package_mrp}}</del>
                            /{{ option.unit_name }}</span><br>
                          <span class="package-contains">{{option.package_contains}}</span>
                        </div>
                        <!-- <p class="mb-0 discount-amt" *ngIf="option.discount_per > 0">
                          {{option.discount_per | roundoff}}% OFF
                        </p> -->
                      </div>
                      <!-- <select class="mt-2 mb-2">
                                                <option>6 PC</option>
                                                <option>6 PC</option>
                                            </select> -->
                      <div class="row">
                        <div class="col-lg-6 col-md-11">
                          <div class="
                      product-quantity  d-flex align-items-center flex-wrap mt-2 fortabview
                        " *ngIf="option.Added_In_Cart">

                            <!-- <span class="product-var__text graycolor">Quantity: &nbsp; </span> -->
                            <form class="modal-quantity-scale ml-1">
                              <div class="value-button" id="modal-decrease" (click)="decreaseValueModal(option)">-</div>
                              <input style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;"
                                type="number" class="inputtextbox inputvalue" [value]="option.Cart_Qty"
                                (change)="onCartQtyChange($event,option)" disabled />
                              <div class="value-button" id="modal-increase" (click)="increaseValueModal(option)">+</div>
                            </form>
                          </div>
                        </div>
                        <!-- <div class="col-lg-5 col-md-11 text-center mt-2 notcenter fortabview"
                          *ngIf="option.Product_Package_Available_For_Subscription == 1 && option.Added_In_Cart"
                          (click)="onSubscribe(option)">
                          <a class=" btn addtocart btn--black btn--black-hover-green">Subscribe</a>
                        </div> -->
                      </div>
                      <div class="row">
                        <div class="col-lg-6 col-md-11 text-center mt-2 notcenter fortabview"
                          *ngIf="!option.Added_In_Cart" (click)="onAddToCart(option)">
                          <a class=" btn addtocart btn--black btn--black-hover-green">Add To Cart</a>
                        </div>

                        <!-- <div class="col-lg-5 col-md-11 text-center mt-2 notcenter fortabview"
                          *ngIf="option.Product_Package_Available_For_Subscription == 1 && !option.Added_In_Cart"
                          (click)="onSubscribe(option)">
                          <a class=" btn addtocart btn--black btn--black-hover-green">Subscribe</a>
                        </div> -->
                      </div>
                      <!-- <div class="text-center mt-4 fortabview" *ngIf="!option.Added_In_Cart"
                        (click)="onAddToCart(option)"><a class="btn  addtocart btn--black btn--black-hover-green ">Add To Cart</a></div>
                      <div class="text-center mt-4 fortabview" *ngIf="option.Product_Package_Available_For_Subscription == 1"
                        (click)="onSubscribe(option)"><a class="btn  addtocart btn--black btn--black-hover-green ">SUBSCRIBE</a></div> -->
                    </div>
                    <!-- <p>Available : {{option.Avl_Qry}}</p> -->
                    <!-- End Product Content -->
                  </div>
                  <!-- End Single Default Product -->

                  <!--VISIBLE ONLY ON MOBILE STRAT-->

                  <!-- Start Single Default Product -->
                  <div class="
                      row
                      product__box product__default--singles
                      d-block d-sm-none
                    " style="background-color: #fff; border-radius: 10px;">
                    <!-- Start Product Image -->
                    <div class="row align-items-center rowforsmall" style="margin: 3px;padding: 0;">
                      <div class="pos-relative text-center col-12 imgholder onlysmallview"
                        style="height: 98px;padding-top: 3px;">
                        <a routerLink="/home/shopallitems" class="product__img--link">
                          <img class="product__img img-fluid" [src]="option.product_image"
                            [alt]="option.product_name" />
                        </a>
                        <p *ngIf="option.package_highlight !=''" class="mb-0 heading-for-highlighter">
                          {{option.package_highlight}}</p>

                        <!-- Start Product Action Link-->
                        <ul class="product__action--link pos-absolute">
                          <!-- <li><a href="#modalAddCart" data-toggle="modal"><i
                                                              class="icon-shopping-cart"></i></a></li> -->
                          <!-- <li (click)="onProductDetail(option)">
                                                      <a href="#modalQuickView" data-toggle="modal"><i
                                                              class="icon-eye"></i>
                                                      </a>
                                                  </li> -->
                          <li (click)="onProductDetail(option)">
                            <a><i class="icon-eye"></i> </a>
                          </li>
                        </ul>
                        <!-- End Product Action Link -->
                      </div>
                      <!-- Start Product Content -->
                      <div class="product__content col-12">
                        <div>
                          <!-- <p class="mb-0 graycolor" style="font-size: 17px; ">
                            {{ option.brand_name }}
                          </p> -->
                          <a routerLink="/home/shopallitems" class="product__link mt-0 mb-2">{{ option.product_name }}
                          </a>
                          <p class="graycolor mt-1 mb-4 color-primary">
                            {{ option.brand_name }}
                          </p>
                        </div>
                        <div style="height: 50px;" class="pricediv">
                          <div class="product__price m-t-5">
                            <span class="product__price">{{ option.selling_amt }} <del class="mrp-cut"
                                *ngIf="option.product_package_mrp > option.selling_amt">{{option.product_package_mrp}}</del>
                              /{{option.unit_name}}</span><br>
                            <span class="package-contains">{{option.package_contains}}</span>
                          </div>
                          <!-- <p class="mb-0 discount-amt" *ngIf="option.discount_per > 0">
                            {{ option.discount_per | roundoff}}% OFF
                          </p> -->
                        </div>
                        <!-- <select class="mt-2 mb-2">
                                                  <option>6 PC</option>
                              
                                                  <option>6 PC</option>
                                              </select> -->

                        <div class="row">
                          <div class="col-xs-6 pr-0 pl-0">

                            <div class="
                            product-quantity
                            d-flex
                            align-items-center
                            flex-wrap
                            mt-2
                          " *ngIf="option.Added_In_Cart">
                              <!-- <span class="product-var__text graycolor">Quantity: &nbsp; </span> -->
                              <form class="modal-quantity-scale ">
                                <div class="value-button" id="modal-decrease" (click)="decreaseValueModal(option)">
                                  -
                                </div>
                                <input style="
                                text-align: center;
                                border: 1px solid #aaabaa;
                                padding: 6px 2px;
                                font-weight: 600;
                              " class="inputvalue" type="number" [value]="option.Cart_Qty"
                                  (change)="onCartQtyChange($event, option)" disabled />
                                <div class="value-button" id="modal-increase" (click)="increaseValueModal(option)">
                                  +
                                </div>
                              </form>
                            </div>
                          </div>
                          <!-- <div class="col-xs-6 text-center mt-2 notcenter" *ngIf=" option.Added_In_Cart"
                            (click)="onSubscribe(option)">
                            <a class=" btn addtocart btn--black btn--black-hover-green">Subscribe</a>
                          </div> -->
                        </div>
                        <div class="row">
                          <div class="col-xs-6 text-center mt-2 notcenter" *ngIf="!option.Added_In_Cart"
                            (click)="onAddToCart(option)">
                            <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                             
                            ">Add To Cart</a>
                          </div>
                          <!-- <div class="col-xs-6 text-center mt-2 notcenter" *ngIf=" !option.Added_In_Cart"
                            (click)="onSubscribe(option)">
                            <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                             
                            ">Subscribe</a>
                          </div> -->
                        </div>
                        <!-- <div class="text-center mt-2 notcenter" *ngIf="!option.Added_In_Cart"
                          (click)="onAddToCart(option)">
                          <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                              text-uppercase
                            ">Add To Cart</a>
                        </div> -->
                        <!-- <div class="text-center mt-2 notcenter" *ngIf="!option.Added_In_Cart"
                          (click)="onSubscribe(option)">
                          <a class="
                              btn
                              addtocart
                              btn--black btn--black-hover-green
                              text-uppercase
                            ">SUBSCRIBE</a>
                        </div> -->
                      </div>
                      <!-- End Product Content -->
                    </div>
                  </div>
                  <!-- End Single Default Product -->

                  <!--VISIBLE ONLY ON MOBILE END-->
                </div>
              </div>
            </div>
            <div class="tab-pane shop-list" id="sort-list" *ngIf="product_count > 0">
              <div class="row">
                <!-- Start Single List Product -->
                <div class="col-12" *ngFor="let option of searchProductList">
                  <div class="product__box product__box--list">
                    <!-- Start Product Image -->
                    <!-- <div class="own-brand own-brand1">
                      <img alt="" class="img-responsive" src="assets/img/logo/desi.png">
                    </div> -->
                    <div class="product__img-box  pos-relative text-center ">
                      <a routerLink="/home/productdetails" class="product__img--link">
                        <img (click)="onProductDetail(option)" class="product__img img-fluid mt-3"
                          [src]="option.product_image" [alt]="option.product_name">
                      </a>
                      <p *ngIf="option.package_highlight !=''" class="mb-0 heading-for-highlighter">
                        {{option.package_highlight}}</p>
                    </div> <!-- End Product Image -->
                    <!-- Start Product Content -->
                    <div class="product__content" style="margin-right: -1px; margin-top: -1px;">

                      <p class="mb-0 graycolor" style="font-size: 17px;margin-bottom: 7px !important;">
                        {{option.brand_name}}
                      </p>
                      <a routerLink="/home/shopallitems" class="product__link mt-0 mb-2">{{option.product_name}} &nbsp;
                        {{option.brand_name}}
                        <!-- {{option.package_qty}} {{option.unit_name}} -->
                      </a>

                      <div class="product__price m-t-5">
                        <span class="product__price">{{option.selling_amt }} <del class="mrp-cut"
                            *ngIf="option.product_package_mrp > option.selling_amt">{{option.product_package_mrp}}</del>
                          /{{option.unit_name}}</span><br>
                        <span class="package-contains">{{option.package_contains}}</span>
                      </div>
                      <!-- <p class="mb-0 discount-amt" *ngIf="option.discount_per > 0">
                        {{option.discount_per}}% OFF
                      </p> -->

                      <!-- Start Product Action Link-->
                      <ul class="product__action--link-list m-t-30">
                        <div class="product-quantity  d-flex align-items-center justify-content-center flex-wrap mt-4"
                          *ngIf="option.Added_In_Cart">
                          <span class="product-var__text graycolor">Quantity: &nbsp; </span>
                          <form class="modal-quantity-scale ml-1">
                            <div class="value-button" id="modal-decrease" (click)="decreaseValueModal(option)">-</div>
                            <input style="text-align: center;border: 1px solid #aaabaa;padding: 6px;font-weight: 600;"
                              type="number" [value]="option.Cart_Qty" (change)="onCartQtyChange($event,option)"
                              disabled />
                            <div class="value-button" id="modal-increase" (click)="increaseValueModal(option)">+</div>
                          </form>
                        </div>
                        <div class="text-center mt-4" *ngIf="!option.Added_In_Cart" (click)="onAddToCart(option)"><a
                            class="btn  addtocart btn--black btn--black-hover-green text-uppercase">Add To Cart</a>
                        </div>
                        <br>
                        <!-- <div class="text-center mt-4" *ngIf="!option.Added_In_Cart" (click)="onSubscribe(option)"><a
                            class="btn  addtocart btn--black btn--black-hover-green text-uppercase">SUBSCRIBE</a></div> -->

                      </ul> <!-- End Product Action Link -->
                    </div> <!-- End Product Content -->
                  </div>
                </div>
                <!-- End Single List Product -->
              </div>
            </div>
          </div>
          <!-- <div *ngIf="searchProductList.length == 0" class="row noprow" style="background: #faf9f5;padding: 60px 40px;align-items: center;
                    margin-top: 30px;">
            <div class="col-md-7 ">
              <h1 class="mb-5 sorryclass" style="  font-family:'MarlinGeo';  font-size: 37px;
                            font-weight: 600;line-height: 1.2;
                            color: #395a26;">No product available in selected category...</h1>
              <h4 style="    font-size: 18px;
                            font-weight: 600;">We're sorry — Please continue shopping with other products.</h4>
              <p style="    font-size: 17px;">We will deliver your product within 2,3 working days.</p>
            </div>
            <div class="col-md-5">
              <img src="assets/img/product/noproduct.png" class="img-responsive">
            </div>
          </div> -->
        </div>

        <!-- <div class="page-pagination" *ngIf="product_count > 0">
          <ul class="page-pagination__list">
            <li class="page-pagination__item"><a class="page-pagination__link"
                (click)="setPage(this.pageNo - 1, 'Prev')"
                [class.disabledATag]="paginator.length == 1 || checkFirstNumber()">Prev</a>
            <li class="page-pagination__item" *ngFor="let option of paginator">
              <a class="page-pagination__link" [class.active]="option == pageNo"
                (click)="setPage(option, 'number')">{{option}}</a>
            </li>
            <li class="page-pagination__item"><a class="page-pagination__link"
                (click)="setPage(this.pageNo + 1, 'Next')"
                [class.disabledATag]="paginator.length == 1 || checkLastNumber()">Next</a>
            </li>
          </ul>
        </div> -->

      </div>

    </div>
  </div>
</main>
<!-- :::::: End MainContainer Wrapper :::::: -->