import { environment } from './../../../../environments/environment.prod';
import { ReqCheckOut } from './../../../models/desifarm-req/desifarms-mycart/desifarms-checkout/reqcheckout';
import { FormControl, Validators } from '@angular/forms';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DesifarmLoginService } from 'src/app/services/desifarm-auth/desifarm-login/desifarm-login.service';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';
import { DesifarmAddressService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-address/desifarm-address.service';
import { DesifarmHeaderService } from 'src/app/services/desifarm-header/desifarm-header.service';
import { ResDistrict } from 'src/app/models/desifarm-res/desifarm-header/resdistrict';
import { ResSubArea } from 'src/app/models/desifarm-res/desifarm-header/ressubarea';
import { DesifarmAddressComponent } from './desifarm-address/desifarm-address.component';
import { Location } from 'src/app/models/desifarm-common/desifarm-local/desifarm-location';
import { DesifarmCheckoutService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-checkout.service';
import { DesifarmsDeliveryTimeslotComponent } from './desifarms-delivery-timeslot/desifarms-delivery-timeslot.component';
import { DatePipe } from '@angular/common'
import { IcustomeWindow, WindowrefService } from 'src/app/services/desifarm-mycart/desifarm-checkout/desifarm-windowRef/windowref.service';
import * as moment from 'moment';
import { DesifarmWalletService } from 'src/app/services/desifarm-dashboard/desifarm-wallet/desifarm-wallet.service';
import { DesifarmVnfAreasService } from 'src/app/services/desifarm-vnf-areas/desifarm-vnf-areas.service';
import { DesifarmSubscribeWalletTopupComponent } from '../../desifarm-subscribe-cart/desifarm-subscribe-wallet-topup/desifarm-subscribe-wallet-topup.component';
import { DesifarmWalletTopupComponent } from '../../desifarms-dashboard/desifarms-mywallet/desifarm-wallet-topup/desifarm-wallet-topup.component';
import { DesifarmsPayOnlineComponent } from './desifarms-pay-online/desifarms-pay-online.component';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-desifarms-checkout',
  templateUrl: './desifarms-checkout.component.html',
  styleUrls: ['./desifarms-checkout.component.scss']
})
export class DesifarmsCheckoutComponent implements OnInit, OnDestroy {

  address: any[] = []
  districts: ResDistrict[] = []
  subAreas: ResSubArea[] = []
  serviceableAreasPinCode: any[] = []

  cartProduct: any[] = []

  Value_Of_Products: number = 0
  Value_Of_Discount: number = 0
  Value_Of_Base_Amt: any = 0
  Value_Of_VAT_Amt: any = 0
  Value_Of_Sub_Total: number = 0
  Value_Of_Total: number = 0
  Delivery_Chargess: number = 0
  Wallet_Amount_Used: number = 0
  walletAmountCount: number = 0
  minimumOrderValue: number = 0

  loginData: any
  location: Location

  form: FormGroup

  addressId: number = 0
  paymentMode: string
  isProceed: boolean = true
  isPinCode: boolean = false
  isFranchiseIdNotMatch: boolean = false
  isOpenGstDetail: boolean = false
  isDeliveryTimeSlot: boolean = false
  delivery_date_time: any = ''
  pinCode: string

  paymentModeRadio = null;

  @ViewChild('closeModal') closeModal: ElementRef


  //CartProducts Strings
  Product_Id: any[] = []
  Product_Packaging_Rate_Id: any[] = []
  Product_Name: any[] = []
  Package_Qty: any[] = []
  Unit_Name: any[] = []
  Unit_Id: any[] = []
  Short_Description: any[] = []
  CGST_Per: any[] = []
  SGST_Per: any[] = []
  IGST_Per: any[] = []
  HSN_Code: any[] = []
  Product_Package_MRP: any[] = []
  Discount_Per: any[] = []
  Selling_Amt: any[] = []
  Order_Qty: any[] = []
  Total_Discount_Amount: any[] = []
  Total_Selling_Amount: any[] = []
  Delivery_Charges: any[] = []
  CGST_Amount: any[] = []
  SGST_Amount: any[] = []
  IGST_Amount: any[] = []
  Product_Status: any[] = []
  ODM_Delivery_Boy_Id: any[] = []
  Order_Date_Time: any[] = []
  Order_Delivered_Date: any[] = []
  Is_Dairy_Product: any[] = []

  orderDetails: any
  _window: IcustomeWindow
  option: any
  rzp: any

  vnfareaslist: any

  Active_Subscription: any

  subsOnlyDairyProdFlag: boolean = false // Subscription Customer Only Dairy Product
  subsOnlyVnfProdFlag: boolean = false // Subscription Customer Only Vnf Product
  subsBothDairyAndVnfProdFlag: boolean = false // Subscription Customer Both Dairy and Vnf Product
  oneTimeOnlyDairyProdFlag: boolean = false // Onetime Customer Only Dairy Product
  oneTimeOnlyVnfProdFlag: boolean = false //  Onetime Customer Only Vnf Product
  oneTimeBothDairyAndVnfProdFlag: boolean = false //  Onetime Customer Both Dairy And Vnf Product


  selectedDistrict: any


  constructor(
    private _fb: FormBuilder,
    private _commonService: DesifarmCommonService,
    private _localCartService: DesifarmLocalCartService,
    private _addressService: DesifarmAddressService,
    private _headerService: DesifarmHeaderService,
    private _checkOutService: DesifarmCheckoutService,
    private _walletServiceService: DesifarmWalletService,
    private _vnfareasServiceService: DesifarmVnfAreasService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private zone: NgZone,
    private winRef: WindowrefService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute
  ) {
    this._localCartService.selectedDistrict.subscribe((District_Id) => {
      this.selectedDistrict = District_Id
    })
    this._window = this.winRef.nativeWindow
  }

  getData() {
    this.Wallet_Amount_Used = 0
    this.location = this._commonService.getData(environment.locationKey)
    // this.location.Min_Order_Amount == null ? 0 : this.location.Min_Order_Amount
    this.loginData = this._commonService.getUser()
    this._vnfareasServiceService.get_vandfareas().subscribe((res: any) => {
      let data = res.data
      let result = data.map(e => e.Pincode_Area)
      this.vnfareaslist = result.toString().replace(/,/g, ", ")
    })

    this.cartCalculation()

    this._addressService.get_customer_address(this.loginData.Cust_Id).subscribe((res: any) => {
      res.data.map(e => {
        e.isShow = false
        e.Selected_District_Id = this.selectedDistrict
        e.Invalid_City = e.District_Id != null ? e.District_Id == this.selectedDistrict ? false : true : false
      })
      this.address = res.data
    })

    this._headerService.get_district().subscribe((res: DesifarmAck) => {
      this.districts = res.data
    })

    this._checkOutService.check_pincode().subscribe((res: any) => {
      this.serviceableAreasPinCode = res.data
    })

    this.getWalletAmount()
    this.getCustomerSubscriptionCount(this.loginData.Cust_Id)

  }

  getCustomerSubscriptionCount(Cust_Id) {
    this._checkOutService.get_customer_active_subscription_count(Cust_Id).subscribe((res: any) => {
      if (res.ack) {
        this.Active_Subscription = res.data.Active_Subscription
        this.subsOnlyDairyProdFlag = this.Active_Subscription > 0 ? this.cartProduct.every(this.subsOnlyDairyProduct) : false
        this.subsOnlyVnfProdFlag = this.Active_Subscription > 0 ? this.cartProduct.every(this.subsOnlyVnfProduct) : false
        this.subsBothDairyAndVnfProdFlag = this.Active_Subscription > 0 ? this.subsBothProduct(this.cartProduct) : false
        this.oneTimeOnlyDairyProdFlag = this.Active_Subscription === 0 ? this.cartProduct.every(this.oneTimeOnlyDairyProduct) : false
        this.oneTimeOnlyVnfProdFlag = this.Active_Subscription === 0 ? this.cartProduct.every(this.oneTimeOnlyVnfProduct) : false
        this.oneTimeBothDairyAndVnfProdFlag = this.Active_Subscription === 0 ? this.oneTimeBothProduct(this.cartProduct) : false
      }
    })
  }

  cartCalculation() {
    this._localCartService.cartList.subscribe((res) => {
      res.map(e => {
        e.Product_Total = e.Cart_Qty * e.selling_amt
        e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
        e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
        e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
        e.Total_Discount_Amount = ((e.product_package_mrp * e.Cart_Qty) * e.discount_per / 100)
        e.Total_Discount_Amount = e.Total_Discount_Amount.toFixed(2)

        e.Total_CGST_Amount = e.Cart_Qty * e.CGST_Amount
        e.Total_SGST_Amount = e.Cart_Qty * e.SGST_Amount
        e.Total_IGST_Amount = e.Cart_Qty * e.IGST_Amount
      })
      if (res.length > 0) {
        this.Value_Of_Products = res
          .map(e => e.Value_Of_Product)
          .reduce((prev, next) => prev + next).toFixed(2)

        this.Value_Of_Discount = res
          .map(e => e.Value_Of_Discount)
          .reduce((prev, next) => prev + next).toFixed(2)

        this.Value_Of_Sub_Total = res
          .map(e => e.Value_Of_Sub_Total)
          .reduce((prev, next) => prev + next).toFixed(2)

        this.Value_Of_Total = res
          .map(e => e.Value_Of_Sub_Total)
          .reduce((prev, next) => prev + next, 0)

        this.Value_Of_Base_Amt = res.map(e => e.base_amt * e.Cart_Qty).reduce((prev, next) => prev + next, 0).toFixed(2)
        this.Value_Of_VAT_Amt = res.map(e => e.vat_amt * e.Cart_Qty).reduce((prev, next) => prev + next, 0).toFixed(2)

        this.Value_Of_Total += this.Delivery_Chargess
        this.Value_Of_Total.toFixed(2)

        this.cartProduct = res
      } else {
        this._commonService.router.navigate([''])
      }
    })
  }

  // Checking Cart Products for COD Buttons
  subsOnlyDairyProduct = (product) => product.Is_Dairy_Product == 'true'
  subsOnlyVnfProduct = (product) => product.Is_Dairy_Product == 'false'
  subsBothProduct = (product) => {
    const vnf = product.some(element => element.Is_Dairy_Product == 'false')
    const dairy = product.some(element => element.Is_Dairy_Product == 'true')
    return vnf && dairy ? true : false
  }
  oneTimeOnlyDairyProduct = (product) => product.Is_Dairy_Product == 'true'
  oneTimeOnlyVnfProduct = (product) => product.Is_Dairy_Product == 'false'
  oneTimeBothProduct = (product) => {
    const vnf = product.some(element => element.Is_Dairy_Product == 'false')
    const dairy = product.some(element => element.Is_Dairy_Product == 'true')
    return vnf && dairy ? true : false
  }



  getWalletAmount() {
    this._walletServiceService.get_wallet_amount(this.loginData.Cust_Id).subscribe((res: any) => {
      this._walletServiceService.walletAmountCount.next(res.data)
      this.walletAmountCount = res.data
    })
  }

  initForm() {
    this.form = this._fb.group({
      Company_Id: ['1'],
      Financial_Year_Id: ['1'],
      Franchise_Id: ['', [Validators.required]],
      Order_status: ['New'],
      Order_Cancelation_Reason: [''],
      Instruction: [''],
      Remark: [''],
      Payment_Mode: ['', [Validators.required]],
      Payment_Transaction_ID: [''],
      Customer_Delivery_Address_State_Id: [''],
      Franchise_State_Id: [''],
      Company_State_Id: [''],
      Cust_Id: [this.loginData.Cust_Id],
      Cust_Name: [this.loginData.Cust_Name],
      Mobile_Number: [this.loginData.Customer_Mobile_Number],
      Email_Id: [this.loginData.Email_Id],
      Order_From: ['Web'],
      Delivery_Address_Id: ['', [Validators.required]],
      Full_Name: ['', [Validators.required]],
      Mobile_No_Address: ['', [Validators.required]],
      Flat_House_No: ['', [Validators.required]],
      Area_Colony_Sreet_Name: ['', [Validators.required]],
      Landmark: [''],
      Pincode: [''],
      City: [''],
      Area_Name: ['', [Validators.required]],
      Sub_Area_Name: [''],
      Sub_Area_Id: [''],
      Address_Type: [''],
      Delivery_LT: ['0'],
      Delivery_LG: ['0'],
      Invoice_Prefix: [''],
      Invoice_No: [''],
      Invoice_Date_Time: [''],
      Delivery_Boy_Id: ['0'],
      Order_Delivery_Charges: ['0'],
      Delivery_Slot_Id: ['', [Validators.required]],
      Slot_Name: ['', [Validators.required]],
      Customer_Delivery_Date: ['', [Validators.required]],
      Slot_Start_Time: ['', [Validators.required]],
      Actual_Delivery_Date_Time: ['0000-00-00 00:00:00'],
      Wallet_Amount_Used: ['0'],
      Referral_Code: [''],
      Area_Id: [''],
      Apartment_Id: [''],
      // Pincode_No: ['', Validators.required],
    })
  }

  getParams = () => {
    this._route.queryParams.subscribe((params) => {
      if (params.msg) {
        params.ack == 'true' ? this._commonService.onSuccess(params.msg, 20000) : this._commonService.onFailed(params.msg, 600000)
      }
    })
  }

  ngOnInit(): void {
    this.location = this._commonService.getData(environment.locationKey)
    this.loginData = this._commonService.getUser()
    this.getData()
    this.initForm()
    this.form.patchValue({
      Referral_Code: this.loginData.Referral_Code
    })
    this.getParams()
  }

  onCity(event) {
    let district = this.districts.find(e => e.District_Id == event.target.value)
    this.form.patchValue({
      District_Id: district.District_Id
    })
    this.getSubArea(district.District_Id)
  }

  onArea(event) {
    let area = this.subAreas.find(e => e.Name == event.target.value)
    this.form.patchValue({
      Pincode: area.Picode,
      Sub_Area_Id: area.Sub_Area_Id
    })
  }

  getSubArea(District_Id) {
    this._headerService.get_sub_area(District_Id).subscribe((res: any) => {
      res.data.map(e => e.Name = `${e.Sub_Area_Name}, ${e.Area_Name}`)
      this.subAreas = res.data
    })
  }

  submit() {
  }

  onAddress() {
    const dialogRef = this._dialog.open(DesifarmAddressComponent, {
      width: '1000px',
      height: '80vh',
      panelClass: 'checkout-address-modal',
    })
    dialogRef.afterClosed().subscribe(() => this.getData())
  }

  onDelivery() {
    const dialogRef = this._dialog.open(DesifarmsDeliveryTimeslotComponent, {
      width: '1000px',
      height: 'auto',
      panelClass: 'checkout-address-modal',
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.isDeliveryTimeSlot = false
        this.delivery_date_time = `${this.datePipe.transform(result.Delivery_Date, 'mediumDate')} ${result.Slot_Name}`
        this.form.patchValue({
          Delivery_Slot_Id: result.Delivery_Slot_Id,
          Slot_Name: result.Slot_Name,
          Customer_Delivery_Date: result.Delivery_Date,
          Slot_Start_Time: result.From_Time
        })
        this.checkProccedStatus()
      } else {
        this.isDeliveryTimeSlot = true
      }

    })
  }

  checkProccedStatus() {
    if (this.paymentMode != undefined && this.addressId != 0 && this.form.valid && this.Value_Of_Sub_Total >= this.minimumOrderValue) this.isProceed = false
    else this.isProceed = true
  }

  onEdit(option) {
    const dialogRef = this._dialog.open(DesifarmAddressComponent, {
      width: '1000px',
      height: '80vh',
      panelClass: 'checkout-address-modal',
      data: option
    })
    dialogRef.afterClosed().subscribe(() => {
      this.getData()
      if (this.paymentMode == 'Online') {
        this.onRemove()
        this.cartCalculation()
        this.checkProccedStatus()
      }
    })
  }

  onDelete(option) {
    if (confirm("Are You Sure To Delete This Address")) {
      this._commonService._spinner.show()
      this._addressService.delete_customer_address(option.Delivery_Address_Id).subscribe((res: any) => {
        this._commonService._spinner.hide()
        if (res.ack) {
          this.getData()
          this._commonService.onSuccess(res.msg)
        } else this._commonService.onFailed(res.msg)
      }, (err) => {
        this._commonService._spinner.hide()
        this._commonService.onFailed(err)
      })
    }
  }


  checkValue(event, value) {
    var date = new Date();

    // add a day
    date.setDate(date.getDate() + 1);
    if (value == 'address') {
      this.resetAddressErrMsg()
      this.isPinCode = false
      // this._commonService._spinner.show()
      this.isFranchiseIdNotMatch = false
      this.addressId = event.target.value
      let address = this.address.find(e => e.Delivery_Address_Id == event.target.value)
      this.isPinCode = false
      this.form.patchValue({
        Delivery_Address_Id: address.Delivery_Address_Id,
        Full_Name: address.Full_Name,
        Mobile_No_Address: address.Mobile_No_Address,
        Flat_House_No: address.Flat_House_No,
        Area_Colony_Sreet_Name: address.Area_Colony_Sreet_Name,
        Landmark: address.Landmark,
        Pincode: address.Pincode,
        City: address.District_Name,
        Area_Id: address.Area_Id,
        Area_Name: address.Area_Name,
        Sub_Area_Name: address.Sub_Area_Name,
        Sub_Area_Id: address.Sub_Area_Id,
        Address_Type: address.Address_Type,
        Franchise_Id: address.Franchise_Id,
        Customer_Delivery_Address_State_Id: address.Customer_Delivery_Address_State_Id,
        // Delivery_Slot_Id: 1,
        // Slot_Name: "Tomorrow",
        // Customer_Delivery_Date: moment(date).format('YYYY-MM-DD'),
        // Slot_Start_Time: "11:30:00",
        Apartment_Id: address.Apartment_Id,
      })
      this.onDelivery()
      this.Delivery_Chargess = 0
      this.minimumOrderValue = 0
      this.Delivery_Chargess = address.Delivery_Charges
      this.minimumOrderValue = address.Min_Order_Amount
      // this.Value_Of_Total += this.Delivery_Chargess
      this.cartCalculation()
      this.checkProccedStatus()
      if (this.paymentMode == 'Online') {
        this.onRemove()
        this.cartCalculation()
        this.checkProccedStatus()
      }
    } else if (value == 'payment') {
      this.onRemove()
      this.paymentMode = event.target.value
      this.form.patchValue({
        Payment_Mode: this.paymentMode,
        Order_status: this.paymentMode == 'Network_Payment' ? 'Pending' : 'New'
      })
      this.cartCalculation()
    }
    this.checkProccedStatus()
  }

  showAddressErrMsg(Delivery_Address_Id) {
    this.address.map(e => e.Delivery_Address_Id == Delivery_Address_Id ? e.isShow = true : e.isShow = false)
  }

  resetAddressErrMsg() {
    this.address.map(e => e.isShow = false)
  }

  closeGstDialog = () => this.isOpenGstDetail = !this.isOpenGstDetail

  handleWalletRedeem() {
    if (this.walletAmountCount == this.Value_Of_Total || this.walletAmountCount > this.Value_Of_Total) {
      let amountToBeMinus = this.walletAmountCount
      let valueToBeMinus = this.Value_Of_Total
      this.Wallet_Amount_Used = valueToBeMinus
      this.Value_Of_Total = this.Value_Of_Total - valueToBeMinus
      this.walletAmountCount = amountToBeMinus - valueToBeMinus
      this.form.patchValue({ Wallet_Amount_Used: this.Wallet_Amount_Used, Payment_Mode: "Online", Order_status: "New" })
      this.paymentMode = "Online"
      this.checkProccedStatus()
    } else {
      this.paymentMode = null
      this.paymentModeRadio = null

      this.form.controls.Payment_Mode.reset()
      this.checkProccedStatus()
      this.onWalletTopupFromRedeem()
    }
  }

  onRedeem() {
    if (this.walletAmountCount > 0) {
      if (this.walletAmountCount <= this.Value_Of_Total) {
        let amountToBeMinus = this.walletAmountCount
        this.Value_Of_Total = this.Value_Of_Total - this.walletAmountCount
        this.walletAmountCount = this.walletAmountCount - amountToBeMinus
        this.Wallet_Amount_Used = amountToBeMinus
        this.form.patchValue({ Wallet_Amount_Used: this.Wallet_Amount_Used })
      } else if (this.walletAmountCount > this.Value_Of_Total) {
        let amountToBeMinus = this.walletAmountCount
        let valueToBeMinus = this.Value_Of_Total
        this.Wallet_Amount_Used = valueToBeMinus
        this.Value_Of_Total = this.Value_Of_Total - valueToBeMinus
        this.walletAmountCount = amountToBeMinus - valueToBeMinus
        this.form.patchValue({ Wallet_Amount_Used: this.Wallet_Amount_Used })
      } else {
        this.paymentModeRadio = null

        this.onWalletTopupFromRedeem()
      }
    } else {
      this.paymentModeRadio = null

      this.onWalletTopupFromRedeem()
    }
  }

  onWalletTopupFromRedeem() {
    let data = { Recharge_Amount: Math.ceil(this.Value_Of_Total - this.walletAmountCount) }

    const dialogRef = this._dialog.open(DesifarmSubscribeWalletTopupComponent, {
      width: '500px',
      // height: '60vh',
      panelClass: 'wallet-topup',
      data: data
    })
    dialogRef.afterClosed().subscribe(() => {
      this.paymentMode = null
      this.paymentModeRadio = null

      this.form.controls.Payment_Mode.reset()
      this.checkProccedStatus()
      this.getWalletAmount()
    })
  }

  onRemove() {
    this.form.controls.Payment_Mode.reset()
    this.checkProccedStatus()
    this.Value_Of_Total = this.cartProduct
      .map(e => e.Value_Of_Sub_Total)
      .reduce((prev, next) => prev + next)

    this.Value_Of_Total += this.Delivery_Chargess
    this.Value_Of_Total.toFixed(2)

    let amountToPlus = this.Wallet_Amount_Used
    this.walletAmountCount = this.walletAmountCount + amountToPlus
    this.Wallet_Amount_Used = 0
    this.form.patchValue({ Wallet_Amount_Used: this.Wallet_Amount_Used })
    this.paymentModeRadio = null

  }

  proceed() {

    if (this.form.valid && this.Value_Of_Sub_Total >= this.minimumOrderValue) {
      // Place order working till 03-03-23
      this.placeOrder()
    } else if (this.Value_Of_Sub_Total < this.minimumOrderValue) {
      // show error msg 
      this._commonService.onFailed(`Order Amount Should be ${this.minimumOrderValue} or greater `)
    } else if (this.isProceed) {
      // show error msg
      this._commonService.onFailed("Address or payment mode not selected")
    }
  }

  async placeCartProducts() {
    await this.cartProduct.forEach((e) => {
      e.product_name = e.product_name.replace(/,/g, " ")
      this.Product_Id.push(e.product_id)
      this.Product_Packaging_Rate_Id.push(e.product_packaging_rate_id)
      this.Product_Name.push(e.product_name)
      this.Package_Qty.push(e.package_qty)
      this.Unit_Name.push(e.unit_name)
      this.Unit_Id.push("")
      this.Short_Description.push(e.short_description)
      this.CGST_Per.push(e.cgst_per)
      this.SGST_Per.push(e.sgst_per)
      this.IGST_Per.push(e.igst_per)
      this.HSN_Code.push(e.hsn_code)
      this.Product_Package_MRP.push(e.product_package_mrp)
      this.Discount_Per.push(e.discount_per)
      this.Selling_Amt.push(e.selling_amt)
      this.Order_Qty.push(e.Cart_Qty)
      this.Total_Discount_Amount.push(e.Value_Of_Discount)
      this.Total_Selling_Amount.push(e.Value_Of_Product)
      this.Delivery_Charges.push(0)
      this.CGST_Amount.push(0)
      this.SGST_Amount.push(0)
      this.IGST_Amount.push(0)
      this.Product_Status.push("New")
      this.ODM_Delivery_Boy_Id.push(0)
      this.Order_Delivered_Date.push("")
    })
  }

  resetArray() {
    this.Product_Id.length = 0
    this.Product_Packaging_Rate_Id.length = 0
    this.Product_Name.length = 0
    this.Package_Qty.length = 0
    this.Unit_Name.length = 0
    this.Unit_Id.length = 0
    this.Short_Description.length = 0
    this.CGST_Per.length = 0
    this.SGST_Per.length = 0
    this.IGST_Per.length = 0
    this.HSN_Code.length = 0
    this.Product_Package_MRP.length = 0
    this.Discount_Per.length = 0
    this.Selling_Amt.length = 0
    this.Order_Qty.length = 0
    this.Total_Discount_Amount.length = 0
    this.Total_Selling_Amount.length = 0
    this.Delivery_Charges.length = 0
    this.CGST_Amount.length = 0
    this.SGST_Amount.length = 0
    this.IGST_Amount.length = 0
    this.Product_Status.length = 0
    this.ODM_Delivery_Boy_Id.length = 0
    this.Order_Date_Time.length = 0
    this.Order_Delivered_Date.length = 0
  }

  onInstruction(event) {
    let instruction = event.target.value
    this.form.patchValue({ Instruction: instruction })
  }

  placeOrder() {
    this.resetArray()
    this.cartProduct.forEach((e) => {
      e.product_name = e.product_name.replace(/,/g, " ")
      this.Product_Id.push(e.product_id)
      this.Product_Packaging_Rate_Id.push(e.product_packaging_rate_id)
      this.Product_Name.push(e.product_name)
      this.Package_Qty.push(e.package_qty)
      this.Unit_Name.push(e.unit_name)
      this.Unit_Id.push("")
      this.Short_Description.push(e.short_description)
      this.CGST_Per.push(e.cgst_per)
      this.SGST_Per.push(e.sgst_per)
      this.IGST_Per.push(e.igst_per)
      this.HSN_Code.push(e.hsn_code)
      this.Product_Package_MRP.push(e.product_package_mrp)
      this.Discount_Per.push(e.discount_per)
      this.Selling_Amt.push(e.selling_amt)
      this.Order_Qty.push(e.Cart_Qty)
      this.Total_Discount_Amount.push(e.Value_Of_Discount)
      this.Total_Selling_Amount.push(e.Value_Of_Sub_Total)
      this.Delivery_Charges.push(0)
      this.CGST_Amount.push(e.CGST_Amount)
      this.SGST_Amount.push(e.SGST_Amount)
      this.IGST_Amount.push(e.IGST_Amount)
      this.Product_Status.push("New")
      this.ODM_Delivery_Boy_Id.push(0)
      this.Order_Delivered_Date.push("")
      this.Is_Dairy_Product.push(e.Is_Dairy_Product)
    })

    this._commonService._spinner.show()
    let forms = this.form.value
    let parameter = new ReqCheckOut()
    parameter.Company_Id = "1"
    parameter.Financial_Year_Id = "1"
    parameter.Franchise_Id = forms.Franchise_Id
    parameter.Order_status = forms.Order_status
    parameter.Order_Cancelation_Reason = forms.Order_Cancelation_Reason
    parameter.Instruction = forms.Instruction
    parameter.Remark = forms.Remark
    parameter.Payment_Mode = forms.Payment_Mode
    parameter.Payment_Transaction_ID = forms.Payment_Transaction_ID
    parameter.Customer_Delivery_Address_State_Id = forms.Customer_Delivery_Address_State_Id
    parameter.Franchise_State_Id = 1
    // parameter.Franchise_State_Id = this.location.Franchise_State_Id == null ? 1 : this.location.Franchise_State_Id
    parameter.Company_State_Id = "1"
    parameter.Cust_Id = forms.Cust_Id
    parameter.Cust_Name = forms.Cust_Name
    parameter.Mobile_Number = forms.Mobile_Number
    parameter.Email_Id = forms.Email_Id
    parameter.Order_From = forms.Order_From
    parameter.Delivery_Address_Id = forms.Delivery_Address_Id
    parameter.Full_Name = forms.Full_Name
    parameter.Mobile_No_Address = forms.Mobile_No_Address
    parameter.Flat_House_No = forms.Flat_House_No
    parameter.Area_Colony_Sreet_Name = forms.Area_Colony_Sreet_Name
    parameter.Landmark = forms.Landmark
    parameter.Pincode = forms.Pincode
    parameter.City = forms.City
    parameter.Area_Name = forms.Area_Name
    parameter.Sub_Area_Name = forms.Sub_Area_Name == null ? "" : forms.Sub_Area_Name
    parameter.Sub_Area_Id = forms.Sub_Area_Id == null ? 0 : forms.Sub_Area_Id
    parameter.Address_Type = forms.Address_Type
    parameter.Delivery_LT = forms.Delivery_LT
    parameter.Delivery_LG = forms.Delivery_LG
    parameter.Invoice_Prefix = forms.Invoice_Prefix
    parameter.Invoice_No = forms.Invoice_No
    parameter.Invoice_Date_Time = forms.Invoice_Date_Time
    parameter.Delivery_Boy_Id = forms.Delivery_Boy_Id
    parameter.Order_Delivery_Charges = this.Delivery_Chargess
    parameter.Delivery_Slot_Id = forms.Delivery_Slot_Id
    parameter.Slot_Name = forms.Slot_Name
    parameter.Customer_Delivery_Date = forms.Customer_Delivery_Date
    parameter.Slot_Start_Time = forms.Slot_Start_Time
    parameter.Actual_Delivery_Date_Time = forms.Actual_Delivery_Date_Time
    parameter.Business_Type_Id = environment.Business_Type_Id
    parameter.Wallet_Amount_Used = forms.Wallet_Amount_Used
    parameter.Referral_Code = forms.Referral_Code
    parameter.Area_Id = forms.Area_Id
    parameter.Apartment_Id = forms.Apartment_Id

    parameter.Product_Id = this.Product_Id.toString()
    parameter.Product_Packaging_Rate_Id = this.Product_Packaging_Rate_Id.toString()
    parameter.Product_Name = this.Product_Name.toString()
    parameter.Package_Qty = this.Package_Qty.toString()
    parameter.Unit_Name = this.Unit_Name.toString()
    parameter.Unit_Id = this.Unit_Id.toString()
    parameter.Short_Description = this.Short_Description.toString()
    parameter.CGST_Per = this.CGST_Per.toString()
    parameter.SGST_Per = this.SGST_Per.toString()
    parameter.IGST_Per = this.IGST_Per.toString()
    parameter.HSN_Code = this.HSN_Code.toString()
    parameter.Product_Package_MRP = this.Product_Package_MRP.toString()
    parameter.Discount_Per = this.Discount_Per.toString()
    parameter.Selling_Amt = this.Selling_Amt.toString()
    parameter.Order_Qty = this.Order_Qty.toString()
    parameter.Total_Discount_Amount = this.Total_Discount_Amount.toString()
    parameter.Total_Selling_Amount = this.Total_Selling_Amount.toString()
    parameter.Delivery_Charges = this.Delivery_Charges.toString()
    parameter.CGST_Amount = this.CGST_Amount.toString()
    parameter.SGST_Amount = this.SGST_Amount.toString()
    parameter.IGST_Amount = this.IGST_Amount.toString()
    parameter.Product_Status = this.Product_Status.toString()
    parameter.ODM_Delivery_Boy_Id = this.ODM_Delivery_Boy_Id.toString()
    parameter.Order_Delivered_Date = this.Order_Delivered_Date.toString()
    parameter.Is_Dairy_Product = this.Is_Dairy_Product.toString()
    parameter.Active_Subscription = this.Active_Subscription
    parameter.Current_Url = '/order-success'
    // console.log(parameter)

    this._checkOutService.place_cart_orders(parameter).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.getWalletAmount()
        this._commonService.setData("placed", JSON.stringify(res.data[0]))
        if (parameter.Order_status == 'Pending') {
          window.open(res.Payment_Url, '_self')
        } else {
          this._commonService.router.navigate(['order-success'])
          this._commonService.onSuccess(res.msg)
        }
      } else {
        this._commonService.onFailed(res.msg)
      }
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed(err)
    })
  }

  placeOnlineOrder() {
    this.resetArray()
    this.cartProduct.forEach((e) => {
      e.product_name = e.product_name.replace(/,/g, " ")
      this.Product_Id.push(e.product_id)
      this.Product_Packaging_Rate_Id.push(e.product_packaging_rate_id)
      this.Product_Name.push(e.product_name)
      this.Package_Qty.push(e.package_qty)
      this.Unit_Name.push(e.unit_name)
      this.Unit_Id.push("")
      this.Short_Description.push(e.short_description)
      this.CGST_Per.push(e.cgst_per)
      this.SGST_Per.push(e.sgst_per)
      this.IGST_Per.push(e.igst_per)
      this.HSN_Code.push(e.hsn_code)
      this.Product_Package_MRP.push(e.product_package_mrp)
      this.Discount_Per.push(e.discount_per)
      this.Selling_Amt.push(e.selling_amt)
      this.Order_Qty.push(e.Cart_Qty)
      this.Total_Discount_Amount.push(e.Value_Of_Discount)
      this.Total_Selling_Amount.push(e.Value_Of_Sub_Total)
      this.Delivery_Charges.push(0)
      this.CGST_Amount.push(e.CGST_Amount)
      this.SGST_Amount.push(e.SGST_Amount)
      this.IGST_Amount.push(e.IGST_Amount)
      this.Product_Status.push("New")
      this.ODM_Delivery_Boy_Id.push(0)
      this.Order_Delivered_Date.push("")
    })

    this._commonService._spinner.show()
    let forms = this.form.value
    let parameter = new ReqCheckOut()
    parameter.Company_Id = "1"
    parameter.Financial_Year_Id = "1"
    parameter.Franchise_Id = forms.Franchise_Id
    parameter.Order_status = forms.Order_status
    parameter.Order_Cancelation_Reason = forms.Order_Cancelation_Reason
    parameter.Instruction = forms.Instruction
    parameter.Remark = forms.Remark
    parameter.Payment_Mode = forms.Payment_Mode
    parameter.Payment_Transaction_ID = forms.Payment_Transaction_ID
    parameter.Customer_Delivery_Address_State_Id = forms.Customer_Delivery_Address_State_Id
    parameter.Franchise_State_Id = 1
    // parameter.Franchise_State_Id = this.location.Franchise_State_Id == null ? 1 : this.location.Franchise_State_Id
    parameter.Company_State_Id = "1"
    parameter.Cust_Id = forms.Cust_Id
    parameter.Cust_Name = forms.Cust_Name
    parameter.Mobile_Number = forms.Mobile_Number
    parameter.Email_Id = forms.Email_Id
    parameter.Order_From = forms.Order_From
    parameter.Delivery_Address_Id = forms.Delivery_Address_Id
    parameter.Full_Name = forms.Full_Name
    parameter.Mobile_No_Address = forms.Mobile_No_Address
    parameter.Flat_House_No = forms.Flat_House_No
    parameter.Area_Colony_Sreet_Name = forms.Area_Colony_Sreet_Name
    parameter.Landmark = forms.Landmark
    parameter.Pincode = forms.Pincode
    parameter.City = forms.City
    parameter.Area_Name = forms.Area_Name
    parameter.Sub_Area_Name = forms.Sub_Area_Name == null ? "" : forms.Sub_Area_Name
    parameter.Sub_Area_Id = forms.Sub_Area_Id == null ? 0 : forms.Sub_Area_Id
    parameter.Address_Type = forms.Address_Type
    parameter.Delivery_LT = forms.Delivery_LT
    parameter.Delivery_LG = forms.Delivery_LG
    parameter.Invoice_Prefix = forms.Invoice_Prefix
    parameter.Invoice_No = forms.Invoice_No
    parameter.Invoice_Date_Time = forms.Invoice_Date_Time
    parameter.Delivery_Boy_Id = forms.Delivery_Boy_Id
    parameter.Order_Delivery_Charges = this.Delivery_Chargess
    parameter.Delivery_Slot_Id = forms.Delivery_Slot_Id
    parameter.Slot_Name = forms.Slot_Name
    parameter.Customer_Delivery_Date = forms.Customer_Delivery_Date
    parameter.Slot_Start_Time = forms.Slot_Start_Time
    parameter.Actual_Delivery_Date_Time = forms.Actual_Delivery_Date_Time
    parameter.Business_Type_Id = environment.Business_Type_Id
    parameter.Wallet_Amount_Used = forms.Wallet_Amount_Used
    parameter.Referral_Code = forms.Referral_Code
    parameter.Area_Id = forms.Area_Id
    parameter.Apartment_Id = forms.Apartment_Id

    parameter.Product_Id = this.Product_Id.toString()
    parameter.Product_Packaging_Rate_Id = this.Product_Packaging_Rate_Id.toString()
    parameter.Product_Name = this.Product_Name.toString()
    parameter.Package_Qty = this.Package_Qty.toString()
    parameter.Unit_Name = this.Unit_Name.toString()
    parameter.Unit_Id = this.Unit_Id.toString()
    parameter.Short_Description = this.Short_Description.toString()
    parameter.CGST_Per = this.CGST_Per.toString()
    parameter.SGST_Per = this.SGST_Per.toString()
    parameter.IGST_Per = this.IGST_Per.toString()
    parameter.HSN_Code = this.HSN_Code.toString()
    parameter.Product_Package_MRP = this.Product_Package_MRP.toString()
    parameter.Discount_Per = this.Discount_Per.toString()
    parameter.Selling_Amt = this.Selling_Amt.toString()
    parameter.Order_Qty = this.Order_Qty.toString()
    parameter.Total_Discount_Amount = this.Total_Discount_Amount.toString()
    parameter.Total_Selling_Amount = this.Total_Selling_Amount.toString()
    parameter.Delivery_Charges = this.Delivery_Charges.toString()
    parameter.CGST_Amount = this.CGST_Amount.toString()
    parameter.SGST_Amount = this.SGST_Amount.toString()
    parameter.IGST_Amount = this.IGST_Amount.toString()
    parameter.Product_Status = this.Product_Status.toString()
    parameter.ODM_Delivery_Boy_Id = this.ODM_Delivery_Boy_Id.toString()
    parameter.Order_Delivered_Date = this.Order_Delivered_Date.toString()

    this._checkOutService.place_online_order(parameter).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this.getWalletAmount()
        this._commonService.setData("placed", JSON.stringify(res.data[0]))
        this.razorpayOnline(res.data[0])
      } else {
        this._commonService.onFailed(res.msg)
      }
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed(err)
    })
  }

  private razorpay() {
    this.option = {
      // key: 'rzp_test_eUjDLjo7mWHo2R',//'rzp_test_xac0VPTP8XZjMi',
      key: environment.rzp_API,
      name: 'NSK Store',
      description: 'Shopping',
      amount: (this.Value_Of_Total * 100),



      prefill: {
        name: this.loginData.Cust_Name,
        email: this.loginData.Email_Id,
        contact: this.loginData.Customer_Mobile_Number
      },
      notes: {},
      theme: {
        color: '#3880FF'
      },
      handler: ((res: any, err: any) => {
        if (res && res.razorpay_payment_id) {
          this.form.patchValue({ Payment_Transaction_ID: res.razorpay_payment_id })
          this.placeOrder();
        } else {
          this._commonService.onFailed("Transaction Failed.. Try Again")
          this._commonService.router.navigate(['/mycart'])

        }
      }),
      modal: {
        ondismiss: (() => {
          this.zone.run(() => {
            //window.location.reload();
            //add current page routing if payment fail
          })
        })
      }
    };

    this.rzp = new this.winRef.nativeWindow['Razorpay'](this.option);
    this.rzp.open();
  }

  private razorpayOnline(data) {
    this.option = {
      key: environment.rzp_API,
      name: 'NSK Store',
      description: 'Shopping',
      amount: (this.Value_Of_Total * 100),
      // order_id: data.Sale_Order_Id,

      prefill: {
        name: this.loginData.Cust_Name,
        email: this.loginData.Email_Id,
        contact: this.loginData.Customer_Mobile_Number
      },
      notes: {
        OrderId: data.Sale_Order_Id,
        CustomerName: data.Cust_Name,
        CustomerMobile: data.Mobile_Number
      },
      theme: {
        color: '#3880FF'
      },
      handler: ((res: any, err: any) => {
        if (res && res.razorpay_payment_id) {
          this.form.patchValue({ Payment_Transaction_ID: res.razorpay_payment_id })
          this.updateOrderStatus(res, data)
        } else {
          this._commonService.onFailed("Status Pending")
          localStorage.removeItem("placed")
          this._localCartService.resetCartProducts()
          this._commonService.router.navigate(['/myorders'])

        }
      }),
      modal: {
        ondismiss: (() => {
          this.zone.run(() => {
            localStorage.removeItem("placed")
            this._localCartService.resetCartProducts()
            this._commonService.router.navigate(['/myorders'])
          })
        })
      }
    };

    this.rzp = new this.winRef.nativeWindow['Razorpay'](this.option);
    this.rzp.open();
  }

  updateOrderStatus(res, detail) {
    let data = { Payment_Transaction_ID: res.razorpay_payment_id, Sale_Order_Id: detail.Sale_Order_Id, Mobile_Number: detail.Mobile_Number }
    this._commonService._spinner.show()
    this._checkOutService.update_order_status(data).subscribe((res: any) => {
      this._commonService._spinner.hide()
      if (res.ack) {
        this._commonService.onSuccess(res.msg)
        this._commonService.router.navigate(['order-success'])
      } else {
        this._commonService.onFailed("Payment Pending")
      }
    }, (err) => {
      this._commonService._spinner.hide()
      this._commonService.onFailed("Payment Pending")
    })
  }

  onPincode(event) {
  }

  handleRecharge() {
    const dialogRef = this._dialog.open(DesifarmWalletTopupComponent, {
      width: '500px',
      // height: '60vh',
      panelClass: 'wallet-topup',
      // data: data
    })
    dialogRef.afterClosed().subscribe((res) => { if (res) this.getWalletAmount() })
  }

  ngOnDestroy() {
    this.addressId = undefined
    this.paymentMode = undefined
    this.isProceed = true
  }

}
