<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb" style="margin-bottom: 0;">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="page-breadcrumb__menu">
          <li class="page-breadcrumb__nav"><a routerLink="/">Home</a></li>
          <li class="page-breadcrumb__nav active">Checkout</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- ::::::  End  Breadcrumb Section  ::::::  -->

<!-- ::::::  Start  Main Container Section  ::::::  -->
<main id="main-container" class="main-container pb-2" style="background: #f1f3f2;">
  <div class="container">

    <div class="row">

      <div class="col-md-8 col-sm-12 mt-1">
        <div class="section-content">
          <h5 class="section-content__title">Address List</h5>
        </div>
        <p class="text-danger" *ngIf="isFranchiseIdNotMatch">Selected Address Not Match with Selected Service Location
        </p>
        <div class="item-holder mt-1" *ngFor="let option of address" [style.opacity]="option.Invalid_City ? '0.6': '1'">
          <span class="custom-badge" *ngIf="option.Is_Verified =='1'">🗸 Verified</span>
          <div class="row align-items-center">
            <div class="col-lg-1 col-md-1 firstdiv col-xs-1" style="padding-left: 30px;">
              <input type="radio" class="shipping-select" name="address" [value]="option.Delivery_Address_Id"
                id="add-express" (input)="checkValue($event, 'address')"
                [disabled]="selectedDistrict != option.District_Id" [style.opacity]="option.Invalid_City ? '0.9': '1'">
            </div>
            <div class="col-lg-7 col-sm-8 col-xs-8">

              <p>{{option.Address_Type}}</p>
              <h2 style="margin-bottom: 5px;">{{option.Full_Name}}</h2>
              <p>
                {{option.Flat_House_No}}, {{option.Area_Colony_Sreet_Name}}, {{option.Landmark}},
                {{option.Pincode}}
              </p>
              <p>
                <b>City</b> : {{option.District_Name}} <b>Area</b> : {{option.Sub_Area_Name}}
                {{option.Area_Name}}
              </p>
              <p style="margin: 5px 0px 0px 0px;" class="text-danger" *ngIf="option.isShow">
                Selected address not in our serviceable area
              </p>
            </div>
            <div class="col-lg-4 col-md-3 col-xs-3" style="text-align: right;">
              <p class="deleteicon mb-4" (click)="onDelete(option)"><i class="far fa-trash-alt"></i></p>
              <a class="btn btn--box btn--extrasmall btn--transparent btn--border-green mtbtn "
                (click)="onEdit(option)">Edit</a>
            </div>
            <div class="col-lg-12 col-md-12 col-xs-12" style="padding-left: 30px;">
              <p class="text-danger" *ngIf="option.Flat_House_No == '' || 
              option.Area_Colony_Sreet_Name == ''" style="padding:15px 0px 5px 45px;">Please update address fields
                Building No/Flat/House No, Apartment/Society Name
              </p>
              <p class="text-danger" *ngIf="option.Invalid_City" style="padding:15px 0px 5px 45px;">
                Address Does Not Belong To Your Selected City
              </p>
            </div>
          </div>
        </div>

        <div class="mt-2 mb-2">
          <a (click)="onAddress()"
            class="btn btn--box btn--small  btn--green btn--green-hover-black btn--uppercase font--semi-bold">Add
            New Delivery Address</a>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 mt-1">
        <div class="section-content">

          <h5 class="section-content__title">Order Summary</h5>
        </div>

        <div class="sidebar__widget mt-1 p-4 pb-2">
          <span class=" mt-1 mb-1" style="font-size: 18px; ">Delivery Time Slot</span>
          <form class="mt-1 mb-1">
            <input id="couponCodeTextBox" class="selectedtimesot-input" name="search" placeholder="Time Slot"
              type="search" [value]="delivery_date_time" disabled>
            <button class="timeslot-changebtn" (click)="onDelivery()">Change</button>
          </form>
          <!-- <span class="text-danger" *ngIf="form.value.Delivery_Address_Id ==''">Select Your Delivery Slot</span> -->
          <hr style="margin-bottom: 0;">
          <div class="row">
            <div class="col-lg-7 col-md-8 col-xs-8 mt-1 mb-1">
              <p>Value of Products </p>
              <p>Discount (-) </p>
              <p>Base Amt </p>
              <p>VAT Amt </p>
              <p>Subtotal </p>
              <p>Delivery Charges (+) </p>
              <p *ngIf="Wallet_Amount_Used != 0">Wallet (-) <span (click)="onRemove()" class="text-danger"
                  style="cursor: pointer;" *ngIf="Wallet_Amount_Used != 0">Remove</span></p>
            </div>
            <div class="col-lg-5 col-md-4 col-xs-4 mt-1 mb-1">
              <p> {{Value_Of_Products | number : '1.0-2'}}</p>
              <p style="color: #62aa00;"> {{Value_Of_Discount| number : '1.0-2' }}</p>
              <!-- |number:'1.2-2' -->
              <p> {{Value_Of_Base_Amt | number : '1.0-2'}}</p>
              <p> {{Value_Of_VAT_Amt | number : '1.0-2'}}</p>
              <p> {{Value_Of_Sub_Total | number : '1.0-2'}}</p>
              <p> {{Delivery_Chargess | number : '1.0-2'}}</p>
              <p *ngIf="Wallet_Amount_Used != 0"> {{Wallet_Amount_Used | number : '1.0-2'}}</p>
            </div>
            <hr class="mt-0 ">
            <div class="col-md-7 col-xs-8">
              <p class="totamt" style="font-size: 22px; cursor: pointer;">Total <br><span
                  style="color:gray;font-size:16px;">(Inclusive of all taxes)</span></p>
              <!-- (click)="closeGstDialog()" -->

            </div>
            <div class="col-md-5 col-xs-4">
              <p class="product__price totamt" style="font-size: 22px ;"> {{Value_Of_Total | number : '1.0-2'}}
              </p>
            </div>
            <hr>
            <div class="col-md-7 col-xs-8">
              <p><i class="fas fa-wallet" style="color: #62aa00;"></i> {{walletAmountCount | number : '1.0-2' }} </p>

            </div>
            <div class="col-md-5 col-xs-4">
              <!-- <p (click)="onRedeem()" *ngIf="Wallet_Amount_Used == 0"><span style="cursor:pointer;color: #62aa00;">Redeem</span></p> -->
            </div>
            <hr>
            <div class="col-md-12 col-xs-12">
              <p class="totamt" (click)="handleRecharge()">
                <a class="rechargeLink">Recharge Your Wallet</a>
              </p>
            </div>
            <hr>
            <div class="col-md-12 col-xs-12">
              <p class="totamt">
                Instructions
              </p>
              <textarea class="form-control" placeholder="Enter Your Instructions"
                (input)="onInstruction($event)"></textarea>
              <br>
            </div>
            <!-- <div class="col-md-5 col-xs-4">
              <p (click)="onRedeem()" *ngIf="Wallet_Amount_Used == 0"><span style="cursor:pointer;color: #395a26;">Redeem</span></p>
            </div> -->
            <hr>
            <p style="font-size: 22px;" class="mt-1 mb-1">Payment</p>
            <ul class="shipping-cost">
              <!-- <li *ngIf="Value_Of_Total > 0">
                <label for="ship-standard">
                  <input type="radio" class="shipping-select" name="shipping-cost" value="Online" id="ship-standard"
                    (input)="checkValue($event, 'payment')"><span>Online Payment</span>
                  (UPI, Net Banking, Debit/Credit Card etc.)
                </label>
              </li> -->
              <li>
                <label for="ship-standard">
                  <input type="radio" class="shipping-select" name="shipping-cost" value="Online" id="ship-standard"
                    (input)="handleWalletRedeem()" [(ngModel)]="paymentModeRadio"><span>Deduct From Wallet</span>
                  (Balance: {{walletAmountCount| number : '1.0-2' }})
                </label>
              </li>

              <li>
                <label for="ship-express">
                  <input type="radio" class="shipping-select" name="shipping-cost" value="COD" id="ship-express"
                    (input)="checkValue($event, 'payment')"><span>Cash On Delivery</span>
                </label>
              </li>

              <li>
                <label for="ship-online">
                  <input type="radio" class="shipping-select" name="shipping-cost" value="Network_Payment" id="ship-online"
                    (input)="checkValue($event, 'payment')"><span>Pay Now</span>
                </label>
              </li>


              <!-- <li *ngIf="subsOnlyVnfProdFlag">
                <label for="ship-express">
                  <input type="radio" class="shipping-select" name="shipping-cost" value="COD" id="ship-express"
                    (input)="checkValue($event, 'payment')"><span>Cash On Delivery</span>
                </label>
              </li>

              <li *ngIf="oneTimeOnlyVnfProdFlag">
                <label for="ship-express">
                  <input type="radio" class="shipping-select" name="shipping-cost" value="COD" id="ship-express"
                    (input)="checkValue($event, 'payment')"><span>Cash On Delivery</span>
                </label>
              </li>

              <li *ngIf="oneTimeBothDairyAndVnfProdFlag">
                <label for="ship-express">
                  <input type="radio" class="shipping-select" name="shipping-cost" value="COD" id="ship-express"
                    (input)="checkValue($event, 'payment')"><span>Cash On Delivery</span>
                </label>
              </li> -->

              <span class="text-danger" style="font-size: 18px;" *ngIf="Value_Of_Sub_Total < minimumOrderValue">Minimum
                Subtotal Value is {{minimumOrderValue}}</span> <br>

              <span class="text-danger text-validation" *ngIf="form.value.Delivery_Address_Id ==''">
                Please select delivery address
              </span> <br>
              <span class="text-danger text-validation" *ngIf="form.value.Delivery_Slot_Id ==''">
                Please select delivery time slot
              </span> <br>
              <span class="text-danger text-validation" *ngIf="form.value.Payment_Mode == ''">
                Please select payment mode
              </span>

            </ul>
          </div>
        </div>
        <div class="text-center mb-3">
          <button style="width: 300px;"
            class="btn btn--box btn--small editwidth btn--green btn--green-hover-black btn--uppercase font--semi-bold"
            [disabled]="isProceed" (click)="proceed()">PROCEED</button>
        </div>
        <div class="text-center mt-2">
          <a [routerLink]="['/products', 'All Products', 0, 0]" style="width: 300px;"
            class="btn btn--box btn--small  editwidth btn--transparent btn--border-green mtbtn " type="submit">
            CONTINUE SHOPPING
          </a>
        </div>
      </div>

    </div>



  </div>
</main>
<!-- ::::::  End  Main Container Section  ::::::  -->

<ng-container *ngIf="isOpenGstDetail">
  <div
    style="position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;z-index: 1050;overflow: hidden;outline: 0;backdrop-filter: blur(2px);">
    <div style="position: fixed;top: 30%;left: 25%;width: 51%;height: 82%;z-index: 1050;overflow: hidden;outline: 0;"
      role="dialog">
      <div class="modal-content">
        <div class="modal-body modal13">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12">
                <p class="text-right"><i class="fa fa-times" style="cursor: pointer;" (click)="closeGstDialog()"></i>
                </p>
              </div>
              <div class="col-12">
                <div class="modal-bg checkout-page">
                  <div class="age-content checkout-form">
                    <div class="grid-container" style="background-color: #dddddd;">
                      <div class="item1">
                        Product Name
                      </div>
                      <div class="item1">
                        VAT on Price
                      </div>
                    </div>
                    <div class="grid-container" *ngFor="let option of cartProduct">
                      <div>
                        {{option.product_name}}{{option.package_qty}}{{option.unit_name}}
                      </div>
                      <div>
                        {{option.Total_CGST_Amount + option.Total_SGST_Amount }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>