<div class="container-fluid p-0">
  <div class="row">
    <div class="col text-right">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true"> <i class="fal fa-times"></i></span>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="product-gallery-box ">
        <div class="modal-product-image--large mb-0">
          <img class="img-fluid" [src]="_data?.product_image" [alt]="_data?.product_name" />
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="product-details-box">
        <!-- <h5 class="title title--normal m-b-20">{{ _data?.brand_name }}</h5> -->
        <h2 class="title title--normal mb-0">
          {{ _data?.product_name }} 
          <br>
          <!-- {{ _data?.package_qty }} {{ _data?.unit_name }} -->
        </h2>
        <p class="graycolor color-primary mb-4">{{_data?.brand_name}}</p>

        <div class="">
          <h3 style="margin-bottom: 0">
            Selling Price:
            <span class="product__price-del product__price"> {{ _data?.selling_amt }}/{{_data?.unit_name}}</span>
            &nbsp;&nbsp;
          </h3>
          <!-- <h3>
            Product MRP:
            <span class="product__price-reg"
              ><del *ngIf="_data?.discount_per > 0"> {{
                _data?.product_package_mrp  
              }}</del></span
            >
          </h3> -->

          <!-- <p class="mb-0 discount-amt" *ngIf="_data?.discount_per > 0">
            {{ _data?.discount_per }}% OFF
          </p> -->
        </div>
        <!-- <ul class="product__review m-t-15">
                    <li class="product__review--fill"><i class="icon-star"></i></li>
                    <li class="product__review--fill"><i class="icon-star"></i></li>
                    <li class="product__review--fill"><i class="icon-star"></i></li>
                    <li class="product__review--fill"><i class="icon-star"></i></li>
                    <li class="product__review--blank"><i class="icon-star"></i></li>
                </ul> -->

        <!-- <div class="product-var p-t-30">
                    <div class="product-quantity product-var__item d-flex align-items-center flex-wrap">
                        <span class="product-var__text">Quantity: </span>
                        <form class="modal-quantity-scale m-l-20">
                            <div class="value-button" id="modal-decrease" onclick="decreaseValueModal()">-</div>
                            <input type="number" value="1" />
                            <div class="value-button" id="modal-increase" onclick="increaseValueModal()">+</div>
                        </form>
                    </div>

                    <div class="text-center mt-2" *ngIf="_data?.Added_In_Cart"><a
                            class="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase">ADD
                            TO CART</a></div>
                </div> -->

        <div class="mycart-details">
          <div class="product-quantity d-flex align-items-center flex-wrap mt-4" *ngIf="_data.Added_In_Cart">
            <form class="modal-quantity-scale ml-1">
              <div class="value-button" id="modal-decrease" (click)="decreaseValueModal(_data)">
                -
              </div>
              <input style="
                  text-align: center;
                  border: 1px solid #aaabaa;
                  padding: 6px;
                  font-weight: 600;
                " type="number" [value]="_data.Cart_Qty" class="input-incdec"
                (change)="onCartQtyChange($event, _data)" disabled />
              <div class="value-button" id="modal-increase" (click)="increaseValueModal(_data)">
                +
              </div>
            </form>
          </div>

          <div class="mt-3" *ngIf="!_data?.Added_In_Cart" (click)="onAddToCart(_data)">
            <a class="
                btn
                addtocart
                btn--black btn--black-hover-green
                font--bold
               
              ">Add To Cart</a>
          </div>
        </div>

        <!-- <div class="product-links">
                    <div class="product-social m-tb-30">
                        <span>SHARE THIS PRODUCT</span>
                        <ul class="product-social-link">
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h2>Product Description</h2>
      <hr />
      <div class="product__desc m-b-30">
        <!-- <p style="font-size: 16px" [innerHTML]="_data?.short_description">{{ _data?.short_description }}</p> -->
        <p style="font-size: 16px;text-align: justify;" [innerHTML]="_data?.short_description"></p>
      </div>
    </div>
  </div>
</div>