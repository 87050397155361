import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNthdaySelection]'
})
export class NthdaySelectionDirective {


  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9.]*/g, '');
    if (initalValue !== this._el.nativeElement.value && (initalValue == 1 || initalValue == 2 || initalValue == 3)) {
      event.stopPropagation();
    }
  }

}
