import { AfterViewInit, Directive } from '@angular/core';
declare var $: any

@Directive({
    selector: '[appBlogNews]'
})
export class BlogNewsDirective implements AfterViewInit {

    constructor() { }
    ngAfterViewInit() {
        /************************************************
         * Blog Slider - Style: Feed [3 Grid, 1 Rows]
         ***********************************************/
        $('.blog-feed-slider-3grid').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            easing: 'linear',
            speed: 1000,
            prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
            nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
            responsive: [

                {
                    breakpoint: 1470,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        slidesToShow: 2,
                        autoplay: true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                        autoplay: true,
                    }
                }
            ]
        });
    }
}
