<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb mb-1">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"><a href="#">Home</a></li>
                    <li class="page-breadcrumb__nav active">Contact Us</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<!-- :::::: Start Main Container Wrapper :::::: -->
<main id="main-container" class="main-container">

    <div class="container">
        <h2 style="text-align: center;margin-bottom: 10px;margin-top: 10px;">GET IN TOUCH!</h2>
        <div class="row" style="justify-content: center;align-items: center;">

            <div class="col-lg-5 col-md-6 col-sm-12 ">

                <div class="contact-info-wrap blue-bg m-t-10 mb-3" style="font-size: 15px;">
                    <div class="contact-info">

                        <!-- <i class="fas fa-mobile-alt fa-3x"></i> -->


                        <div class="row align-items-center">
                            <div class="col-md-2 col-xs-2"><i class="fas fa-phone-square-alt fa-2x"></i></div>
                            <div class="col-md-8 col-xs-10">
                                <p class="mt-2 "> <a href="tel://+971 52 2520001">+971 52 2520001/</a><br>
                                    <a href="tel://+971 4 3202200"> +971 4 3202200</a>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="contact-info mb-3 mt-1">


                        <div class="row align-items-center">
                            <div class="col-md-2 col-xs-2"><i class="fas fa-envelope fa-2x"></i></div>
                            <div class="col-md-10 col-xs-10">
                                <a href="mailto://sales@nasiktrade.com"> <span>Email:
                                    </span>sales@nasiktrade.com</a>
                            </div>
                        </div>


                    </div>
                    <div class="contact-info mb-3">
                        <div class="row align-items-center">
                            <div class="col-md-2 col-xs-2"><img src="assets/img/icon/contact.png"
                                    class="img-responsive"></div>
                            <div class="col-md-10 col-xs-10">
                                <div class="contact-info-dec">

                                    <p class="mt-1 mb-1" style="font-size: 19px;font-weight: 500;"> Address: </p>
                                    <span>
                                        NASIK FRUITS AND VEGETABLES TRADING LLC SHOP-21, WHOLESALE
                                        BUILDING NO-1,CENTRAL FRUITS AND VEGETABLES MARKET,Al AWEER, DUBAI, U.A.E.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-info mb-3">
                        <div class="row align-items-center">

                        </div>
                    </div>
                    <div class="contact-info mb-2">
                        <div class="row align-items-center">


                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-7 col-md-6 col-sm-12">
                <div class="contact-form gray-bg new-bg m-t-10 pb-2">

                    <div class="alert alert-primary" role="alert" *ngIf="msgFlag">
                        {{message}}
                    </div>

                    <form class="contact-form-style" id="contact-form" [formGroup]="form">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-box__single-group">
                                    <input formControlName="First_Name" type="text" placeholder="First Name*">
                                    <span class="text-danger"
                                        *ngIf="f.get('First_Name').touched && f.get('First_Name').invalid &&f.get('First_Name').hasError('required')">First
                                        Name is required</span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-box__single-group">
                                    <input formControlName="Last_Name" type="text" placeholder="Last Name*">
                                    <span class="text-danger"
                                        *ngIf="f.get('Last_Name').touched && f.get('Last_Name').invalid &&f.get('Last_Name').hasError('required')">Last
                                        Name is required</span>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-box__single-group">
                                    <input formControlName="Email_Id" type="email" placeholder="Email*">
                                    <span class="text-danger"
                                        *ngIf="f.get('Email_Id').touched && f.get('Email_Id').invalid && f.get('Email_Id').hasError('required')">Email
                                        is required</span>
                                    <span class="text-danger" *ngIf="f.get('Email_Id').hasError('email')">Please Enter
                                        Valid Email ID</span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-box__single-group">
                                    <input formControlName="Contact_No" type="text" placeholder="Contact Number*"
                                        minlength="9" maxlength="9" (keypress)="numberOnly($event)">
                                    <span class="text-danger"
                                        *ngIf="f.get('Contact_No').touched && f.get('Contact_No').invalid &&f.get('Contact_No').hasError('required')">Contact
                                        No is required</span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-box__single-group">
                                    <input formControlName="Subject" type="text" placeholder="Subject*">
                                    <span class="text-danger"
                                        *ngIf="f.get('Subject').touched && f.get('Subject').invalid &&f.get('Subject').hasError('required')">Subject
                                        is required</span>
                                </div>
                            </div>
                            <div class="col-lg-12" style="padding-bottom: 15px;">
                                <div class="form-box__single-group">
                                    <textarea formControlName="Message" rows="5" placeholder="Your Messege"></textarea>
                                    <span class="text-danger"
                                        *ngIf="f.get('Message').touched && f.get('Message').invalid &&f.get('Message').hasError('required')">Message
                                        is required</span>
                                </div>
                                <button [disabled]="form.invalid" (click)="onSubmit()"
                                    class="btn btn--box btn--medium btn--radius-tiny btn--black btn--black-hover-green btn--uppercase font--bold m-t-30"
                                    type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-3">
        <div class="row">
            <div class="col-12">
                <div class="contact-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14443.472920035281!2d55.3870116!3d25.1739268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x85bf4debb6c37086!2sAl%20Aweer%20Central%20Fruit%20%26%20Vegetable%20Market!5e0!3m2!1sen!2sin!4v1655357878204!5m2!1sen!2sin"
                        width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
        </div>
    </div>
</main> <!-- :::::: End MainContainer Wrapper :::::: -->

<script src="https://goo.gl/maps/vDVHmSrSThSSyf2i6"></script>