import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-desifarm-pagination',
  templateUrl: './desifarm-pagination.component.html',
  styleUrls: ['./desifarm-pagination.component.scss']
})
export class DesifarmPaginationComponent implements OnInit {

  @Input() products: any[] = [];
  @Input() paginate: any = {};

  @Output() setPage  : EventEmitter<any> = new EventEmitter<any>();
    
  constructor() { 
  }

  ngOnInit(): void {
  }

  pageSet(page: number) {
    this.setPage.emit(page);  // Set Page Number  
  }

}
