import { AfterViewChecked, AfterViewInit, Directive, OnInit } from '@angular/core';
declare var $: any

@Directive({
  selector: '[appHeroSlider]'
})
export class HeroSliderDirective implements OnInit, AfterViewInit, AfterViewChecked {

  constructor() { }

  ngOnInit() {
    // $('.hero').slick({
    //   arrows: true,
    //   fade: true,
    //   dots: true,
    //   easing: 'linear',
    //   speed: 2000,
    //   prevArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--left"><i class="far fa-chevron-left"></i></button>',
    //   nextArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--right"><i class="far fa-chevron-right"></i></button>',
    //   responsive: [

    //     {
    //       breakpoint: 768,
    //       settings: {
    //         arrows: false,
    //       }
    //     },
    //   ]
    // });
  }

  ngAfterViewInit() {
    $('.hero').slick({
      arrows: true,
      appendArrows: $('.slider-arrows'),
      fade: true,
      dots: true,
      easing: 'linear',
      speed: 2000,
      prevArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--left"><i class="far fa-chevron-left"></i></button>',
      nextArrow: '<button type="button" class="hero-slider__arrow hero-slider__arrow--right"><i class="far fa-chevron-right"></i></button>',
      responsive: [

        {
          breakpoint: 768,
          settings: {
            arrows: false,
          }
        },
      ]
    });
  }

  ngAfterViewChecked() {

  }

}
