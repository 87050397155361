import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmWalletService } from 'src/app/services/desifarm-dashboard/desifarm-wallet/desifarm-wallet.service';

@Component({
  selector: 'app-desifarm-refer-and-earn',
  templateUrl: './desifarm-refer-and-earn.component.html',
  styleUrls: ['./desifarm-refer-and-earn.component.scss']
})
export class DesifarmReferAndEarnComponent implements OnInit {

  loginData: any

  constructor(
    private _commonService: DesifarmCommonService,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loginData = this._commonService.getUser()
  }

  /* To copy Text from Textbox */
  copyToClipboard() {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    // dummy.value = text;
    dummy.value = `DESI${this.loginData.Cust_Id}`;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    this._commonService.onSuccess("Refer Code Copied", 2000)
  }

}
