import { Location } from './../../models/desifarm-common/desifarm-local/desifarm-location';
import { DesifarmMainCategoriesImagesService } from './../../services/desifarm-homepage/desifarm-main-categories-images/desifarm-main-categories-images.service';
import { DesifarmLocalCartService } from './../../services/desifarm-local-cart/desifarm-local-cart.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmHeaderService } from 'src/app/services/desifarm-header/desifarm-header.service';
import { MatDialog } from '@angular/material/dialog';
import { DesifarmLoginRegisterComponent } from '../desifarm-login-register/desifarm-login-register.component';
import { DesifarmCompanyService } from 'src/app/services/desifarm-company/desifarm-company.service';
import { ResCompany } from 'src/app/models/desifarm-res/desifarm-company/rescompany';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { ResDistrict } from 'src/app/models/desifarm-res/desifarm-header/resdistrict';
import { ResSubArea } from 'src/app/models/desifarm-res/desifarm-header/ressubarea';
import { ResMainCategories } from 'src/app/models/desifarm-res/desifarm-homepage/desifarm-categories-images/resmaincategoriesimages';
import { DesifarmSearchProductsComponent } from '../desifarm-search-products/desifarm-search-products.component';
import { Inject } from '@angular/core';
import { DOCUMENT, SlicePipe } from '@angular/common';
import { DesifarmComplaintAndSuggestionComponent } from '../desifarm-complaint-and-suggestion/desifarm-complaint-and-suggestion.component';
import { FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DesifarmWalletService } from 'src/app/services/desifarm-dashboard/desifarm-wallet/desifarm-wallet.service';
import { DesifarmVnfAreasService } from 'src/app/services/desifarm-vnf-areas/desifarm-vnf-areas.service';
import { DesifarmLoginService } from 'src/app/services/desifarm-auth/desifarm-login/desifarm-login.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NskLoginComponent } from '../nsk-login/nsk-login.component';




@Component({
  selector: 'app-desifarms-headerfooter',
  templateUrl: './desifarms-headerfooter.component.html',
  styleUrls: ['./desifarms-headerfooter.component.scss'],
  host: {
    "(window:click)": "closeSideNav()"
  }
})
export class DesifarmsHeaderfooterComponent implements OnInit {

  @ViewChild('search') SearchBox: ElementRef

  showCart: boolean = false
  searchTerm: boolean = false
  public loginFlag: boolean = true
  locationModalFlag = false
  isSideNav: boolean = false
  isLocationCorrect: boolean = false

  public loginData: any
  companyDetails: ResCompany
  Whtas_App_No: any

  districts: ResDistrict[] = []
  areas: any[] = []
  subAreas: ResSubArea[] = []
  FilterSubArea: ResSubArea[] = []
  cartProduct: any[] = []
  mainCategories: ResMainCategories[] = []

  location: Location = {
    District_Id: 0,
    District_Name: '',
    Sub_Area_Name: '',
    Franchise_Id: 0,
    Sub_Area_Id: 0,
    Pincode: 0,
    Franchise_State_Id: 0,
    Area_Name: '',
  }

  serviceDistrict: any = ''
  serviceArea: any = ''

  District_Name: string
  Sub_Area_Name: string
  Franchise_Id: any

  District_Name_Form: string
  Area_Name_Form: string
  Sub_Area_Name_Form: string
  Franchise_Id_Form: any

  Sub_Total_Amt: number = 0
  walletAmountCount: number = 0

  form: FormGroup

  relationShipManagerDetail: any

  vnfareaslist: any

  cityselection: boolean = false
  selectedCity: any

  searchProductList: any[] = []

  constructor(
    private _fb: FormBuilder,
    public _commonService: DesifarmCommonService,
    private _headerService: DesifarmHeaderService,
    private _companyService: DesifarmCompanyService,
    public _localCartService: DesifarmLocalCartService,
    private _mainCategoriesImageService: DesifarmMainCategoriesImagesService,
    private _searchProductComponent: DesifarmSearchProductsComponent,
    private _walletServiceService: DesifarmWalletService,
    private _vnfareasServiceService: DesifarmVnfAreasService,
    @Inject(DOCUMENT) private _document: Document,
    private _authService: DesifarmLoginService,
    private _dialog: MatDialog,
    private _router: Router
  ) {
    // this._router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) { 
    //   }
    // })
    this._localCartService.getSelectedDistrict()
    this.getLoginDetails()
  }

  initForm() {
    this.form = this._fb.group({
      District_Id: ['', Validators.required],
      Area_Id: ['', Validators.required],
      Sub_Area_Id: ['', Validators.required],
      District_Name: [''],
      Area_Name: [''],
      Sub_Area_Name: [''],
      Franchise_Id: [''],
      Pincode: [''],
      Franchise_State_Id: [''],
      Min_Order_Amount: [''],
      Delivery_Charges: [''],
    })
  }

  getData() {

    // this._vnfareasServiceService.get_vandfareas().subscribe((res: any) => {
    //   let data = res.data
    //   let result = data.map(e => e.Pincode_Area)
    //   this.vnfareaslist = result.toString().replace(/,/g, ", ")
    // })

    this.loginData = this._commonService.getUser()
    if (this.loginData == null) {
      this.loginFlag = true
    } else {
      this.loginFlag = false
      this.updateLoginDetails(this.loginData.Email_Id)
    }

    this._headerService.get_district().subscribe((res: DesifarmAck) => {
      this.districts = res.data
      this.form.patchValue({
        District_Id: this.districts[0].District_Id,
        District_Name: this.districts[0].District_Name
      })
      this.getAreaList(this.districts[0].District_Id)
    })

    // this.getLocation()
    this._localCartService.getCartProducts()

    this._localCartService.cartList.subscribe((res) => {
      if (res) {
        const result = res.every(element => element.hasOwnProperty('Is_Dairy_Product'))
        if (result) {
          // if all objects has property
          res.map(e => e.Product_Total = e.Cart_Qty * e.selling_amt)
          this.cartProduct = res
          if (this.cartProduct.length > 0) {
            this.Sub_Total_Amt = this.cartProduct
              .map(e => e.Product_Total)
              .reduce((prev, next) => prev + next)
          } else this.Sub_Total_Amt = 0
        } else {
          // reset exisiting cart because property not availble
          this._localCartService.resetCartProducts()
        }
      }

    })

    // this._localCartService.cartList.subscribe((res) => {
    //   res.map(e => e.Product_Total = e.Cart_Qty * e.selling_amt)
    //   this.cartProduct = res
    //   if (this.cartProduct.length > 0) {
    //     this.Sub_Total_Amt = this.cartProduct
    //       .map(e => e.Product_Total)
    //       .reduce((prev, next) => prev + next)
    //   } else this.Sub_Total_Amt = 0
    // })

    this._companyService.get_company().subscribe((res: any) => {
      this.companyDetails = res.data[0]
      let Whtas_App_No = res.data[0].Whtas_App_No.slice(4);
      this.Whtas_App_No = `91${Whtas_App_No}`
    })

    // this._mainCategoriesImageService.get_main_category_images(this._localCartService.selectedDistrict).subscribe((res: DesifarmAck) => {
    //   let array = [{
    //     Main_Category_Id: 0.0,
    //     Reg_Date: "",
    //     Category_Name: "All Products",
    //     Category_Image: "",
    //     Rank_In_App: "",
    //     Status: 1,
    //     Staff_Id: 0,
    //   }]
    //   this.mainCategories = array.concat(res.data)
    // })

    this.get_main_category_images()

    try {
      this._walletServiceService.get_wallet_amount(this.loginData.Cust_Id).subscribe((res: any) => {
        this._walletServiceService.walletAmountCount.next(res.data)
        this._walletServiceService.walletAmountCount.subscribe(res => {
          this.walletAmountCount = res
        })
      })
    } catch (error) {

    }
  }

  getLoginDetails() {
    this._commonService.loggedInData.subscribe((res: any) => {
      if (res) {
        this.loginData = res
      } else {
        if (this._commonService.getUser()) {
          this.loginData = this._commonService.getUser()
        }
      }
    })
  }

  get_main_category_images() {
    this._localCartService.selectedDistrict.subscribe((res: any) => {
      this._mainCategoriesImageService.get_main_category_images(res).subscribe((res: DesifarmAck) => {
        let array = [{
          Main_Category_Id: 0.0,
          Reg_Date: "",
          Category_Name: "All Products",
          Category_Image: "",
          Rank_In_App: "",
          Status: 1,
          Staff_Id: 0,
        }]
        // this.mainCategories = array.concat(res.data)
        this.mainCategories = res.data
      })
    })
  }

  // updateLoginDetails(Customer_Mobile_Number) {
  //   this._authService.sign_in({ Customer_Mobile_Number }).subscribe((res: any) => {
  //     try {
  //       if (res.ack) {
  //         this._commonService.setUser(res.data[0])

  //       }
  //     } catch (error) {

  //     }
  //   })
  // }

  ngOnInit(): void {
    this.initForm()
    this.getData()
    localStorage.removeItem(environment.locationKey)
    this.updateLogin()
    if (localStorage.getItem(environment.selectedCity)) {
      this.cityselection = false
      this.selectedCity = JSON.parse(localStorage.getItem(environment.selectedCity))
    } else {
      this.cityselection = true
    }
  }

  closeCart() { this.showCart = !this.showCart }
  searchTermsbtn() { this.searchTerm = !this.searchTerm }
  toggleSideNav($event) {
    $event.stopPropagation();
    this.isSideNav = !this.isSideNav
  }
  closeSideNav() { this.isSideNav = false }

  //NEW CODE TODO:
  onCity(event) {
    let district = this.districts.find(e => e.District_Name == event.target.value)

    this.form.controls.Area_Id.reset()
    this.form.controls.Area_Name.reset()
    this.form.controls.Delivery_Charges.reset()
    this.form.controls.Min_Order_Amount.reset()

    this.form.controls.Sub_Area_Id.reset()
    this.form.controls.Sub_Area_Name.reset()
    this.form.controls.Franchise_Id.reset()
    this.form.controls.Pincode.reset()
    this.form.controls.Franchise_State_Id.reset()


    if (district) {
      // this.form.reset()
      this.getAreaList(district.District_Id)
      this.form.patchValue({
        District_Id: district.District_Id,
        District_Name: district.District_Name
      })

    } else {
      this.form.controls.District_Id.reset()
      this.form.controls.District_Name.reset()
    }
  }

  getAreaList(District_Id) {
    this._headerService.get_area(District_Id).subscribe((res: any) => {
      this.areas = res.data
    })
  }

  onAreaSelection(event) {
    let area = this.areas.find(e => e.Area_Name == event.target.value)
    this.form.controls.Sub_Area_Id.reset()
    this.form.controls.Sub_Area_Name.reset()
    this.form.controls.Franchise_Id.reset()
    this.form.controls.Pincode.reset()
    this.form.controls.Franchise_State_Id.reset()
    if (area) {
      this.getSubArea(area.Area_Id)
      this.form.patchValue({
        Area_Id: area.Area_Id,
        Area_Name: area.Area_Name,
        Delivery_Charges: area.Delivery_Charges,
        Min_Order_Amount: area.Min_Order_Amount,
      })
    } else {
      this.form.controls.Area_Id.reset()
      this.form.controls.Area_Name.reset()
      this.form.controls.Delivery_Charges.reset()
      this.form.controls.Min_Order_Amount.reset()
    }
  }

  getSubArea(Area_Id) {
    this._headerService.get_sub_area(Area_Id).subscribe((res: any) => {
      res.data.map(e => e.Name = `${e.Sub_Area_Name}`)
      this.subAreas = res.data
    })
  }

  onArea(event) {
    let subArea = this.subAreas.find(e => e.Name == event.target.value)
    if (subArea) {
      this.form.patchValue({
        Sub_Area_Id: subArea.Sub_Area_Id,
        Sub_Area_Name: subArea.Sub_Area_Name,
        Franchise_Id: subArea.Franchise_Id,
        Pincode: subArea.Picode,
        Franchise_State_Id: subArea.Franchise_State_Id,
      })
    } else {
      this.form.controls.Sub_Area_Id.reset()
      this.form.controls.Sub_Area_Name.reset()
      this.form.controls.Franchise_Id.reset()
      this.form.controls.Pincode.reset()
      this.form.controls.Franchise_State_Id.reset()
    }
  }

  submit() {
    if (this._commonService.getData(environment.locationKey) != null) {
      let location: Location = this._commonService.getData(environment.locationKey)
      let Franchise_Id = this.form.controls.Franchise_Id.value

      this.Sub_Area_Name = ` ${this.form.controls.Sub_Area_Name.value}, ${this.form.controls.Area_Name.value}`
      this._commonService.setData(environment.locationKey, this.form.value)
      this._commonService.onSuccess("Location Saved Successfully.")
      this.locationModalFlag = false
      this.updateLocation()
      if (this._commonService.router.url == '/') {
        this.refreshPage()
      } else {
        setTimeout(() => {
          this.refreshPage()
        }, 100)
      }



      // if (Franchise_Id === location.Franchise_Id) {
      //   // No Need To Clear
      //   this.Sub_Area_Name = ` ${this.form.controls.Sub_Area_Name.value}, ${this.form.controls.Area_Name.value}`
      //   this._commonService.setData("serviceLocation", this.form.value)
      //   this._commonService.onSuccess("Location Saved Successfully.")
      //   this.locationModalFlag = false
      //   this.updateLocation()
      //   // this._commonService.router.url == '/' ? this.refreshPage() : this._commonService.router.navigate([''])
      //   if (this._commonService.router.url == '/') {
      //     this.refreshPage()
      //   } else {
      //     this._commonService.router.navigate([''])
      //     setTimeout(() => {
      //       this.refreshPage()
      //     }, 100)
      //   }
      // } else {
      //   //Clear Cart
      //   this.Sub_Area_Name = ` ${this.form.controls.Sub_Area_Name.value}, ${this.form.controls.Area_Name.value}`
      //   if (confirm("If You Change The Location You Cart Products Will Be Deleted")) {
      //     this._commonService.setData("serviceLocation", this.form.value)
      //     this._commonService.onSuccess("Location Saved Successfully.")
      //     this.locationModalFlag = false
      //     this._localCartService.resetCartProducts()
      //     this.updateLocation()
      //     // this._commonService.router.url == '/' ? this.refreshPage() : this._commonService.router.navigate([''])
      //     if (this._commonService.router.url == '/') {
      //       this.refreshPage()
      //     } else {
      //       this._commonService.router.navigate([''])
      //       setTimeout(() => {
      //         this.refreshPage()
      //       }, 100)
      //     }
      //   }
      // }
    } else {
      this._commonService.setData(environment.locationKey, this.form.value)
      this._commonService.onSuccess("Location Saved Successfully.")
      this.locationModalFlag = false
      this.updateLocation()
      this.refreshPage()
    }
  }

  getLocation() {
    if (this._commonService.getData(environment.locationKey) != null) {
      let serviceLocation: Location = this._commonService.getData(environment.locationKey)
      this.Sub_Area_Name = ` ${serviceLocation.Sub_Area_Name}, ${serviceLocation.Area_Name}`
      this.form.patchValue({
        District_Id: serviceLocation.District_Id,
        Area_Id: serviceLocation.Area_Id,
        Sub_Area_Id: serviceLocation.Sub_Area_Id,
        District_Name: serviceLocation.District_Name,
        Area_Name: serviceLocation.Area_Name,
        Sub_Area_Name: serviceLocation.Sub_Area_Name,
        Franchise_Id: serviceLocation.Franchise_Id,
        Pincode: serviceLocation.Pincode,
        Franchise_State_Id: serviceLocation.Franchise_State_Id,
        Min_Order_Amount: serviceLocation.Min_Order_Amount,
        Delivery_Charges: serviceLocation.Delivery_Charges,
      })
      this.getAreaList(serviceLocation.District_Id)
      this.getSubArea(serviceLocation.Area_Id)
      this.isLocationCorrect = true
      if (this.location.Relationship_Manager_Id == undefined) {
        this.getRelationshipDetailBySubArea(serviceLocation.Sub_Area_Id)
      } else {
        this.getRelationshipDetailByStaff(serviceLocation.Relationship_Manager_Id)
      }
    } else {
      this.locationModalFlag = true
    }
  }

  getRelationshipDetailBySubArea(Sub_Area_Id) {
    this._headerService.relationship_manager_by_subarea(Sub_Area_Id).subscribe((res: any) => {
      this.relationShipManagerDetail = res.data[0]
    })
  }

  getRelationshipDetailByStaff(Staff_Id) {
    this._headerService.relationship_manager_by_subarea(Staff_Id).subscribe((res: any) => {
      this.relationShipManagerDetail = res.data[0]
    })
  }

  toggleLocationModal() {
    this.getLocation()
    this.locationModalFlag = !this.locationModalFlag
  }

  // isLocationSet() {
  //   if (this._commonService.getData(environment.locationKey) != null) {
  //     this.toggleLocationModal()
  //   } else this._commonService.onFailed("Please Set Location")
  // }

  onSignOut() {
    localStorage.removeItem(environment.loginKey)
    this._commonService.router.navigate([''])
    this.getData()
  }

  updateLocation() {
    this.Sub_Area_Name = this.Sub_Area_Name_Form
  }

  onSignIn() {
    const dialogRef = this._dialog.open(NskLoginComponent, {
      width: '600px',
      panelClass: 'loginRegisterComponent',
    })
    dialogRef.afterClosed().subscribe(() => this.getData())
  }

  onDelete(option) {
    let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    this.cartProduct.splice(index, 1)
    this._localCartService.setCartProducts(this.cartProduct)
    this._commonService.onFailed("Product Deleted Successfully..")
  }

  navigate(option) {
    // if (this._commonService.getData(environment.locationKey) != null) {
    //   const { Category_Name, Main_Category_Id } = option
    //   this._commonService.router.navigate(['products', Category_Name, Main_Category_Id, 0])
    //   this.searchTermsbtn()
    // } else this.locationModalFlag = true

    const { Category_Name, Main_Category_Id } = option
    this._commonService.router.navigate(['products', Category_Name, Main_Category_Id, 0])
    this.searchTermsbtn()
  }

  getAllProduct() {
    if (this._commonService.getData(environment.locationKey) != null) {
      this._commonService.router.navigate(['products', "All Products", 0.0, 0])
      this.searchTermsbtn()
    } else this.locationModalFlag = true
  }

  decreaseValue(option) {
    this.cartProduct.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        if (e.Cart_Qty == 1) {
          this.onDelete(option)
          this._commonService.onFailed("Product Deleted Successfully..")
        } else {
          e.Cart_Qty = e.Cart_Qty - 1
          this.updateCart()
        }

      }
    })
  }

  increaseValue(option) {
    this.cartProduct.map((e) => {
      if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
        e.Cart_Qty = e.Cart_Qty + 1
        this.updateCart()
      }
    })
  }

  updateCart() {
    this.cartProduct.map((e) => {
      e.Product_Total = e.Cart_Qty * e.selling_amt
      e.Value_Of_Product = e.Cart_Qty * e.product_package_mrp
      e.Value_Of_Discount = e.Cart_Qty * e.discount_amt
      e.Value_Of_Sub_Total = e.Cart_Qty * e.selling_amt
    })
    this._localCartService.setCartProducts(this.cartProduct)
  }

  onCart() {
    if (this.cartProduct.length > 0) {
      this._commonService.router.navigate(['mycart'])
      this.closeCart()
    } else this._commonService.onFailed("No Product Available In Cart")
  }

  onCartButton() {
    if (this.cartProduct.length === 0) this._commonService.onFailed("No Products In Cart")
    else this._commonService.router.navigate(['mycart'])
  }

  onCartQtyChange(event, option) {
    let qty = event.target.value
    if (qty && qty > 0) {
      this.cartProduct.map((e) => {
        if (e.product_packaging_rate_id == option.product_packaging_rate_id) {
          e.Cart_Qty = parseInt(qty)
        }
      })
    } else if (qty < 0 || qty == '' || qty == 0) {
      this.deleteProductFromCart(option)
    }


  }

  deleteProductFromCart(option) {
    let index = this.cartProduct.findIndex(e => e.product_packaging_rate_id == option.product_packaging_rate_id)
    this.cartProduct.splice(index, 1)
    this.updateCart()
  }

  searchProduct(event) {
    if (event.target.value) {
      let data = {
        District_Id: this.selectedCity.District_Id,
        Keyword: event.target.value
      }
      this._headerService.search_products_name_list(data).subscribe((res: any) => {
        if (res.ack) {
          this.searchProductList = res.data
        } else {
          this.searchProductList = []
        }
      })

      var isInputEvent = (Object.prototype.toString.call(event).indexOf("InputEvent") > -1)
      if (!isInputEvent) {
        // alert("Selected: " + event.target.value)
        let keyword = event.target.value
        this._searchProductComponent.onSearch(keyword)
        sessionStorage.setItem("keyword", JSON.stringify(keyword))
      }
    } else {
      this.searchProductList = []
    }
  }

  handleSeachBtn(value) {
    let keyword = value
    this._searchProductComponent.onSearch(keyword)
    sessionStorage.setItem("keyword", JSON.stringify(keyword))
  }

  // old code working
  // searchProduct(event) {
  //   let keyword = event.target.value
  //   this._searchProductComponent.onSearch(keyword)
  //   sessionStorage.setItem("keyword", JSON.stringify(keyword))
  // }

  refreshPage() {
    this._document.defaultView.location.reload();
  }

  getCurrentRoute() {
    if (
      this._commonService.router.url == '/mycart'
      || this._commonService.router.url == '/checkout'
      || this._commonService.router.url == '/order-success'
    ) { return true }
  }

  orderNowMenu() {
    this._commonService.router.navigate(['products', this.mainCategories[0].Category_Name, this.mainCategories[0].Main_Category_Id, 0])
  }


  //Open Complaint Dialog
  openComplaintSuggestion() {
    const dialogRef = this._dialog.open(DesifarmComplaintAndSuggestionComponent, {
      width: '800px',
      height: '60vh',
      panelClass: 'myclass2',
      // data: data
    })
    // dialogRef.afterClosed().subscribe(() => this.getData())
  }

  getCurrentUrl() {
    return this._commonService.router.url
  }

  updateLogin() {
    this._commonService.isLoggedIn.subscribe((res: any) => {
      if (res) this.getData()
    })

  }

  handleCity(option) {
    this.selectedCity = option
    // this._localCartService.selectedDistrict.next(option.District_Id)
    // localStorage.setItem(environment.selectedCity, JSON.stringify(option))
    this._localCartService.setSelectedDistrict(option)
    this.cityselection = false
  }

  closeCitySelection() {
    if (localStorage.getItem(environment.selectedCity)) {
      this.cityselection = false
    }
  }

  updateLoginDetails(Email_Id) {
    this._authService.customer_info({ Email_Id, Device_Source: 'Web' }).subscribe((res: any) => {
      try {
        if (res.ack) {
          this._commonService.setUser(res.data)
        } else if (res.res_code == -2) {
          this._commonService.onFailed(res.msg, 10000)
          this.onSignOut()
        } else {

        }
      } catch (error) {

      }
    })
  }
}
