import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DesifarmAck } from 'src/app/models/desifarm-common/desifarmAck';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesifarmSignupService {

  constructor(private http: HttpClient) { }

  get_district(): Observable<any> {
    return this.http.get<DesifarmAck>(environment.url + 'get_district')
  }

  get_area(District_Id): Observable<any> {
    return this.http.get<DesifarmAck>(environment.url + 'get_area'+ '/'+District_Id)
  }

  get_sub_area(Area_Id): Observable<any> {
    return this.http.post<DesifarmAck>(environment.url + 'get_sub_area', {Area_Id})
  }

  get_source(): Observable<any> {
    return this.http.get<DesifarmAck>(environment.url + 'get_source')
  }
}
