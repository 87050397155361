import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DesifarmCommonService } from 'src/app/services/desifarm-common/desifarm-common.service';
import { DesifarmLocalCartService } from 'src/app/services/desifarm-local-cart/desifarm-local-cart.service';

@Component({
  selector: 'app-desifarms-order-success',
  templateUrl: './desifarms-order-success.component.html',
  styleUrls: ['./desifarms-order-success.component.scss']
})
export class DesifarmsOrderSuccessComponent implements OnInit, OnDestroy {

  orderDetails
  orderValue:number = 0

  constructor(
    private _commonService: DesifarmCommonService,
    private _localCartService: DesifarmLocalCartService,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.orderDetails = JSON.parse(this._commonService.getData("placed"))
    this.orderValue = this.orderDetails?.Total_Selling_Amount + this.orderDetails?.Order_Delivery_Charges
    // this.orderValue = this.orderDetails?.Total_Selling_Amount + this.orderDetails?.Order_Delivery_Charges - this.orderDetails?.Wallet_Amount_Used
    this.orderValue % 1 != 0 ? Math.round(this.orderValue) : this.orderValue
  }

  viewMyOrders(){
    this._commonService.router.navigate(['/myorders'])
  }


  ngOnDestroy() {
    localStorage.removeItem("placed")
    this._localCartService.resetCartProducts()
  }

}
