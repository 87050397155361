import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desifarms-privacypolicy',
  templateUrl: './desifarms-privacypolicy.component.html',
  styleUrls: ['./desifarms-privacypolicy.component.scss']
})
export class DesifarmsPrivacypolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
