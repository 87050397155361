// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://localhost:5001/desi_farm/web_api/', // Local
  // url: 'https://newnsknode.staggingservers.in/desi_farm/web_api/', // Stagging
  url: 'https://nsknode.nasiktrade.com/desi_farm/web_api/', // Live
  deliveryChargesKey: "nskchargesForProd",
  rzp_API: "rzp_test_l9r9MmOfKMmHBI", //Razorpay_Test_sb
  rzp_Secret_Key: "EHLtrrhqJKNHNw8EezZmd444", //Razorpay_Test_sb

  // Business_Type_Id:1
  Business_Type_Id: 2,
  locationKey: "vegeLocation",
  loginKey: "nskstorelg",
  selectedCity: "SelectedCityNsk"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
