import { AfterViewInit, Directive } from '@angular/core';
declare var $: any

@Directive({
    selector: '[appNewProduct]'
})
export class NewProductDirective implements AfterViewInit {

    constructor() { }

    ngAfterViewInit() {
        /************************************************
         * Product Slider - Style: Default [4 Grid, 1 Rows]
         ***********************************************/
        $('.product-default-slider-4grid-1rows').slick({
            arrows: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,
            easing: 'ease-out',
            speed: 1000,
            prevArrow: '<button type="button" class="default-slider__arrow default-slider__arrow--left prevArrow"><i class="far fa-chevron-left"></button>',
            nextArrow: '<button type="button"  class="default-slider__arrow default-slider__arrow--right nextArrow"><i class="far fa-chevron-right"></button>',
            responsive: [

                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
    }

}
