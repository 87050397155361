<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb" style="margin-bottom: 0;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav" routerLink="/"><a>Home</a></li>
                    <li class="page-breadcrumb__nav active">Our Franchises</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<section class="mainsec">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-md-10">
                <div class="row mt-4 ">
                    <div class="col-md-5">
                        <img src="assets/img/store/info.png" class="img-responsive" />
                    </div>
                    <div class="col-md-7">
                        <h3 style="color: #088ace;font-weight: bold;">Our Franchise Outlets</h3>
                        <p>A franchise model designed in a way that it benefits not only the store owner but also
                            addresses the
                            convenience of Desi Farms’ consumers.</p>
                        <p>Within a short span, we have successfully established multiple franchise outlets across Pune.
                        </p>
                        <p>Partner with us for a profitable business with a small initial investment. We practise the
                            highest standards of hygiene and storage of the products at each
                            franchise. All our outlets are open from 6 am to 9 pm all days of the week.</p>
                    </div>
                </div>

                <!-- <div class="text-center mt-2 mb-2">
                    <img src="assets/img/about/franchise.jpg" class="img-responsive"/>
                </div> -->


            </div>
        </div>
    </div>

</section>
<section class="mainsec another-sec mt-3">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-md-10">
                <div class="row align-items-center">
                    <div class="col-md-7 ">
                        <h3 style="font-weight: bold;">Why choose Desi Farms Franchise ?</h3>
                        <ul class="myul">
                            <li>Excellent earning opportunity up to 12 lacs per year</li>
                            <li>200+ products</li>
                            <li>Recurring revenue</li>
                            <li>Multiple Revenue Streams</li>
                            <li>Preservative and chemical-free dairy products</li>
                            <li>Full training</li>
                            <li>Operation, Marketing, & Technology support</li>
                            <!-- <li><i class="fas fa-check-circle mytik"></i> &nbsp;Operation, Marketing, & Technology support</li> -->
                        </ul>
                    </div>
                    <div class="col-md-5 ">
                        <img src="assets/img/store/vegi.png" class="img-responsive" />
                    </div>
                </div>

            </div>
        </div>
    </div>

</section>
<section class="mainsec mt-3">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-md-10">
                <div class="formback">
                    <div class="alert alert-primary" role="alert" *ngIf="msgFlag">
                        {{message}}
                    </div>
                    
                    <h3 class="myh3">Register your interest NOW!</h3>
                    <form [formGroup]="form">
                        <div class="row ">
                            <div class="col-md-6">
                                <input formControlName="Contact_Person_Name" type="text" placeholder="Name"
                                    class="form-control">
                                <span class="text-danger"
                                    *ngIf="f.get('Contact_Person_Name').touched && f.get('Contact_Person_Name').invalid &&f.get('Contact_Person_Name').hasError('required')">Name
                                    is required</span>
                            </div>
                            <div class="col-md-6">
                                <input formControlName="Email_Id" type="email" placeholder="Email" class="form-control">
                                <span class="text-danger"
                                    *ngIf="f.get('Email_Id').touched && f.get('Email_Id').invalid &&f.get('Email_Id').hasError('email')">Incorrect
                                    Email</span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <input formControlName="Contact_No_1" placeholder="Contact No" class="form-control"
                                    minlength="10" maxlength="10" (keypress)="numberOnly($event)">
                                <span class="text-danger"
                                    *ngIf="f.get('Contact_No_1').touched && f.get('Contact_No_1').invalid &&f.get('Contact_No_1').hasError('required')">Contact
                                    No
                                    is required</span>
                                <span class="text-danger"
                                    *ngIf="f.get('Contact_No_1').touched && f.get('Contact_No_1').invalid &&f.get('Contact_No_1').hasError('patter')">Contact
                                    No
                                    is Invalid</span>
                            </div>
                            <div class="col-md-6">
                                <input formControlName="Area_Based_In" type="text" placeholder="Area Based In"
                                    class="form-control">
                                <span class="text-danger"
                                    *ngIf="f.get('Area_Based_In').touched && f.get('Contact_Person_Name').invalid &&f.get('Area_Based_In').hasError('required')">Area
                                    is
                                    is required</span>
                            </div>
                        </div>
                        <div class="row mt-3 mb-5">
                            <div class="col-md-6">
                                <input formControlName="Current_Occupation" type="text" placeholder="Current Occupation"
                                    class="form-control">
                                <span class="text-danger"
                                    *ngIf="f.get('Current_Occupation').touched && f.get('Current_Occupation').invalid &&f.get('Current_Occupation').hasError('required')">Occupation
                                    is required</span>
                            </div>
                            <div class="col-md-6">
                                <input formControlName="Other_Information" type="text"
                                    placeholder="Any Other Information" class="form-control">
                            </div>
                        </div>
                        <div class="text-center  mb-2 pb-2">
                            <button
                                class="btn btn--box btn--medium submitbtn btn--dark btn--black btn--black-hover-green font--semi-bold"
                                (click)="onSubmit()">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>