<div style="background: #ececec; border-top: 2px dashed #ea6d14">
  <div class="container pt-2 pb-4">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <app-desifarm-dashboard-side-menu></app-desifarm-dashboard-side-menu>
      </div>
      <!-- //TODO: Working Code  START-->
      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12" *ngIf="userOrderGroup.length == 0">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <h1 class="tab-heading">My Subscription</h1>
              <div class="text-center p-4 pt-0">
                <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-8">
                    <img src="assets/img/dashboard/emptyorder.png" class="img-responsive" />
                  </div>
                </div>

                <h3 class="mt-3" style="font-size: 30px; color: #00679f; font-weight: 500">
                  Empty Orders
                </h3>
                <!-- <p style="font-size: 18px">You have no Orders. Start adding!</p> -->
                <button class="
                      btn
                      btn--box
                      btn--small
                      btn--green
                      btn--green-hover-black
                      btn--uppercase
                      font--semi-bold
                    " routerLink="/">
                  START SHOPPING
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //TODO: Working Code  END-->

      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12" *ngIf="userOrderGroup.length > 0">
        <div class="tab-details">
          <div class="row">
            <div class="col-md-12">
              <h1 class="tab-heading">My Subscriptions</h1>

              <div class="order-holder mb-0" *ngFor="let userOrder of userOrderGroup">
                <div style="background: #e2e0e0; padding: 10px 5px 0px">
                  <div class="row orderheader align-items-center">
                    <div class="col-md-3">
                      <span><b>ID: </b></span>
                      <span>{{ userOrder.Subscription_Order_Id }}</span>
                    </div>
                    <div class="col-md-5">
                      <span><b>Subscription: </b></span>
                      <span *ngIf="userOrder.Subscription_Type == 'Everyday'">Everyday</span>
                      <span *ngIf="userOrder.Subscription_Type != 'Everyday'">Everyday {{userOrder.Interval_Days}}
                        Days</span>
                    </div>
                    <div class="col-md-4">
                      <span><b>Status: </b></span>
                      <span [ngClass]="{
                          'delivered-color':userOrder.Subscription_Current_Status.Order_status == 'Active',
                          'cancelled-color':userOrder.Subscription_Current_Status.Order_status == 'Cancel',
                          'assign-color':userOrder.Subscription_Current_Status.Order_status == 'Pause'
                        }"><b>{{ userOrder.Subscription_Current_Status }}</b></span>
                      &nbsp; &nbsp;
                      <a style="margin-bottom: 5px;" class="
                            btn
                            btn--small
                            btn--transparent
                            btn--border-green
                            btn--border-green-hover-green
                          " (click)="toggleStatusFlag(userOrder)"
                        *ngIf="userOrder.Subscription_Current_Status != 'Cancel'">{{userOrder.Subscription_Current_Status
                        == "Pause" ?
                        "Active" :"Pause"}}</a>
                      <a style="margin-bottom: 5px;" class="
                            btn
                            btn--small
                            btn--transparent
                            btn--border-green
                            btn--border-green-hover-green
                          " (click)="toggleStatusActiveFlag(userOrder)"
                        *ngIf="userOrder.Subscription_Current_Status == 'Cancel'">Active</a>
                    </div>
                  </div>
                  <div class="row order-detail">

                    <div class="col-md-3 firstdiv">
                      <p class="headings"><b>Product Detail</b></p>
                      <p class="info">{{userOrder.Product_Name}}</p>
                      <p class="info">{{userOrder.Package_Qty}} {{userOrder.Unit_Name}}</p>
                      <p class="info">AED {{userOrder.Selling_Amt}}/PC </p>
                      <p class="info">{{userOrder.Order_Qty}} Qty</p>
                    </div>
                    <div class="col-md-4 middlediv">
                      <p class="headings"><b>Subcription Info</b></p>
                      <p class="info">Next Order : &nbsp; {{userOrder.Next_Delivery_Date}}</p>
                      <p class="info">Start On : &nbsp; {{userOrder.Subscription_Start_Date }}</p>
                      <p class="info"
                        *ngIf="userOrder.Subscription_End_Date != null && userOrder.Subscription_End_Date != '' ">End On
                        : &nbsp; {{userOrder.Subscription_End_Date }}</p>
                      <p class="info"
                        *ngIf="userOrder.Subscription_End_Date == '' || userOrder.Subscription_End_Date == null ">End On
                        : &nbsp;</p>

                    </div>
                    <div class="col-md-5 ">
                      <p class="mb-0 headings"><b>Shipping Address</b></p>
                      <p class="info">
                        {{ userOrder.Full_Name }},<br />
                        <span *ngIf="userOrder.Area_Colony_Sreet_Name">
                          {{ userOrder.Area_Colony_Sreet_Name }},</span>
                        <span *ngIf="userOrder.Landmark">
                          {{ userOrder.Landmark }}</span>
                        <span *ngIf="userOrder.Pincode">
                          - {{ userOrder.Pincode }},<br /></span>
                        <span *ngIf="userOrder.City">
                          Emirate : {{ userOrder.City }}</span>
                        <span *ngIf="userOrder?.Customer_State_Name">State :
                          {{ userOrder?.Customer_State_Name }}</span>
                        <span *ngIf="userOrder.Mobile_No_Address"><br />
                          {{ userOrder.Mobile_No_Address }}</span>
                      </p>

                    </div>
                  </div>
                  <div class="row mb-2" style="background-color: #fff;    margin-right: -5px;
                    margin-left: -5px;">
                    <div class="col-md-12 text-center">
                      <a class=" btn btn--small btn--transparent btn--border-green btn--border-green-hover-green " (click)="toggleCancelFlag(userOrder)" *ngIf="userOrder.Subscription_Current_Status != 'Cancel'">Cancel</a>
                      &nbsp; &nbsp;
                      <!-- <a class=" btn btn--small btn--transparent btn--border-green btn--border-green-hover-green">Modify</a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Order Modal Start -->
<ng-container *ngIf="isOrderCancelFlag">
  <div style="
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1050;
        overflow: hidden;
        outline: 0;
        backdrop-filter: blur(1px);
      ">
    <div class="my-location-modal" style="
          position: fixed;
          top: 10%;
          left: 25%;
          width: 53%;
          height: 85%;
          z-index: 1050;
          overflow: hidden;
          outline: 0;
        " role="dialog">
      <div class="modal-content resmodal">
        <div class="modal13" style="overflow: auto">
          <div class="modal-body" style="padding: 0">
            <div class="container-fluid">
              <div class="row locationmodal">
                <div class="row">
                  <div class="col-md-12" style="text-align: right; z-index: 999; margin-top: 11px">
                    <span aria-hidden="true" class="closecart" style="
                          font-size: 40px !important;
                          color: gray !important;
                          text-align: right !important;
                          cursor: pointer;
                        " (click)="onClose()">×</span>
                  </div>
                  <div class="col-md-12">
                    <h3 style="
                          margin-top: -35px;
                          color: #e6531b;
                          font-weight: 500;
                          font-size: 18px;
                          font-family: 'MarlinGeo';
                        ">
                      Are you sure you want to cancel this subscription ?
                    </h3>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="container-fluid pb-2" style="border: 1px solid lightgray; font-family: 'MarlinGeo'">
                    <div class="row" style="
                          background: lightgray;
                          padding-top: 5px;
                          margin-right: -15px;
                          margin-left: -15px;
                        ">
                      <div class="col-md-3">
                        <p>
                          <b>Order No: </b>{{ orderCancelDetail.Subscription_Order_Id }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <p>
                          <b>Order Placed:</b>
                          {{
                          orderCancelDetail.Subscription_Order_Date
                          
                          }}
                          {{
                          orderCancelDetail.Subscription_Order_Date
                          
                          }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Order Status: </b>
                          {{ orderCancelDetail.Subscription_Current_Status }}
                        </p>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-md-12">
                        <form [formGroup]="form">
                          <textarea formControlName="Order_Cancelation_Reason" class="form-control" rows="4" cols="80"
                            placeholder="Reason for cancellation">
                            </textarea>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2 mb-4" style="text-align: right">
                    <a class="btn cancelbtn btn--black btn--black-hover-green" (click)="submit()">Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- Cancel Order Modal End -->

<!-- isOrderStatusFlag Order Modal Start -->
<ng-container *ngIf="isOrderStatusFlag">
  <div style="
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1050;
        overflow: hidden;
        outline: 0;
        backdrop-filter: blur(1px);
      ">
    <div class="my-location-modal" style="
          position: fixed;
          top: 10%;
          left: 25%;
          width: 53%;
          height: 85%;
          z-index: 1050;
          overflow: hidden;
          outline: 0;
        " role="dialog">
      <div class="modal-content resmodal">
        <div class="modal13" style="overflow: auto">
          <div class="modal-body" style="padding: 0">
            <div class="container-fluid">
              <div class="row locationmodal">
                <div class="row">
                  <div class="col-md-12" style="text-align: right; z-index: 999; margin-top: 11px">
                    <span aria-hidden="true" class="closecart" style="
                          font-size: 40px !important;
                          color: gray !important;
                          text-align: right !important;
                          cursor: pointer;
                        " (click)="onStatusClose()">×</span>
                  </div>
                  <div class="col-md-12">
                    <h3 style="
                          margin-top: -35px;
                          color: #e6531b;
                          font-weight: 500;
                          font-size: 18px;
                          font-family: 'MarlinGeo';
                        ">
                      Are you sure you want to change the status to {{ orderCancelDetail.Subscription_Current_Status ==
                      'Active' ? 'Pause' :'Active' }} ?
                    </h3>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="container-fluid pb-2" style="border: 1px solid lightgray; font-family: 'MarlinGeo'">
                    <div class="row" style="
                          background: lightgray;
                          padding-top: 5px;
                          margin-right: -15px;
                          margin-left: -15px;
                        ">
                      <div class="col-md-3">
                        <p>
                          <b>Order No: </b>{{ orderCancelDetail.Subscription_Order_Id }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <p>
                          <b class="text-danger">Next Delivery Date : </b> &nbsp; 
                          <b class="text-danger">{{ orderCancelDetail.Next_Delivery_Date }}</b>
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Order Status: </b>
                          {{ orderCancelDetail.Subscription_Current_Status }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2 mb-4" style="text-align: center">
                    <a class="btn cancelbtn btn--black btn--black-hover-green" (click)="statusChange(true)">Yes</a>
                    &nbsp; &nbsp;&nbsp;
                    <a class="btn cancelbtn btn--black btn--black-hover-green" (click)="statusChange(false)">No</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- isOrderStatusFlag Order Modal End -->

<!-- isOrderStatusFlag Order Modal Start -->
<ng-container *ngIf="isOrderStatusActiveFlag">
  <div style="
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1050;
        overflow: hidden;
        outline: 0;
        backdrop-filter: blur(1px);
      ">
    <div class="my-location-modal" style="
          position: fixed;
          top: 10%;
          left: 25%;
          width: 53%;
          height: 85%;
          z-index: 1050;
          overflow: hidden;
          outline: 0;
        " role="dialog">
      <div class="modal-content resmodal">
        <div class="modal13" style="overflow: auto">
          <div class="modal-body" style="padding: 0">
            <div class="container-fluid">
              <div class="row locationmodal">
                <div class="row">
                  <div class="col-md-12" style="text-align: right; z-index: 999; margin-top: 11px">
                    <span aria-hidden="true" class="closecart" style="
                          font-size: 40px !important;
                          color: gray !important;
                          text-align: right !important;
                          cursor: pointer;
                        " (click)="onStatusActiveClose()">×</span>
                  </div>
                  <div class="col-md-12">
                    <h3 style="
                          margin-top: -35px;
                          color: #e6531b;
                          font-weight: 500;
                          font-size: 18px;
                          font-family: 'MarlinGeo';
                        ">
                      Are you sure you want to change the status to {{ orderCancelDetail.Subscription_Current_Status ==
                      'Active' ? 'Pause' :'Active' }} ?
                    </h3>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="container-fluid pb-2" style="border: 1px solid lightgray; font-family: 'MarlinGeo'">
                    <div class="row" style="
                          background: lightgray;
                          padding-top: 5px;
                          margin-right: -15px;
                          margin-left: -15px;
                        ">
                      <div class="col-md-3">
                        <p>
                          <b>Order No: </b>{{ orderCancelDetail.Subscription_Order_Id }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <p>
                          <b>Order Placed:</b>
                          {{ orderCancelDetail.Subscription_Order_Date }}
                          {{ orderCancelDetail.Subscription_Order_Date }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Order Status: </b>
                          {{ orderCancelDetail.Subscription_Current_Status }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="mt-2 mb-4" style="text-align: center">
                    <a class="btn cancelbtn btn--black btn--black-hover-green"
                      (click)="statusActiveChange(true)">Yes</a>
                    &nbsp; &nbsp;&nbsp;
                    <a class="btn cancelbtn btn--black btn--black-hover-green"
                      (click)="statusActiveChange(false)">No</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- isOrderStatusFlag Order Modal End -->

<!-- <div class="card card-custom" style="display: none">
  <div class="card-body table-body">
    <div class="aaa" id="invoice">
      <br>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <img src="../../../../assets/img/logo/desi.png" alt="" style="width: 82%" />
        </div>
        <div class="col-md-8">
          <h1 style="color: black" style="font-size: 20px;">
            Desifarms
            &nbsp;&nbsp; Call .:&nbsp; +91-9260605353 &nbsp; Whatsapp .:&nbsp; +91-7972242446
          </h1>
          <p>
            SNA Milk & Milk Products Pvt. Ltd. Plot M-25, MIDC Ahmednagar,
            Maharashtra – 414111
          </p>
        </div>
      </div>
      <br />

      <div class="row justify-content-center">
        <div class="col-md-4">
          <p style="color: black">
            To <br>
            <b>{{ printInvoiceData?.Orders[0]?.Full_Name | titlecase}} </b> <br>
            {{ printInvoiceData?.Orders[0]?.Flat_House_No }},
            {{ printInvoiceData?.Orders[0]?.Area_Colony_Sreet_Name }},
            {{ printInvoiceData?.Orders[0]?.Landmark }},
            {{ printInvoiceData?.Orders[0]?.Pincode }} <br />
            Emirate: <b>{{ printInvoiceData?.Orders[0]?.City }}</b> Area : <b> {{
              printInvoiceData?.Orders[0]?.Sub_Area_Name }} {{
              printInvoiceData?.Orders[0]?.Area_Name }}</b> <br>
            <b> Contact No.: {{ printInvoiceData?.Orders[0]?.Mobile_No_Address }}</b> <br>
          </p>
        </div>
        <div class="col-md-2">
        </div>
        <div class="col-md-3">
          <p style="color: black">
            Date: {{ printInvoiceData?.Orders[0]?.Order_Date_Time[0] | date:"dd-MMM-yyyy" }} <br>
            <b>Order No .: {{printInvoiceData?.Orders[0]?.Sale_Order_Id}}</b> <br>
            Payment Mode: <b>{{ printInvoiceData?.Orders[0]?.Payment_Mode }}</b>
          </p>
        </div>
        <div class="col-md-1"></div>
      </div>


      <div class="row justify-content-center">
        <div class="col-md-10">
          <table class="table">
            <tr>
              <td class="text-center">Sr.No</td>
              <td class="text-center">Items</td>
              <td class="text-center">Packing</td>
              <td class="text-center">MRP</td>
              <td class="text-center">Disc %</td>
              <td class="text-center">Disc Amt</td>
              <td class="text-center">Selling Amt</td>
              <td class="text-center">Qty</td>
              <td class="text-center">Net Amt</td>
            </tr>
            <tr *ngFor="let option of printInvoiceData?.Orders; let i = index">
              <td class="text-center">{{ i + 1 }}</td>
              <td class="text-center">{{ option.Product_Name }}</td>
              <td class="text-center">
                {{ option.Package_Qty }} {{ option.Unit_Name }}
              </td>
              <td class="text-center">
                {{ option.Product_Package_MRP }}
              </td>
              <td class="text-center">{{ option.Discount_Per }}</td>
              <td class="text-center">
                {{ option.Total_Discount_Amount  }}
              </td>
              <td class="text-center">
                {{ option.Selling_Amt  }}
              </td>
              <td class="text-center">{{ option.Order_Qty }}</td>
              <td class="text-center">
                {{ option.Order_Qty * option.Selling_Amt  }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-5">
        </div>


        <div class="col-md-5">
          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">Sub Total : {{ Sub_Total_Amount  }}</b>
          </p>

          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">You Save : {{ Total_Discount_Amount }}</b>
          </p>
          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">Delivery Charges :
              {{ Order_Delivery_Charges  }}</b>
          </p>

          <p style="color: black; text-align: right">
            <b style="padding: 5px 6px;">Sub Total + Delivery :
              {{
              SubTotalPlusDeliveryCharges 
              }}</b>
          </p>

          <p style="color: black; text-align: right" *ngIf="printInvoiceData?.Orders[0]?.Wallet_Amount_Used != 0">
            <b style="padding: 5px 6px;">Wallet Redeem (-) :
              {{ printInvoiceData?.Orders[0]?.Wallet_Amount_Used  }}</b>
          </p>


          <p style="color: black; text-align: right;">
            <b style="border: 1px solid black;
              padding: 5px 6px;    font-size: initial;">Payable Amount :
              {{ printInvoiceData?.Order_Amount   }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</div> -->