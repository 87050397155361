<!-- ::::::  Start  Breadcrumb Section  ::::::  -->
<div class="page-breadcrumb">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="page-breadcrumb__menu">
                    <li class="page-breadcrumb__nav"><a routerLink="/">Home</a></li>
                    <li class="page-breadcrumb__nav active">Product Details</li>
                </ul>
            </div>
        </div>
    </div>
</div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

<!-- :::::: Start Main Container Wrapper :::::: -->
<main id="main-container" class="main-container">

    <!-- Start Product Details Gallery -->
    <div class="product-details">
        <div class="container">
            <div class="row">
                <div class="col-md-5">
                    <div class="product-gallery-box product-gallery-box--default m-b-60">
                        <div class="product-image--large product-image--large-horizontal">
                            <img class="img-fluid" id="img-zoom" src="assets/img/product/d3.jpg"
                                data-zoom-image="assets/img/product/d3.jpg"
                                alt="" >
                            <!-- <lib-ngx-image-zoom 
                            thumbImage="assets/img/product/6.jpg" 
                            fullImage="assets/img/product/6.jpg"
                            zoomMode='hover'
                            >
                            </lib-ngx-image-zoom> -->
                            <!-- <ngx-image-zoom 
                            [thumbImage]=myThumbnail [fullImage]=myFullresImage [zoomMode]="'hover'">
                            </ngx-image-zoom> -->
                        </div>
                        <div id="gallery-zoom" class="product-image--thumb product-image--thumb-horizontal pos-relative" appProductSliders>
                            <a class="zoom-active" data-image="assets/img/product/d6.jpg" data-zoom-image="assets/img/product/d6.jpg">
                                <img class="img-fluid" src="assets/img/product/d6.jpg" alt="">
                            </a>
                            <a data-image="assets/img/product/d7.jpg" data-zoom-image="assets/img/product/d7.jpg">
                                <img class="img-fluid" src="assets/img/product/d7.jpg" alt="">
                            </a>
                            <a data-image="assets/img/product/d8.jpg" data-zoom-image="assets/img/product/d8.jpg">
                                <img class="img-fluid" src="assets/img/product/d8.jpg" alt="">
                            </a>
                            <a data-image="assets/img/product/d9.jpg" data-zoom-image="assets/img/product/d9.jpg">
                                <img class="img-fluid" src="assets/img/product/d9.jpg" alt="">
                            </a>
                        </div>

                        <!-- TODO: Working Code for slider -->
                        <!-- <div id="gallery-zoom"
                            class="product-image--thumb product-image--thumb-horizontal pos-relative">
                            <owl-carousel-o [options]="customOptions">
                                <ng-template carouselSlide style="width: 145px !important;">
                                    <a class="zoom-active"
                                        data-image="assets/img/product/gallery/gallery-large/product-gallery-large-1.jpg"
                                        data-zoom-image="assets/img/product/gallery/gallery-large/product-gallery-large-1.jpg">
                                        <img class="img-fluid" src="assets/img/product/6.jpg" alt=""
                                            style="width: 138px;height: 138px;">
                                    </a>
                                </ng-template>
                                <ng-template carouselSlide style="width: 145px !important;">
                                    <a class="zoom-active"
                                        data-image="assets/img/product/gallery/gallery-large/product-gallery-large-1.jpg"
                                        data-zoom-image="assets/img/product/gallery/gallery-large/product-gallery-large-1.jpg">
                                        <img class="img-fluid" src="assets/img/product/6.jpg" alt=""
                                            style="width: 138px;height: 138px;">
                                    </a>
                                </ng-template>
                                <ng-template carouselSlide style="width: 145px !important;">
                                    <a class="zoom-active"
                                        data-image="assets/img/product/gallery/gallery-large/product-gallery-large-1.jpg"
                                        data-zoom-image="assets/img/product/gallery/gallery-large/product-gallery-large-1.jpg">
                                        <img class="img-fluid" src="assets/img/product/6.jpg" alt=""
                                            style="width: 138px;height: 138px;">
                                    </a>
                                </ng-template>
                            </owl-carousel-o>
                        </div> -->
                        <!-- TODO: Working Code for slider -->
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="product-details-box m-b-60">
                        <h4 class="font--regular m-b-20">Fresh Green Apple</h4>
                        
                        <div class="product__price m-t-5">
                            <span class="product__price product__price--large">AED 19.00 <del>AED 29.00</del></span>
                            <span class="product__tag m-l-15 btn--tiny btn--green">-34%</span>
                        </div>

                        <div class="product__desc m-t-25 m-b-30">
                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                                voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint
                                occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                                mollitia animi, id est laborum et dolorum fuga.</p>
                        </div>
                        <div class="product-var p-tb-30">
                            <div class="product__stock m-b-20">
                                <span class="product__stock--in"><i class="fas fa-check-circle"></i> 199 IN STOCK</span>
                            </div>
                            <div class="product-quantity product-var__item">
                                <ul class="product-modal-group">
                                    <li><a href="#modalSizeGuide" data-toggle="modal"
                                            class="link--gray link--icon-left"><i
                                                class="fal fa-money-check-edit"></i>Size Guide</a></li>
                                    <li><a href="#modalShippinginfo" data-toggle="modal"
                                            class="link--gray link--icon-left"><i
                                                class="fal fa-truck-container"></i>Shipping</a></li>
                                    <li><a href="#modalProductAsk" data-toggle="modal"
                                            class="link--gray link--icon-left"><i class="fal fa-envelope"></i>Ask About
                                            This product</a></li>
                                </ul>
                            </div>
                            <div class="product-quantity product-var__item d-flex align-items-center">
                                <span class="product-var__text">Quantity: </span>
                                <form class="quantity-scale m-l-20">
                                    <div class="value-button" id="decrease" onclick="decreaseValue()">-</div>
                                    <input type="number" id="number" value="1" />
                                    <div class="value-button" id="increase" onclick="increaseValue()">+</div>
                                </form>
                            </div>
                            <div class="product-var__item">
                                <a href="#modalAddCart" data-toggle="modal" data-dismiss="modal"
                                    class="btn btn--long btn--radius-tiny btn--green btn--green-hover-black btn--uppercase btn--weight m-r-20">Add
                                    to cart</a>
                               
                            </div>
                            <div class="product-var__item">
                                <div class="dynmiac_checkout--button">
                                    <input type="checkbox" id="buy-now-check" value="1" class="p-r-30">
                                    <label for="buy-now-check" class="m-b-20">I agree with the terms and
                                        condition</label>
                                    <a href="cart.html"
                                        class="btn btn--block btn--long btn--radius-tiny btn--green btn--green-hover-black text-uppercase m-r-35">Buy
                                        It Now</a>
                                </div>
                            </div>
                            <div class="product-var__item">
                                <span class="product-var__text">Guaranteed safe checkout </span>
                                <ul class="payment-icon m-t-5">
                                    <li><img src="assets/img/icon/payment/paypal.svg" alt=""></li>
                                    <li><img src="assets/img/icon/payment/amex.svg" alt=""></li>
                                    <li><img src="assets/img/icon/payment/ipay.svg" alt=""></li>
                                    <li><img src="assets/img/icon/payment/visa.svg" alt=""></li>
                                    <li><img src="assets/img/icon/payment/shoify.svg" alt=""></li>
                                    <li><img src="assets/img/icon/payment/mastercard.svg" alt=""></li>
                                    <li><img src="assets/img/icon/payment/gpay.svg" alt=""></li>
                                </ul>
                            </div>
                            <div class="product-var__item d-flex align-items-center">
                                <span class="product-var__text">Share: </span>
                                <ul class="product-social m-l-20">
                                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Product Details Gallery -->

    <!-- Start Product Details Tab -->
    <div class="product-details-tab-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="product-details-content">
                        <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-30 nav">
                            <li><a class="nav-link active" data-toggle="tab" href="#product-desc">Description</a></li>
                            <li><a class="nav-link" data-toggle="tab" href="#product-dis">Product Details</a></li>
                            <li><a class="nav-link" data-toggle="tab" href="#product-review">Reviews</a></li>
                        </ul>
                        <div class="product-details-tab-box">
                            <div class="tab-content">
                                <!-- Start Tab - Product Description -->
                                <div class="tab-pane show active" id="product-desc">
                                    <div class="para__content">
                                        <p class="para__text">Use the Canon VIXIA GX10 Camcorder to capture UHD 4K video
                                            at 60 fps, recording in MP4 to dual SD memory card slots. This camcorder
                                            packs several pro-style features into its compact form, including Dual-Pixel
                                            Autofocus (DPAF). The GX10's 1" 8.29MP CMOS sensor and dual DIGIC DV 6 image
                                            processors support Wide DR Gamma with high sensitivity and low noise. Slow
                                            and fast-motion recording up to 120 fps offers special looks for
                                            highlighting sports and other special events. Smooth, steady shooting is
                                            assisted by the GX10's five-axis optical image stabilization. For composing
                                            and viewing your footage, the VIXIA GX10 incorporates a flip-out 3.5"
                                            touchscreen LCD, and a 0.24" electronic viewfinder. </p>
                                        <p class="para__text">Additional GX10 features include an HDMI 2.0 port for
                                            outputting your 4K UHD footage, assignable user buttons, and remote control
                                            using the included WL-D89 controller. Wi-Fi connectivity offers live
                                            streaming, FTP file sharing, and remote control via iOS and Android apps.
                                        </p>
                                        <h6 class="para__title">Product Highlights:</h6>
                                        <ul class="para__list">
                                            <li>UHD 4K Output up to 60 fps</li>
                                            <li>8.29MP, 1" CMOS Sensor</li>
                                            <li>Dual-Pixel CMOS Autofocus Feature</li>
                                            <li>Integrated 15x Optical Zoom Lens</li>
                                            <li>2 x DIGIC DV 6 Processors</li>
                                            <li>5-Axis Optical Image Stabilization</li>
                                            <li>Wide Dynamic Range Support</li>
                                            <li>Records 4K UHD/HD to Dual SD Card Slots</li>
                                            <li>3.5" Touchscreen LCD &amp; 0.24" EVF</li>
                                            <li>Live Stream/FTP/Remote App via Wi-Fi</li>
                                        </ul>
                                    </div>
                                </div> <!-- End Tab - Product Description -->

                                <!-- Start Tab - Product Details -->
                                <div class="tab-pane" id="product-dis">
                                    <div class="product-dis__content">
                                        <a href="#" class="product-dis__img m-b-30"><img
                                                src="assets/img/logo/another-logo.jpg" alt=""></a>
                                        <div class="table-responsive-md">
                                            <table class="product-dis__list table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td class="product-dis__title">Weight</td>
                                                        <td class="product-dis__text">400 g</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="product-dis__title">Materials</td>
                                                        <td class="product-dis__text">60% cotton, 40% polyester</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="product-dis__title">Dimensions</td>
                                                        <td class="product-dis__text">10 x 10 x 15 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="product-dis__title">Other Info</td>
                                                        <td class="product-dis__text">American heirloom jean shorts pug
                                                            seitan letterpress</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> <!-- End Tab - Product Details -->

                                <!-- Start Tab - Product Review -->
                                <div class="tab-pane " id="product-review">
                                    <!-- Start - Review Comment -->
                                    <ul class="comment">
                                        <!-- Start - Review Comment list-->
                                        <li class="comment__list">
                                            <div class="comment__wrapper">
                                                <div class="comment__img">
                                                    <img src="assets/img/user/image-1.png" alt="">
                                                </div>
                                                <div class="comment__content">
                                                    <div class="comment__content-top">
                                                        <div class="comment__content-left">
                                                            <h6 class="comment__name">Kaedyn Fraser</h6>
                                                            <ul class="product__review">
                                                                <li class="product__review--fill"><i
                                                                        class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i
                                                                        class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i
                                                                        class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i
                                                                        class="icon-star"></i></li>
                                                                <li class="product__review--blank"><i
                                                                        class="icon-star"></i></li>
                                                            </ul>
                                                        </div>
                                                        <div class="comment__content-right">
                                                            <a href="#" class="link--gray link--icon-left m-b-5"><i
                                                                    class="fas fa-reply"></i>Reply</a>
                                                        </div>
                                                    </div>

                                                    <div class="para__content">
                                                        <p class="para__text">Lorem ipsum dolor sit amet, consectetur
                                                            adipisicing elit. Tempora inventore dolorem a unde modi iste
                                                            odio amet, fugit fuga aliquam, voluptatem maiores animi
                                                            dolor nulla magnam ea! Dignissimos aspernatur cumque nam
                                                            quod sint provident modi alias culpa, inventore deserunt
                                                            accusantium amet earum soluta consequatur quasi eum eius
                                                            laboriosam, maiores praesentium explicabo enim dolores
                                                            quaerat! Voluptas ad ullam quia odio sint sunt. Ipsam
                                                            officia, saepe repellat. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Start - Review Comment Reply-->
                                            <ul class="comment__reply">
                                                <li class="comment__reply-list">
                                                    <div class="comment__wrapper">
                                                        <div class="comment__img">
                                                            <img src="assets/img/user/image-2.png" alt="">
                                                        </div>
                                                        <div class="comment__content">
                                                            <div class="comment__content-top">
                                                                <div class="comment__content-left">
                                                                    <h6 class="comment__name">Oaklee Odom</h6>
                                                                </div>
                                                                <div class="comment__content-right">
                                                                    <a href="#"
                                                                        class="link--gray link--icon-left m-b-5"><i
                                                                            class="fas fa-reply"></i>Reply</a>
                                                                </div>
                                                            </div>

                                                            <div class="para__content">
                                                                <p class="para__text">Lorem ipsum dolor sit amet,
                                                                    consectetur adipisicing elit. Tempora inventore
                                                                    dolorem a unde modi iste odio amet, fugit fuga
                                                                    aliquam, voluptatem maiores animi dolor nulla magnam
                                                                    ea! Dignissimos aspernatur cumque nam quod sint
                                                                    provident modi alias culpa, inventore deserunt
                                                                    accusantium amet earum soluta consequatur quasi eum
                                                                    eius laboriosam, maiores praesentium explicabo enim
                                                                    dolores quaerat! Voluptas ad ullam quia odio sint
                                                                    sunt. Ipsam officia, saepe repellat. </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul> <!-- End - Review Comment Reply-->
                                        </li> <!-- End - Review Comment list-->
                                        <!-- Start - Review Comment list-->
                                        <li class="comment__list">
                                            <div class="comment__wrapper">
                                                <div class="comment__img">
                                                    <img src="assets/img/user/image-3.png" alt="">
                                                </div>
                                                <div class="comment__content">
                                                    <div class="comment__content-top">
                                                        <div class="comment__content-left">
                                                            <h6 class="comment__name">Jaydin Jones</h6>
                                                            <ul class="product__review">
                                                                <li class="product__review--fill"><i
                                                                        class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i
                                                                        class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i
                                                                        class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i
                                                                        class="icon-star"></i></li>
                                                                <li class="product__review--blank"><i
                                                                        class="icon-star"></i></li>
                                                            </ul>
                                                        </div>
                                                        <div class="comment__content-right">
                                                            <a href="#" class="link--gray link--icon-left m-b-5"><i
                                                                    class="fas fa-reply"></i>Reply</a>
                                                        </div>
                                                    </div>

                                                    <div class="para__content">
                                                        <p class="para__text">Lorem ipsum dolor sit amet, consectetur
                                                            adipisicing elit. Tempora inventore dolorem a unde modi iste
                                                            odio amet, fugit fuga aliquam, voluptatem maiores animi
                                                            dolor nulla magnam ea! Dignissimos aspernatur cumque nam
                                                            quod sint provident modi alias culpa, inventore deserunt
                                                            accusantium amet earum soluta consequatur quasi eum eius
                                                            laboriosam, maiores praesentium explicabo enim dolores
                                                            quaerat! Voluptas ad ullam quia odio sint sunt. Ipsam
                                                            officia, saepe repellat. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> <!-- End - Review Comment list-->
                                    </ul> <!-- End - Review Comment -->

                                    <!-- Start Add Review Form-->
                                    <div class="review-form m-t-40">
                                        <div class="section-content">
                                            <h6 class="font--bold text-uppercase">ADD A REVIEW</h6>
                                            <p class="section-content__desc">Your email address will not be published.
                                                Required fields are marked *</p>
                                        </div>
                                        <form class="form-box" action="#" method="post">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-box__single-group">
                                                        <label for="form-name">Your Rating*</label>
                                                        <ul class="product__review">
                                                            <li class="product__review--fill"><i class="icon-star"></i>
                                                            </li>
                                                            <li class="product__review--fill"><i class="icon-star"></i>
                                                            </li>
                                                            <li class="product__review--fill"><i class="icon-star"></i>
                                                            </li>
                                                            <li class="product__review--fill"><i class="icon-star"></i>
                                                            </li>
                                                            <li class="product__review--blank"><i class="icon-star"></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-box__single-group">
                                                        <label for="form-name">Your Name*</label>
                                                        <input type="text" id="form-name" placeholder="Enter your name">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-box__single-group">
                                                        <label for="form-email">Your Email*</label>
                                                        <input type="email" id="form-email"
                                                            placeholder="Enter your email" required>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-box__single-group">
                                                        <label for="form-review">Your review*</label>
                                                        <textarea id="form-review" rows="8"
                                                            placeholder="Write a review"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <button
                                                        class="btn btn--box btn--small btn--black btn--black-hover-green btn--uppercase font--bold m-t-30"
                                                        type="submit">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div> <!-- End Add Review Form- -->
                                </div> <!-- Start Tab - Product Review -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- End Product Details Tab -->

    <!-- ::::::  Start  Product Style - Default Section  ::::::  -->
    <div class="product m-t-100">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Start Section Title -->
                    <div class="section-content section-content--border m-b-35">
                        <h5 class="section-content__title">Related Product
                        </h5>
                        <a routerLink="/home/shopallitems"
                            class="btn btn--icon-left btn--small btn--radius btn--transparent btn--border-green btn--border-green-hover-green font--regular text-capitalize">More
                            Products<i class="fal fa-angle-right"></i></a>
                    </div> <!-- End Section Title -->
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="default-slider default-slider--hover-bg-red product-default-slider">
                        <div class="product-default-slider-4grid-1rows gap__col--30 gap__row--40" appNewProduct>

                            <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                            <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                            <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                            <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                           <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                            <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                           <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                            <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->

                           <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                            <!-- Start Single Default Product -->
                        <div class="product__box product__default--single pl-3 pr-3 pt-2 pb-2">
                            <!-- Start Product Image -->
                            <div class="pos-relative text-center">
                                <a routerLink="/home/shopallitems" class="product__img--link ">
                                    <img class="product__img img-fluid" src="assets/img/product/mango_alphonso_1__1.png" alt="">
                                </a>
                                <!-- Start Procuct Label -->
                                <span class="product__label product__label--sale-dis">-34%</span>
                                <!-- End Procuct Label -->
                                <!-- Start Product Action Link-->
                                <ul class="product__action--link pos-absolute">
                                    <li><a href="#modalAddCart" data-toggle="modal"><i class="icon-shopping-cart"></i></a></li>
                                    <li><a href="#modalQuickView" data-toggle="modal"><i class="icon-eye"></i></a></li>
                                </ul> <!-- End Product Action Link -->
                            </div>    
                            <!-- Start Product Content -->
                            <div class="product__content">
                                <p class="mb-0 graycolor" style="font-size: 16px;">Desi Farms</p>
                                <a routerLink="/home/shopallitems" class="product__link mt-0">Fresh green vegetable</a>
                                <div class="product__price m-t-5">
                                    <span class="product__price">AED 19.00 <del>MRP 29.00</del></span>
                                </div>
                                <select class="mt-2 mb-2">
                                    <option>6 PC</option>
                                    <option>6 PC</option>
                                </select>
                                <div class="product-quantity  d-flex align-items-center flex-wrap">
                                    <span class="product-var__text graycolor">Quantity: </span>
                                    <form class="modal-quantity-scale m-l-20">
                                        <div class="value-button" id="modal-decrease"
                                            onclick="decreaseValueModal()">-</div>
                                        <input type="number" id="modal-number" value="1" />
                                        <div class="value-button" id="modal-increase"
                                            onclick="increaseValueModal()">+</div>
                                    </form>
                                </div>
                               <div class="text-center mt-2"><a href="#modalAddCart" class ="btn  addtocart btn--black btn--black-hover-green font--bold text-uppercase" data-toggle="modal">ADD TO CART</a></div>      
                            </div> <!-- End Product Content -->
                        </div> <!-- End Single Default Product -->
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- ::::::  End  Product Style - Default Section  ::::::  -->

   <!-- ::::::  Start  Company Logo Section  ::::::  -->
   <div class="company-logo m-t-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="company-logo__area default-slider" appCompanySlider>
                    <!-- Start Single Company Logo Item -->
                    <div class="company-logo__item">
                        <a href="#" class="company-logo__link">
                            <img src="assets/img/company-logo/company-logo-1.png" alt="" class="company-logo__img">
                        </a>
                    </div> <!-- End Single Company Logo Item -->
                    <!-- Start Single Company Logo Item -->
                    <div class="company-logo__item">
                        <a href="#" class="company-logo__link">
                            <img src="assets/img/company-logo/company-logo-2.png" alt="" class="company-logo__img">
                        </a>
                    </div> <!-- End Single Company Logo Item -->
                    <!-- Start Single Company Logo Item -->
                    <div class="company-logo__item">
                        <a href="#" class="company-logo__link">
                            <img src="assets/img/company-logo/company-logo-3.png" alt="" class="company-logo__img">
                        </a>
                    </div> <!-- End Single Company Logo Item -->
                    <!-- Start Single Company Logo Item -->
                    <div class="company-logo__item">
                        <a href="#" class="company-logo__link">
                            <img src="assets/img/company-logo/company-logo-4.png" alt="" class="company-logo__img">
                        </a>
                    </div> <!-- End Single Company Logo Item -->
                    <!-- Start Single Company Logo Item -->
                    <div class="company-logo__item">
                        <a href="#" class="company-logo__link">
                            <img src="assets/img/company-logo/company-logo-5.png" alt="" class="company-logo__img">
                        </a>
                    </div> <!-- End Single Company Logo Item -->
                    <!-- Start Single Company Logo Item -->
                    <div class="company-logo__item">
                        <a href="#" class="company-logo__link">
                            <img src="assets/img/company-logo/company-logo-6.png" alt="" class="company-logo__img">
                        </a>
                    </div> <!-- End Single Company Logo Item -->
                    <!-- Start Single Company Logo Item -->
                    <div class="company-logo__item">
                        <a href="#" class="company-logo__link">
                            <img src="assets/img/company-logo/company-logo-7.png" alt="" class="company-logo__img">
                        </a>
                    </div> <!-- End Single Company Logo Item -->
                </div>
            </div>
        </div>
    </div>
</div> 
<!-- ::::::  End  Company Logo Section  ::::::  -->

</main> <!-- :::::: End MainContainer Wrapper :::::: -->


<!-- Start Modal Add cart -->
<div class="modal fade" id="modalAddCart" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col text-right">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"> <i class="fal fa-times"></i></span>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="modal__product-img">
                                        <img class="img-fluid" src="assets/img/product/d3.jpg" alt="">
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="link--green link--icon-left"><i class="fal fa-check-square"></i>Added to
                                        cart successfully!</div>
                                    <div class="modal__product-cart-buttons m-tb-15">
                                        <a routerLink="/home/mycart"
                                            class="btn btn--box  btn--tiny btn--green btn--green-hover-black btn--uppercase">View
                                            Cart</a>
                                        <a routerLink="/home/checkout"
                                            class="btn btn--box  btn--tiny btn--green btn--green-hover-black btn--uppercaset">Checkout</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 modal__border">
                            <ul class="modal__product-shipping-info">
                                <li class="link--icon-left"><i class="icon-shopping-cart"></i> There Are 5 Items In Your
                                    Cart.</li>
                                <li>TOTAL PRICE: <span>AED 187.00</span></li>
                                <li><a href="index.html" class="btn text-underline color-green"
                                        data-dismiss="modal">CONTINUE
                                        SHOPPING</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> <!-- End Modal Add cart -->

<!-- Start Modal Quickview cart -->
<div class="modal fade" id="modalQuickView" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col text-right">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"> <i class="fal fa-times"></i></span>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="product-gallery-box m-b-60">
                                <div class="modal-product-image--large">
                                    <img class="img-fluid" src="assets/img/product/d5.jpg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="product-details-box">
                                <h5 class="title title--normal m-b-20">Aliquam lobortis</h5>
                                <div class="product__price">
                                    <span class="product__price-del">AED 35.90</span>
                                    <span class="product__price-reg">AED 31.69</span>
                                </div>
                               
                                <div class="product__desc m-t-25 m-b-30">
                                    <p>On the other hand, we denounce with righteous indignation and dislike men who are
                                        so beguiled and demoralized by the charms of pleasure of the moment, so blinded
                                        by desire, that they cannot foresee the pain and trouble that are bound to
                                        ensue; and equal blame belongs to those who fail in their duty through weakness
                                        of will</p>
                                </div>

                                <div class="product-var p-t-30">
                                    <div class="product-quantity product-var__item d-flex align-items-center flex-wrap">
                                        <span class="product-var__text">Quantity: </span>
                                        <form class="modal-quantity-scale m-l-20">
                                            <div class="value-button" id="modal-decrease"
                                                onclick="decreaseValueModal()">-</div>
                                            <input type="number" id="modal-number" value="1" />
                                            <div class="value-button" id="modal-increase"
                                                onclick="increaseValueModal()">+</div>
                                        </form>
                                    </div>
                                </div>

                                <div class="product-links">
                                    <div class="product-social m-tb-30">
                                        <span>SHARE THIS PRODUCT</span>
                                        <ul class="product-social-link">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> <!-- End Modal Quickview cart -->